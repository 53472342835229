import { React, useState, useEffect } from "react";
import { Box, Card, CardHeader, CardContent, Grid } from "@mui/material";
import OnboardStyles from './OnboardStyles'
import Text from "../../components/customText/Text";
import { validate_emptyField, validate_zipcode, validate_city, validate_charWithSpace, validate_usContact, isValidMulti, address_validation, validate_empty_address } from "../../components/Validation";
import { addErrorMsg, addLoader, addWarningMsg, removeLoader } from "../../utils/utils";
import onBoardLinkApi from "../../apis/onBoardLink/onBoardLinkApi";
import warning from '../../assets/svg/warning.svg';
import Input from '../../components/input/Input';
import Button from '../../components/customButton/Button';
import Select from '../../components/select/Select';
import onBoardLinkApiInstance from "../../apis/onBoardLink/onBoardLinkApi";
import LocalStorage from "../../utils/LocalStorage";
import { domain } from "../../config/Domain";

function EmergencyContactInformation({ setMainStep, mainStep, state, setState, id, getInviteViaLink }) {
    const [erro, setErro] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [stateList1, setStateList1] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const classes = OnboardStyles();

    const [dummyState, setDummyState] = useState([
        {
            relationship_id: '',
            relation_name: null,
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            state_name: null,
            country_id: '',
            country_name: null,
            email_id: ''
        },
        {
            relationship_id: '',
            relation_name: null,
            name: '',
            mobile_number: '',
            address_1: '',
            address_2: '',
            city: '',
            zip_code: '',
            state_id: '',
            state_name: null,
            country_id: '',
            country_name: null,
            email_id: ''
        },
    ])

    const changeHandler = (e, index) => {
        // console.log(index, "index");
        if (e.target.name == "mobile_number") {
            convertFormat(e, index);
        }
        else if (e.target.name == "country_id") {
            dummyState[index][e.target.name] = e.target.value
            dummyState[index]['state_id'] = ''
            dummyState[index]['zip_code'] = ''
            if (e.target.value !== '') {
                statesListApi(e.target.value, index);
            }
        } else {
            dummyState[index][e.target.name] = e.target.value
        }
        setDummyState([...dummyState])
        handleValidateContact(e, index);
    };

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' ? 6 : 5)) {
            dummyState[index][e.target.name] = `${first}-${middle}-${last}`
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
        else if (input.length > 3) {
            dummyState[index][e.target.name] = `${first}-${middle}`
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
        else if (input.length >= 0) {
            dummyState[index][e.target.name] = input
            setDummyState([...dummyState], handleValidateContact(e, index))
        }
    }

    const handleValidateContact = (e, index) => {
        let input = e.target;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "mobile_number":
                error.mobile_number = validate_usContact(input.value, "Mobile number");
                break;
            case "relationship_id":
                error.relationship_id = validate_emptyField(input.value);
                break;
            case "address_1":
                error.address_1 = address_validation(input.value);
                break;
            case "address_2":
                error.address_2 = validate_empty_address(input.value);
                break;
            case "name":
                error.name = validate_charWithSpace(input.value);
                break;
            case "validate_emptyField":
                error.validate_emptyField = validate_emptyField(input.value);
                break;
            case "city":
                error.city = validate_city(input.value);
                break;
            case "zip_code":
                error.zip_code = validate_zipcode(input.value, dummyState[index].country_id);
                break;
            case "country_id":
                error.country_id = validate_emptyField(input.value);
                break;
            case "state_id":
                error.state_id = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setErro(s2);
    };

    useEffect(() => {
        getCountryList();
        getRelationList();
        // eslint-disable-next-line
    }, []);


    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImI5ZjQ2ZWNmLTk0ZGItNDRhNC04YmQyLTQyMDhhMTQ4MGRhNSIsImlzX3N1cGVyX2FkbWluIjpmYWxzZSwidXNlck5hbWUiOiJtZWdoYXJhaiBwbiIsInRlbmFudF9pZCI6ImI3OTQzZGE2LWRiMWYtNDdlZi04YjMzLWRlMjk4MmMxZTI3MiIsInN1YmRvbWFpbl9uYW1lIjoibmV3c2NyZWVuIiwicm9sZV9pZCI6MiwiaWF0IjoxNzA1OTI4Mzg5LCJleHAiOjE3MDYwMTQ3ODl9.GjX597fbmB-kAL9alSqdpV7JfqkrnFEmb03VJPD_Y30";

    const getRelationList = () => {
        onBoardLinkApi.relation().then((res) => {
            if (res.data.statusCode === 1003) {
                setRelationList(res.data.data);
            } else {
                addErrorMsg(res.data.message);
            }
        });
    }

    const getCountryList = () => {
        onBoardLinkApi.getCountryList().then((res) => {
            if (res.data.statusCode === 1003) {
                setCountryList(res.data.data);
            } else {
                addErrorMsg(res.data.message);
            }
        });
    }

    const statesListApi = (args, index) => {
        onBoardLinkApi.getStatesList(args).then((res) => {
            if (res.data.statusCode === 1003) {
                if (index == 0) {
                    setStateList(res.data.data);
                }
                else {
                    setStateList1(res.data.data);
                }
            } else {
                addErrorMsg(res.data.message);
            }
        });
    }

    const handleSubmit = () => {
        let errors = validateAll();
        if (isValidMulti(errors)) {
            state['subdomain_name'] = domain
            state['request_id'] = LocalStorage.uid();
            state.emergency_contacts = dummyState
            state['final'] = true
            setState({ ...state })
            addLoader(true)
            onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
                removeLoader()
                if (res.data.statusCode == 1003) {
                    getInviteViaLink(id);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        }
        else {
            removeLoader()
            let s1 = { erro }
            s1 = errors;
            setErro(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    };

    const validateAll = () => {
        let errorsus = {};
        let err = [];
        dummyState.map((value) => {
            errorsus = {};
            errorsus.name = validate_charWithSpace(value.name);
            errorsus.relationship_id = validate_emptyField(value.relationship_id);
            errorsus.address_1 = address_validation(value.address_1);
            errorsus.address_2 = validate_empty_address(value.address_2);
            errorsus.mobile_number = validate_usContact(value.mobile_number, "Mobile Number");
            errorsus.city = validate_city(value.city);
            errorsus.zip_code = validate_zipcode(value.zip_code, value.country_id);
            errorsus.country_id = validate_emptyField(value.country_id);
            errorsus.state_id = validate_emptyField(value.state_id);
            setErro(err);
            return err.push(errorsus);
        });
        return err;
    }

    return (
        <Grid container lg={9} justifyContent='center'>
            <Card sx={{ boxShadow: '0px 2px 24px 0px #919EAB1F', borderRadius: '8px', padding: '10px 20px' }}>
                <CardHeader
                    title={
                        <Box display='flex' flexDirection='row' gap={1.5} className={classes.information}>
                            <Box pt={'5px'}>
                                <img src={warning} alt="warning" />
                            </Box>
                            <Box>
                                <Text mediumOrangeRehire>Please provide information of 2 of your emergency contacts.</Text>
                            </Box>
                        </Box>}
                />
                <CardContent sx={{ textAlign: 'start', height: '400px', overflowY: 'scroll' }}>
                    {
                        dummyState.map((item, index) => (
                            <><Text headerBlack sx={{ paddingTop: '10px' }}>Emergency Contact Details - {index + 1}</Text>
                                <Grid container spacing={3} mt={2} mb={2}>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'name',
                                                value: item.name,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            labelText='Name'
                                            error={erro.length > 0 && erro[index] ? erro[index].name : ''} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].name : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'mobile_number',
                                                value: item.mobile_number,
                                                inputProps: { maxLength: 12 },
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            labelText='Mobile Number'
                                            error={erro.length > 0 && erro[index] ? erro[index].mobile_number : ""} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].mobile_number : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Select
                                            name='relationship_id'
                                            value={item.relationship_id}
                                            onChange={(e) => changeHandler(e, index)}
                                            options={relationList}
                                            label={'Relation'}
                                            error={erro.length > 0 && erro[index] ? erro[index].relationship_id : ""}
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].relationship_id : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_1',
                                                value: item.address_1,
                                                inputProps: { maxLength: 225 }
                                            }}
                                            clientInput
                                            handleChange={(e) => changeHandler(e, index)}
                                            labelText='Address Line 1'
                                            error={erro.length > 0 && erro[index] ? erro[index].address_1 : ""} />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].address_1 : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'address_2',
                                                value: item.address_2,
                                                inputProps: { maxLength: 225 }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            labelText='Address Line 2 (Optional)'
                                            error={erro.length > 0 && erro[index] ? erro[index].address_2 : ""} />
                                            {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].address_2 : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Select
                                            name='country_id'
                                            value={item.country_id}
                                            onChange={(e) => changeHandler(e, index)}
                                            options={countryList}
                                            label={'Country'}
                                            error={erro.length > 0 && erro[index] ? erro[index].country_id : ""}
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].country_id : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Select
                                            name='state_id'
                                            value={item.state_id}
                                            onChange={(e) => changeHandler(e, index)}
                                            options={index == 0 ? stateList : stateList1}
                                            label={'State'}
                                            error={erro.length > 0 && erro[index] ? erro[index].state_id : ""}
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].state_id : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'city',
                                                value: item.city,
                                                inputProps: { maxLength: 50 }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            error={erro.length > 0 && erro[index] ? erro[index].city : ""}
                                            labelText='City'
                                        />
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].city : ""}</Text>) : ''}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'zip_code',
                                                value: item.zip_code,
                                                disabled: item.country_id === '',
                                                inputProps: { maxLength: item.country_id == 1 ? 6 : 5 }
                                            }}
                                            handleChange={(e) => changeHandler(e, index)}
                                            clientInput
                                            error={erro.length > 0 && erro[index] ? erro[index].zip_code : ""}
                                            labelText={item.country_id == 1 ? 'Pincode' : 'Zipcode'}
                                        />
                                        {/*{ console.log(erro, 'erro')}*/}
                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].zip_code : ""}</Text>) : ''}
                                    </Grid>
                                </Grid></>
                        ))
                    }
                </CardContent>
            </Card>
            <Grid item container lg={12} justifyContent='space-between' pt={3}>
                <Button blackCancel onClick={() => setMainStep(mainStep - 1)}>Back</Button>
                <Button saveNcontinueSmall onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
    )

}

export default EmergencyContactInformation;