import { makeStyles } from "@mui/styles";
import { btnBgGrey, } from "../../../../theme";

const TimeSheetStyles = makeStyles(() => ({

    // Breadcrumbs Styles
    breadcrumbsName: {
        fontFamily: "Averta !important",
        fontWeight: "600 !important",
        color: "#092333 !important",
        fontSize: "14px !important",
    },

    breadcrumbsLink: {
        fontFamily: "Averta !important",
        fontWeight: "600 !important",
        color: "#849199 !important",
        fontSize: "14px !important",
        textDecoration: "none !important",
        cursor: "pointer !important",
    },
    // Breadcrumbs Styles End
    sideTooltip: {
        height: "30px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
    },

    sideTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Averta",
        fontWeight: "400",
        color: "#FFFFFF",
    },

    textarea: {
        width: "100% !important",
        font: "14px Averta !important",
        fontWeight: "400 !important",
        color: `${btnBgGrey.shade6} !important`,
    },


    cardCount: {
        fontFamily: "Averta !important",
        fontWeight: "600 !important",
        color: "#171717 !important",
        fontSize: "40px !important",
    },

    cardText: {
        fontFamily: "Averta !important",
        fontWeight: "500 !important",
        color: "#171717 !important",
        fontSize: "16px !important",
    },

    card: {
        padding: "10px",
        height: "160px",
        borderRadius: "12px",
        cursor: "pointer",
        border: "2px solid #FFFFFF",
    },

    cardHead: {
        width: "100%", height: "75px", display: "flex", justifyContent: "space-between", alignItems: "center"
    },

    total_pending_timesheets: {
        backgroundColor: "#FFF5E4"
    },
    total_pending_timesheets_active: {
        border: "2px solid #F9A71E",
    },
    total_pending_approval_timesheets: {
        backgroundColor: "#E7E0FE"
    },
    total_placements_active: {
        border: "2px solid #0C75EB !important",
    },
    active_placements_active: {
        border: "2px solid #8763FC !important",
    },
    ending_in_placements_active: {
        border: "2px solid #F8B525 !important",
    },
    ended_placements_active: {
        border: "2px solid #2BBA35 !important",
    },
    total_pending_approval_timesheets_active: {
        border: "2px solid #8763FC",
    },
    total_invoice_ready_timesheets: {
        backgroundColor: "#77D2B71A"
    },
    total_invoice_ready_timesheets_active: {
        border: "2px solid #77D2B7",
    },
    // total_invoiced_timesheets: {
    //     // cursor:"default",
    //     backgroundColor: "#0C75EB1A"
    // },
    // total_invoiced_timesheets_active: {
    //     border: "2px solid #0C75EB",
    // },
    total_timesheets: {
        // cursor:"default",
        backgroundColor: "#0C75EB1A"
    },
    total_timesheets_active: {
        border: "2px solid #0C75EB",
    },
    cardBody: {
        width: "100%", height: "75px", display: "flex", alignItems: "center"
    },

    moreiconBox: {
        height: "48px"
    },

    primarytext: {
        fontSize: "22px !important",
        fontFamily: "Averta !important",
        display: "flex !important",
        alignSelf: "center !important",
        fontWeight: "600 !important",
        color: "rgba(38, 38, 38, 1) !important"
    },
    primarytext2: {
        fontSize: "18px !important",
        fontFamily: "Averta !important",
        display: "flex !important",
        alignSelf: "center !important",
        fontWeight: "500 !important",
        color: "rgba(38, 38, 38, 1) !important"
    },

    secondarytext: {
        fontSize: "14px !important",
        fontFamily: "Averta !important",
        fontWeight: "500 !important",
        color: "rgba(115, 115, 115, 1) !important"
    },

    searchInput: {
        border: "none",
        padding: "0px 0px 0px 10px",
        width: "215px",
        height: "100%",
        background: "transparent",
        color: "rgba(38, 38, 38, 1)",
        fontFamily: "Averta",
        fontSize: "12px",
        fontWeight: "600",
        transition: "all .3s ease",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1)",
        },
        '&:focus': {
            outline: "none"
        }
    },
    employeViewContainer: {
        width: "100%",
        height: "120px",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "12px",
        boxShadow: "0px 0px 2.0015923976898193px 0px #919EAB4D, 0px 2.0015923976898193px 24.019107818603516px 0px #919EAB1F ",
        "@media (min-width:1200px) and (max-width:1400px)": {
            height: "160px !important",
        },
    },

    employeViewBox: {
        width: "100%",
        height: '80px'
    },
    flexAlineCenter: {
        display: 'flex',
        alignItems: "center"
    },
    placementCard: {
        padding: "10px",
        height: "160px",
        borderRadius: "12px",
        cursor: "pointer",
        border: "2px solid #F2F2F2",
    },

    step: {
        display: 'flex',
        marginBottom: '2px',
    },
    stepLabel: {
        flexBasis: '120px', // Adjust the width of the step label
    },
    stepContent: {
        flex: 1,
        paddingLeft: '1px', // Adjust spacing as needed
        position: 'relative',
    },
    connector: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '2px', // Adjust the width of the connector
        backgroundColor: '#ccc', // Adjust the color of the connector
    },
    dropzoneMainBox: {
        width: '100%',
        height: "15vh",
    },
    dropBox: {
        width: '100%',
        minHeight: "15vh",
        display: 'inline-block',
        backgroundColor: "#F2F7FF",
        border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
        cursor: "pointer"
    },
    dropZone: {
        background: '#F0F7FF',
        padding: '20px',
        borderRadius: '12px'
    },

    btnStyles: {
        width: "100px !important", height: "48px !important", font: "16px Averta !important", fontWeight: `${700} !important`
    },
    aiButtonBeta: {
        width: "42px", height: "20px", borderRadius: "60px", padding: "4px 7px", color: "#ffffff", background: 'linear-gradient(113.4deg, #E88CFF 0%, #1A30FF 100%)', font: "10px Averta !important", fontWeight: `${600} !important`, textAlign: "center"
    },
    profileTooltipText: {
        fontSize: "12px !important",
        fontFamily: "Averta !important",
        fontWeight: "400 !important",
        color: "#FFFFFF !important",
    },
    statusBox: {
        width: "150px", height: "27px", background: "#0C75EB !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxP: {
        width: "163px", height: "27px", background: "#F59E0B !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxA: {
        width: "88px", height: "27px", background: "#16A34A !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    statusBoxR: {
        width: "82px", height: "27px", background: "#E52525 !important",borderRadius:"33px",padding:"5px 0px",textAlign:"center",
    },
    labales:{
        fontSize: "14px !important",
        fontFamily: "Averta !important",
        fontWeight: "600 !important",
    },
}))

export default TimeSheetStyles;