import React, { useEffect, useState } from 'react'
import ViewStyles from './ViewStyles';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import info from '../../../../assets/svg/fi_info.svg'
import moment from 'moment';
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, dateFormat, openDocumentInNewtab, removeLoader } from '../../../../utils/utils';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Date from '../../../../components/datePicker/Date';
import Select from '../../../../components/select/Select';
import Input from '../../../../components/input/Input';
import Dropzone from 'react-dropzone';
import { blue } from '@mui/material/colors';
import download from '../../../../assets/employee/filedownload.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '../../../../components/customButton/Button';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import CommonApi from '../../../../apis/CommonApi';
import { address_validation, empty_Email_id, isValid, onNumberOnlyChange, validate_city, validate_emptyField, validate_empty_address, validate_zipcode } from '../../../../components/Validation';
import LocalStorage from '../../../../utils/LocalStorage';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import ReusableDelete from '../../../../components/reuablePopup/ReusableDelete';
import FileSvg from '../../../../assets/svg/File.svg'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#404040",
    padding: "6px 14px",
    minWidth: 100,
    border: "1px solid #404040"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
    "&::before": {
      backgroundColor: "#404040",
      border: "1px solid #404040"
    }
  },
}));

function PlacementDetails(props) {

  const classes = ViewStyles();
  const placementID = props.id ? props.id : "";

  const [loading, setLoading] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [jonTitles, setJobTitles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(false);
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const [state, setState] = useState({
    job_title_id: "",
    address_line_one: "",
    address_line_two: "",
    city: "",
    state_id: "",
    country_id: "",
    zip_code: "",
    work_email_id: "",
    recruiter_id: "",
    start_date: "",
    end_date: "",
    supporting_documents: [],
    delete_supporting_document_ids: [],
  })


  useEffect(() => {
    getJobTitles();
    countriesList()
    getRecruiters();
    getPlacementData(placementID)// eslint-disable-next-line
  }, []);

  const getPlacementData = (id) => {
    setLoading(true);
    PlacementApi.getPlacementIndex("placement-details", id).then((res) => {
      if (res.data.statusCode === 1003) {
        if (res.data.data.placement_details.country_id) {
          statesList(res.data.data.placement_details.country_id);
        }
        res.data.data.placement_details['delete_supporting_document_ids'] = [];
        setState({ ...res.data.data.placement_details });
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  const getRecruiters = () => {
    CommonApi.getRecruiterDropdownList().then((res) => {
      if (res.data.statusCode === 1003) {
        setRecruiters(res.data.data)
      }
    })
  }

  const getJobTitles = () => {
    CommonApi.getJobTitlesDropdownList('').then((response) => {
      if (response.data.statusCode == 1003) {
        setJobTitles(response.data.data);
      }
    });
  };

  const countriesList = () => {
    CommonApi.getCountryList('').then((res) => {
      if (res.data.statusCode === 1003) {
        setCountries(res.data.data);
      }
    })
  }

  const statesList = (id) => {
    CommonApi.getStatesList(id).then((res) => {
      if (res.data.statusCode === 1003) {
        setStates(res.data.data);
      }
    })
  }

  const changeHandler = (e) => {
    if (e.target.name === "country_id") {
      if (e.target.value !== "") {
        state.state_id = "";
        state.city = "";
        state.zip_code = "";
        statesList(e.target.value);
      } else if (e.target.value == "") {
        state.state_id = "";
      }
    }
    if (e.target.name === "state_id") {
      state.city = "";
      state.zip_code = "";
    }
    state[e.target.name] = e.target.value;
    setState({ ...state }, handleValidate(e));
  }

  const dateChange = (e, name) => {
    let date = e.$d
    let event = {
      target: {
        name: name,
        value: date
      }
    }
    state[name] = moment(date).format(dateFormat())
    setState({ ...state })
    handleValidate(event)
  }

  const handleEdit = () => {
    props.setformEdit(true);
    setError({});
    setEdit(true);
  }

  const multiDocsUpload = (files) => {
    for (const key in files) {
      if (files[key].type == "image/jpg" || files[key].type == "image/jpeg" || files[key].type == "image/png" || files[key].type == "application/pdf") {
        var fileSize = files[key].size / (1024 * 1024);
        if (fileSize.toFixed(2) <= 25) {
          addLoader(true);
          const formData = new FormData();
          formData.append('files', files[key]);
          formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
          CommonApi.documentUpload("placement", formData, LocalStorage.getAccessToken()).then((response) => {
            if (response.data.statusCode == 1003) {
              var obj = {
                document_name: response.data.data.document_name,
                temp_document_id: response.data.data.id,
                document_url: response.data.data.document_url,
              }
              if (state.supporting_documents.length < 5) {
                state.supporting_documents.push(obj);
                setState({ ...state });
              } else {
                return false;
              }
            }
            else {
              addErrorMsg(response.data.message);
            }
          })
          removeLoader();
        } else {
          removeLoader();
          addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
      } else {
        removeLoader();
        addErrorMsg(` please upload document Format PDF/Images`);
      }

    }
  }

  const handleSubmit = () => {
    let errors = validateAll();
    if (isValid(errors)) {
      setSaveLoading(true);
      const data = {
        request_id: LocalStorage.uid(),
        placement_id: placementID,
        placement_details: state
      }
      PlacementApi.placementDetailsUpdate(data).then((res) => {
        if (res.data.statusCode === 1003) {
          props.setformEdit(false);
          setSaveLoading(false);
          addSuccessMsg("Placement details updated successfully");
          getPlacementData(placementID);
          setEdit(false);
        } else {
          setSaveLoading(false);
          addErrorMsg(res.data.message);
        }
      })
    } else {
      let s1 = { error }
      s1 = errors
      setError(s1);
      addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
    }
  }

  const handleValidate = (e) => {
    let input = e.target;
    switch (input.name || input.tagName) {

      case "job_title_id":
        error.job_title_id = validate_emptyField(input.value);
        break;
      case "work_email_id":
        error.work_email_id = empty_Email_id(input.value);
        break;
      // case "recruiter_id":
      //   error.recruiter_id = validate_emptyField(input.value);
      //   break;
      case "address_line_one":
        error.address_line_one = address_validation(input.value);
        break;
      case "address_line_two":
        error.address_line_two = validate_empty_address(input.value);
        break;
      case "country_id":
        error.country_id = validate_emptyField(input.value);
        break;
      case "state_id":
        error.state_id = validate_emptyField(input.value);
        break;
      case "city":
        error.city = validate_city(input.value);
        break;
      case "zip_code":
        error.zip_code = validate_zipcode(input.value, state.country_id);
        break;
      case "effective_from":
        error.effective_from = validate_emptyField(input.value);
        break;
      // case "end_date":
      //   error.end_date = validate_emptyField(input.value);
      //   break;
      default:
        break;
    }
    setError({ ...error });
  }

  //ValidateAll Functions 
  const validateAll = () => {
    let { job_title_id, address_line_one, address_line_two, city, state_id, country_id, zip_code, work_email_id } = state;
    let errors = {};
    errors.job_title_id = validate_emptyField(job_title_id);
    errors.address_line_one = validate_emptyField(address_line_one);
    errors.address_line_two = validate_empty_address(address_line_two);
    errors.city = validate_city(city);
    errors.state_id = validate_emptyField(state_id);
    errors.country_id = validate_emptyField(country_id);
    errors.zip_code = validate_zipcode(zip_code, state.country_id);
    // errors.recruiter_id = validate_emptyField(recruiter_id);
    errors.work_email_id = empty_Email_id(work_email_id);
    setError(errors);
    return errors;
  }

  const documentRemove = (index) => {
    setDeleteOpen(true);
    setDeleteIndex(index);
  }

  const deleteDoc = (index) => {
    if (state.supporting_documents[index].id != "") {
      state.delete_supporting_document_ids.push(state.supporting_documents[index].id);
    }
    state.supporting_documents.splice(index, 1);
    setState({ ...state });
    setDeleteOpen(false);
    setDeleteIndex(null);
  }

  const openInNewTab = (args, documentUrl) => {
    if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
      const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    else {
      openDocumentInNewtab(args.document_key, args.document_path)
    }
  }

  return (
    <Grid container >
      <Grid item lg={12} md={12} sm={12} xs={12} >
        <Grid container p={3} alignItems={"center"}>
          <Grid item lg={6} md={6} sm={6}>
            <Text largeBlue>Placement Details</Text>
          </Grid>
          <Grid item lg={6} md={6} sm={6} textAlign={"end"}>
            {
              props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                <>
                  {(!edit && !loading) ? <img onClick={() => { handleEdit() }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} /> : ""}
                </>
                : ""
            }
          </Grid>
          {
            edit ?
              <Grid container columnSpacing={4}>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  <Select
                    name='job_title_id'
                    value={state.job_title_id}
                    onChange={(e) => { changeHandler(e) }}
                    options={jonTitles}
                    label={'Job Title'}
                    helperText={error.job_title_id ? <Text red>{error.job_title_id}</Text> : ""}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  <Input
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'work_email_id',
                      value: state.work_email_id,
                    }}
                    handleChange={changeHandler}
                    clientInput
                    labelText={`Work Email Id (Optional)`}
                    error={error.work_email_id}
                  />
                  <Text red>{error.work_email_id ? error.work_email_id : ""}</Text>
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  <Select
                    name='recruiter_id'
                    value={state.recruiter_id}
                    onChange={(e) => { changeHandler(e) }}
                    options={recruiters}
                    label={'Recruiter Name (Optional)'}
                  // helperText={error.recruiter_id ? <Text red>{error.recruiter_id}</Text> : ""}
                  />
                </Grid>
                {
                  state.end_date !== "" ?
                    <Grid item lg={4} md={4} sm={4} pt={4}>
                      <Date
                        labelText={`End Date`}
                        name='end_date'
                        value={state.end_date}
                        minDate={moment().format(dateFormat())}
                        onChange={(value) => dateChange(value, 'end_date')}
                        height='56px'
                        disabled={true}
                      />
                    </Grid>
                    : ""
                }

              </Grid> :
              <>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Job Title</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.job_title_name === "" ? "--" :
                            state.job_title_name && state.job_title_name.length > 16 ?
                              <BlackToolTip title={state.job_title_name} placement="right" arrow>
                                {state.job_title_name.slice(0, 16) + (state.job_title_name.length > 16 ? "..." : "")}
                              </BlackToolTip>
                              :
                              state.job_title_name
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Work Email Id (Optional)</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.work_email_id === "" ? "--" : state.work_email_id && state.work_email_id.length > 40 ?
                            <BlackToolTip title={state.work_email_id} placement="right" arrow>
                              {state.work_email_id.slice(0, 40) + (state.work_email_id.length > 40 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.work_email_id
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={2}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Recruiter Name (Optional)</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.recruiter_name === "" ? "--" : state.recruiter_name && state.recruiter_name.length > 16 ?
                            <BlackToolTip title={state.recruiter_name} placement="right" arrow>
                              {state.recruiter_name.slice(0, 16) + (state.recruiter_name.length > 16 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.recruiter_name
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        {
                          state.end_date &&
                          <>
                            <Text mediumGreyHeader1>End Date</Text>
                            <Text mediumBlack14 pt={0.8} noWrap>{state.end_date ? state.end_date : "--"}</Text>
                          </>
                        }
                      </>
                  }
                </Grid>
              </>
          }

          <Grid item lg={12} md={12} sm={12} pt={5}>
            <Text largeBlue>Work Location
              {
                !edit &&
                <HtmlTooltip
                  placement="bottom"
                  arrow
                  title={
                    <React.Fragment>
                      <Box>
                        <Typography className={classes.profileTooltipText}>
                          If you are working remotely <br />Please add your home<br /> address
                        </Typography>
                      </Box>
                    </React.Fragment>
                  }
                >
                  <img src={info} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px" }} />
                </HtmlTooltip>}
            </Text>
          </Grid>
          {
            edit ?
              <Grid container columnSpacing={4}>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Input
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'address_line_one',
                      value: state.address_line_one,
                      inputProps: { maxLength: 225 }
                    }}
                    handleChange={changeHandler}
                    clientInput
                    labelText={`Address Line-1 `}
                    error={error.address_line_one}
                  />
                  <Text red>{error.address_line_one ? error.address_line_one : ""}</Text>
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Input
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'address_line_two',
                      value: state.address_line_two,
                      inputProps: { maxLength: 225 }
                    }}
                    handleChange={changeHandler}
                    clientInput
                    labelText={`Address Line-2 (Optional)`}
                    error={error.address_line_two}
                  />
                  <Text red>{error.address_line_two ? error.address_line_two : ""}</Text>
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Select
                    name='country_id'
                    value={state.country_id}
                    onChange={(e) => { changeHandler(e) }}
                    options={countries}
                    label={'Country'}
                    disabled={true}
                    helperText={error.country_id ? <Text red>{error.country_id}</Text> : ""}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Select
                    name='state_id'
                    value={state.state_id}
                    onChange={(e) => { changeHandler(e) }}
                    options={states}
                    label={'State'}
                    helperText={error.state_id ? <Text red>{error.state_id}</Text> : ""}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Input
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'city',
                      value: state.city,
                      inputProps: { maxLength: 50 }
                    }}
                    handleChange={changeHandler}
                    clientInput
                    labelText={`City`}
                    error={error.city}
                  />
                  <Text red>{error.city ? error.city : ""}</Text>
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  <Input
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: 'zip_code',
                      value: state.zip_code,
                      inputProps: { maxLength: state.country_id === 2 ? 5 : 6 }
                    }}
                    handleChange={changeHandler}
                    onKeyPress={onNumberOnlyChange}
                    clientInput
                    labelText={state.country_id === 2 ? `Zip Code` : "Pin Code"}
                    error={error.zip_code}
                  />
                  <Text red>{error.zip_code ? error.zip_code : ""}</Text>
                </Grid>
              </Grid> :
              <>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Address Line-1 </Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.address_line_one === "" ? "--" : state.address_line_one && state.address_line_one.length > 30 ?
                            <BlackToolTip title={state.address_line_one} placement="right" arrow>
                              {state.address_line_one.slice(0, 30) + (state.address_line_one.length > 30 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.address_line_one
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Address Line-2 (Optional)</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.address_line_two === "" ? "--" : state.address_line_two && state.address_line_two.length > 30 ?
                            <BlackToolTip title={state.address_line_two} placement="right" arrow>
                              {state.address_line_two.slice(0, 30) + (state.address_line_two.length > 30 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.address_line_two
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>Country</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.country_name === "" ? "--" : state.country_name && state.country_name.length > 30 ?
                            <BlackToolTip title={state.country_name} placement="right" arrow>
                              {state.country_name.slice(0, 30) + (state.country_name.length > 30 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.country_name
                          }</Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>State</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.state_name === "" ? "--" : state.state_name && state.state_name.length > 30 ?
                            <BlackToolTip title={state.state_name} placement="right" arrow>
                              {state.state_name.slice(0, 30) + (state.state_name.length > 30 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.state_name
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>City</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>
                          {state.city === "" ? "--" : state.city && state.city.length > 30 ?
                            <BlackToolTip title={state.city} placement="right" arrow>
                              {state.city.slice(0, 30) + (state.city.length > 30 ? "..." : "")}
                            </BlackToolTip>
                            :
                            state.city
                          }
                        </Text>
                      </>
                  }
                </Grid>
                <Grid item lg={4} md={4} sm={4} pt={4}>
                  {
                    loading ?
                      <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      :
                      <>
                        <Text mediumGreyHeader1>{state.country_id == 2 ? "Zip Code" : "Pin Code"}</Text>
                        <Text mediumBlack14 pt={0.8} noWrap>{state.zip_code ? state.zip_code : "--"}</Text>
                      </>
                  }
                </Grid>
              </>
          }

          <Grid item lg={12} md={12} sm={12} pt={5}>
            <Text largeBlue>{edit && "Add Placement "}Supporting Documents</Text>
          </Grid>
          {
            edit ?
              <>
                <Grid item lg={12} md={12} pt={3}>
                  <Box className={classes.dropzoneMainBox}>
                    <Box className={classes.dropBox}>
                      <Dropzone onDrop={acceptedFiles => { multiDocsUpload(acceptedFiles) }}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <section className={classes.dropZone}>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {
                                isDragActive ?
                                  <Grid container spacing={1} justifyContent='center'>
                                    <Text normal my={10} className={classes.dropzoneText}>drop files here...</Text>
                                  </Grid>
                                  :
                                  <Grid container spacing={1} textAlign='center'>
                                    <Grid item lg={12} md={12} xs={12}>
                                      <img src={download} alt='download' />
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12} textAlign='center'>
                                      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                        <Text largeBlack>Drag & Drop Documents</Text>
                                        <Text smallGrey>or</Text>
                                        <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                        <Text largeBlack>To Upload</Text>
                                      </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12} mt={1}>
                                      <Text mediumOrange>Add your PO, MSA, COI etc..</Text>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12} mt={1}>
                                      <Text smallGreyFont>Document Size 25MB, Format - PDF, Images</Text>
                                    </Grid>
                                  </Grid>
                              }
                            </div>
                          </section>
                        )}
                      </Dropzone>

                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} pt={2}>
                  {
                    state.supporting_documents.length > 0 ?
                      <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                        <Text smallLabel sx={{ font: "12px Averta !important" }}>Uploaded Documents</Text>
                        <Grid item container lg={12} md={12} columnGap={1.5}>
                          {
                            state.supporting_documents.map((item, index) => (
                              <Box mt={1.5} sx={{ display: "flex", alignItems: "center", padding: "4px 8px", background: "#EEEEEE", borderRadius: "50px" }}>
                                <Text sx={{ color: "#0C75EB !important", font: "12px Averta !important", fontWeight: `${600} !important` }}>
                                  <a href={item.document_url} style={{ color: "#0C75EB" }} target="_blank" rel="noreferrer">
                                    {item.document_name.length > 16 ?
                                      <BlackToolTip arrow placement='top' title={item.document_name}>
                                        {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                      </BlackToolTip>
                                      :
                                      <>
                                        {item.document_name}
                                      </>}
                                  </a>
                                </Text>
                                <HighlightOffIcon sx={{ cursor: "pointer", color: "#737373" }} onClick={() => { documentRemove(index) }} />
                              </Box>
                            ))
                          }
                        </Grid>
                      </Box> :
                      <Box sx={{ border: "1px solid #C7CCD3", width: "100%", borderRadius: "8px", padding: "10px" }}>
                        <Text smallLabel sx={{ font: "14px Averta !important" }}>Uploaded Documents</Text>
                        <Grid item container lg={12} columnGap={1.5}>
                        </Grid>
                      </Box>
                  }
                </Grid>
                <Grid container item lg={12} md={12} sm={12} pt={3} justifyContent={"space-between"}>
                  <Button blackCancel1 onClick={() => { setEdit(false); props.setformEdit(false); getPlacementData(placementID) }}>Cancel</Button>
                  <LoadingButton saveBtn loading={saveLoading} onClick={() => handleSubmit()}>Save</LoadingButton>
                </Grid>
              </>
              :
              <>
                {
                  loading ?
                    [1, 2, 3].map((item) => (
                      <Grid item lg={3} md={3} sm={3} pt={2}>
                        <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                      </Grid>
                    ))
                    :
                    <>
                      {
                        state.supporting_documents.length > 0 && state.supporting_documents.map((item, index) => (
                          <Grid item lg={4} md={4} sm={4} pt={4}>
                            <Text mediumGreyHeader1>Supporting Document -{index + 1} (Optional) </Text>
                            <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item, item.document_url) }}>
                              <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                              <Text mediumBlue sx={{ font: "14px Averta !important", fontWeight: `${600} !important`, paddingLeft: "5px" }} noWrap>
                                <BlackToolTip arrow placement='top' title={item.document_name}>
                                  {item.document_name.slice(0, 16)} {item.document_name.length > 16 ? '...' : ''}
                                </BlackToolTip>
                              </Text>

                            </Stack>
                          </Grid>
                        ))
                      }
                    </>
                }
              </>
          }
        </Grid>
      </Grid>

      {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(deleteIndex) }} />}
    </Grid>
  )
}

export default PlacementDetails