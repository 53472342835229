module.exports = {
    /* Development */
    // API_URL: 'https://workforce-dev-api.codetru.org/api/v1/',
    API_URL: 'https://workforce-api.codetru.org/api/v1/',
    AdminURL: 'https://gateway-dev.japfu.app/api/v1/',
    client_URL: 'https://gateway-dev.japfu.app/api/v1/',
    org_URL: "https://gateway-dev.japfu.app/api/v1/",
    employee_URL: 'https://gateway-dev.japfu.app/api/v1/',
    placement_URL: 'https://gateway-dev.japfu.app/api/v1/',
    timesheet_URL: 'https://gateway-dev.japfu.app/api/v1/',
    Socket_URL: 'https://gateway-dev.japfu.app',
    SSE_URL: 'https://gateway-dev.japfu.app/',

    // AdminURL: 'http://192.168.0.130:8000/api/v1/'
    // client_URL: 'http://192.168.0.70:8003/api/v1/',
    // API_URL: 'http://192.168.0.70:8000/api/v1/',
    // org_URL: "http://192.168.0.50:8001/api/v1/",
    // employee_URL: "http://192.168.0.235:8002/api/v1/",
    // placement_URL: 'http://192.168.0.31:8004/api/v1/',
    // timesheet_URL: 'http://192.168.0.161:8005/api/v1/',

    /*  QA URL  */
    // AdminURL: 'https://gateway-qa.japfu.app/api/v1/',
    // client_URL: 'https://gateway-qa.japfu.app/api/v1/',
    // org_URL: "https://gateway-qa.japfu.app/api/v1/",
    // employee_URL: 'https://gateway-qa.japfu.app/api/v1/',
    // placement_URL: 'https://gateway-qa.japfu.app/api/v1/',
    // timesheet_URL: 'https://gateway-qa.japfu.app/api/v1/',
    // Socket_URL: 'https://gateway-qa.japfu.app',
 
    /*  UAT URL  */
    // AdminURL: 'https://gateway-uat.japfu.app/api/v1/',
    // client_URL: 'https://gateway-uat.japfu.app/api/v1/',
    // org_URL: "https://gateway-uat.japfu.app/api/v1/",
    // employee_URL: 'https://gateway-uat.japfu.app/api/v1/',
    // placement_URL: 'https://gateway-uat.japfu.app/api/v1/',
    // timesheet_URL: 'https://gateway-uat.japfu.app/api/v1/',
    // Socket_URL: 'https://gateway-uat.japfu.app',

    dev_url: 'japfu-dev.codetru.org',
    qa_url: 'japfu-qa.codetru.org',
    uat_url: 'japfu-uat.codetru.org',

    /*  OCR URL  */
    Chatbot_URL: 'https://chatbot.codetru.org/',
    // Socket_URL: 'https://japfu-organization-dev.codetru.org',
    ocr_URL: 'https://ocr.codetru.org',
    token: 'QIo5LzZf64tF3re7eeBiphJyK/1Vr34I0wnmKICo3Co3PwqY+Tr1OymZ5atK4CWzVOJQhSiBExuaFqPFswOacbLVUuZ9NKUGxRGauH0+GOa/SMbbIOXEXiZbGjJX1J/VRFaxajeRrbtbhzmvkaD8ShRM0J2OH3pfB5eHuhekdGV3vG6URq0FHZE9JEEFmYEp+q7bRrjlTMJEng2Y4HEDQld70mx8Rjbr1a4i/B8uYfkL983pR7JkZElzjaXF/KmN7O+DQ9FooffJyMT3Qkc/mapa6IWlblS4jByj/jg8qefrq3P8AQ4EpyC+E8in9Oz116Fl/Py1hlKz3HzDhQZHQg=='
}