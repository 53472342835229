import React, { useEffect, useRef, useState } from 'react';
import { Grid, Stack, Divider, Box, Skeleton, Avatar } from "@mui/material";
import Styles from './profile/UserProfileStyles';
import Text from "../../components/customText/Text";
import { BlackToolTip, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, removeLoader } from '../../utils/utils';
import Verified from '../../assets/svg/Component87.svg';
import FileInput from '../../components/muiFileInput/FileInput';
import TimesheetApi from '../../apis/admin/timesheets/TimesheetApi';
import LocalStorage from '../../utils/LocalStorage';
import moment from 'moment';
import TimeSheetTable from '../admin/timesheets/newTimeSheets/TimeSheetTable';
import Input from '../../components/input/Input';
import LoadingScreen from '../admin/employees/userprofile/document/LoadingScreen';
import noDataFound from '../../assets/svg/noDataFound.svg';
import emptyTS from '../../assets/employee/emp-employee.svg';
import Button from '../../components/customButton/Button';
import ReusablePopup from '../../components/reuablePopup/ReusablePopup';
import rejectImg from '../../assets/svg/timesheet_reject.svg';
import LoadingButton from '../../components/customButton/LoadingButton';
import rejectSVg from "../../assets/svg/placementPop.svg";
import approveImg from '../../assets/timeSheets/pending-approval.svg';
import disableVerified from '../../assets/client/disableVerified.svg';

function PendingTimesheets(props) {
  let { employee, fetchId, setTSNewRequests } = props;
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const [list, setList] = useState([]);
  const classes = Styles();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(fetchId); // eslint-disable-next-line
  const scrollBox = useRef(null);
  const [approveStatus, setApproveState] = useState('');
  const [rejectComment, setRejectComment] = useState("");
  const [error, setError] = useState("");
  const [rejectLoad, setRejectLoad] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    limit: 100,
    page: 1,
    search: props.filterData.search,
    status: 'Pending Approval',
    placement_ids: props.filterData.placement_ids,
    timesheet_cycles: props.filterData.timesheet_cycles,
    start_date_from: props.filterData.start_date_from,
    start_date_to: props.filterData.start_date_to,
    end_date_from: props.filterData.end_date_from,
    end_date_to: props.filterData.end_date_to,
    employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
    sort_column: "ts.created_at",
    sort_order: "desc",
    total: ""
  });

  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenRejected] = useState(false);

  const handleScroll = () => {
    const { current } = scrollBox;
    if (current) {
      const { scrollTop, scrollHeight, clientHeight } = current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (Number(filterData.total) >= filterData.limit) {
          loadMore();
        }
      }
    }
  };

  const loadMore = () => {
    addLoader(true)
    filterData.limit = Number(filterData.limit) + 6;
    filterData['status'] = 'Pending Approval'
    setFilterData({ ...filterData });
    setLoader(true);
    listingApi(filterData);
  }

  const [values, setValues] = useState({
    request_id: LocalStorage.uid(),
    employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id, placement_id: "",
    client_id: "",
    client_name: "",
    timesheet_cycle: "",
    timesheet_start_day: "",
    timesheet_start_date: "",
    end_client_id: "",
    end_client_name: "",
    default_hours: "08:00",
    is_timesheet_attachment_mandatory: false,
    start_date: "",
    end_date: "",
    comments: "",
    total_billable_hours: "",
    is_editable: true,
    is_submit: true,
    is_delete: false,
    total_hours: "",
    total_ot_hours: "",
    is_approver: false,
    weekly: [],
    documents: [],
  });

  useEffect(() => {
    listingApi(filterData);
    if (employee == '') {
      getTimeSheetDataWithId(id)
    }// eslint-disable-next-line
  }, [])

  useEffect(() => {
    filterData.search = props.filterData.search
    filterData.placement_ids = props.filterData.placement_ids
    filterData.timesheet_cycles = props.filterData.timesheet_cycles
    filterData.start_date_from = props.filterData.start_date_from
    filterData.start_date_to = props.filterData.start_date_to
    filterData.end_date_from = props.filterData.end_date_from
    filterData.end_date_to = props.filterData.end_date_to
    filterData.sort_column = "ts.created_at"
    filterData.sort_order = "desc"
    filterData.request_id = LocalStorage.uid();
    filterData.employee_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id;
    listingApi(filterData);
    // eslint-disable-next-line
  }, [props.filterData])

  const listingApi = (args) => {
    setLoading(true);
    args.status = 'Pending Approval'
    args.request_id = LocalStorage.uid();
    args.employee_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id;
    if (LocalStorage.getUserData().admin_login) {
      TimesheetApi.timesheetListView(args).then((res) => {
        setLoader(false);
        removeLoader();
        setRejectLoad(false);
        if (res.data.statusCode == 1003) {
          setLoading(false);
          setList(res.data.data);
          if (res.data.data.length > 0) {
            setFilterData({
              ...res.data.pagination,
              limit: res.data.pagination.perPage,
              page: res.data.pagination.currentPage
            });
            setTSNewRequests(res.data.pagination.total);
          } else {
            setTSNewRequests('');
          }
        } else {
          setLoading(false);
          if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

          } else {
            addErrorMsg(res.data.message);
          }
        }
      })
    }
    else {
      TimesheetApi.timesheetListViewConsultant(args).then((res) => {
        setLoader(false);
        removeLoader();
        setRejectLoad(false);
        if (res.data.statusCode == 1003) {
          setLoading(false);
          setList(res.data.data);
          if (res.data.data.length > 0) {
            setFilterData({
              ...res.data.pagination,
              limit: res.data.pagination.perPage,
              page: res.data.pagination.currentPage
            });
            setTSNewRequests(res.data.pagination.total);
          } else {
            setTSNewRequests('');
          }
        } else {
          setLoading(false);
          if (res.data.message == `You don't have access to perform this action. Please contact admin`) {

          } else {
            addErrorMsg(res.data.message);
          }
        }
      })
    }
  }

  const getTimeSheetDataWithId = (args) => {
    setLoader(true);
    TimesheetApi.getTimesheet(args).then((res) => {
      setLoader(false);
      if (res.data.statusCode === 1003) {
        if (res.data.data.length > 0) {
          setValues({
            ...res.data.data[0],
            documents_deleted_ids: [],
            weekly: handleTable(res.data.data[0].timesheet)
          });
        }
      }
    })
  };

  const globalTimeCalculation = (arr) => {
    var start = "00:00";
    for (var i = 0; i < arr.length; i++) {
      start = sumTime(start, arr[i]);
    }
    return start;
  }

  function sumTime(time1, time2) {
    // Split time into hours and minutes
    var [hours1, minutes1] = time1.split(':');
    var [hours2, minutes2] = time2.split(':');

    // Convert time to minutes
    var totalMinutes = parseInt(hours1) * 60 + parseInt(minutes1) + parseInt(hours2) * 60 + parseInt(minutes2);

    // Convert minutes back to HH:MM format
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;

    // Add leading zeros if necessary
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    return hours + ':' + minutes;
  }

  const handleTable = (param) => {
    var dumArr = [];
    var finalDumArr = [];
    for (var i in param) {
      dumArr.push(param[i]);
      if (moment(param[i].date).format("d") === '0') {
        let finalObj = {
          billable_hours: handleLoopingArray(dumArr, "bill"),
          ot_hours: handleLoopingArray(dumArr, "ot"),
          total_hours: handleLoopingArray(dumArr, "total"),
          timesheet: dumArr,
        }
        finalDumArr.push(finalObj);
        dumArr = [];
      }
    }
    if (dumArr.length !== 0) {
      let finalOb = {
        billable_hours: handleLoopingArray(dumArr, "bill"),
        ot_hours: handleLoopingArray(dumArr, "ot"),
        total_hours: handleLoopingArray(dumArr, "total"),
        timesheet: dumArr,
      }
      finalDumArr.push(finalOb);
    }
    handleLoopingArray(dumArr);
    return finalDumArr;
  }

  const handleLoopingArray = (param, param1) => {
    var dumpArray = [];
    if (param1 === "total") {
      for (var i in param) {
        dumpArray.push(param[i].total_hours);
      }
      return globalTimeCalculation(dumpArray);
    }
    else if (param1 === "ot") {
      for (var k in param) {
        dumpArray.push(param[k].ot_hours);
      }
      return globalTimeCalculation(dumpArray);
    }
    else {
      for (var j in param) {
        dumpArray.push(param[j].billable_hours);
      }
      return globalTimeCalculation(dumpArray);
    }
  }

  const changeHandler = (e) => {
    values['comments'] = e.target.value
    setValues({ ...values })
  }

  const handleClick = (args) => {
    setId(args.timesheet_id);
    setValues(args);
    getTimeSheetDataWithId(args.timesheet_id);
  }

  const approveUpdate = (args) => {
    setApproveState(args);
    const data = {
      comments: values.comments,
      id: values.id,
      placement_id: values.placement_id,
      request_id: LocalStorage.uid(),
      status: args
    }
    if (args == 'Rejected') {
      setOpenRejected(true);
    } else {
      if (args == 'Rejected') {
        setRejectLoad(true);
      } else {
        setApproveLoader(true);
      }
      TimesheetApi.approvalUpdate(id, data).then((res) => {
        setRejectLoad(false);
        setApproveLoader(false);
        if (res.data.statusCode == 1003) {
          setOpenApprove(true);
        } else {
          addErrorMsg(res.data.message);
        }
      })
    }
  }

  const rejectHandler = () => {
    if (rejectComment == '') {
      setError('This field is required')
    } else {
      const rejectData = {
        id: values.id,
        request_id: LocalStorage.uid(),
        placement_id: values.placement_id,
        status: 'Rejected',
        reason_rejection: rejectComment,
        comments: values.comments,
      }
      setRejectLoad(true)
      TimesheetApi.approvalUpdate(id, rejectData).then((res) => {
        setRejectLoad(false);
        if (res.data.statusCode == 1003) {
          setOpenRejected(false);
          setOpenApprove(true);
        } else {
          addErrorMsg(res.data.message);
        }
      })
    }
  }

  const handleOkay = () => {
    setOpenApprove(false);
    filterData['status'] = 'Pending Approval'
    setFilterData({ ...filterData })
    setRejectLoad(true);
    listingApi(filterData);
    setId('');
  }

  const handleRejectChange = (e) => {
    setRejectComment(e.target.value);
    setError('')
  }

  return (
    <>
      {
        LocalStorage.getRolesData() == null || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true)) ?
          <>
            {
              loading == false && list.length == 0 ?
                <Box justifyContent='center' width='100%' alignItems='center'>
                  <img src={noDataFound} alt="noDataFound" className={classes.nodata} />
                </Box>
                :
                <Grid container lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <Grid item lg={3} md={3} sm={3} xs={12}>
                    {
                      loading ?
                        <Box className={classes.sidecard}>
                          <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                            <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                              <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                              <Stack gap='8px'>
                                <Skeleton animation='wave' width='100px' />
                                <Skeleton animation='wave' width='100px' />
                              </Stack>
                            </Stack>
                            <Stack alignContent={'center'} alignItems={'center'}>
                              <Skeleton animation='wave' width='50px' />
                            </Stack>
                          </Stack>
                          <Divider sx={{ width: '100%', border: '1px solid #F5F5F5' }} />
                          <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'} p={'10px'}>
                            <Stack direction={'column'} justifyContent={'space-between'}>
                              <Skeleton animation='wave' width='100px' />
                              <Skeleton animation='wave' width='100px' />
                            </Stack>
                            <Skeleton animation='wave' width='100px' />
                          </Stack>
                        </Box>
                        :
                        <Box className={classes.sidecardScroll} ref={scrollBox} onScroll={handleScroll} pt={1}>
                          {
                            list.length == 0 ?
                              <Box justifyContent='center' width='100%'>
                                <img src={noDataFound} alt="noDataFound" style={{ width: '100%' }} />
                              </Box>
                              :
                              list.length > 0 ? list.map((item) => (
                                <Grid item container xl={11} lg={11} md={12} xs={12}
                                  sx={{ border: item.timesheet_id == id ? '1px solid #0C75EB' : '1px solid #fff' }}
                                  className={classes.sidecard} onClick={() => handleClick(item)}
                                >
                                  <Grid item container xl={12} lg={12} xs={12} p={2}>
                                    <Grid item xl={2} md={3} lg={3} xs={12}>
                                      <Avatar variant="rounded" sx={{ width: 40, height: 40, borderRadius: '50%' }} src={item.profile_picture_url} alt={item.employee_name ? capitalizeAndAddSpace(item.employee_name[0]) : ''} />
                                      {/* <img src={item.profile_picture_url} alt={item.employee_name ? item.employee_name[0] : ''} style={{ height: '40px', width: '40px', borderRadius: '50%' }} /> */}
                                    </Grid>
                                    <Grid item xl={7} md={9} lg={9} xs={12} pl={1}>
                                      <Text dashboardblack14>
                                        {
                                          item.employee_name ? item.employee_name.length > 16 ?
                                            <BlackToolTip title={capitalizeAndAddSpace(item.employee_name)} placement="top" arrow>
                                              {capitalizeAndAddSpace(item.employee_name.slice(0, 16)) + capitalizeAndAddSpace(item.employee_name.length > 16 ? "..." : "")}
                                            </BlackToolTip>
                                            : capitalizeAndAddSpace(item.employee_name) : '--'
                                        }
                                        {
                                          item.employee_e_verified != 0 ?
                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has Been E-Verified</Text>}>
                                              <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                            </BlackToolTip>
                                            :
                                            <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has not E-Verified</Text>}>
                                              <img src={disableVerified} alt='svg' style={{ margin: '0px 0px -3px 3px' }} />
                                            </BlackToolTip>
                                        }
                                      </Text>
                                      <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text greysmallLabel>{item.placement_reference_id ? item.placement_reference_id : '-'}</Text>
                                        <Box className={classes.greyDot} />
                                        <Text greysmallLabel>{item.timesheet_reference_id ? item.timesheet_reference_id : '-'}</Text>
                                      </Box>
                                    </Grid>
                                    <Grid item xl={3} lg={12} md={12}>
                                      <Box textAlign='center' className={item.status == 'Approved' ? classes.statusBox : item.status == 'Pending Approval' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : item.status == 'Draft' ? classes.redBox : ''}>
                                        <Text mediumWhite400>{item.status == 'Pending Approval' ? 'Pending' : item.status}</Text>
                                      </Box>
                                    </Grid>
                                    <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                    <Grid item xl={4.5} lg={12} xs={12} md={6} sm={6}>
                                      <Text greysmallLabel>Client Name</Text>
                                      <Text dashboardblack12>
                                        {
                                          item.client_name ? item.client_name.length > 16 ?
                                            <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                              {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                            </BlackToolTip>
                                            : capitalizeAndAddSpace(item.client_name) : '--'
                                        }
                                      </Text>
                                    </Grid>
                                    <Grid item container lg={7} md={6} sm={6} xs={12} alignContent={'center'} justifyContent='end'>
                                      <Text mediumBlue600>Timesheet</Text>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )) : ''
                          }
                        </Box>
                    }
                  </Grid>
                  <Grid item container lg={9} md={9} sm={9} xs={12}>
                    {
                      id == '' || id == null | id == undefined ?
                        <Box justifyContent='center' width='100% !important' alignContent='center' textAlign='center'>
                          <img src={emptyTS} alt='empty' className={classes.nodata} />
                          <Text mediumBlack700>Select any timesheet</Text>
                        </Box> :
                        loader ? <LoadingScreen /> :
                          <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F !important", padding: '25px 30px' }}>
                            <Grid item container lg={4} md={4} sm={4} xs={12}>
                              <Box display='flex' gap={2}>
                                {
                                  loader ?
                                    <Skeleton animation='wave' width="40px" height='65px' style={{ borderRadius: '50%' }} /> :
                                    <Avatar src={values.client_logo_url == '' || values.client_logo_url == null ? capitalizeAndAddSpace(values.client_name[0]) : values.client_logo_url} alt={capitalizeAndAddSpace(values.client_name[0])} sx={{ height: '50px', width: '50px', borderRadius: '50% !important' }} />
                                }
                                <Box>
                                  {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                      <Text blackHeader1>
                                        {values.client_name ? values.client_name.length > 16 ?
                                          <BlackToolTip title={capitalizeAndAddSpace(values.client_name)} placement="top" arrow>
                                            {capitalizeAndAddSpace(values.client_name.slice(0, 16)) + capitalizeAndAddSpace(values.client_name.length > 16 ? "..." : "")}
                                          </BlackToolTip>
                                          : capitalizeAndAddSpace(values.client_name) : '-'
                                        }
                                      </Text>
                                  }
                                  {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                      <Text largeGrey14 noWrap sx={{ paddingTop: '8px' }}>{values.client_reference_id ? values.client_reference_id : '-'}</Text>
                                  }
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item container lg={8} md={8} sm={8} xs={12}>
                              <Grid item lg={4} md={4} sm={4}>
                                <Text largeGrey14 sx={{ paddingBottom: '10px' }}>Total Logged Hours</Text>
                                <Text mediumBoldBlack>{values.total_billable_hours ? values.total_billable_hours : '-'}<span className={classes.greenText}>{values.total_ot_hours == '00:00' ? '' : ` + ${values.total_ot_hours}`}</span></Text>
                              </Grid>
                              <Grid item lg={4} md={4} sm={4}>
                                <Text largeGrey14 sx={{ paddingBottom: '10px' }}>Timesheet Cycle</Text>
                                <Text mediumBoldBlack>{values.timesheet_cycle ? values.timesheet_cycle : '-'}</Text>
                              </Grid>
                              <Grid item lg={4} md={4} sm={4}>
                                <Text largeGrey14 sx={{ paddingBottom: '10px' }}>Submitted Date</Text>
                                <Text mediumBoldBlack>{values.submitted_on ? values.submitted_on : '-'}</Text>
                              </Grid>
                            </Grid>
                            <Grid item container lg={12} xs={12}>
                              <Box className={classes.tsScroll}>
                                <Grid item lg={5} md={12} sm={12} xs={12}>
                                  <FileInput
                                    name='timesheet_document_id'
                                    FileName={values.documents.length > 0 ? values.documents[0].document_name : ''}
                                    // handleChange={(e) => uploadDocs(e)}
                                    label='Timesheet Document'
                                    disabled={true}
                                    // handleDelete={deleteDocs}
                                    actionState={values.documents.length > 0 && values.documents[0].document_name ? 'view' : ''}
                                    documentUrl={values.documents.length > 0 ? values.documents[0].document_url : ''}
                                    state={values.documents[0]}
                                  />
                                </Grid>
                                {values.timesheet != undefined && values.weekly != undefined && values.timesheet.length > 0 && values.weekly.length > 0 ? (
                                  <Box sx={{ width: '100%' }}>
                                    {values.weekly.map((value, index) => (
                                      <TimeSheetTable
                                        data={value}
                                        index={index}
                                      // onChange={tableHandleChange}
                                      // status={stateId.state !== null && stateId.state.status}
                                      // otHoursHandleChange={otHoursHandleChange}
                                      // isEdit={!edit}
                                      />
                                    ))}
                                  </Box>
                                ) : null}
                                <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'comments',
                                      value: values.comments,
                                      inputProps: {
                                        maxLength: 150
                                      }
                                    }}
                                    disabled={LocalStorage.getUserData().admin_login == false}
                                    handleChange={changeHandler}
                                    clientInput
                                    labelText={'Comments (Optional)'}
                                  />
                                </Grid>
                                <Divider sx={{ width: '100%', margin: '20px 0px 30px 0px', border: '1px solid #F5F5F5' }} />
                                {
                                  LocalStorage.getUserData().admin_login && values.is_approver &&
                                  <Grid item lg={12} ms={12} sm={12} xs={12} textAlign='center'>
                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>
                                      <LoadingButton loading={rejectLoad} smallBorderBlue onClick={() => approveUpdate('Rejected')}>Reject</LoadingButton>
                                      <LoadingButton scrollBtn loading={approveLoader} onClick={() => approveUpdate('Approved')}>Approve</LoadingButton>
                                    </Box>
                                  </Grid>
                                }
                              </Box>
                            </Grid>
                            <ReusablePopup openPopup={openApprove} setOpenPopup={setOpenApprove} iconHide fixedWidth white>
                              <Grid container lg={12} xs={12} textAlign='center' spacing={2}>
                                <Grid item lg={12} xs={12}>
                                  <img src={approveStatus == 'Rejected' ? rejectImg : approveImg} alt="approve" />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Text largeBlack22>Timesheet {approveStatus}!</Text>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <Text offBoardBodyFont>You have {approveStatus} timesheet of <span className={classes.boldName}>{capitalizeAndAddSpace(values.employee_name)}</span></Text>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                  <LoadingButton saveBtn400 loading={rejectLoad} onClick={handleOkay}>Okay</LoadingButton>
                                </Grid>
                              </Grid>
                            </ReusablePopup>
                            <ReusablePopup openPopup={openReject} setOpenPopup={setOpenRejected} iconHide fixedWidth white>
                              <Grid container>
                                <Grid item md={12} sm={12} textAlign={"center"}>
                                  <img src={rejectSVg} alt='rejectSVg' />
                                </Grid>
                                <Grid item md={12} sm={12} pt={2} textAlign={"center"}>
                                  <Text offBoardHeadFont sx={{ fontSize: "22px !important" }}>Are you sure?</Text>
                                </Grid>
                                <Grid item md={12} sm={12} pt={1} textAlign={"center"}>
                                  <Text offBoardBodyFont>Do you want to reject timesheet?</Text>
                                </Grid>
                                <Grid item md={12} sm={12} mt={3} >
                                  <Input
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'rejectComment',
                                      value: rejectComment,
                                      inputProps: { maxLength: 100 }
                                    }}
                                    clientInput
                                    labelText={'Add reason for rejection'}
                                    handleChange={handleRejectChange}
                                    error={error}
                                  />
                                  <Text red> {error ? error : ""}</Text>
                                </Grid>
                                <Grid container item md={12} sm={12} mt={3} justifyContent={"center"} gap={2}>
                                  <Button sx={{ width: "150px !important", font: "16px Averta !important", fontWeight: `${600} !important`, borderRadius: "8px !important" }} outlineBlueMedium1 onClick={() => { setOpenRejected(false); setError(''); setRejectComment(''); setRejectLoad(false) }} >Cancel</Button>
                                  <LoadingButton saveBtn sx={{ minWidth: '150px !important', font: "16px Averta !important", }} loading={rejectLoad} onClick={rejectHandler}>Yes, Reject</LoadingButton>
                                </Grid>
                              </Grid>
                            </ReusablePopup>
                          </Grid>
                    }
                  </Grid >
                </Grid >
            }
          </> :
          <>
            {NoPermission()}
          </>
      }
    </>
  )
}

export default PendingTimesheets