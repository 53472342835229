import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Grid, Checkbox, Stack, Slide, DialogContent } from "@mui/material";
import Stepper from './Stepper';
import successImg from '../../../assets/svg/succesIcon.svg';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Input from "../../../components/input/Input";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/customText/Text";
import LocalStorage from "../../../utils/LocalStorage";
import Button from "../../../components/customButton/Button";
import OnboardStyles from "./onBoard/OnboardStyles";
import ReusablePopup from "../../../components/reuablePopup/ReusablePopup";
import info from '../../../assets/svg/orangeInfo.svg';
import { empty_name, isValid, validate_charWithSpace, validate_emptyField, validate_usContact, validates_emailId } from "../../../components/Validation";
import { addErrorMsg, addLoader, addWarningMsg, capitalize, dateFormat, removeLoader } from "../../../utils/utils";
import Date from "../../../components/datePicker/Date";
import moment from "moment";
import FileInput from "../../../components/muiFileInput/FileInput";
import CommonApi from "../../../apis/CommonApi";
import OnBoardApi from "../../../apis/admin/employees/OnBoardApi";
import { domain } from '../../../config/Domain'
import LoaderIcon from '../../../assets/svg/sandtimer.svg';
import Select from '../../../components/select/Select'
import EmployeeVerifyApi from "../../../apis/admin/employees/EmployeeVerifyApi";
import EmployeesApi from "../../../apis/admin/employees/EmployeesApi";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import cancelImg from '../../../assets/svg/OffBoardPop.svg';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//     return {
//         id: `full-width-tab-${index}`,
//         'aria-controls': `full-width-tabpanel-${index}`,
//     };
// }
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});// eslint-disable-next-line
export default function AddEmployee() {
    const classes = OnboardStyles();
    const genderList = require('../../../utils/jsons/Gender.json');
    const navigate = useNavigate();
    const theme = useTheme();
    const param = new URLSearchParams(window.location.search);
    const id = param.get('id');
    const [value, setValue] = useState(0);
    const [error, setError] = useState({});
    const employeeVerifyId = LocalStorage.getEmployeeVerifyId();
    const [success, setSuccess] = useState(false);// eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [optionalData, setoptionalData] = useState([]);
    const [defaultData, setdefaultData] = useState([]);
    const [enableAdditional, setEnableAdditional] = useState(false);
    const [args, setArgs] = useState('')
    const [val, setVal] = useState('')
    const [categories, setCategory] = useState([]);// eslint-disable-next-line
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        subdomain_name: domain,
        first_name: '',
        middle_name: '',
        last_name: '',
        email_id: '',
        gender: '',
        mobile_number: '',
        offer_letter_url: '',
        dob: '',
        offer_letter_id: '',
        docName: '',
        employment_type_id: 2,
        employee_category_id: '',
        upload_documents: [
            {
                name: '',
                slug: '',
                is_mandatory: ''
            }
        ]
    })
    const [cancelPopup, setCancelPopup] = useState(false);

    useEffect(() => {
        categoryList(2);
        if (id) {
            getInviteViaLink(id);
        }
        if (employeeVerifyId) {
            getInviteViaLink(employeeVerifyId);
        } // eslint-disable-next-line
    }, []);

    const getInviteViaLink = (id) => {
        EmployeeVerifyApi.getVerifyEmployeeIndex(id).then((res) => {
            if (res.data.statusCode === 1003) {
                setState({
                    ...state,
                    first_name: res.data.data.first_name,
                    middle_name: res.data.data.middle_name,
                    last_name: res.data.data.last_name,
                    email_id: res.data.data.email_id,
                    gender: res.data.data.gender,
                    mobile_number: res.data.data.mobile_number,
                    offer_letter_url: res.data.data.offer_letter_url,
                    dob: res.data.data.dob,
                    offer_letter_id: '',
                    employment_type_id: 2,
                    employee_category_id: res.data.data.employee_category_id,
                })
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const categoryList = (args) => {
        CommonApi.CategoryList(LocalStorage.uid(), args, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCategory(res.data.data);
            }
        })
    }

    // eslint-disable-next-line
    const changeHandler = (e, index) => {
        if (e.target.name == 'offer_letter_id') {
            setState({
                ...state,
                [e.target.name]: e.target.files[0].name
            })
        } else if (e.target.name == 'mobile_number') {
            convertFormat(e)
            checkEmailHandle(e.target.value, 'mobile')
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name == 'email_id') {
            checkEmailHandle(e.target.value, 'email')
        }
        handleValidate(e);
    }

    const uploadDocs = (value) => {
        addLoader('ss')
        if (value.target.files[0].type.split('/').some(r => ['pdf'].includes(r))) {
            if (((value.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
                const formData = new FormData();
                formData.append("files", value.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                CommonApi.documentUpload("personal-document", formData, LocalStorage.getAccessToken())
                    .then((response) => {
                        if (response.data.statusCode == 1003) {
                            state.offer_letter_id = response.data.data.id
                            state.offer_letter_url = response.data.data.document_url
                            state.docName = value.target.files[0].name
                            setState({ ...state }, handleValidate(value))
                            removeLoader()
                        } else {
                            addErrorMsg(response.data.message);
                            removeLoader()
                        }
                    });
            } else {
                removeLoader()
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PDF format only.");
            removeLoader();
        }
    };

    const deleteDoc = (args) => {
        state.offer_letter_id = ''
        state.offer_letter_url = ''
        state.docName = ''
        setState({ ...state })
    }

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' ? 6 : 5)) {
            state[e.target.name] = `${first}-${middle}-${last}`
            setState(state, handleValidate(e))
        }
        else if (input.length > 3) {
            state[e.target.name] = `${first}-${middle}`
            setState(state, handleValidate(e))
        }
        else if (input.length >= 0) {
            state[e.target.name] = input
            setState(state, handleValidate(e))
        }
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: date == '' ? '' : moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const handleValidate = (e) => {
        let input = e.target
        switch (input.name || input.tagName) {
            case 'first_name':
                error.first_name = validate_charWithSpace(input.value, 'first ')
                break
            case 'last_name':
                error.last_name = validate_charWithSpace(input.value, 'last ')
                break
            case 'middle_name':
                error.middle_name = empty_name(input.value, 'middle ')
                break
            case 'email_id':
                error.email_id = validates_emailId(input.value)
                break
            case 'dob':
                error.dob = validate_emptyField(input.value)
                break
            case 'gender':
                error.gender = validate_emptyField(input.value)
                break
            case 'offer_letter_id':
                error.offer_letter_id = validate_emptyField(input.value)
                break
            case 'mobile_number':
                error.mobile_number = validate_usContact(input.value, 'Mobile Number')
                break
            case 'employee_category_id':
                error.employee_category_id = validate_emptyField(input.value)
                break
            default:
                break
        }
        setError({ ...error });
    }
    // eslint-disable-next-line
    const validateAll = () => {
        const { first_name, last_name, email_id, mobile_number, dob, gender, offer_letter_id, middle_name } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        let errors = {}
        errors.first_name = validate_charWithSpace(first_name);
        errors.last_name = validate_charWithSpace(last_name);
        errors.middle_name = empty_name(middle_name);
        errors.email_id = validates_emailId(email_id);
        errors.mobile_number = validate_usContact(mobile_number, 'Contact Number');
        errors.dob = '' ? moment(actualDOB).isBefore(belowDOB) ? '' : 'Minimum age should be greater than 18' : validate_emptyField(dob);
        errors.gender = validate_emptyField(gender);
        errors.offer_letter_id = validate_emptyField(offer_letter_id);
        return errors;
    }
    // eslint-disable-next-line
    const getDocsIndex = () => {
        // setLoading(true)
        OnBoardApi.selfOnboarddocsList().then((res) => {
            if (res.data.statusCode === 1003) {
                setTimeout(() => {
                    state['upload_documents'] = res.data.data
                    setState({ ...state });
                    // setLoading(false);
                }, 800)
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const getDocsIndex1 = () => {
        addLoader('ss')
        // setLoading(true)
        OnBoardApi.getDocuments().then((res) => {
            if (res.data.statusCode === 1003) {
                setTimeout(() => {
                    let data = res.data.data
                    state['upload_documents'] = res.data.data
                    let defaultArray = []
                    let optionalArray = []
                    let allTheData = [
                        {
                            name: 'all_previous_i20s',
                            label: 'All Previous I-20’s'
                        },
                        {
                            name: 'copy_of_void_cheque',
                            label: 'Copy of Void Cheque'
                        },
                        {
                            name: 'counter_signed_offer_letter',
                            label: 'Countersigned offer letter'
                        },
                        {
                            name: 'drivers_license',
                            label: `Driver's License`
                        },
                        {
                            name: 'education_documents',
                            label: 'Educational Documents'
                        },
                        {
                            name: 'i94',
                            label: 'I-94'
                        },
                        {
                            name: 'passport',
                            label: 'Passport'
                        },
                        {
                            name: 'signed_ssn',
                            label: 'SSN'
                        },
                        {
                            name: 'work_authorization',
                            label: 'Work Authorization'
                        }
                    ]
                    for (let i = 0; i < allTheData.length; i++) {
                        let name = allTheData[i].name
                        if (data[name].default) {
                            defaultArray.push({
                                checked: data[name].checked,
                                default: data[name].default,
                                status: data[name].status,
                                label: allTheData[i].label,
                                name: name
                            })
                        }
                        else {
                            optionalArray.push({
                                checked: data[name].checked,
                                default: data[name].default,
                                status: data[name].status,
                                label: allTheData[i].label,
                                name: name
                            })
                        }
                    }
                    setoptionalData(optionalArray)
                    setdefaultData(defaultArray)
                    setState({ ...state });
                    setEnableAdditional(res.data.data.enable_check)
                    removeLoader()
                    // setLoading(false);
                }, 800)
            } else {
                removeLoader()
                addErrorMsg(res.data.message);
            }
        })
    }

    const checkEmailHandle = (args, val) => {
        setArgs(args);
        setVal(val)

    }

    const checkEmail = (args, val) => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'email') {
                            setError({
                                ...error,
                                email_id: "Email ID already exists"
                            })

                        }
                        else if (val == 'mobile') {
                            setError({
                                ...error,
                                mobile_number: "Mobile Number already exists"
                            })
                        }
                        else {
                            setError({
                                ...error,
                                ssn: "SSN already exists"
                            })
                        }
                    }
                }
                else {
                    addErrorMsg(res.data.message)
                }
            });
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: '',
                invited_employee_id: LocalStorage.getEmployeeVerifyId()
            }
            let err = validates_emailId(obj.email_id)
            if (Mobileobj.contact_number.length > 11 || (err == '')) {
                EmployeesApi.checkDuplicate(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (res.data.valid) {

                        } else {
                            if (val == 'email') {
                                error.email_id = 'Email ID already exists'
                                setError({ ...error })
                            }
                            else if (val == 'mobile') {
                                error.mobile_number = "Mobile Number already exists"
                                setError({ ...error })
                            }
                            else {
                                error.ssn = "SSN already exists"
                                setError({ ...error })
                            }
                        }
                    }
                    else {
                        addErrorMsg(res.data.message)
                    }
                });
            }

        }, 300)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line
    }, [args, val])

    const basicDetailsError = () => {
        const { first_name, last_name, dob, gender, offer_letter_id, email_id, mobile_number, employee_category_id } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        error.first_name = validate_charWithSpace(first_name, 'first ')
        error.last_name = validate_charWithSpace(last_name, 'last ')
        error.dob = dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : 'Minimum age should be greater than 18' : validate_emptyField(dob);
        error.gender = validate_emptyField(gender);
        error.offer_letter_id = validate_emptyField(offer_letter_id);
        error.email_id = error.email_id == 'Email ID already exists' ? 'Email ID already exists' : validates_emailId(email_id);
        error.mobile_number = error.mobile_number == 'Mobile Number already exists' ? 'Mobile Number already exists' : validate_usContact(mobile_number, 'Contact Number');
        error.employee_category_id = validate_emptyField(employee_category_id);
        setError({ ...error })
        checkEmail(email_id, 'email');
        checkEmail(mobile_number, 'mobile');
        return setError({ ...error })
    }


    const scrollToTop = () => {
        if (boxRef.current) {
            boxRef.current.scrollTop = 0;
        }
    };
    const boxRef = useRef(null);

    const handleSubmit = () => {
        // let errors = validateAll();
        if (value == 0) {
            basicDetailsError()
            let basic_error = {
                first_name: error.first_name,
                last_name: error.last_name,
                dob: error.dob,
                offer_letter_id: error.offer_letter_id,
                gender: error.gender,
                email_id: error.email_id,
                mobile_number: error.mobile_number,
                employee_category_id: error.employee_category_id,
                middle_name: error.middle_name ? error.middle_name : '',
                blood_group: error.blood_group ? error.blood_group : ''
            }
            if (isValid(basic_error)) {
                setValue(value + 1);
                scrollToTop()
                // getDocsIndex();
                getDocsIndex1()
            }
            else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                return true
            }
        }
        else if (value == 1) {
            addLoader('ss')
            OnBoardApi.selfOnboardStore(state).then((res) => {
                if (res.data.statusCode === 1003) {
                    removeLoader()
                    setSuccess(true);
                } else {
                    removeLoader()
                    addErrorMsg(res.data.message);
                }
            })
        }
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const goToHome = () => {
        setSuccess(false);
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeFromPage();
        navigate('/employees');
    }

    const checkBoxChange = (e) => {
        state.upload_documents[e.target.name].checked = e.target.checked
    }

    return (
        <Box>
            <Box>
                <Grid container justifyContent='center' >
                    <Grid item container lg={7} md={9} sm={11} xs={10} p={'15px 0px'} justifyContent='center' position='fixed' zIndex='1' sx={{ background: '#FFFFFF' }}>
                        <Grid item lg={6} md={8} sm={8} xs={12} textAlign='center' p={'10px 0px !important'}>
                            <Stepper activeStepper={value} />
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ width: "100%", padding: "90px 10px 10px 10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{
                        width: "45%",
                        "@media (min-width: 681px) and (max-width: 968px)": {
                            width: "65%"
                        },
                        "@media (min-width: 320px) and (max-width: 680px)": {
                            width: "90%"
                        },
                        "@media (min-width: 1000px) and (max-width: 1300px)": {
                            width: "55%"
                        },
                        borderRadius: "12px", boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.05)", padding: '0px 10px'
                    }}>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Box my={1}>
                                    <Box mb={2}>
                                        <Typography sx={{
                                            fontSize: "18px", fontFamily: "Averta", fontWeight: "600", color: "rgba(38, 38, 38, 1)",
                                            "@media (min-width: 900px) and (max-width: 1400px)": {
                                                fontSize: "16px",
                                                fontWeight: "600"
                                            },
                                        }}>
                                            Basic Details
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2} columnSpacing={3} mb={3}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'first_name',
                                                    value: state.first_name,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'First Name'}
                                                error={error.first_name}
                                            />
                                            {
                                                error.first_name &&
                                                <Text red>{error.first_name ? error.first_name : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'middle_name',
                                                    value: state.middle_name,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                error={error.middle_name}
                                                labelText='Middle Name (Optional)'
                                            />
                                            {
                                                error.middle_name &&
                                                <Text red>{error.middle_name ? error.middle_name : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'last_name',
                                                    value: state.last_name,
                                                    inputProps: { maxLength: 33 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Last Name'}
                                                error={error.last_name}
                                            />
                                            {
                                                error.last_name &&
                                                <Text red>{error.last_name ? error.last_name : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'email_id',
                                                    value: state.email_id
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                error={error.email_id}
                                                labelText={'Email ID'}
                                            />
                                            {
                                                error.email_id &&
                                                <Text red>{error.email_id ? error.email_id : ''}</Text>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} columnSpacing={3}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    name: 'mobile_number',
                                                    value: state.mobile_number,
                                                    inputProps: { maxLength: 12 }
                                                }}
                                                handleChange={changeHandler}
                                                clientInput
                                                labelText={'Mobile Number'}
                                                error={error.mobile_number}
                                            />
                                            {
                                                error.mobile_number &&
                                                <Text red>{error.mobile_number ? error.mobile_number : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box >
                                                <Date
                                                    labelText={'Date of Birth'}
                                                    name='dob'
                                                    value={state.dob}
                                                    onChange={(value => dateChange(value, 'dob'))}
                                                    height='54px'
                                                    maxDate={moment().subtract(18, "years")}
                                                    error={error.dob ? error.dob : ''}
                                                />
                                            </Box>
                                            {
                                                error.dob &&
                                                <Text red>{error.dob ? error.dob : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: 'Consultant',
                                                    disabled: true
                                                }}
                                                disabled={true}
                                                clientInput
                                                labelText={'Employment Type'}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Select
                                                name={'employee_category_id'}
                                                label={'Employment Category'}
                                                value={state.employee_category_id}
                                                options={categories}
                                                onChange={changeHandler}
                                                error={error.employee_category_id && error.employee_category_id}
                                            />
                                            {
                                                error.employee_category_id &&
                                                <Text red>{error.employee_category_id ? error.employee_category_id : ''}</Text>
                                            }
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <Box pt={'8px'}>
                                                <Select
                                                    name='gender'
                                                    value={state.gender}
                                                    onChange={changeHandler}
                                                    options={genderList}
                                                    label={'Gender'}
                                                    error={error.gender ? error.gender : ''}
                                                />
                                                {
                                                    error.gender &&
                                                    <Text red>{error.gender ? error.gender : ''}</Text>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FileInput
                                                name='offer_letter_id'
                                                FileName={state ? state.docName : ''}
                                                handleChange={(e) => uploadDocs(e)}
                                                isDisabled={false}
                                                handleDelete={() => deleteDoc()}
                                                actionState={state.docName ? 1 : ''}
                                                documentUrl={state.offer_letter_url}
                                                label={'Upload Offer Letter'}
                                                error={error.offer_letter_id ? error.offer_letter_id : ''}
                                            />
                                            {
                                                error.offer_letter_id &&
                                                <Text red>{error.offer_letter_id ? error.offer_letter_id : ''}</Text>
                                            }
                                            <Text greysmallLabel>{employeeVerifyId ? `Please upload the latest offer letter` : `*Offer letter is mandatory please upload it`}</Text>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Box m={2}>
                                    <Grid container spacing={2} columnSpacing={3} mt={2} mb={3}>
                                        {
                                            loading ?
                                                <Box className={classes.ViewContainer}>
                                                    <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                                                        <img src={LoaderIcon} height={100} width={100} alt='loading' />
                                                    </Stack>
                                                </Box> :
                                                <Grid container lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Text sx={{
                                                            fontSize: "18px", fontFamily: "Averta", fontWeight: "600", color: "rgba(38, 38, 38, 1)",
                                                            "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                fontSize: "16px",
                                                                fontWeight: "600"
                                                            },
                                                        }} >Default Documents</Text>
                                                    </Grid>
                                                    {defaultData.length > 0 && defaultData.map((item) => (
                                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                                            <Box className={item.checked == true ? classes.checkBoxbg : classes.checkBox} pl={1} alignItems='center'>
                                                                <Checkbox size='medium' disabled={item.checked == true ? true : false} defaultChecked={item.checked == true ? true : false} name={item.label} className={classes.checkboxColor} />
                                                                <Text largeLabel>{item.label}</Text>
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                    {
                                                        enableAdditional ?
                                                            <Grid item container spacing={2} xl={12} lg={12} xs={12}>
                                                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                                                    <Text sx={{
                                                                        fontSize: "18px", fontFamily: "Averta", fontWeight: "600", color: "rgba(38, 38, 38, 1)",
                                                                        "@media (min-width: 900px) and (max-width: 1400px)": {
                                                                            fontSize: "16px",
                                                                            fontWeight: "600"
                                                                        },
                                                                    }} >Additional Documents</Text>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Box display='flex' flexDirection='row' sx={{
                                                                        background: '#F59E0B33 !important',
                                                                        height: '58px',
                                                                        borderRadius: '8px', gap: 2, alignItems: 'center', paddingLeft: '18px'
                                                                    }}>
                                                                        <img src={info} alt="info" style={{ height: '24px', width: '24px' }} />
                                                                        <Text mediumOrange>Please select and request documents from your employee.</Text>
                                                                    </Box>
                                                                </Grid>
                                                                {optionalData.length > 0 && optionalData.map((item) => (
                                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                        <Box className={item.checked == true ? classes.checkBoxbg : classes.checkBox} pl={1} alignItems='center'>
                                                                            <Checkbox size='medium' disabled={item.checked == true ? true : false} defaultChecked={item.checked == true ? true : false} name={item.name} className={classes.checkboxColor} onChange={checkBoxChange} />
                                                                            <Text largeLabel>{item.label}</Text>
                                                                        </Box>
                                                                    </Grid>

                                                                ))}
                                                            </Grid> : ''
                                                    }
                                                </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </TabPanel>
                        </SwipeableViews>
                        {/* </Box> */}
                    </Box>

                </Box>

                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ width: "45%", display: "flex", justifyContent: "end", alignItems: "center", padding: "20px 10px", gap: 2 }}>
                        <Button cancelOutlineRed onClick={() => { if (value == 1) { setValue(0) } else { setCancelPopup(true) } }}>{value == 1 ? 'Back' : 'Cancel'}</Button>
                        {
                            value == 1 ?
                                <Button finishFilledAverta onClick={handleSubmit}>Send Invite</Button> :
                                <Button finishFilledAverta onClick={handleSubmit}>Next</Button>
                        }
                    </Box>
                </Box>
                {
                    success &&
                    <ReusablePopup openPopup={success} setOpenPopup={setSuccess} white fixedWidth iconHide>
                        <Box textAlign='center' justifyContent='center'>
                            <img src={successImg} alt='success' style={{ height: '150px', width: '150px', marginBottom: '5px' }} />
                            {
                                employeeVerifyId ?
                                    '' :
                                    <Text largeGreen>Congratulations</Text>
                            }
                            {
                                employeeVerifyId
                                    ?
                                    <Text mediumBlack sx={{ marginTop: '8px !important' }}>Re - Request link Successfully sent.</Text>
                                    :
                                    <Text mediumBlack sx={{ marginTop: '8px !important' }}>Invite Link Successfully Sent to <b>{state.first_name}</b></Text>

                            }
                            <Button brownMnSave sx={{ margin: '20px 0px 0px 0px !important' }} onClick={goToHome}>Go To Home</Button>
                        </Box>
                    </ReusablePopup>
                }
                <BootstrapDialog
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="customized-dialog-title"
                    open={cancelPopup}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogContent sx={{ margin: "40px", }}>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                            <img src={cancelImg} alt='noactive' />
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                            <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                        </Grid>
                        <Grid container justifyContent={'center'} alignContent={'center'}>
                            <Text offBoardBodyFont>Do you really wish to cancel the invitation </Text>
                        </Grid>
                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                            <Stack direction={"row"} spacing={3}>
                                <Button smallBlueOutline onClick={() => { setCancelPopup(false) }}>
                                    No
                                </Button>
                                <Button redBackground onClick={() => {
                                    setCancelPopup(false);
                                    navigate('/employees')
                                    LocalStorage.removeRedirectedModule();
                                    LocalStorage.removeSubModule();
                                    LocalStorage.removeNotificationEmpId();
                                }}>
                                    Yes, Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </DialogContent>
                </BootstrapDialog>
            </Box >
        </Box >
    )
};
