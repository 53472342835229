import { Autocomplete, Box, FormHelperText, Paper, TextField, inputLabelClasses } from '@mui/material';
import React from 'react';
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import flag from '../../assets/employee/flag.png';

function InputSearchSelect(props) {
    const { options, name, label, onChange, value, helperText, ...extra } = props;
    /**
   * Props:
   * - name: target name for input, expected type string
   * - value: target value, expected type string
   * - onChange: onChange functionality, expected type Function
   * - label: label name for input field, expected type string
   * - options: Menu items for select field, expected type Array
   * - helperText: error message is placed below the input field
   */


    const CustomPaper = React.forwardRef((props, ref) => (
        <Paper {...props} ref={ref}
            style={{
                maxHeight: 180,
                overflowY: 'auto',  // Set overflow to 'auto' to enable the scrollbar
                scrollbarWidth: 'none',  // Set the width of the scrollbar (thin, auto, etc.)
                msOverflowStyle: 'none',  // Hide scrollbar for IE and Edge
                "&::-webkit-scrollbar": {
                    display: "none",  // Hide scrollbar for Chrome, Safari, and newer versions of Edge
                },
            }} />
    ));

    return (
        <div style={{ marginTop: "-8px" }}>
            <Autocomplete
                {...extra}
                popupIcon={<KeyboardArrowDownIcon />}
                PaperComponent={CustomPaper}
                name={name}
                options={options}
                autoHighlight
                value={value}
                onChange={onChange}
                getOptionLabel={(option) => (option ? option.value : '')}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.value ? option.value : null}
                        {option.days ? option.days : null}
                        {option.name ? option.name : null}
                        {option.parameter ? option.parameter : null}
                        {option.date ? option.date : null}
                    </Box>
                )}
                renderInput={(params) => (
                    <>
                        <TextField
                            {...params}
                            label={label}
                            variant="filled"
                            margin="dense"
                            InputLabelProps={{
                                sx: {
                                    color: helperText && value == null ? 'red' : '#737373',
                                    font: '14px Averta !important',
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: helperText && value == null ? 'red' : '#0C75EB',
                                        font: '14px Averta !important',
                                    },
                                    fontWeight: `${400} !important`
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: { font: '14px Averta', fontWeight: 600, color: "#525252 !important" },
                                // Conditionally render the clear icon based on the value
                                // endAdornment: value ? params.InputProps.endAdornment : null,
                                // endAdornment: value || params.inputProps.value ? params.InputProps.endAdornment : null,
                                // startAdornment:
                                //     <img src={flag} alt='flag' style={{ height: '20px', width: '20px',borderRight:'1px solid grey' }} />

                            }}
                            sx={{
                                width: '100%',
                                '& .MuiFilledInput-root': {
                                    borderRadius: '8px',
                                    background: props.disabled ? "#FAFAFA" : '#ffffff',
                                    border: props.disabled ? 'none' : helperText && value == null ? '1px solid red !important' : '1px solid #C7CCD3',
                                    '&.Mui-focused': {
                                        background: '#ffffff',
                                        border: props.disabled ? 'none' : helperText && value == null ? '1px solid red !important' : "1px solid #0C75EB",
                                    },
                                    '.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input.Mui-disabled': {
                                        '-webkit-text-fill-color': '#525252'
                                    },

                                    '&:hover': {
                                        background: props.disabled ? "#FAFAFA" : '#ffffff',
                                    },
                                },
                            }}
                        />
                    </>
                )}
                sx={{
                    marginTop: '-8px',
                }}
            />
            {helperText && <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>}
        </div>
    )
}

InputSearchSelect.propTypes = {
    onChange: PropTypes.func,  // onChange function prop
    name: PropTypes.string,  // Name prop
    value: PropTypes.any,  // Value prop
    label: PropTypes.string,  // Label prop
    options: PropTypes.array,  // Options prop
    helperText: PropTypes.bool,  // Helper text prop
};

export default InputSearchSelect;
