import { Grid, Box, Stack, Skeleton, Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../components/customText/Text';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import AnalyticStyles from './AnalyticStyles';
import ReactApexChart from 'react-apexcharts';
import AnalyticsApiTimesheet from '../../../../apis/admin/timesheets/AnalyticsApiTimesheet';
import { BlackToolTip, NoDataFound, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, removeLoader } from '../../../../utils/utils';
import Verified from '../../../../assets/svg/Component87.svg';
import LocalStorage from '../../../../utils/LocalStorage';
import CommonApi from '../../../../apis/CommonApi';
import disableVerified from '../../../../assets/client/disableVerified.svg';
import Tooltip from "@mui/material/Tooltip";
import infoCircle from '../../../../assets/images/info-circle.png';


function PlacementAnalystics() {
    const navigate = useNavigate();
    const classes = AnalyticStyles();
    const location = useLocation();
    const data = location && location.state.data
    const dropdown = location && location.state.dropdown
    const [active, setActive] = useState('USD');
    const [activeID, setActiveID] = useState(data.placement_id);
    const [loader, setLoader] = useState(false);
    const [cardData, setCardData] = useState({
        id: '',
        client_name: '',
        logo_url: '',
        profile_percentage: '',
        reference_id: '',
        total_billed: '',
        total_hours_logged: '',
        total_pay: '',
        total_placements: ''
    })
    const [dollar, setDollar] = useState({
        series: [],
        labels: [],
        dates: [],
        colors: []
    })
    const [hours, setHours] = useState({
        series: [],
        labels: [],
        dates: [],
        colors: []
    })
    const [placementList, setPlacementList] = useState([]);
    const [years, setYears] = useState([]);

    const hoursList = [
        { color: '#9747FF', label: 'Week 1' },
        { color: '#165DFF', label: 'Week 2' },
        { color: '#14C9C9', label: 'Week 3' },
        { color: '#F7BA1E', label: 'Week 4' },
        { color: '#F9A791', label: 'Week 5' }
    ]

    const quarterList = require('../../../../utils/jsons/QuarterList.json');

    const [state, setState] = useState({
        selectedYear: dropdown.selectedYear,
        selectQuarter: dropdown.selectQuarter,
        selectQuarterName: ''
    })
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    useEffect(() => {
        defaultYears();
        if (dropdown.selectQuarter == '' || dropdown.selectQuarter == null || dropdown.selectQuarter == undefined) {
            state['selectQuarterName'] = ''
            setState({ ...state });
        } else {
            let a = quarterList.filter((item) => item.id == dropdown.selectQuarter)
            state['selectQuarterName'] = a[0].value
            setState({ ...state })
        }
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
            PlacementCard(state.selectedYear, data.placement_id, data.id);
            getPlacements(state.selectedYear, state.selectQuarter);
        }// eslint-disable-next-line
    }, [])

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }


    const PlacementCard = (year, args, employee_id) => {
        setLoader(true);
        let changeQuarter = state.selectQuarter == 'All' ? '' : state.selectQuarter
        AnalyticsApiTimesheet.getPlacementCardDetails(year, changeQuarter, args, employee_id).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                setLoader(false);
                setCardData(res.data.data.placement_details);
                dollar['series'] = res.data.data.billed_amount_in_dollars.series
                dollar['labels'] = res.data.data.billed_amount_in_dollars.labels
                dollar['dates'] = res.data.data.billed_amount_in_dollars.dates
                dollar['colors'] = res.data.data.billed_amount_in_dollars.colors
                setDollar({ ...dollar });
                hours['series'] = res.data.data.billed_amount_in_hours.series
                hours['labels'] = res.data.data.billed_amount_in_hours.labels
                hours['dates'] = res.data.data.billed_amount_in_hours.dates
                hours['colors'] = res.data.data.billed_amount_in_hours.colors
                setHours({ ...hours });
            } else {
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const getPlacements = (year, quarter) => {
        setLoader(true);
        let changeQuarter = quarter == 'All' ? '' : quarter
        AnalyticsApiTimesheet.getPlacementList(year, changeQuarter, data.id).then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                setLoader(false);
                setPlacementList(res.data.data);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const changeHandler = (e) => {
        if (e.target.name == 'selectQuarter') {
            let name = quarterList.filter((i) => i.id == e.target.value)
            state[e.target.name] = e.target.value
            state['selectQuarterName'] = name[0].value
            setState({ ...state });
            addLoader(true);
            PlacementCard(state.selectedYear, data.placement_id, data.id);
            getPlacements(state.selectedYear, e.target.value);
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
            addLoader(true);
            PlacementCard(e.target.value, data.placement_id, data.id);
            getPlacements(e.target.value, state.selectQuarter);
        }
    }

    const handleTab = (args) => {
        setActive(args);
    }

    const areaOptions = {
        series: dollar.series,
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: dollar.colors,
            width: 0,
            curve: 'straight'
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none'
                }
            }
        },
        fill: {
            colors: dollar.colors,
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false,
            total: {
                enabled: true,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        formatter: function (val) {
                            return val != 0 ? active == 'hours' ? val : '$' + val : '';
                        },
                        style: {
                            fontSize: '13px',
                            fontWeight: 400,
                            color: '#1D2129'
                        }
                    }
                }
            },
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                return `
                  <div style="padding: 10px; color: black; background: #fff; border-radius: 5px;">
                    <strong>${category}</strong>:  $ ${value}
                  </div>
                `;
            },
            style: {
                fontSize: '14px',
                fontFamily: 'Averta',
                background: 'fff'
            },
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            categories: dollar.labels,
            labels: {
                show: true,
                style: {
                    fontSize: '10px',
                    fontWeight: 400,
                }
            }
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        }
    }

    const hourlyOptions = {
        series: hours.series,
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none'
                }
            }
        },
        stroke: {
            show: true,
            colors: hours.colors,
            width: 0,
            curve: 'straight'
        },
        fill: {
            colors: hours.colors,
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const category = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                return `
                  <div style="padding: 10px; color: black; background: #fff; border-radius: 5px;">
                    <strong>${category}</strong>: ${value}
                  </div>
                `;
            },
            style: {
                fontSize: '14px',
                fontFamily: 'Averta',
                background: 'fff'
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        formatter: function (val) {
                            return val != 0 ? val : '';
                        },
                        style: {
                            fontSize: '13px',
                            fontWeight: 400,
                            color: '#1D2129'
                        }
                    }
                }
            },
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            categories: hours.labels,
            labels: {
                show: true,
                style: {
                    fontSize: '10px',
                    fontWeight: 400,
                }
            }
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        }
    }

    const activePlacement = (args) => {
        setActiveID(args.id);
        addLoader(true);
        PlacementCard(state.selectedYear, args.id, args.employee_id);
    }

    return (
        <Grid container pl={15} pt={2} pb={2}>
            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                <>
                    <Grid item container lg={11.5} xs={11} mb={2}>
                        <Grid item lg={9} sm={7} xs={9} display='flex' flexDirection='row' gap={1} alignItems='center'>
                            <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={() => navigate('/placement/timesheets-analytics', { state: { activeState: 'placement' } })} />
                            <Text boldblack600 pl={1}>Placement Analytics</Text>
                        </Grid>
                        <Grid item container lg={3} sm={5} xs={3} spacing={2}>
                            <Grid item lg={5} xs={6}>
                                <CustomSelect
                                    sx={{
                                        '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                            // 'min-height': '30px',
                                            'minWidth': 'auto',
                                        }
                                    }}
                                    viewDrop
                                    scrollTrue={true}
                                    name='selectedYear'
                                    options={years}
                                    value={state.selectedYear}
                                    onChange={changeHandler}
                                />
                            </Grid>
                            <Grid item lg={7} xs={6}>
                                <CustomSelect
                                    sx={{
                                        '.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select': {
                                            // 'min-height': '35px',
                                            'minWidth': 'auto'
                                        }
                                    }}
                                    viewDrop
                                    scrollTrue={true}
                                    name='selectQuarter'
                                    options={quarterList}
                                    value={state.selectQuarter}
                                    onChange={changeHandler}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={11.5} sm={12} xs={11} className={classes.Clientaccordian}>
                        <Grid item lg={3} md={3} sm={3}>
                            <Box display='flex' alignItems='center' gap={2}>
                                {
                                    loader ?
                                        <Skeleton animation='wave' width="40px" height='65px' style={{ borderRadius: '50%' }} /> :
                                        <CircularProgress
                                            percentage={cardData.profile_percentage}
                                            color={cardData.profile_percentage >= 76 ? 'green' : cardData.profile_percentage <= 75 && cardData.profile_percentage >= 51 ? 'yellow' : cardData.profile_percentage <= 50 ? "#FFBF00" : ''}
                                            src={cardData.logo_url}
                                            name={cardData.logo_url == undefined || cardData.logo_url == null || cardData.logo_url == '' ? cardData.client_name && cardData.client_name[0] : ''}
                                            imgWidth='50px !important'
                                            imgHeight='50px !important'
                                            avatarWidth='50px !important'
                                            avatarHeight='50px !important'
                                            value={cardData.profile_percentage}
                                            size={58}
                                            tooltip
                                            tooltipContent={
                                                <Box p={'3px 0px'} width='100%'>
                                                    <Text mediumBoldWhite400>{`Profile Completion - ${cardData.profile_percentage}%`}</Text>
                                                </Box>
                                            }
                                        />
                                }
                                <Box>
                                    {
                                        loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                            <Text mediumBoldBlack>
                                                {cardData.employee_name ? cardData.employee_name.length > 16 ?
                                                    <BlackToolTip title={capitalizeAndAddSpace(cardData.employee_name)} placement="top" arrow>
                                                        {capitalizeAndAddSpace(cardData.employee_name.slice(0, 16)) + capitalizeAndAddSpace(cardData.employee_name.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    : capitalizeAndAddSpace(cardData.employee_name) : '-'
                                                }
                                                {
                                                    cardData.e_verified != 0 ?
                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has Been E-Verified</Text>}>
                                                            <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                        </BlackToolTip>
                                                        :
                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has not E-Verified</Text>}>
                                                            <img src={disableVerified} alt='svg' style={{ margin: '0px 0px -3px 3px' }} />
                                                        </BlackToolTip>
                                                }
                                            </Text>
                                    }
                                    {
                                        loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                            <Text mediumGreybold noWrap sx={{ paddingTop: '8px' }}>{cardData.reference_id ? cardData.reference_id : '-'}</Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container spacing={2} lg={9} xs={9} sm={9}>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Placements</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_placements || cardData.total_placements == 0 ? cardData.total_placements : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Logged Hours</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_hours_logged || cardData.total_hours_logged == 0 ? `${cardData.total_hours_logged} hrs` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2.5} xs={2.5}>
                                <Text largeLabel noWrap>Total Billed</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_billed || cardData.total_billed == 0 ? `${LocalStorage.getCurrencySymbol()} ${cardData.total_billed}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel noWrap>Total Pay</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_pay || cardData.total_pay == 0 ? `${LocalStorage.getCurrencySymbol()} ${cardData.total_pay}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <Text largeLabel noWrap>Margin</Text>
                                {
                                    loader ? <Skeleton animation="wave" className={classes.textSkeleton} /> :
                                        <Text smallBoldBlack sx={{ paddingTop: '8px' }} noWrap>{cardData.total_margin || cardData.total_margin == 0 ? `${LocalStorage.getCurrencySymbol()}  ${cardData.total_margin}` : '-'}</Text>
                                }
                            </Grid>
                            <Grid item lg={0.5} xs={0.5} container display={'flex'} justifyContent={'center'} alignItems={'center'}>
                               <Tooltip
                                    placement="bottom-end"
                                    arrow
                                    PopperProps={{ disablePortal: true }}
                                    sx={{fontSize:'14px !important'}}
                                    title= "The Total pay and Margin values are approximated and not accurate for salaried employees."
                                    >
                                    <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container lg={11.5} xs={11} spacing={2} mt={1} alignItems='center'>
                        <Grid item lg={9} md={8} sm={6} xs={12}>
                            <Text black14>Billed {active == 'USD' ? 'Amount' : 'Hours'} {state.selectQuarter == '' ? '' : `${(state.selectQuarterName)}`}</Text>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12} textAlign='end'>
                            <Box className={classes.tabBg}>
                                <Box className={active == 'USD' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('USD')}>
                                    {
                                        active == 'USD' ?
                                            <Text largeWhite400>In USD ($)</Text> :
                                            <Text blackHeader>In USD ($)</Text>
                                    }
                                </Box>
                                <Box className={active == 'hours' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('hours')}>
                                    {
                                        active == 'hours' ?
                                            <Text largeWhite400>In Hours (hrs)</Text> :
                                            <Text blackHeader>In Hours (hrs)</Text>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container lg={11.5} xs={11} spacing={2} mt={1}>
                        <Grid item container lg={4} md={12} sm={12} xs={12}>
                            <Grid item lg={12} xs={12}>
                                <Text mediumBoldBlack700>Placement List</Text>
                            </Grid>
                            {
                                placementList.length == 0 && !loader ?
                                    NoDataFound('')
                                    :
                                    <Box sx={{
                                        height: '300px', overflowY: 'scroll', width: '100%'
                                    }}>
                                        {
                                            placementList.map((item) => (
                                                <Grid item container lg={12} xs={12} alignItems='center' onClick={() => activePlacement(item)}
                                                    sx={{
                                                        background: (activeID == item.id) ? '#EDF5FE' : '#fff', height: '54px', padding: '2px 10px', margin: '6px 0px', alignItems: 'center', cursor: 'pointer',
                                                        '&:hover': {
                                                            borderRadius: '8px',
                                                            transition: "all .3s ease",
                                                            boxShadow: '0px 0px 15px 1px #cce9ffeb !important'
                                                        }
                                                    }}
                                                >
                                                    <Grid item container lg={7} md={6} sm={6} xs={6} columnSpacing={2} pt={'4px'}>
                                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                                            {
                                                                loader ? <Skeleton animation="wave" height='40px' width='35px' style={{ borderRadius: '50%' }} /> :
                                                                    <Avatar src={item.profile_picture_url ? item.profile_picture_url : item.client_name && capitalizeAndAddSpace(item.client_name[0])} alt={item.client_name && capitalizeAndAddSpace(item.client_name[0])} className={classes.clientLogo} />
                                                            }
                                                        </Grid>
                                                        <Grid item lg={9} md={9} sm={9} xs={9}>
                                                            {
                                                                loader ? <Skeleton animation="wave" className={classes.placementSkeleton} /> :
                                                                    <Text blue14 sx={{ paddingBottom: '4px' }}>
                                                                        {
                                                                            item.client_name ? item.client_name.length > 16 ?
                                                                                <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                                    {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                                </BlackToolTip>
                                                                                : capitalizeAndAddSpace(item.client_name) : '--'
                                                                        }
                                                                    </Text>
                                                            }
                                                            {
                                                                loader ? <Skeleton animation="wave" className={classes.placementSkeleton} /> :
                                                                    <Text mediumGrey700>{item.client_reference_id ? item.client_reference_id : '-'}</Text>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={5} md={6} sm={6} xs={6}>
                                                        {
                                                            loader ? <Skeleton animation="wave" className={classes.placementSkeleton} /> :
                                                                <Text smallBlackBold sx={{ paddingBottom: '4px' }}>{item.start_date} {item.end_date ? `to ${item.end_date}` : `to Current`}</Text>
                                                        }
                                                        {
                                                            loader ? <Skeleton animation="wave" className={classes.placementSkeleton} /> :
                                                                <>
                                                                    {
                                                                        item.status == 'Active' ?
                                                                            <Text mediumGreen700>{item.status ? item.status : '-'}</Text> :
                                                                            <Text grey700>{item.status ? item.status : '-'}</Text>
                                                                    }
                                                                </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Box>
                            }
                        </Grid>
                        <Grid item container lg={8} md={12} sm={12} xs={12} sx={{ paddingRight: '20px' }}>
                            <Grid item lg={12} xs={12} mt={1}>
                                <Grid item container lg={12} xs={12} justifyContent='end'>
                                    {
                                        hoursList.map((series, key) => (
                                            <Grid item lg={1}>
                                                <Stack spacing={1} direction="row" alignItems='center'>
                                                    <Box sx={{ height: "12px", width: "12px", backgroundColor: series.color, borderRadius: "50%" }}></Box>
                                                    <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                        <Text smallLabelblack>{series.label}</Text>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                {
                                    active === 'hours' ?
                                        <ReactApexChart
                                            options={hourlyOptions}
                                            series={hourlyOptions.series}
                                            type="bar"
                                            height='300vh'
                                        /> :
                                        <ReactApexChart
                                            options={areaOptions}
                                            series={areaOptions.series}
                                            type="bar"
                                            height='300vh'
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <>
                    <Grid item lg={9} xs={6} display='flex' flexDirection='row' gap={1} alignItems='center'>
                        <Text boldblack600 pl={1}>Placement Analytics</Text>
                    </Grid>
                    {NoPermission()}
                </>
            }
        </Grid>
    )
}

export default PlacementAnalystics