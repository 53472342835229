import { makeStyles } from "@mui/styles";

const workAuthStyles = makeStyles(()=>({
    ViewContainer: {
        height: '45vh',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    },
    optional: {
        font: '12px Averta !important',
        color: 'rgb(194 188 188) !important',
        marginLeft: '2px !important'
    },
}))

export default workAuthStyles;