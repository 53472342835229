import React, { useRef } from 'react'
import Button from '../customButton/Button';
import FileInputStyles from './FileInputStyles';
// import { ReactComponent as PaperClip } from '../../assets/svg/Browse.svg';
// import browse from '../../assets/svg/Browse.svg';
import uploadArrowBlue from '../../assets/employee/blue-upload.svg';
import { Box } from '@mui/material';
import eye from '../../assets/employee/viewithBg.svg';
import deleteIcon from "../../assets/employee/deletewithBg.svg";
import { openDocumentInNewtab } from '../../utils/utils';

export default function FileInput(props) {
    const { name, handleChange, label, isDisabled, FileName, actionState, value, handleDelete, disabled, uploadKeyName, error, documentUrl, state, required } = props;

    const fileInputRef = useRef(null);

    const handleFileClick = () => {
        if (!isDisabled) {
            fileInputRef.current.click();
        }
    };

    const onInputClick = (event) => {
        event.target.value = ''
    }

    const openInNewTab = (documentUrl) => {
        if (state == undefined || state == null) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            if (state.aws_s3_status == 0 || state.aws_s3_status == false) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            } else if (state.aws_s3_status == undefined || state.aws_s3_status == undefined) {
                const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null
            }
            else {
                openDocumentInNewtab(state.document_key, state.document_path)
            }
        }
    }

    const classes = FileInputStyles();
    return (
        <Box className={`${isDisabled || disabled ? classes.rootDisabled : error ? classes.errorRoot : classes.root}`}>
            <Box className={classes.inputField}>

                {FileName != '' ?
                    (
                        <>
                            {error ?
                                <Box className={classes.labelActiveRed}>{label} {required ?<span style={{color: 'red'}}>*</span> : '' }</Box> :
                                (isDisabled || disabled) ?
                                    <Box className={classes.labeldisabled}>{label} {required ?<span style={{color: 'red'}}>*</span> : '' }</Box> :
                                    <Box className={classes.labelActive}>{label} {required ?<span style={{color: 'red'}}>*</span> : '' }</Box>
                            }
                            <Box className={classes.fileName}>
                                {FileName}
                            </Box>
                        </>
                    )
                    : (error ? <Box className={classes.labelRed}>{label}</Box> : <Box className={classes.label}>{label}{required ?<span style={{color: 'red'}}>*</span> : '' }</Box>)}
            </Box>

            {
                actionState == 'deleteState' ?
                    <Box display='flex' flexDirection='row' columnGap={1}>
                        <img src={eye} alt='view' className={classes.viewIcon} onClick={() => openInNewTab(documentUrl)} />
                        <img src={deleteIcon} alt='delete Icon' />
                    </Box>
                    : actionState == 'view' ?
                        <img src={eye} alt='view' className={classes.cursor} onClick={() => openInNewTab(documentUrl)} />
                        : actionState == 1 ?
                            <>
                                {
                                    isDisabled || disabled ?
                                        <img src={eye} alt='view' className={classes.cursor} onClick={() => openInNewTab(documentUrl)} /> :
                                        <Box display='flex' flexDirection='row' gap={2} textAlign='center' alignItems='center'>
                                            <img src={eye} alt='view' className={classes.cursor} onClick={() => openInNewTab(documentUrl)} />
                                            <img src={deleteIcon} alt='delete Icon' className={classes.cursor} onClick={handleDelete} />
                                        </Box>
                                }
                            </>
                            :
                            actionState == "Upload" ?
                                <Button
                                    browseBtnUpload
                                    startIcon={<img src={uploadArrowBlue} alt='browse' />}
                                    onClick={handleFileClick}
                                    disabled={isDisabled}
                                >
                                    {uploadKeyName ? `${uploadKeyName}` : 'Upload'}
                                </Button>
                                :
                                <Button
                                    browseBtn
                                    startIcon={<img src={uploadArrowBlue} alt='browse' />}
                                    onClick={handleFileClick}
                                    disabled={isDisabled}
                                >
                                    {uploadKeyName ? `${uploadKeyName}` : 'Upload'}
                                </Button>
            }
            <input
                name={name}
                type='file'
                onClick={onInputClick}
                className={classes.input}
                onChange={handleChange}
                ref={fileInputRef}
                actionState={actionState}
                value={value}
                handleDelete={handleDelete}
                disabled={disabled}
            />

        </Box>
    )
}
