import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Button from '../customButton/Button';
import { useDropzone } from 'react-dropzone';
import DropZoneStyles from './DropZoneStyles';
// import Text from "../customText/CustomText";
import Text from '../customText/Text';
import UploadSvg from '../../assets/svg/UploadSvg.svg';
import UploadSvg1 from '../../assets/svg/UploadSvg1.svg';

function Dropzone(props) {
    const classes = DropZoneStyles();
    const onDrop = useCallback(acceptedFiles => {
        props.callApi(acceptedFiles)
        // eslint-disable-next-line
    }, [])
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ noClick: true, onDrop })

    return (
        <Box {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <Text normal my={1} className={classes.dropzoneText}>{
                        props.education ? "Add educational documents such as transcripts, diplomas, certificates, etc." : props.otherDocuments ? "drop field and uploaded documents similar to work authorization and educational documents" : "Drop the file here ..."}</Text> :
                    <Box className={classes.dropzoneContentContainer}>
                        {
                            props.multiDocs ?
                                <Box className={classes.dropzoneContentBox}>
                                    {props.education ? "" : <img src={UploadSvg} alt="UploadSvg" />}
                                    <Box>
                                        <Text my={props.education ? 0 : 1} className={classes.dropzoneText1}>Drag & Drop  Documents <span className={classes.or}>  or</span><Button className={props.disableDrop ? classes.browseButton1 : classes.browseButton} onClick={props.disableDrop ?"":open} >Click here</Button> To Upload</Text>
                                    </Box>
                                    {
                                        props.education ?
                                            <Text sx={{ font: "14px Averta !important", fontWeight: `${400} !important`, color: "#D97706 !important" }}>Add your educational documents like Grade card,<br /> certificates, transcripts, etc</Text> :
                                            props.otherDocuments ?
                                                <Text sx={{ font: "14px Averta !important", fontWeight: `${400} !important`, color: "#D97706 !important" }}>Add any other documentation like Countersigned Offer Letter,<br /> Other Personal and Visa related documents.</Text> :
                                                <Text sx={{ font: "14px Averta !important", fontWeight: `${400} !important`, color: "#D97706 !important" }}>*Add visa documents like EAD, I20's, I983, I-129, I-797, <br />GC, GC EAD, etc</Text>
                                    }
                                    {props.education && <img src={UploadSvg1} alt="UploadSvg" style={{ paddingTop: "15px" }} />}
                                    <Text sx={{ font: "14px Averta !important", fontWeight: `${400} !important`, color: "#737373 !important", marginTop: "15px !important" }}>Document Size 25MB, Format - PDF, Docs</Text>
                                    {/* <Box className={classes.browseBtnBox}>
                                    </Box> */}
                                </Box>
                                :
                                <Box className={classes.dropzoneContentBox}>
                                    {/* <img src={UploadSvg} alt="UploadSvg" /> */}
                                    <Text my={1} className={classes.dropzoneText}>Drag & Drop timesheets to Create Template</Text>
                                    <Text my={1} className={`${classes.dropzoneText} ${classes.or}`}>or</Text>
                                    <Box className={classes.browseBtnBox}>
                                        <Button className={classes.browseButton} onClick={open} >
                                            Click here
                                        </Button>
                                    </Box>
                                </Box>
                        }

                    </Box>
            }
        </Box>
    )
}

export default Dropzone;