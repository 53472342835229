import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Dialog, Grid, Slide, Stack } from '@mui/material';
import Input from '../../../../../components/input/Input';
import Text from '../../../../../components/customText/Text';
import { validate_emptyField, validates_Integer, validate_routingNumber, validate_accountNumber, onNumberOnlyChange, validate_bank_name, validate_deposit_value } from "../../../../../components/Validation";
import Button from '../../../../../components/customButton/Button';
import CommonApi from '../../../../../apis/CommonApi';
import { addErrorMsg, addLoader, addWarningMsg, removeLoader } from '../../../../../utils/utils';
import LocalStorage from "../../../../../utils/LocalStorage";
import FileInput from '../../../../../components/muiFileInput/FileInput';
import Select from '../../../../../components/select/Select';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import OnBoardApi from '../../../../../apis/admin/employees/OnBoardApi';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import successImg from '../../../../../assets/svg/succesIcon.svg';
import sampleVoid from '../../../../../assets/svg/sampleVoid.svg';
import offBoardSure from '../../../../../assets/svg/offBoardSure.svg';
import { blue } from '../../../../../theme';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '../../../../../components/customButton/LoadingButton';
import minus from '../../../../../assets/client/minus-circle.svg';

const AccountTypeOptions = [
    {
        id: 1,
        value: "Savings"
    },
    {
        id: 2,
        value: "Current"
    }
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"up"} ref={ref} {...props} />;
});

export default function NewBankDetails(props) {

    const { inviteData, handleBackBankToDocs } = props;

    const navigate = useNavigate();
    const [deleteId, setdeleteId] = useState([]);
    const [success, setSuccess] = useState(false);
    const [depositTypeOptions, setDepositTypeOptions] = useState([{
        id: 1,
        value: 'Full Net',
        disabled: false
    },
    {
        id: 2,
        value: 'Partial $',
        disabled: false
    },
    {
        id: 3,
        value: 'Partial %',
        disabled: false
    },
    {
        id: 4,
        value: 'Remainder',
        disabled: false
    }]);
    const [state, setState] = useState({
        deposit_form_documents: [
            {
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: '',
                slug: ""
            }
        ],
        i9_form_documents: [
            {
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: '',
                slug: ""
            }
        ],
        bank_information: [
            {
                bank_name: "",
                account_type: "",
                account_number: "",
                confirm_account_number: "",
                routing_number: "",
                confirm_routing_number: "",
                deposit_type: '',
                deposit_value: "",
                void_cheque_documents: [
                    {
                        id: "",
                        new_document_id: "",
                        document_url: "",
                        document_name: "",
                        slug: ""
                    }
                ],
            }]
    }
    );
    const [error, setError] = useState([]);
    const [w4error, setW4error] = useState({ deposit_form_documents: '' })
    const [i9error, setI9error] = useState({ deposit_form_documents: '' })
    const [fullNetPopup, setFullNetPopup] = useState(false) // eslint-disable-next-line
    const [fullNetIndex, setFullNetIndex] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (inviteData != null) {
            for (const key in inviteData.upload_documents) {
                const dataArray = inviteData.upload_documents[key].data;
                if (dataArray.length >= 1) {
                    const item = dataArray[0];
                    if (item.name === "copy_of_void_cheque") {
                        const approved = dataArray.find(item => item.approval_status == 'Approved')
                        if (approved) {
                            state.bank_information.splice(0, 1);
                            setState({ ...state });
                        }
                        for (var i in dataArray) {
                            if (dataArray[i].approval_status == "Approved") {
                                var newState = {
                                    bank_account_details_id: '',
                                    bank_name: dataArray[i].bank_name,
                                    account_number: dataArray[i].account_number,
                                    confirm_account_number: dataArray[i].account_number,
                                    routing_number: dataArray[i].routing_number,
                                    confirm_routing_number: dataArray[i].routing_number,
                                    account_type: dataArray[i].account_type,
                                    deposit_type: dataArray[i].deposit_type,
                                    deposit_value: [2, 3].includes(dataArray[i].deposit_type) ? dataArray[i].deposit_value : '',
                                    description: '',
                                    void_cheque_documents: [
                                        {
                                            new_document_id: dataArray[i].new_document_id,
                                            document_name: dataArray[i].document_name,
                                            document_url: dataArray[i].document_url,
                                            document_slug: dataArray[i].name,
                                            slug: "invite_via_link",

                                        }
                                    ],
                                }
                                state.bank_information.push(newState);
                            }
                        }
                    }
                }
            }
            if (inviteData.i9andw4.w4_document.approval_status == 'Approved') {
                state.deposit_form_documents = [
                    {
                        new_document_id: inviteData.i9andw4.w4_document.new_document_id,
                        document_slug: 'i9andw4',
                        slug: "invite_via_link",
                        document_name: inviteData.i9andw4.w4_document.document_name,
                        document_url: inviteData.i9andw4.w4_document.document_url
                    }
                ]
            }
            if (inviteData.i9andw4.i9_document.approval_status == 'Approved') {
                state.i9_form_documents = [
                    {
                        new_document_id: inviteData.i9andw4.i9_document.new_document_id,
                        document_slug: 'i9andw4',
                        slug: "invite_via_link",
                        document_name: inviteData.i9andw4.i9_document.document_name,
                        document_url: inviteData.i9andw4.i9_document.document_url
                    }
                ]
            }
            setState({ ...state });

            const type = inviteData.upload_documents.copy_of_void_cheque.data[0]?.deposit_type
            if ([2, 3].includes(type)) {
                let disable = type == 2 ? 2 : 1;
                let enable = type == 2 ? 1 : 2;
                depositTypeOptions[disable].disabled = true
                depositTypeOptions[enable].disabled = false
                setDepositTypeOptions([...depositTypeOptions]);
            }
        }
        // eslint-disable-next-line
    }, []);

    /* fun is for Uploading Documents */
    const uploadDocs = (value, index, fieldName) => {
        addLoader(true)
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (((value.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
            CommonApi
                .documentUpload("bank-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader();
                        let docInfo = response.data.data;
                        let newStateArr = { ...state };
                        if (fieldName === "void_cheque_documents") {
                            // const data = {
                            //     request_id: LocalStorage.uid(),
                            //     document_id: response.data.data.id
                            // }
                            // EmployeeCreateAPI.ocrDocumentUpload('cheque', data).then((res) => {
                            //     if (res.data.statusCode == 1003) {
                            //         removeLoader()
                            //         newStateArr.bank_information[index].void_cheque_documents[0].new_document_id = docInfo.id;
                            //         newStateArr.bank_information[index].void_cheque_documents[0].document_url = docInfo.document_url;
                            //         newStateArr.bank_information[index].void_cheque_documents[0].document_name = value.target.files[0].name;
                            //     } else {
                            //         removeLoader()
                            //         newStateArr.bank_information[index].void_cheque_documents[0].new_document_id = docInfo.id;
                            //         newStateArr.bank_information[index].void_cheque_documents[0].document_url = docInfo.document_url;
                            //         newStateArr.bank_information[index].void_cheque_documents[0].document_name = value.target.files[0].name;
                            //     }
                            //     setState({ ...newStateArr });
                            // })
                            newStateArr.bank_information[index].void_cheque_documents[0].new_document_id = docInfo.id;
                            newStateArr.bank_information[index].void_cheque_documents[0].document_url = docInfo.document_url;
                            newStateArr.bank_information[index].void_cheque_documents[0].document_name = value.target.files[0].name;
                            setState({ ...newStateArr });
                        } else if (fieldName === "i9_form_documents") {
                            removeLoader()
                            newStateArr.i9_form_documents[0].new_document_id = docInfo.id;
                            newStateArr.i9_form_documents[0].document_name = value.target.files[0].name;
                            newStateArr.i9_form_documents[0].document_url = docInfo.document_url;
                        }
                        else {
                            removeLoader()
                            newStateArr.deposit_form_documents[0].new_document_id = docInfo.id;
                            if (value.target.name == 'deposit_form_documents') {
                                newStateArr[value.target.name][0].document_url = docInfo.document_url;
                            }
                            else {
                                newStateArr[index][value.target.name][0].document_url = docInfo.document_url;
                            }
                            newStateArr.deposit_form_documents[0].document_name = value.target.files[0].name;
                        }
                        setState({ ...newStateArr });
                        removeLoader();
                        handleValidate(fieldName, value.target.value, index); // Pass the field name directly
                    } else {
                        addErrorMsg(response.data.message);
                        removeLoader();
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
    };

    const deleteDoc = (index, args) => {
        if (args == 'void_cheque_documents') {
            if (inviteData != null) {
                delete state.bank_information[index].void_cheque_documents[0]['document_slug'];
            }
            state.bank_information[index].void_cheque_documents[0]['slug'] = "";
            state.bank_information[index].void_cheque_documents[0].new_document_id = ''
            state.bank_information[index].void_cheque_documents[0].document_name = ''
            state.bank_information[index].void_cheque_documents[0].aws_s3_status = false
        } else if (args == 'deposit_form_documents') {
            if (inviteData != null) {
                delete state.deposit_form_documents[0]['document_slug'];
            }
            state.deposit_form_documents[0]['slug'] = "";
            state.deposit_form_documents[0].new_document_id = ''
            state.deposit_form_documents[0].document_name = ''
            state.deposit_form_documents[0].aws_s3_status = false
        }
        else if (args == 'i9_form_documents') {
            if (inviteData != null) {
                delete state.i9_form_documents[0]['document_slug'];
            }
            state.i9_form_documents[0]['slug'] = "";
            state.i9_form_documents[0].new_document_id = ''
            state.i9_form_documents[0].document_name = ''
            state.i9_form_documents[0].aws_s3_status = false
        }
        setState({ ...state });
        setDelDoc(null)
        setDeleteIndex(null);
        setDocDeleteOpen(false);
    }

    // const handleChange = (e, index) => {
    //     setCurrentIndex(index)
    //     let { name, value } = e.target;
    //     let bankDetailsArr = state.bank_information;
    //     if (name == "deposit_type") {
    //         if (index == 0) {
    //             if (value == 2 || value == 3) {
    //                 let deleteAll = deleteId;
    //                 let disable = value == 2 ? 2 : 1;
    //                 let enable = value == 2 ? 1 : 2;
    //                 const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                 deleteAll = deletedArray.map(obj => obj.id);
    //                 setdeleteId([...deleteAll]);
    //                 bankDetailsArr.splice(index + 1);
    //                 bankDetailsArr[index][name] = value
    //                 bankDetailsArr[index].deposit_value = ''
    //                 let errorBank = error[index] || {}
    //                 errorBank.deposit_value = ''
    //                 error.length = 0
    //                 error.push(errorBank)
    //                 setError([...error])
    //                 if (value == 2 && state.bank_information.length !== 5) {
    //                     addNewAccount(index, e.target.value);

    //                 }
    //                 // else if (value == 3 && state.bank_information[index].deposit_value > 100) {
    //                 //     bankDetailsArr[index][name] = value
    //                 //     bankDetailsArr[index].deposit_value = ''
    //                 //     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value, deposit_value: '' };
    //                 // } else if (value == 3 && state.bank_information[index].deposit_value < 100 && state.bank_information[index].deposit_value !== '') {
    //                 //     addNewAccount(index, e.target.value)
    //                 //     bankDetailsArr[index][name] = value
    //                 // } else {
    //                 //     bankDetailsArr[index][name] = value
    //                 // }
    //                 depositTypeOptions[disable].disabled = true
    //                 depositTypeOptions[enable].disabled = false
    //                 setDepositTypeOptions([...depositTypeOptions]);
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //             else if (bankDetailsArr.length > 1 && value == 1) {
    //                 setFullNetIndex(index)
    //                 setFullNetPopup(true)
    //             }
    //             else {
    //                 let deleteAll = deleteId;
    //                 if (bankDetailsArr[index].id) {
    //                     if (index !== 0) {
    //                         deleteAll.push(bankDetailsArr[index].id)
    //                     }
    //                 }
    //                 setdeleteId([...deleteAll]);
    //                 if (error.length > 0) {
    //                     error[index].deposit_value = ''
    //                 }
    //                 bankDetailsArr.splice(1);
    //                 bankDetailsArr[0][name] = value
    //                 bankDetailsArr[0].deposit_value = ''
    //                 // bankDetailsArr[0] = { ...bankDetailsArr[0], [name]: value, deposit_value: '' };
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //         } else {
    //             if (value == 2) {
    //                 if (state.bank_information.length !== 5 && (value !== state.bank_information[index].deposit_type)) {
    //                     if (state.bank_information.length > 1) {
    //                         if (state.bank_information[index - 1].deposit_type == e.target.value) {
    //                             addNewAccount(index, e.target.value)
    //                             bankDetailsArr[index][name] = value
    //                             depositTypeOptions[2].disabled = true
    //                             setDepositTypeOptions([...depositTypeOptions])
    //                             setState({
    //                                 ...state,
    //                                 bank_information: [...bankDetailsArr]
    //                             });
    //                         }
    //                         else {
    //                             addErrorMsg('You cannot change the type');
    //                             bankDetailsArr[index][name] = bankDetailsArr[index].deposit_type
    //                             // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: bankDetailsArr[index].deposit_type };
    //                             setState({
    //                                 ...state,
    //                                 bank_information: [...bankDetailsArr]
    //                             });
    //                         }
    //                     }
    //                     else {
    //                         addNewAccount(index, e.target.value)
    //                         bankDetailsArr[index][name] = value
    //                         setState({
    //                             ...state,
    //                             bank_information: [...bankDetailsArr]
    //                         });
    //                         depositTypeOptions[2].disabled = true
    //                         setDepositTypeOptions([...depositTypeOptions])
    //                     }
    //                 }
    //                 else {
    //                     bankDetailsArr[index][name] = value
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                     depositTypeOptions[2].disabled = true
    //                     setDepositTypeOptions([...depositTypeOptions])
    //                 }
    //             }
    //             else if (value == 1) {
    //                 setFullNetIndex(index)
    //                 setFullNetPopup(true)
    //             }
    //             else if (value == 4) {
    //                 state.bank_information[index].deposit_value = ''
    //                 let sumArr = bankDetailsArr.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
    //                 let sumOFF = sumArr.reduce((a, b) => a + b)
    //                 const filteredArray = bankDetailsArr.filter(obj => obj.deposit_type === 3);
    //                 if (sumOFF === 0 && filteredArray.length > 0 && value.deposit_type == 3) {
    //                     let sampleData = []
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     if (deletedArray.length > 0 && deletedArray[index].id) {
    //                         deleteAll = deletedArray.map(obj => obj.id);
    //                     }
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr[index][name] = 1
    //                     bankDetailsArr[index].deposit_value = ''
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: 1, deposit_value: '' };
    //                     sampleData.push(bankDetailsArr[index])
    //                     if (error.length > 0) {
    //                         error[index].deposit_value = ''
    //                     }
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                 } else {
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     deleteAll = deletedArray.map(obj => obj.id);
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr.splice(index + 1);
    //                     bankDetailsArr[index][name] = value
    //                     bankDetailsArr[index].deposit_value = ''
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value, deposit_value: '' };
    //                     if (error.length > 0) {
    //                         error[index].deposit_value = ''
    //                     }
    //                     setState({
    //                         ...state,
    //                         bank_information: [...bankDetailsArr]
    //                     });
    //                 }
    //             }
    //             else {
    //                 state.bank_information[index].deposit_value = '';
    //                 const filteredArray = bankDetailsArr.filter(obj => (obj.deposit_type == 2 || obj.deposit_type == 3));
    //                 if (filteredArray.length == 0) {
    //                     let depositOptions = depositTypeOptions.map(obj => {
    //                         const newObj = { ...obj };

    //                         // Update the specified key with the new value
    //                         newObj['disabled'] = false;

    //                         return newObj;
    //                     })
    //                     setDepositTypeOptions([...depositOptions])
    //                 }
    //                 bankDetailsArr[index][name] = value
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             }
    //         }

    //         handleValidate(name, e.target.value, index);
    //     }
    //     else if (name == "deposit_value") {
    //         const filteredArray = bankDetailsArr.filter(obj => obj.deposit_type === 4);
    //         let sumArr = bankDetailsArr.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
    //         let sumOFF = sumArr.reduce((a, b) => a + b)
    //         if (bankDetailsArr[index].deposit_type == 3 && state.bank_information.length !== 5) {
    //             bankDetailsArr[index][name] = value
    //             if (sumOFF > 100) {
    //                 addErrorMsg("Please Enter Valid Deposit Percentage");
    //                 bankDetailsArr[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
    //                 // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value.length == 1 ? 0 : Math.floor(value / 10) };
    //             }
    //             else if (e.target.value == 100 || sumOFF == 100) {
    //                 let deleteAll = deleteId;
    //                 const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                 deleteAll = deletedArray.map(obj => obj.id);
    //                 setdeleteId([...deleteAll])
    //                 bankDetailsArr.splice(index + 1);
    //                 bankDetailsArr[index][name] = value
    //             }
    //             else if (filteredArray.length === 0) {
    //                 bankDetailsArr[index][name] = value
    //                 addNewAccount(index, 3)
    //             }
    //             setState({
    //                 ...state,
    //                 bank_information: [...bankDetailsArr]
    //             });
    //             depositTypeOptions[1].disabled = true;
    //             setDepositTypeOptions([...depositTypeOptions]);
    //         }
    //         else {
    //             if (state.bank_information.length < 5) {
    //                 bankDetailsArr[index][name] = value
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //             } else if (state.bank_information.length == 5) {
    //                 if (sumOFF > 100) {
    //                     addErrorMsg("Please Enter Valid Deposit Percentage");
    //                     bankDetailsArr[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
    //                     // bankDetailsArr[index] = { ...bankDetailsArr[index], [name]: value.length == 1 ? 0 : Math.floor(value / 10) };
    //                 } else if (sumOFF == 100 && filteredArray.length == 1) {
    //                     let deleteAll = deleteId;
    //                     const deletedArray = bankDetailsArr.slice(index + 1, bankDetailsArr.length);
    //                     deleteAll = deletedArray.map(obj => obj.id);
    //                     setdeleteId([...deleteAll])
    //                     bankDetailsArr.splice(index + 1);
    //                     bankDetailsArr[index][name] = value
    //                 } else {
    //                     bankDetailsArr[index][name] = value
    //                 }
    //                 setState({
    //                     ...state,
    //                     bank_information: [...bankDetailsArr]
    //                 });
    //                 depositTypeOptions[1].disabled = true;
    //                 setDepositTypeOptions([...depositTypeOptions]);
    //             }
    //         }
    //         handleValidate(name, e.target.value, index);
    //     }
    //     else {
    //         bankDetailsArr[index][name] = value
    //         setState({
    //             ...state,
    //             bank_information: [...bankDetailsArr]
    //         });
    //         handleValidate(name, e.target.value, index);
    //     }
    // }

    const handleChange = (e, index) => {
        setCurrentIndex(index);
        const name = e.target.name
        const value = e.target.value
        if (name == 'bank_name') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['account_type'] = ''
            // state.bank_information[index]['account_number'] = ''
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
            // error[index]['account_type'] = ''
            // error[index]['account_number'] = ''
            // error[index]['confirm_account_number'] = ''
            // error[index]['routing_number'] = ''
            // error[index]['confirm_routing_number'] = ''
            // error[index]['deposit_type'] = ''
            // error[index]['deposit_value'] = ''
            setError([...error])
            handleValidate(name, value, index)
        } else if (name == 'account_type') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['account_number'] = ''
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state })
        } else if (name == 'account_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['confirm_account_number'] = ''
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'confirm_account_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['routing_number'] = ''
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'routing_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['confirm_routing_number'] = ''
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'confirm_routing_number') {
            state.bank_information[index][name] = value
            // state.bank_information[index]['deposit_type'] = ''
            // state.bank_information[index]['deposit_value'] = ''
            setState({ ...state });
        } else if (name == 'deposit_type') {

            if (value == 1) {
                if (state.bank_information.length > 1) {
                    setFullNetPopup(true);
                }
                else {
                    state.bank_information[index][name] = value
                    state.bank_information[index]['deposit_value'] = ''
                    error[index]['deposit_value'] = ''
                    setState({ ...state })
                }
            } else {
                if (value == 4) {
                    error[index]['deposit_value'] = ''
                }
                state.bank_information[index][name] = value
                state.bank_information[index]['deposit_value'] = ''
                setState({ ...state })
            }
        }
        else if (name == 'deposit_value') {
            if (state.bank_information[index].deposit_type == 3) {
                let sumArr = state.bank_information.map((value) => { if (value.deposit_type == 3) { return Number(value.deposit_value != '' ? value.deposit_value : 0) } else { return 0 } });
                let sumOFF = sumArr.reduce((a, b) => a + b)
                if (sumOFF > 100) {
                    addWarningMsg('The deposit percentage cannot exceed 100');
                    state.bank_information[index][name] = value.length == 1 ? 0 : Math.floor(value / 10)
                    setState({ ...state });
                } else {
                    state.bank_information[index][name] = value
                    setState({ ...state });
                }
            } else {
                state.bank_information[index][name] = value
                setState({ ...state });
            }
        }
        else {
            state.bank_information[index][name] = value
            setState({ ...state })
        }
        handleValidate(name, value, index)
    }

    const handleValidate = (fieldName, value, index) => {
        let bankdetailsArr = state.bank_information;
        let errorsArr = Array.isArray(error) ? [...error] : [];
        if (!errorsArr[index]) {
            errorsArr[index] = {};
        }
        let errors = errorsArr[index];
        switch (fieldName) {
            case "bank_name":
                errors["bank_name"] = validate_bank_name(value, 'bank ');
                break;
            case "account_type":
                errors["account_type"] = validate_emptyField(value);
                break;
            case "account_number":
                errors["account_number"] = validate_accountNumber(value);
                if (bankdetailsArr[index].confirm_account_number !== '' && value !== bankdetailsArr[index].confirm_account_number) {
                    errors["confirm_account_number"] = "Account number not matching";
                } else {
                    errors["confirm_account_number"] = "";
                }
                break;
            case "confirm_account_number":
                if (value == "") {
                    errors["confirm_account_number"] = "This field is required";
                } else if (value != bankdetailsArr[index].account_number) {
                    errors["confirm_account_number"] = "Account number not matching";
                } else {
                    errors["confirm_account_number"] = "";
                }
                break;
            case "routing_number":
                errors["routing_number"] = validate_routingNumber(value);
                if (bankdetailsArr[index].confirm_routing_number !== '' && value != bankdetailsArr[index].confirm_routing_number) {
                    errors["confirm_routing_number"] = "Route number not matching";
                } else {
                    errors["confirm_routing_number"] = "";
                }
                break;
            case "confirm_routing_number":
                if (value == "") {
                    errors["confirm_routing_number"] = "This field is required";
                } else if (value != bankdetailsArr[index].routing_number) {
                    errors["confirm_routing_number"] = "Route number not matching";
                } else {
                    errors["confirm_routing_number"] = "";
                }
                break;
            case "deposit_type":
                errors["deposit_type"] = validate_emptyField(value);
                break;
            case "deposit_value":
                errors["deposit_value"] = validate_deposit_value(value);
                break;
            case "deposit_form_documents":
                w4error["deposit_form_documents"] = validate_emptyField(value);
                setW4error(w4error)
                break;
            case "i9_form_documents":
                i9error["deposit_form_documents"] = validate_emptyField(value);
                setI9error(i9error)
                break;
            case "void_cheque_documents":
                errors["void_cheque_documents"] = validate_emptyField(value);
                break;
            default:
                break;
        }
        errorsArr[index] = errors;
        setError(errorsArr);
    };

    const formValidations = () => {
        let result = [];
        let bankdetailsArr = state.bank_information;
        let errorsArr = [];
        w4error.deposit_form_documents = validate_emptyField(state.deposit_form_documents[0].document_name);
        setW4error(w4error)
        i9error.deposit_form_documents = validate_emptyField(state.i9_form_documents[0].document_name);
        setI9error(i9error)
        bankdetailsArr.forEach(bank => {
            let errors = {};
            errors.bank_name = validate_bank_name(bank.bank_name, 'bank ');
            errors.account_type = bank.bank_name == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.account_type);
            errors.account_number = bank.account_type == '' || bank.bank_name.length < 2 ? '' : validate_accountNumber(bank.account_number);
            if (bank.account_number != bank.confirm_account_number) {
                errors.confirm_account_number = 'Account number not matching';
            } else {
                errors.confirm_account_number = bank.account_number == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.confirm_account_number);
            }
            errors.routing_number = (bank.account_number != bank.confirm_account_number) ? '' : validate_routingNumber(bank.routing_number);
            if (bank.routing_number != bank.confirm_routing_number) {
                errors.confirm_routing_number = 'Routing number not matching';
            } else {
                errors.confirm_routing_number = bank.routing_number == '' || bank.bank_name.length < 2 ? '' : validates_Integer(bank.confirm_routing_number);
            }
            errors.deposit_type = bank.confirm_routing_number == '' || bank.bank_name.length < 2 ? '' : validate_emptyField(bank.deposit_type);
            // errors.deposit_value = (bank.deposit_type == 4 || bank.deposit_type == 1 || bank.deposit_type == '' || bank.deposit_value == 0) ? '' : validate_deposit_value(bank.deposit_value);
            if (bank.deposit_type != 4 && bank.deposit_type != 1 && bank.deposit_type !== '' && bank.deposit_value !== 0 && bank.deposit_value !== '0') {
                errors.deposit_value = validate_deposit_value(bank.deposit_value);
            } else {
                if (bank.deposit_value != '' && bank.deposit_value != null && bank.deposit_value !== 0 && bank.deposit_value !== '0') {
                    errors.deposit_value = validate_deposit_value(bank.deposit_value);
                }
            }
            errorsArr.push(errors);
        });
        setError(errorsArr);
        errorsArr.forEach(errVal => {
            let errArr = [];
            Object.keys(errVal).forEach(err => {
                if (errVal[err] != '') {
                    errArr.push(err)
                }
            });
            if (errArr.length > 0) {
                result.push(errArr);
            }
        });
        return result;
    };

    const handleSubmit = () => {
        let errors = formValidations();
        if (errors.length == 0 && w4error.deposit_form_documents == '' && i9error.deposit_form_documents == '') {
            for (let i = 0; i < state.bank_information.length; i++) {
                if (state.bank_information[i].bank_name == '' || state.bank_information[i].bank_name.length < 2) {
                    addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
                    return true
                }
            }
            storeBankDetails();
        } else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const [bankBtnLoader, setBankBtnLoader] = useState(false);

    const storeBankDetails = () => {
        // const data = {
        //     employee_id: LocalStorage.getEmployeeId(),
        //     bank_information: state,
        //     delete_bank_accounts: []
        // }
        state['request_id'] = LocalStorage.uid();
        state['employee_id'] = LocalStorage.getEmployeeId();
        state['delete_bank_accounts'] = [];
        setState({ ...state })
        setBankBtnLoader(true)
        OnBoardApi.bankStore(state, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setBankBtnLoader(false)
                setSuccess(true);
                bankIndexApi(LocalStorage.getEmployeeId());
            }
            else {
                setBankBtnLoader(false)
                addErrorMsg(res.data.message)
            }
        })
    }

    const bankIndexApi = (id) => {
        OnBoardApi.bankIndex(LocalStorage.uid(), id ? id : '', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setState(res.data.data);
            }
        })
    }

    const removeBank = (index) => {
        // let bankdetailsArr = state.bank_information;
        // let deleteAll = deleteId;
        // let errorBank = error
        // errorBank.splice(index, 1)
        // setError([...errorBank])
        // if (bankdetailsArr[index].id) {
        //     if (index !== 0) {
        //         deleteAll.push(bankdetailsArr[index].id)
        //     }
        // }

        // bankdetailsArr.splice(index, 1);
        // setdeleteId([...deleteAll])
        // setState({
        //     ...state,
        //     bank_information: [...bankdetailsArr]
        // });
        // setDeleteBank(null);
        // setDeleteBankOpen(false);
        deleteId.push(state.bank_information[index].id)
        setdeleteId([...deleteId]);
        state.bank_information.splice(index, 1);
        setState({ ...state });
    }

    // eslint-disable-next-line
    const addNewAccount = (index, value) => {
        let data = state
        data.bank_information.push(
            {
                bank_name: "",
                account_type: "",
                account_number: "",
                confirm_account_number: "",
                routing_number: "",
                confirm_routing_number: "",
                deposit_type: 4,
                deposit_value: "",
                void_cheque_documents: [
                    {
                        id: "",
                        new_document_id: "",
                        document_url: "",
                        document_name: "",
                    }
                ]
            }
        )
        setState({ ...data })
    }

    const back = () => {
        handleBackBankToDocs();
    }
    // eslint-disable-next-line
    const [deleteBank, setDeleteBank] = useState(null);
    const [deleteBankOpen, setDeleteBankOpen] = useState(false);
    const [voidOpen, setVoidOpen] = useState(false);
    const [docDeleteOpen, setDocDeleteOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [delDoc, setDelDoc] = useState(null);



    const handleW4Open = () => {
        window.open('https://japfu-organization-dev.codetru.org/api/v1/documents/Default/w4.jpg', '_blank', 'noopener,noreferrer')
    }

    const handleI9Open = () => {
        window.open('https://japfu-organization-dev.codetru.org/api/v1/documents/Default/i-9.png', '_blank', 'noopener,noreferrer')

    }
    const goToHome = () => {
        setSuccess(false);
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeFromPage();
        navigate('/employees');
    }

    const deleteDocument = (index, args) => {
        setDelDoc(args)
        setDeleteIndex(index);
        setDocDeleteOpen(true);
    }

    const changeToFullNet = () => {
        let name = 'deposit_type'
        let value = 1
        const bank_details = { ...state }
        const bankDetailsArr = bank_details.bank_information;
         for (let i = 0; i < bankDetailsArr.length; i++) {
            if (i != currentIndex) {
                deleteId.push(bankDetailsArr[i].id);
                setdeleteId([...deleteId])
            }
        }
        // let deleteAll = deleteId;
        // if (bankDetailsArr[currentIndex]?.id) {
        //     if (currentIndex !== 0) {
        //         deleteAll.push(bankDetailsArr[currentIndex].id)
        //     }
        // }
        // setdeleteId([...deleteAll])
        const keptObject = bankDetailsArr[currentIndex];
        bankDetailsArr.length = 0;
        bankDetailsArr.push(keptObject);
        bankDetailsArr[0][name] = value
        bankDetailsArr[0].deposit_value = ''
        setState({
            ...state,
            copy_of_void_cheque: [...bankDetailsArr]
        });
        let depositOptions = depositTypeOptions.map(obj => {
            const newObj = { ...obj };
            newObj['disabled'] = false;
            return newObj;
        })
        if (fullNetIndex >= 0) {
            let errorBank = error[fullNetIndex] || {}
            errorBank.deposit_value = ''
            error.length = 0
            error.push(errorBank)
            setError([...error])
        }
        setDepositTypeOptions([...depositOptions])
        setFullNetPopup(false)
    }

    const addNewBank = () => {
        let errors = formValidations();
        if (state.bank_information[0].deposit_type == 1) {
            addWarningMsg('Please change the deposit configuration from full net to others.')
        }
        else {
            if (errors.length == 0 && w4error.deposit_form_documents == '' && i9error.deposit_form_documents == '') {
                state.bank_information.push({
                    bank_name: "",
                    account_type: "",
                    account_number: "",
                    confirm_account_number: "",
                    routing_number: "",
                    confirm_routing_number: "",
                    deposit_type: "",
                    deposit_value: "",
                    void_cheque_documents: [
                        {
                            id: "",
                            new_document_id: "",
                            document_url: "",
                            document_name: "",
                        }
                    ]
                })
                setState({ ...state });
            } else {
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        }
    }

    return (
        <Grid container justifyContent='center' pb={4}>
            <Grid item lg={6} md={7.5} pt={16}>
                <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)!important', borderRadius: '15px' }}>
                    <CardContent sx={{ padding: '10px 20px !important' }}>
                        <Grid container lg={12} md={12} sm={12} xs={12} p={2} pl={2} pt={2} pr={1}>
                            <Grid item sm={6} p={2}>
                                <FileInput
                                    name={"i9_form_documents"}
                                    FileName={state.i9_form_documents[0]?.document_name}
                                    handleChange={(e) => uploadDocs(e, 0, "i9_form_documents")}
                                    label={"I-9 Documents"} isDisabled={false}
                                    handleDelete={() => deleteDocument(0, 'i9_form_documents')}
                                    actionState={state.i9_form_documents[0].document_name ? 1 : ''}
                                    documentUrl={state.i9_form_documents[0].document_url}
                                    error={i9error.deposit_form_documents ? i9error.deposit_form_documents : ""}
                                />
                                <Text errorText> {i9error.deposit_form_documents ? i9error.deposit_form_documents : ""}</Text>
                                <Text smallLabel sx={{ font: "12px Averta !important", color: "#849199 !important" }}>To view I-9 sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { handleI9Open() }}>Click here</span></Text>
                                {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}

                            </Grid>
                            <Grid item sm={6} p={2}>
                                <FileInput
                                    name={"deposit_form_documents"}
                                    FileName={state.deposit_form_documents[0]?.document_name}
                                    handleChange={(e) => uploadDocs(e, 0, "deposit_form_documents")}
                                    label={"W-4 Form"} isDisabled={false}
                                    handleDelete={() => deleteDocument(0, 'deposit_form_documents')}
                                    actionState={state.deposit_form_documents[0]?.document_name ? 1 : ''}
                                    documentUrl={state.deposit_form_documents[0]?.document_url}
                                    error={w4error.deposit_form_documents ? w4error.deposit_form_documents : ""}
                                />
                                <Text errorText> {w4error.deposit_form_documents ? w4error.deposit_form_documents : ""}</Text>
                                <Text smallLabel sx={{ font: "12px Averta !important", color: "#849199 !important" }}>To view W-4 sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { handleW4Open() }}>Click here</span></Text>
                                {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}

                            </Grid>
                        </Grid>
                        {
                            state.bank_information.map((bank, index) => (
                                <Box>
                                    <Grid container spacing={0} pl={2} pt={2} pr={1}>
                                        <Grid container item spacing={0} alignItems='center'>
                                            <Grid item xs={11} md={11} sm={11} lg={11}>
                                                <Box p={1} pl={2.6} pt={2}>
                                                    {state.bank_information.length > 1 ? <Text largeBlue>Bank Details - {index + 1} </Text> : <Text largeBlue>Bank Details</Text>}
                                                </Box>
                                            </Grid>
                                            {
                                                state.bank_information.length >= 2 ?
                                                    <Grid item xs={1} md={1} sm={1} lg={1} textAlign='center'>
                                                        <img src={minus} alt='minus' onClick={() => removeBank(index)} style={{ cursor: 'pointer' }} />
                                                    </Grid> : ''
                                            }
                                        </Grid>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                            <Box p={2}>
                                                <FileInput
                                                    name={"void_cheque_documents"}
                                                    FileName={bank.void_cheque_documents[0].document_name}
                                                    handleChange={(e) => uploadDocs(e, index, "void_cheque_documents")}
                                                    label={"Void Cheque (Optional)"} isDisabled={false}
                                                    handleDelete={() => deleteDocument(index, 'void_cheque_documents')}
                                                    actionState={bank.void_cheque_documents[0].document_name ? 1 : ''}
                                                    documentUrl={bank.void_cheque_documents[0].document_url}
                                                    error={error[index] && error[index].void_cheque_documents ? error[index].void_cheque_documents : ""}
                                                />
                                                <Text errorText> {error[index] && error[index].void_cheque_documents ? error[index].void_cheque_documents : ""}</Text>
                                                <Text smallLabel sx={{ font: "12px Averta !important", color: "#849199 !important" }}>To view void cheque sample document <span style={{ color: "#0C75EB", cursor: "pointer" }} onClick={() => { setVoidOpen(true) }}>Click here</span></Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>

                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'bank_name',
                                                        value: state.bank_information[index].bank_name,
                                                        inputProps: { maxLength: 50 }
                                                    }}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    labelText={'Bank Name'}
                                                    error={error[index] && error[index].bank_name ? error[index].bank_name : ""}
                                                />
                                                <Text errorText> {error[index] && error[index].bank_name ? error[index].bank_name : ""}</Text>
                                            </Box>

                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Select
                                                    label='Account Type'
                                                    options={AccountTypeOptions}
                                                    name='account_type'
                                                    value={state.bank_information[index].account_type}
                                                    onChange={(e) => handleChange(e, index)}
                                                    error={(bank.bank_name != '' && bank.bank_name.length > 1) && error[index] && error[index].account_type ? error[index].account_type : ""}
                                                    disabled={(bank.bank_name == '' || bank.bank_name.length < 2) ? true : false}
                                                />
                                                <Text errorText> {(bank.bank_name != '' && bank.bank_name.length > 1) && error[index] && error[index].account_type ? error[index].account_type : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'account_number',
                                                        value: state.bank_information[index].account_number,
                                                        inputProps: { minLength: 8, maxLength: 12 }
                                                    }}
                                                    disabled={bank.account_type == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    labelText={'Account Number'}
                                                    onKeyPress={onNumberOnlyChange}
                                                    error={bank.account_type != '' && error[index] && error[index].account_number ? error[index].account_number : ""}
                                                />
                                                <Text errorText> {bank.account_type != '' && error[index] && error[index].account_number ? error[index].account_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'confirm_account_number',
                                                        value: state.bank_information[index].confirm_account_number,
                                                        inputProps: { minLength: 8, maxLength: 12 },
                                                    }}
                                                    disabled={bank.account_number == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Confirm Account Number'}
                                                    error={bank.account_number != '' && error[index] && error[index].confirm_account_number ? error[index].confirm_account_number : ""}
                                                />
                                                <Text errorText> {bank.account_number != '' && error[index] && error[index].confirm_account_number ? error[index].confirm_account_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'routing_number',
                                                        value: state.bank_information[index].routing_number,
                                                        inputProps: { maxLength: 9 }
                                                    }}
                                                    disabled={bank.confirm_account_number == '' || (bank.account_number != bank.confirm_account_number) ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Routing Number'}
                                                    error={(bank.confirm_account_number == '' || bank.account_number != bank.confirm_account_number) ? '' : error[index] && error[index].routing_number ? error[index].routing_number : ""}
                                                />
                                                {
                                                    (bank.confirm_account_number == '' || bank.account_number != bank.confirm_account_number) ? '' :
                                                        <Text errorText> {error[index] && error[index].routing_number ? error[index].routing_number : ""}</Text>
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    onCut={(e) => { e.preventDefault() }}
                                                    onCopy={(e) => { e.preventDefault() }}
                                                    onPaste={(e) => { e.preventDefault() }}
                                                    inputProps={{
                                                        name: 'confirm_routing_number',
                                                        value: state.bank_information[index].confirm_routing_number,
                                                        inputProps: { maxLength: 9 }
                                                    }}
                                                    disabled={bank.routing_number == '' ? true : false}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    onKeyPress={onNumberOnlyChange}
                                                    labelText={'Confirm Routing Number'}
                                                    error={bank.routing_number != '' && error[index] && error[index].confirm_routing_number ? error[index].confirm_routing_number : ""}
                                                />
                                                <Text errorText> {bank.routing_number != '' && error[index] && error[index].confirm_routing_number ? error[index].confirm_routing_number : ""}</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Select
                                                    label='Choose Deposit Configuration'
                                                    options={index == 0 ? [{ id: 1, value: 'Full Net' }, { id: 2, value: 'Partial $' }, { id: 3, value: 'Partial %' }, { id: 4, value: 'Remainder' }] : depositTypeOptions}
                                                    name='deposit_type'
                                                    value={bank.deposit_type}
                                                    onChange={(e) => handleChange(e, index)}
                                                    error={bank.confirm_routing_number != '' && error[index] && error[index].deposit_type ? error[index].deposit_type : ""}
                                                    disabled={bank.confirm_routing_number == ''}
                                                />
                                                <Text errorText> {bank.confirm_routing_number != '' && error[index] && error[index].deposit_type ? error[index].deposit_type : ""}</Text>
                                                <Text smallLabel sx={{ font: "10px Averta !important", color: "#849199 !important", textAlign: 'left' }}>Choose your bank deposit configuration and division</Text>
                                            </Box>
                                        </Grid>
                                        <Grid lg={6} md={6} sm={6} xs={12}>
                                            <Box p={2}>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'deposit_value',
                                                        value: state.bank_information[index].deposit_value,
                                                        inputProps: { maxLength: state.bank_information[index].deposit_type == 2 ? 9 : 3 }
                                                    }}
                                                    handleChange={(e) => handleChange(e, index)}
                                                    clientInput
                                                    disabled={bank.deposit_type == 1 || bank.deposit_type == 4 || state.bank_information[index].deposit_type == '' ? true : false}
                                                    labelText={bank.deposit_type == 3 ? 'Deposit Percentage' : `Deposit Value`}
                                                    error={(bank.deposit_type != 1 || bank.deposit_type != 4 || state.bank_information[index].deposit_type != '') && error[index] && error[index].deposit_value ? error[index].deposit_value : ""}
                                                />
                                                <Text errorText> {(bank.deposit_type != 1 || bank.deposit_type != 4 || state.bank_information[index].deposit_type != '') && error[index] && error[index].deposit_value ? error[index].deposit_value : ""}</Text>
                                                <Text smallLabel sx={{ font: "10px Averta !important", color: "#849199 !important", textAlign: 'left' }}>Enter the deposit {bank?.deposit_type == 3 ? 'percentage' : `value`} that you want to have in this bank</Text>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))
                        }
                        {
                            state.bank_information.length == 5 ? '' :
                                <Grid item xs={12} px={3.5} pt={2} pb={2}>
                                    <Button addTitleBtn onClick={() => addNewBank()}>Add New Bank</Button>
                                </Grid>
                        }
                    </CardContent>
                </Card>
                <Grid item container m={'25px 0px 10px 0px'}>
                    <Grid item lg={6} md={6}>
                        <Button blackCancel onClick={back}>Back</Button>
                    </Grid>
                    <Grid item lg={6} md={6} textAlign='end'>
                        <LoadingButton loading={bankBtnLoader} saveNcontinue onClick={() => handleSubmit()}>{state.bank_information.length == 0 && state.bank_information[0]?.deposit_type != 1 ? 'Save' : 'Finish'}</LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            {deleteBankOpen && <ReusableDelete open={deleteBankOpen} setOpen={setDeleteBankOpen} onClick={() => { removeBank(deleteBank) }} />}
            {docDeleteOpen && <ReusableDelete open={docDeleteOpen} setOpen={setDocDeleteOpen} onClick={() => { deleteDoc(deleteIndex, delDoc) }} />}
            <Dialog onClose={() => { setVoidOpen(false) }} open={voidOpen} TransitionComponent={Transition}>
                <img src={sampleVoid} alt="void" />
            </Dialog>
            <ReusablePopup openPopup={success} setOpenPopup={setSuccess} white iconHide fixedWidth>
                <Box textAlign='center' justifyContent='center' p={'20px 0px'}>
                    <img src={successImg} alt='success' style={{ height: '150px', width: '150px', marginBottom: '5px' }} />
                    <Text largeGreen>Congratulations</Text>
                    <Text mediumBlack sx={{ marginTop: '8px !important' }}>You Have Successfully Onboarded <span style={{ color: `${blue}` }}>&nbsp;{LocalStorage && LocalStorage.getFullName()}</span></Text>
                    <Button brownMnSave sx={{ margin: '20px 0px 0px 0px !important' }} onClick={goToHome}>Go To Home</Button>
                </Box>
            </ReusablePopup>
            <ReusablePopup white iconHide fixedWidth openPopup={fullNetPopup} setOpenPopup={setFullNetPopup} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={offBoardSure} alt='noactive' />
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', fontWeight: `${400} !important`, textAlign: 'center' }}>
                            By selecting Full Net the other bank details will be deleted
                        </Text>
                    </Box>
                    <Box>
                        <Stack direction={"row"} spacing={3}>
                            <Button smallBlueOutline onClick={() => { setFullNetPopup(false) }}>
                                No
                            </Button>
                            <Button loading={false} redBackgroundWidth
                                onClick={changeToFullNet}>
                                Yes, Continue
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </ReusablePopup>
        </Grid>
    );
}