import { Box, Card, CardContent, CardHeader, Divider, Grid, Slide, Step, Stepper } from '@mui/material'
import React from 'react'
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import { BrownMnCustomisedConnector, BrownMnColorlibStepLabel, BrownMnCustomStepIcon, blue } from '../../../theme';
import { useState } from 'react';
import Input from '../../../components/input/Input';
import ClientStyles from './ClientStyles';
import { address_validation, empty_name, isValid, validate_Extension_Optional, validate_alphaNumeric, validate_charWithSpace, validate_city, validate_contact_number, validate_emptyField, validate_empty_address, validate_optional_contact, validate_zipcode, validates_emailId } from '../../../components/Validation';
import { isValidMulti } from '../../../components/Validation';
import minus from '../../../assets/client/minus-circle.svg';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import success from '../../../assets/client/clientSuccess.svg';
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage';
import { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FileInput from '../../../components/muiFileInput/FileInput';
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, removeLoader } from '../../../utils/utils';
import CommonApi from '../../../apis/CommonApi';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';
import Search from '../../../components/select/Select';
import ToggleSwitch from '../../../components/toggle/CustomToggle';
import cancelImg from '../../../assets/svg/OffBoardPop.svg';
import LoaderButton from '../../../components/customButton/LoadingButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function AddVendor() {
    const classes = ClientStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [activeStep, setActiveStep] = useState(0);
    const [communicationError, setCommunicationError] = useState([]);
    const [countries, setCountries] = useState([]);
    const [getStates, setGetStates] = useState([]);
    const [value, setValue] = useState(0);
    const [openCancel, setOpenCancel] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    // eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    const [state, setState] = useState({
        id: '',
        name: '',
        reference_id: '',
        logo_path_id: '',
        logo_name: '',
        logo_url: '',
        address: [
            {
                address_line_one: "",
                address_line_two: "",
                city: "",
                state_id: "",
                state_name: "",
                country_id: '',
                country_name: "",
                zip_code: "",
            },
        ],
    })
    const [error, setError] = useState({});
    const [erro, setErro] = useState([]);
    const [open, setOpen] = useState(false);

    const [contacts, setContacts] = useState([
        {
            first_name: "",
            middle_name: "",
            last_name: "",
            email_id: "",
            telephone_number: "",
            telephone_number_ext: "",
            mobile_number: "",
            is_primary: true
        }
    ])

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_create" && item.is_allowed == true))) {
            getCountries();
            vendorIDApi();
        }
        // eslint-disable-next-line
    }, [])

    const getCountries = () => {
        CommonApi.getCountryList('United States')
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    state.address[0].country_id = response.data.data[0].id
                    setState({ ...state })
                    getStatesApi(response.data.data[0].id);
                    setCountries(response.data.data);
                }
            })
    }

    // States Dropdown List
    const getStatesApi = (id) => {
        CommonApi.getStatesList(id)
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    setGetStates(response.data.data);
                }
            });
    };

    const vendorIDApi = () => {
        CommonApi.prefix("vendor").then((res) => {
            if (res.data.statusCode == 1003) {
                state.reference_id = res.data.data
                setState({
                    ...state,
                });
            } else {
                addErrorMsg(res.data.message);
            }
        });
    };

    const vendorStore = (args) => {
        ClientsApi.storeCompany(args, 'vendor').then((res) => {
            if (res.data.statusCode === 1003) {
                setButtonLoader(false);
                addSuccessMsg(res.data.message);
                setActiveStep(activeStep + 1);
                setValue(value + 1)
                LocalStorage.setVendorID(res.data.data.id);
            } else {
                setButtonLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const updatevendor = (args) => {
        ClientsApi.updateCompany(args, 'vendor').then((res) => {
            if (res.data.statusCode === 1003) {
                setButtonLoader(false);
                addSuccessMsg(res.data.message);
                setActiveStep(activeStep + 1);
                setValue(value + 1);
            } else {
                setButtonLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const IndexApi = (args) => {
        ClientsApi.companyIndexApi(args, 'vendor').then((res) => {
            if (res.data.statusCode === 1003) {
                if (res.data.data.address[0].country_id != "") {
                    getStatesApi(res.data.data.address[0].country_id);
                }
                setState({ ...res.data.data })
            }
        })
    }

    const changeHandler = (e) => {
        if (e.target.name == 'name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleCompanyValidate(e));
        }
        else{
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleCompanyValidate(e));
        }       
    }

    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            const formData = new FormData();
            formData.append('files', e.target.files[0]);
            formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
            addLoader(true);
            CommonApi.documentUpload("company-logo", formData, LocalStorage.getAccessToken())
                .then((res) => {
                    if (res.data.statusCode === 1003) {
                        removeLoader();
                        state.logo_path_id = res.data.data.id
                        state.logo_name = e.target.files[0].name
                        state.logo_url = res.data.data.document_url
                        setState({ ...state })
                    } else {
                        removeLoader();
                        addErrorMsg(res.data.message);
                    }
                });
        } else {
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const deleteDocs = (index) => {
        state.logo_path_id = ''
        state.logo_name = ''
        state.logo_url = ''
        setState({ ...state })
    }

    const communicationChangeHandler = (e, value, index) => {
        if(e.target.name == 'state_id'){
            state.address[index].city = '';
            state.address[index].zip_code = '';
        }
        state.address[index][e.target.name] = e.target.value;
        setState(state, handleValidateCommunicationAdd(e.target, index));
    };

    const handleCompanyValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "name":
                error.name = validate_charWithSpace(input.value, 'Vendor ');
                break;
            default:
                break;
        }
        let s1 = { ...error };
        setError(s1);
    };

    const handleValidateCommunicationAdd = (e, index) => {
        let input = e;
        let error =
            communicationError.length > 0
                ? communicationError
                    ? communicationError[index]
                    : communicationError
                : communicationError;
        for (var k = 0; k <= index; k++) {
            communicationError.push({});
        }
        let s1 =
            communicationError.length > 0
                ? [...communicationError]
                : [{ ...communicationError }];
        switch (input.name || input.tagName) {
            case "address_line_one":
                error.address_line_one = address_validation(input.value);
                break;
            case "address_line_two":
                error.address_line_two = validate_empty_address(input.value);
                break;
            case "city":
                error.city = validate_city(input.value);
                break;
            case "state_id":
                error.state_id = validate_emptyField(input.value);
                break;
            case "country_id":
                error.country_id = validate_emptyField(input.value);
                break;
            case "zip_code":
                error.zip_code = validate_zipcode(input.value, state.address[index].country_id);
                break;
            default:
                break;
        }
        setCommunicationError(s1);
    };

    const companyDetailsValidations = () => {
        let { name } = state;
        let errors = {};
        errors.name = validate_alphaNumeric(name);
        return errors;
    };

    const companyCommAddressValidations = () => {
        let errors = {};
        let err = []; // eslint-disable-next-line
        state.address.map((value) => {
            errors = {};
            errors.address_line_one = address_validation(value.address_line_one);
            errors.address_line_two = validate_empty_address(value.address_line_two);
            errors.state_id = validate_emptyField(value.state_id);
            errors.city = validate_city(value.city);
            errors.zip_code = validate_zipcode(value.zip_code, value.country_id);
            err.push(errors);
        });
        return err;
    };

    const handleChange = (e, index) => {
        if (e.target.name == "mobile_number" || e.target.name == "telephone_number") {
            convertFormat(e, index)
        }
        else if (e.target.name == 'is_primary') {
            for (let i = 0; i < contacts.length; i++) {
                if (i === index) {
                    contacts[i]['is_primary'] = e.target.checked;
                } else {
                    contacts[i]['is_primary'] = !e.target.checked
                }
            }
            setContacts([...contacts]);
            handleValidateContact(e, index);
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            contacts[index][e.target.name] = capitalize(e.target.value);
            setContacts([...contacts]);
            handleValidateContact(e, index)
        }
        else {
            contacts[index][e.target.name] = e.target.value
            setContacts([...contacts], handleValidateContact(e, index));
        }
    };

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' || name == 'telephone_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' || name == 'telephone_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' || name == 'telephone_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' || name == 'telephone_number' ? 6 : 5)) {
            contacts[index][e.target.name] = `${first}-${middle}-${last}`
            setContacts([...contacts], handleValidateContact(e, index))
        }
        else if (input.length > 3) {
            contacts[index][e.target.name] = `${first}-${middle}`
            setContacts([...contacts], handleValidateContact(e, index))
        }
        else if (input.length >= 0) {
            contacts[index][e.target.name] = input
            setContacts([...contacts], handleValidateContact(e, index))
        }
    }

    const handleSubmit = () => {
        let errors = companyDetailsValidations();
        let commErrors = companyCommAddressValidations();
        let contactErrors = validateContacts();
        if (activeStep == 0) {
            if (isValid(errors) && isValidMulti(commErrors)) {
                state['request_id'] = LocalStorage.uid();
                setButtonLoader(true);
                if (state.id !== '' && state.id !== null && state.id !== undefined) {
                    updatevendor(state)
                } else {
                    vendorStore(state)
                }
            } else {
                let s1 = { error };
                s1 = errors;
                let s2 = { error };
                s2 = commErrors;
                setError(s1);
                setCommunicationError(s2);
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        } else if (activeStep == 1) {
            if (isValidMulti(contactErrors)) {
                const data = {
                    request_id: LocalStorage.uid(),
                    company_id: LocalStorage.getVendorID(),
                    contacts: contacts
                }
                data['request_id'] = LocalStorage.uid();
                ClientsApi.storeContact(data, 'vendor').then((res) => {
                    if (res.data.statusCode === 1003) {
                        setOpen(true);
                    }
                })
            } else {
                let s2 = { erro };
                s2 = contactErrors;
                setErro(s2);
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        }
    }

    const back = () => {
        if (activeStep == 0) {
            setOpenCancel(true);
        } else if (activeStep == 1) {
            IndexApi(LocalStorage.getVendorID());
            setActiveStep(0);
            setValue(value - 1)
        }
    }

    const addContact = (action, index) => {
        let obj = {
            id: '',
            first_name: "",
            middle_name: "",
            last_name: "",
            email_id: "",
            telephone_number: "",
            telephone_number_ext: "",
            mobile_number: "",
            is_primary: false
        };
        if (action == "Add") {
            contacts.push(obj);
        } else if (action == 'Remove') {
            if (contacts[index].id == '' || contacts[index].id == undefined || contacts[index].id == null) {
                if (contacts[index].is_primary == true) {
                    if (index == 1) {
                        contacts[0].is_primary = true;
                    } else {
                        contacts[index - 1].is_primary = true;
                    }
                }
                contacts.splice(index, 1);
            } else {
                const data = {
                    request_id: LocalStorage.uid(),
                    company_id: LocalStorage.getVendorID()
                }
                ClientsApi.destroyContact('vendor', contacts[index].id, data).then((res) => {
                    if (res.data.statusCode == 1003) {
                        contacts.splice(index, 1);
                        IndexApi(LocalStorage.getVendorID());
                    } else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        setContacts([...contacts])
    }


    const validateContacts = () => {
        let errorsus = {};
        let err = [];
        contacts.map((value) => {
            errorsus = {};
            errorsus.first_name = validate_charWithSpace(value.first_name, 'first ');
            errorsus.last_name = validate_charWithSpace(value.last_name, 'last ');
            errorsus.email_id = validates_emailId(value.email_id);
            errorsus.telephone_number = validate_contact_number(value.telephone_number, "phone Number");
            errorsus.telephone_number_ext = validate_Extension_Optional(value.telephone_number_ext);
            errorsus.middle_name = empty_name(value.middle_name, 'middle ');
            errorsus.mobile_number = validate_optional_contact(value.mobile_number, "mobile");
            return err.push(errorsus);
        });
        return err;
    };

    const handleValidateContact = (e, index) => {
        let input = e.target;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "telephone_number":
                if (input.value == '' && contacts[index].mobile_number == '') {
                    error.telephone_number = ''
                } else if (input.value == contacts[index]['mobile_number']) {
                    error.telephone_number = "mobile number and phone number should Not be Same"
                } else {
                    error.telephone_number = usMobileNumberValidator.test(input.value) ? '' : validate_contact_number(input.value, 'phone number')
                }
                break;
            case "telephone_number_ext":
                error.telephone_number_ext = validate_Extension_Optional(input.value);
                break;
            case "mobile_number":
                if (input.value == '' && contacts[index].telephone_number == '') {
                    error.mobile_number = ''
                } else if (input.value !== '' && input.value == contacts[index]['telephone_number']) {
                    error.mobile_number = "mobile number and phone number should Not be Same"
                } else {
                    error.mobile_number = usMobileNumberValidator.test(input.value) ? '' : validate_optional_contact(input.value, "mobile");
                }
                break;
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, "first");
                break;
            case "middle_name":
                error.middle_name = empty_name(input.value, "middle ");
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, "last ");
                break;
            case "email_id":
                error.email_id = validates_emailId(input.value);
                break;
            default:
                break;
        }
        setErro(s2);
    };

    return (
        <Grid container justifyContent='center' pb={5}>
            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_create" && item.is_allowed == true))) ?
                <>
                    <Grid item container lg={7} md={10} sm={9} xs={12} justifyContent='center' position='fixed' zIndex='10' sx={{ background: '#FFFFFF' }}>
                        <Grid item lg={6} md={10} sm={10} xs={12} pt={2} textAlign='center' p={'30px 0px !important'}>
                            <Stepper activeStep={activeStep}
                                connector={<BrownMnCustomisedConnector />}
                            >
                                <Step>
                                    <BrownMnColorlibStepLabel StepIconComponent={BrownMnCustomStepIcon}>
                                        <Text BrowmnMnStepperText> Vendor Details</Text>
                                    </BrownMnColorlibStepLabel>
                                </Step>
                                <Step>
                                    <BrownMnColorlibStepLabel StepIconComponent={BrownMnCustomStepIcon}>
                                        <Text BrowmnMnStepperText> Contact Details</Text>
                                    </BrownMnColorlibStepLabel>
                                </Step>
                            </Stepper>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={8} sm={8} xs={12} pt={9}>
                        <SwipeableViews index={value} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} onChangeIndex={handleChangeIndex}>
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <Card sx={{ padding: '20px 10px 10px 10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }}>
                                    <CardHeader sx={{ padding: '20px 0px 0px 28px !important' }} title={
                                        <Text headerBlack>Vendor Details</Text>
                                    } />
                                    <CardContent
                                        TransitionComponent={Transition}
                                        sx={{ padding: '20px 30px 30px 30px !important' }}
                                    >
                                        {
                                            activeStep == 0 &&
                                            <Grid item container spacing={3} lg={12} md={12} sm={12} xs={12}>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'name',
                                                            value: state.name,
                                                            inputProps: { maxLength: 50 },
                                                        }}
                                                        handleChange={changeHandler}
                                                        clientInput
                                                        labelText='Vendor Name'
                                                        error={error.name ? error.name : ''}
                                                    />
                                                    {
                                                        error.name ?
                                                            <Text red>{error.name ? error.name : ''}</Text> : ''
                                                    }
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Input
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            name: 'reference_id',
                                                            value: state.reference_id,
                                                        }}
                                                        disabled={true}
                                                        handleChange={changeHandler}
                                                        clientInput
                                                        labelText='Vendor ID'
                                                        error={error.reference_id ? error.reference_id : ''}
                                                    />
                                                    {
                                                        error.reference_id ?
                                                            <Text red>{error.reference_id ? error.reference_id : ''}</Text> : ''
                                                    }
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box pt={'11px'}>
                                                        <FileInput
                                                            name='logo_path_id'
                                                            FileName={state.logo_name}
                                                            handleChange={(e) => uploadDocs(e)}
                                                            label='Vendor Logo (Optional)'
                                                            handleDelete={deleteDocs}
                                                            actionState={state.logo_name ? 1 : ''}
                                                            documentUrl={state.logo_url}
                                                        />
                                                    </Box>
                                                </Grid>
                                                {
                                                    state.address.map((item, index) => (
                                                        <>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'address_line_one',
                                                                        value: item.address_line_one,
                                                                        inputProps: { maxLength: 225 },
                                                                    }}
                                                                    handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                    clientInput
                                                                    labelText='Address Line 1'
                                                                    error={communicationError.length > 0 && communicationError[index]
                                                                        && communicationError[index].address_line_one}
                                                                />
                                                                {communicationError.length > 0 ? (
                                                                    <Text red>
                                                                        {communicationError[index]
                                                                            ? communicationError[index].address_line_one
                                                                            : ""}
                                                                    </Text>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'address_line_two',
                                                                        value: item.address_line_two,
                                                                        inputProps: { maxLength: 225 },
                                                                    }}
                                                                    handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                    clientInput
                                                                    labelText='Address Line 2 (Optional)'
                                                                    error={communicationError.length > 0 && communicationError[index]
                                                                        && communicationError[index].address_line_two}
                                                                />
                                                                {communicationError.length > 0 ? (
                                                                    <Text red>
                                                                        {communicationError[index]
                                                                            ? communicationError[index].address_line_two
                                                                            : ""}
                                                                    </Text>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Search
                                                                    name='country_id'
                                                                    value={item.country_id}
                                                                    options={countries}
                                                                    label='Country'
                                                                    disabled
                                                                    onChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Search
                                                                    name='state_id'
                                                                    value={item.state_id}
                                                                    options={getStates}
                                                                    label='State'
                                                                    error={communicationError.length > 0 && communicationError[index] && communicationError[index].state_id}
                                                                    onChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                />
                                                                {communicationError.length > 0 ? (
                                                                    <Text red>
                                                                        {communicationError[index]
                                                                            ? communicationError[index].state_id
                                                                            : ""}
                                                                    </Text>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'city',
                                                                        value: item.city,
                                                                        inputProps: { maxLength: 50 },
                                                                    }}
                                                                    handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                    clientInput
                                                                    labelText='City'
                                                                    error={communicationError.length > 0 && communicationError[index] && communicationError[index].city}
                                                                />
                                                                {communicationError.length > 0 ? (
                                                                    <Text red>
                                                                        {communicationError[index]
                                                                            ? communicationError[index].city
                                                                            : ""}
                                                                    </Text>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Input
                                                                    formControlProps={{
                                                                        fullWidth: true
                                                                    }}
                                                                    inputProps={{
                                                                        name: 'zip_code',
                                                                        value: item.zip_code,
                                                                        disabled: item.country_id === '',
                                                                        inputProps: { maxLength: item.country_id == 1 ? 6 : 5 }
                                                                    }}
                                                                    error={communicationError.length > 0 && communicationError[index] && communicationError[index].zip_code}
                                                                    handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                    clientInput
                                                                    labelText={item.country_id == 1 ? 'Pin Code' : 'Zip Code'}
                                                                />
                                                                {communicationError.length > 0 ? (
                                                                    <Text red>
                                                                        {communicationError[index]
                                                                            ? communicationError[index].zip_code
                                                                            : ""}
                                                                    </Text>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Grid>
                                                        </>
                                                    ))
                                                }
                                            </Grid >
                                        }
                                    </CardContent>
                                </Card>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <Card sx={{ padding: '20px 10px 10px 10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }}>
                                    <CardHeader sx={{ padding: '15px 0px 0px 25px !important' }} title={
                                        <Text headerBlack>Contact Details {contacts.length == 1 && <span className={classes.optional}>(Primary)</span>}</Text>
                                    } />
                                    <CardContent
                                        TransitionComponent={Transition}
                                        sx={{ padding: '20px 30px 30px 30px !important' }}
                                    >
                                        {
                                            activeStep == 1 &&
                                            contacts.map((item, index) => (
                                                <Grid container spacing={3} pt={index > 0 ? '15px' : ''}>
                                                    {
                                                        index > 0 &&
                                                        <>
                                                            <Grid item container lg={12} md={12} sm={12} xs={12} mt={2}>
                                                                <Grid item lg={8} md={8} sm={8} xs={10}>
                                                                    <Text largeBlack>Contact Details -{index + 1}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={4} sm={4} xs={2} textAlign='end'>
                                                                    <img src={minus} alt='Minus' style={{ cursor: 'pointer' }} onClick={() => addContact('Remove', index)} />
                                                                </Grid>
                                                            </Grid><Divider className={classes.dividerContact} />
                                                        </>
                                                    }
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'first_name',
                                                                value: item.first_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].first_name
                                                            }
                                                            labelText='First Name'
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].first_name : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'middle_name',
                                                                value: item.middle_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].middle_name
                                                            }
                                                            labelText='Middle Name (Optional)'
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].middle_name : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'last_name',
                                                                value: item.last_name,
                                                                inputProps: { maxLength: 33 }
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            labelText='Last Name'
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].last_name
                                                            }
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].last_name : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'email_id',
                                                                value: item.email_id,
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            labelText='Email ID'
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].email_id
                                                            }
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].email_id : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'telephone_number',
                                                                value: item.telephone_number,
                                                                inputProps: { maxLength: 12 },
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            labelText='Phone Number'
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].telephone_number
                                                            }
                                                            helperText={
                                                                erro.length > 0 &&
                                                                erro[index] && erro[index].telephone_number &&
                                                                <span className={classes.helperTextError}>{erro[index].telephone_number}</span>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'telephone_number_ext',
                                                                value: item.telephone_number_ext,
                                                                inputProps: { maxLength: 6 },
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            labelText='Extension (Optional)'
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].telephone_number_ext
                                                            }
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].telephone_number_ext : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'mobile_number',
                                                                value: item.mobile_number,
                                                                inputProps: { maxLength: 12 },
                                                            }}
                                                            handleChange={(e) => handleChange(e, index)}
                                                            clientInput
                                                            labelText='Mobile Number (Optional)'
                                                            error={
                                                                erro.length > 0 && erro[index] && erro[index].mobile_number
                                                            }
                                                        />
                                                        {erro.length > 0 ? (<Text red>{erro[index] ? erro[index].mobile_number : ""}</Text>) : ''}
                                                    </Grid>
                                                    <Grid item container lg={12} m='10px 0px 10px 10px !important'>
                                                        <Grid item lg={12}>
                                                            <ToggleSwitch name='is_primary' isActive={item.is_primary} disable={contacts.length == 1} switchChange={(e) => handleChange(e, index)} sx={{ height: '24px !important' }} label={<Text largeLabel sx={{ paddingLeft: '10px' }}>Make This as Primary Contact</Text>} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        {
                                            contacts.length <= 4 &&
                                            <Grid item lg={12} md={12} sm={12} xs={12} m={'25px 0px 0px 0px !important'}>
                                                <Button lightBlue onClick={() => addContact('Add')}>Add New Contact</Button>
                                            </Grid>
                                        }
                                    </CardContent>
                                </Card>
                            </TabPanel>
                        </SwipeableViews>
                        <Grid item container p={'10px 20px 0px 20px'}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button blackCancelBtn onClick={back}>{activeStep == 0 ? 'Cancel' : 'Back'}</Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6} textAlign='end'>
                                <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>{activeStep == 0 ? 'Save & Continue' : 'Finish'}</LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <Grid container>
                    <Box sx={{ marginLeft: "100px" }}>
                        <Text mediumViewAmt >Add Vendor</Text>
                    </Box>
                    {NoPermission()}
                </Grid>
            }
            <ReusablePopup iconHide openPopup={open} setOpenPopup={setOpen} white statusWidth>
                <Box textAlign='center' p={'10px 20px 0px 20px'}>
                    <img src={success} alt='success' />
                    <Text veryLargeLabel sx={{ paddingTop: '25px !important' }}>Succesfully Added!</Text>
                    <Text mediumLabelColor sx={{ padding: '10px 0px 30px 0px !important' }}>You Have Successfully {state.id !== '' && state.id !== null && state.id !== undefined ? 'Updated' : 'Added'} <span style={{ color: `${blue}`, textTransform: 'capitalize' }}>{state.name}</span> Details.</Text>
                    <Button onClick={() => navigate('/clients', { state: { page: 'vendors' } })} blueButton>Go To Home</Button>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide openPopup={openCancel} setOpenPopup={setOpenCancel} white fixedWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={cancelImg} alt='success' />
                    <Text popupHead1 sx={{ paddingTop: '25px !important' }}>Are you Sure?</Text>
                    <Text mediumOverView sx={{ padding: '10px 0px 30px 0px !important' }}>Do you want to cancel adding this Vendor?<br /> Please be aware that any data you've<br /> entered may be lost.</Text>
                    <Grid item container lg={12} columnSpacing={2} justifyContent='center'>
                        <Grid item lg={5}>
                            <Button onClick={() => setOpenCancel(false)} redBorder>No</Button>
                        </Grid>
                        <Grid item lg={5}>
                            <Button onClick={() => navigate('/clients', { state: { page: 'vendors' } })} blueButton>Yes</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
        </Grid >
    )
}

export default AddVendor