import React, { useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import Text from '../../../../../components/customText/Text';
import MainStyles from '../../MainStyles'
import EmployeeTypesApi from '../../../../../apis/configurations/employee/EmployeeTypesApi';
import {  addErrorMsg } from '../../../../../utils/utils';
import LocalStorage from '../../../../../utils/LocalStorage';

function EmploymentType({ current }) {

    const classes = MainStyles();// eslint-disable-next-line
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [getList, setGetList] = React.useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
            employementTypeApi()
        }, 300)

    }, [])

    const employementTypeApi = () => {
        setLoading(true);
        EmployeeTypesApi.getAllEmployeeTypes().then((res) => {
            if (res.data.statusCode == 1003) {
                setTimeout(() => {
                    setLoading(false)
                    setGetList(res.data.data)
                }, 300)
            } else {
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }



    return (
        <div>
            {/* //     {
        //         (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employment_type_view" && item.is_allowed == true))) ? */}
            <Box sx={{
                height: '75vh',
                overflow: 'auto',
                padding: '16px',
            }}>
                <Box className={classes.activeItemBox} >
                    <Box className={classes.activeBoxHeading}>
                        <Text profileTitle >{current}</Text>
                    </Box>
                    {loading ? <>
                        {[1, 2, 3].map((item, index) => (
                            <Grid container key={index} mt={3}>
                                <Grid item lg={8}>
                                    <Skeleton animation="wave" width="200px" />
                                    <Skeleton animation="wave" width="200px" />
                                </Grid>
                                <Grid item lg={3}>
                                    <Skeleton animation="wave" width="200px" />
                                    <Skeleton animation="wave" width="200px" />
                                </Grid>
                            </Grid>
                        ))}
                    </>
                        :
                        getList.map((item, index) => (
                            <>
                                {
                                    item.id != 1 &&
                                    <Box className={classes.descriptionBoxStyle} key={index}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item lg={9} md={6} sm={6} xs={12} container direction={'column'} gap={1}>
                                                <Text mediumBlackColor>{item.name}</Text>
                                                <Text greyLabel>{item.description}</Text>
                                            </Grid>
                                            <Grid item lg={3} md={3} sm={3} xs={12} container direction={'column'} gap={1}>
                                                <Text mediumBlackColor>Created By</Text>
                                                <Text greyLabel>{item.created_by}</Text>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </>
                        ))}
                </Box>
            </Box>
            {/* //         :
            //         <>
            //             {NoPermission()}
            //         </>
            // } */}
        </div>
    )
}

export default EmploymentType;
