import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Text from '../../components/customText/Text';
import { ReactComponent as User } from '../../assets/svg/fi_user.svg';
import { ReactComponent as Configuration } from '../../assets/svg/Configuration.svg';
import { ReactComponent as PasswordIcon } from '../../assets/svg/changePassword.svg';
import { ReactComponent as Support } from '../../assets/svg/Support.svg';
import { ReactComponent as Logout } from '../../assets/svg/Logout.svg';
import { ReactComponent as Release } from '../../assets/svg/release-note.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountMenuStyles from './AccountMenuStyles';
import styled from '@emotion/styled';
import { Badge, Dialog, DialogContent, Grid, Slide, Stack } from '@mui/material';
import LocalStorage from '../../utils/LocalStorage';
import CommonApi from '../../apis/CommonApi';
import { addErrorMsg, addSuccessMsg, BlackToolTip } from '../../utils/utils';
import Button from '../../components/customButton/Button';
import offBoardSave from "../../assets/svg/offBoardSave.svg"

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    border: '2px solid white',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
  },
}));


export default function AccountMenu(props) {

  const classes = AccountMenuStyles();
  let location = useLocation();
  const person = props.person ? props.person : () => { };
  const UserName = props.UserName ? props.UserName : () => { };
  const MailId = props.MailId ? props.MailId : () => { };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
      borderRadius: "16px",
      width: "500px"
    }
  }));// eslint-disable-next-line
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });// eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopup, setOpenpopup] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  let formsIncude = ['/employees/onboard', '/employees/add', '/timesheet/add-timesheet', '/placements/addclientAndendclient']
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    //userData passing thorough the api
    const userData = {
      request_id: LocalStorage.uid(),
      login_id: LocalStorage.getUserData().login_id
    }
    CommonApi.Logout(userData, LocalStorage.getAccessToken()).then((response) => {
      if (response.data.statusCode == 1003) {
        addSuccessMsg("Sucessfully Logged Out") //for success msg Alert
        localStorage.clear();
        setAnchorEl(null);
        window.location.reload();
      }
      else {
        addErrorMsg(response.data.message); //for Error msg Alert
      }
    })
  };

  const handleViewProfile = () => {
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/myprofile')
    } else {
      navigate('/myprofile')
    }
  }
  const handleClickNavigateToConfiguration = () => {
    LocalStorage.removeRedirectedModule();
    LocalStorage.removeFromPage();
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/configuration')
      setAnchorEl(null);
    } else {
      navigate('/configuration');
      setAnchorEl(null);
    }
  }

  const handleClickNavigateToPassword = () => {
    if (formsIncude.includes(location.pathname)) {
      setOpenpopup(true);
      setUrl('/change-password')
      setAnchorEl(null);
    } else {
      navigate('/change-password');
      setAnchorEl(null);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: 'pointer' }}>

        <Box className={`${open ? classes.avatarBoxActive : classes.avatarBox}`} onClick={handleClick} >
          <Avatar sx={{ width: 40, height: 40 }} src={person} />
        </Box>

      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className={classes.menu}
        sx={{
          '& .MuiPaper-root': {
            height: LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ? '323px' : '160px'
          }
        }}
        PaperProps={{
          sx: {
            mt: "6px",
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className={classes.menuItem} onClick={handleClose} sx={{

        }}>

          <Box className={classes.profileInfo}>

            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar sx={{ width: 40, height: 40 }} src={person} />

            </StyledBadge>
            <Box sx={{ diplay: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <Text largeBlack>{UserName.length > 20 ?
                    <BlackToolTip title={UserName} placement="top" arrow>
                      {(UserName).slice(0, 15) + (UserName.length > 15 ? "..." : "")}
                    </BlackToolTip>
                    : (UserName)}</Text>
              <Text mediumLabel>                
                {
                  MailId.length > 20 ?
                    <BlackToolTip title={MailId} placement="top" arrow>
                      {(MailId).slice(0, 20) + (MailId.length > 20 ? "..." : "")}
                    </BlackToolTip>
                    : (MailId)
                }
              </Text>
            </Box>
          </Box>

        </MenuItem>

        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />

        {
          LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
            <MenuItem onClick={handleViewProfile} className={classes.menuItemIn}>

              <Box className={classes.ListItem}>
                <User />
                <Text mediumBlack >View profile</Text>
              </Box>
            </MenuItem>
            : ''}
        {
          LocalStorage.getUserData().admin_login && LocalStorage.getUserData().org_config_set ?
            <div>
              <MenuItem onClick={handleClickNavigateToConfiguration} className={classes.menuItemIn}>
                <Box className={classes.ListItem}>
                  <Configuration />

                  <Text mediumBlack >Configuration</Text>
                </Box>
              </MenuItem>
              <MenuItem onClick={handleClickNavigateToPassword} className={classes.menuItemIn}>
                <Box className={classes.ListItem}>
                  <PasswordIcon />

                  <Text mediumBlack >Change Password</Text>
                </Box>
              </MenuItem>
              <a href='https://www.japfu.ai/contact' rel="noreferrer" target='_blank'>
                <MenuItem onClick={handleClose} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <Support />

                    <Text mediumBlack >Support</Text>
                  </Box>
                </MenuItem>
              </a>
              <a href='https://www.japfu.ai/product-updates' rel="noreferrer" target='_blank'>
                <MenuItem onClick={handleClose} className={classes.menuItemIn}>
                  <Box className={classes.ListItem}>
                    <Release />

                    <Text mediumBlack >Release Notes</Text>
                  </Box>
                </MenuItem>
              </a>
            </div>
            :
            LocalStorage.getUserData().org_config_set == false ? '' :
              <MenuItem onClick={handleClickNavigateToPassword} className={classes.menuItemIn}>
                <Box className={classes.ListItem}>
                  <PasswordIcon />

                  <Text mediumBlack >Change Password</Text>
                </Box>
              </MenuItem>
        }

        <Divider sx={{ margin: '0px !important', borderColor: '#F2F4F7 !important', borderWidth: '1px !important' }} />
        <MenuItem onClick={handleLogout} className={classes.menuItemIn}>

          <Box className={classes.ListItem} >
            <Logout />
            <Text mediumBlack sx={{ color: '#F4535C !important' }}>Log out</Text>
          </Box>
        </MenuItem>
      </Menu>
      <BootstrapDialog
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="customized-dialog-title"
        open={openPopup}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent sx={{ margin: "50px", }}>
          <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
            <img src={offBoardSave} alt='noactive' />
          </Grid>
          <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
          </Grid>
          <Grid container justifyContent={'center'} alignContent={'center'}>
            <Text offBoardBodyFont>Your progress will not be saved</Text>
          </Grid>
          <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
            <Stack direction={"row"} spacing={3}>
              <Button smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                Cancel
              </Button>
              <Button smallBlue redBackground onClick={() => { navigate(url); setOpenpopup(false) }} >
                Yes
              </Button>
            </Stack>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}