import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import { Hidden } from "@mui/material";
import { LoginStyles } from "./LoginStyles";
import Box from "@mui/material/Box";
import CustomInput from "../../components/input/Input";
import Text from "../../components/customText/Text";
import { isValid, validate_emptyField, validates_password } from "../../components/Validation";
import { LoadingButton } from "@mui/lab";
import Logo from '../../assets/logo.svg';
import CommonApi from "../../apis/CommonApi";
import { addErrorMsg } from "../../utils/utils";
import LocalStorage from "../../utils/LocalStorage";
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import { domain } from '../../config/Domain';


function CreatePassword() {
    const classes = LoginStyles(); // eslint-disable-next-line
    const [formData, setFormData] = useState({
        password: "",
        confirmpassword: "",
    });
    const [error, setError] = useState({}); // eslint-disable-next-line
    const [isVisible, setVisible] = useState(false); //For password hide and show  
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState(false);
    const [eye, setEye] = useState({
        password: false,
        confirmpassword: false
    });
    const lowerCase = new RegExp(/.*[a-z].*$/);
    const upperCase = new RegExp(/.*[A-Z].*$/);
    const digit = new RegExp(/.*\d.*$/);
    const specialCharacter = new RegExp(/.*\W.*$/);
    const [errorShow, setErrorShow] = useState(false);
    const changeHandler = (e) => {
        setErrorShow(true)
        setFormData(
            {
                ...formData,
                [e.target.name]: e.target.value,
            },
            handleValidate(e)
        );
    };


    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "email":
                error.email = validate_emptyField(input.value);
                break;
            case "password":
                error.password = validate_emptyField(input.value);
                error.confirmpassword = formData.confirmpassword != "" ? input.value ==  formData.confirmpassword ? '' : "Password not matched" : '';
                break;
            case "confirmpassword":
                error.confirmpassword = formData.password == input.value ? '' : "Password not matched";
                break;
            default:
                break;
        }
        let err = { ...error };
        setError(err);
    };
    /*email validation */
    const emailValidations = () => {
        let { password, confirmpassword } = formData;
        let errors = {};
        errors.password = validates_password(password);
        errors.confirmpassword = password == confirmpassword ? validates_password(confirmpassword) : "Password not matched";
        return errors;
    };
    const submit = (e) => {
        let errors = emailValidations();
        e.preventDefault()
        const token = location.search.split('=')
        let data = {}
        if(location.state && location.state.data){
            data = {
                request_id: LocalStorage.uid(),
                subdomain_name: location.state.data.subdomain_name,
                email_id: location.state.data.email,
                temp_access_token: location.state.data.token,
                confirm_password: formData.confirmpassword,
                password: formData.password
            }
        }
        else{
            data = {
                request_id: LocalStorage.uid(),
                subdomain_name: domain,
                temp_access_token: token[1],
                confirm_password: formData.confirmpassword,
                password: formData.password
            }
        }
        
        setLoader(true)
        if (isValid(errors)) {
            CommonApi.changePassword(data).then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        setLoader(false)
                        navigate('login')
                    }
                    else {
                        setLoader(false)
                        addErrorMsg(response.data.message)
                    }
                }
            )
        }
        else {
            setLoader(false)
            let err = { error };
            err = errors;
            setError(err);
        }
    }

    const ImagesList = [
        Image1,
        Image2,
        // Image3,
        Image4
      ];
      // eslint-disable-next-line
      const [images, setImages] = useState(ImagesList);
      const [currentImage, setCurrentImage] = useState(0);
    
      function changeBackgroundImage() {
        if (currentImage == 0) {
          setTimeout(() => {
            setCurrentImage(1)
          }, 3000)
        }
        else if (currentImage == 1) {
          setTimeout(() => {
            setCurrentImage(2)
          }, 3000)
        }
        else if (currentImage == 2) {
          setTimeout(() => {
            setCurrentImage(0)
          }, 3000)
        }
        // else if (currentImage == 3) {
        //   setTimeout(() => {
        //     setCurrentImage(0)
        //   }, 3000)
        // }
      }
    
      useEffect(() => {
        const intervalId = setInterval(() => changeBackgroundImage(), 2000);
        return () => clearInterval(intervalId);
      });

      useEffect(() => {
        let tokenData = location.search.split('=')
        if(tokenData[1] != undefined){
            CommonApi.checkTokenAlready(tokenData[1]).then((res)=>{
                if(res.data.statusCode == 1003){
    
                }
                else{
                    navigate('/login')
                }
            })
        }

        // eslint-disable-next-line
      },[])


    return (
        <div>
            <Grid container alignItems='center' sx={{ height: '100vh', overflow: 'hidden' }}>
                <Hidden mdDown>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.rightBox}>
                    {ImagesList.map((image, index) => {
              return (
                <img src={images[currentImage]} alt={'img'} width={'100%'} />
              )
            })}
                    </Grid>
                </Hidden>
                <Grid item lg={1} md={1} sm={12} xs={12} p={2}></Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} p={2}>
                    <form onSubmit={(e)=>{submit(e)}}>
                    <Box className={classes.boxOne}>
                        <Grid container>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                    <img src={Logo} alt='logo' />
                                </Box>
                            </Grid>
                            <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                    <Text popupHead1 sx={{ color: '#090914 !important' }}>Create Password</Text>
                                </Box>
                            </Grid>
                            <Grid item sm={11} xs={12} pt={5} pl={3}>
                                <Box p={1}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: "password",
                                            value: formData.password,
                                            type: eye.password ? 'text' : 'password',
                                            inputProps: { maxLength: 16 }
                                        }}
                                        eyeIcon={eye.password}
                                        eyeCloseIcon={eye.password == false}
                                        eyeHandleChange={() => {
                                            setEye({
                                                ...eye,
                                                password: !eye.password
                                            })
                                        }}
                                        iconText
                                        handleChange={changeHandler}
                                        labelText={'Enter Password'}
                                        error={error.password}
                                    />
                                    {error.password ? <Text red>{error.password}</Text> : ""}
                                </Box>

                            </Grid>
                            <Grid item sm={11} xs={12} pl={3}>
                                <Box p={1}>
                                    <CustomInput
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            name: "confirmpassword",
                                            value: formData.confirmpassword,
                                            type: eye.confirmpassword ? 'text' : 'password',
                                            inputProps: { maxLength: 16 }
                                        }}
                                        eyeIcon={eye.confirmpassword}
                                        eyeCloseIcon={eye.confirmpassword == false}
                                        iconText
                                        eyeHandleChange={() => {
                                            setEye({
                                                ...eye,
                                                confirmpassword: !eye.confirmpassword
                                            })
                                        }}
                                        handleChange={changeHandler}
                                        labelText={'Confirm Password'}
                                        error={error.confirmpassword}
                                    />
                                    {error.confirmpassword ? <Text red>{error.confirmpassword}</Text> : ""}
                                </Box>
                            </Grid>
                            {
                                errorShow ?
                                    <Grid item sm={12} xs={12} p={1} pl={4}>
                                        <Text mediumLabel sx={{ color: '#111827 !important', paddingBottom: '5px' }}>Password Requirements:</Text>
                                        <Text mediumLabel sx={{ color: upperCase.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}> <span style={{
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: '50%',
                                            background: upperCase.test(formData.password) ? '#404040' : 'red',
                                            display: 'flex',
                                            margin: '6.5px 8px 2px 0px'
                                        }}> </span> At least one upper case {upperCase.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        <Text mediumLabel sx={{ color: lowerCase.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: '50%',
                                            background: lowerCase.test(formData.password) ? '#404040' : 'red',
                                            display: 'flex',
                                            margin: '6.5px 8px 2px 0px'
                                        }}> </span> At least one lower case {lowerCase.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        <Text mediumLabel sx={{ color: specialCharacter.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: '50%',
                                            background: specialCharacter.test(formData.password) ? '#404040' : 'red',
                                            display: 'flex',
                                            margin: '6.5px 8px 2px 0px'
                                        }}> </span>At least one special character {specialCharacter.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        <Text mediumLabel sx={{ color: formData.password.length > 7 ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: '50%',
                                            background: formData.password.length > 7 ? '#404040' : 'red',
                                            display: 'flex',
                                            margin: '6.5px 8px 2px 0px'
                                        }}> </span>The password length min of 8 characters. {formData.password.length > 7 ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                        <Text mediumLabel sx={{ color: digit.test(formData.password) ? '#404040 !important' : 'red !important', fontSize: '14px !important', display: 'flex', flexDirection: 'row' }}><span style={{
                                            height: '6px',
                                            width: '6px',
                                            borderRadius: '50%',
                                            background: digit.test(formData.password) ? '#404040' : 'red',
                                            display: 'flex',
                                            margin: '8px 8px 2px 0px'
                                        }}> </span>At least one number {digit.test(formData.password) ? <CheckCircleIcon sx={{ color: 'green', width: '14px', height: '14px', marginTop: '3px', marginLeft: '5px' }} /> : ''}</Text>
                                    </Grid>
                                    : ''
                            }
                            <Grid item xs={12} pl={3} pr={4}>
                                <Box className={classes.buttonStyles}>
                                    <LoadingButton
                                        className={classes.loginButton}
                                        loading={loader}
                                        type='submit'
                                        variant="contained"
                                        onClick={() => { submit() }}
                                        fullWidth
                                    >
                                        Create
                                    </LoadingButton>
                                </Box>
                            </Grid>
                            <Grid item lg={11} sx={{ display: 'flex', position: 'absolute', bottom: 3 }} >
                                <Box p={1} pl={24}>
                                    <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}>
                                        <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    </form>
                </Grid>

            </Grid>
        </div>
    );
}

export default CreatePassword;


