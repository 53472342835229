import { Box, Grid } from '@mui/material'
import React from 'react'
import Text from '../../../../components/customText/Text'
import Date from '../../../../components/datePicker/Date'
import CustomButton from '../../../../components/customButton/Button';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import OffBoardImg from "../../../../assets/svg/OffBoardPop.svg"

function UserDisable(props) {
    const { lastWorkingDate, dateChange, error,handleClosePopUp, minDate,loader,handleSubmit } = props;
    return (
        <>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <img src={OffBoardImg} alt="OffBoard" />
            </Box>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                <Text my={1} popupHead1  sx={{fontSize:"18px !important"}}> Are You Sure?</Text>
            </Box>
            <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
            <Text my={1} popupHead2 sx={{color:"#525252 !important",fontSize:"14px !important"}}>The following changes will be made after initiating the offboarding</Text>
            </Box>

            <Box my={2} sx={{ background: '#F8F8F8', borderRadius: '8px' }} p={'20px'} alignItems='center'>
                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                    <Box sx={{ height: '5px', width: '5px', background: 'black', borderRadius: '50%' }}></Box>
                    <Text smallBlack sx={{fontSize:"14px !important"}} >The offboarding will be initiated and the progress will shown one week before the last working day.</Text>
                </Box>              
            </Box>
            <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                <Grid item lg={8} md={8} sm={8} xs={12}>
                    <Date
                        name='lastWorkingDate'
                        value={lastWorkingDate}
                        labelText={'Last Working Day'}
                        height='54px'
                        minDate={minDate.min_last_working_day}
                        onChange={(value => dateChange(value, 'lastWorkingDate'))}
                        error={error && error ? error : ''}
                    />
                    {error && <Text red>{error ? error : ''}</Text>}
                </Grid>
            </Grid>
            <Box mt={3} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                <CustomButton cancelOutlineRed onClick={() => handleClosePopUp()}>
                    Cancel
                </CustomButton>               
               <LoaderButton loading={loader} finishFilledAverta onClick={handleSubmit}>Continue</LoaderButton>
            </Box>
        </>
    )
}

export default UserDisable