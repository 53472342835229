import React, { useCallback, useEffect, useState } from 'react';
import UserProfileStyles from './UserProfileStyles';
import { Box, Typography, Breadcrumbs, Stack, Grid, Divider, ListItemButton, Menu, Skeleton, Button, DialogTitle, IconButton, Slide, SwipeableDrawer, Hidden } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DialogContent from "@mui/material/DialogContent";
import CustomButton from '../../../../components/customButton/Button';
import OffBoardImg from "../../../../assets/svg/OffBoardPop.svg"
import GeneralDetails from './generaldetails';
import Documents from './document';
import Immigration from './Immigration';
import Timesheet from './timesheet';
import Skills from './skills';
import Dependents from './dependents';
import VacationHistory from './vacationHistory';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import EmployeeAPI from '../../../../apis/admin/employees/EmployeesApi';
import { BlackToolTip, NoPermission, addErrorMsg, dateFormat, } from '../../../../utils/utils';
import Text from '../../../../components/customText/Text';
import { Link, useLocation, } from 'react-router-dom';
import UserProfileApi from '../../../../apis/admin/employees/UserProfileApi';
import { addSuccessMsg } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import verified from '../../../../assets/svg/Component87.svg';
import Verify from '../../../../assets/svg/verify_icon.svg';
import Pending from '../../../../assets/svg/PendingIcon.svg';
import Rejected from '../../../../assets/svg/Rejected.svg';
import EverifySuccesPopUp from '../../../../assets/svg/Isolation_Mode.svg';
import { useNavigate } from 'react-router-dom';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import CommonApi from '../../../../apis/CommonApi';
import ActivityLog from './ActivityLog';
import { blue, orange } from '../../../../theme';
import moment from 'moment';
import eVerifyPending from '../../../../assets/employee/pendingEverify.svg';
import UserDisable from './UserDisable';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GreenVerif from "../../../../assets/svg/GreenVerify.svg"
import ReactApexChart from 'react-apexcharts';
import OffboardApi from '../../../../apis/admin/offboard/OffboardApi';
import warning from '../../../../assets/employee/info.svg';
import ViewEmergency from './generaldetails/ViewEmergency';
import AllSections from './document/AllSections';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg'
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import FileSaver from 'file-saver';
import BankDetails from './payconfiguration/BankDetails';
import contactExists from '../../../../assets/employee/offboarding/contact-exists.svg';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import deleteIcon from '../../../../assets/employee/deleteImg.svg';
import EmployeeActions from '../../../../components/employee/EmployeeActions';
import Initiated from "../../../../assets/svg/InitiatedImg.svg"
import OffboardingChecklistStyles from './OffboardingChecklistStyles';
import CancelOffboarding from '../../../../components/employee/CancelOffBoarding';
import LoadingButton from '../../../../components/customButton/LoadingButton';

const dataArr = ["General Details", "Documents", "Bank Details",
    //  "Immigration", "Timesheet",
    "Skills",
    //    "Dependents", "Vacation History"
];


export default function ControlledAccordions(props) {
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} timeout={500} style={{ position: 'fixed', bottom: 60, right: 50 }} />;
    });
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = UserProfileStyles();
    const offboardChecklistClasses = OffboardingChecklistStyles()
    const location = useLocation();
    const param = new URLSearchParams(window.location.search);
    const employee_id = param.get('id');
    const id = employee_id ? employee_id : LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? LocalStorage.getNotificationEmpId() : location.state.id : location.state.id
    const offBoardButton = location && location.state && location.state.offBoardButton
    const progress = location && location.state && location.state.progress
    const active = location && location.state && location.state.active
    const jump = location && location.state && location.state.jump
    const grButn = location && location.state && location.state.grButn
    const page = LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? 1 : (location && location.state && location.state.page) : ''
    const stage = LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? 'General Details' : (location && location.state && location.state.stage) : 'General Details'
    const [current, setCurrent] = useState("General Details");
    const [anchorEl, setAnchorEl] = useState(null);// eslint-disable-next-line
    const open = Boolean(anchorEl);
    const [lastWorkingDate, setLastWorkingDate] = useState('');
    const [lastWorkingDateCopy, setLastWorkingDateCopy] = useState('');
    const [error, setError] = useState('');
    const [openPayroll, setOpenPayroll] = useState(false);
    const [continueOffboard, setContinueOffboard] = useState(false);
    const [empExists, setEmpExistis] = useState(false);
    const [employee, setEmployee] = useState({
        basic_details: {
            first_name: "",
            middle_name: "",
            last_name: "",
            dob: "",
            gender: "",
            blood_group: "",
            marital_status: "",
            full_name: ""
        },
        contact_details: {
            contact_number: "",
            alternate_contact_number: "",
            email_id: "",
            alternate_email: "",
        },
        current_address: {
            address_line_one: "",
            zip_code: "",
            city: "",
            state_id: "",
            country_id: "",
        },
        emergency_contacts: [{
            id: "",
            relationship_id: "",
            name: "",
            email_id: "",
            contact_number: "",
            address_1: "",
            city: "",
            zip_code: "",
            state_id: "",
            country_id: ""
        }],
        employment_details: {
            reference_id: "",
            date_of_joining: "",
            employment_type_id: "",
            employment_category_id: "",
            ssn: "",
            is_usc: "",
            visa_type_id: "",
            reporting_manager_id: "",
            department_id: "",
            team_id: "",
            role_id: "",
            vendor_id: '',
            vendor_price: '',
            showFullSSN: false
        },
        profile_picture_url: "",
        enable_login: "",
        profile_progress: "",
        e_verified: ""
    });
    const [EmployeeProfile, setEmployeeProfile] = useState();// eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [show, setShow] = useState("");
    const [loading, setLoading] = useState(false);
    const [formEdit, setformEdit] = useState(false);
    const [openPopup, setOpenpopup] = useState(false)
    const [stepItem, setStepItem] = useState('')
    const [activityTotal, setActivityTotal] = useState()
    const [activityData, setActivityData] = useState([])// eslint-disable-next-line
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: "",
        request_id: LocalStorage.uid(),
        employee_id: ''
    })
    const [offboardData, setOffboardData] = useState({
        last_working_day: {
            date: '',
            status: true
        },
        placement: {
            status: false,
            data: []
        },
        disable_user_access: {
            status: false
        },
        send_revocation: {
            email_sent_to: '',
            date: '',
            disabled: false,
            proof_of_document: {
                id: '',
                document_name: '',
                document_url: ''
            },
            status: false
        },
        delete_mail: {
            email_deleted_on: '',
            proof_of_document: {
                id: '',
                document_name: '',
                document_url: ''
            },
            status: false
        },
        expense_settlement: {
            reimbursement_amount: '',
            reimbursement_payment: '',
            deduction_amount: '',
            deduction_payment: '',
            status: false
        },
        settle_balance: {
            total_balance: '',
            pay_via: '',
            status: false
        },
        finish_once: false,
        type: 1
    })
    const [limitExceed, setLimitExceed] = useState(false);
    const [finishPopup, setFinishPopup] = React.useState(false);
    const [cancelPopup, setCancelPopup] = React.useState(false);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialog-paper ": {
            borderRadius: "16px",
            width: "500px"
        },
        "& .MuiDialogContent-root": {
            // padding: theme.spacing(2)
            // padding: theme.spacing(3)
        },
        "& .MuiDialogActions-root": {
            // padding: theme.spacing(1)
            // padding: theme.spacing(5)
        }
    }));
    const typeSno = {
        1: {
            disable: 1,
            delete: 2,
            expense: 3,
            settle: 4,
            placement: '',
            send: '',
            // bills: ''
        },
        2: {
            placement: 1,
            disable: 2,
            send: 3,
            delete: 4,
            expense: 5,
            settle: 6,
            // bills: ''
        },
        3: {
            placement: 1,
            disable: 2,
            delete: 3,
            expense: 4,
            // bills: 4,
            settle: 5,
            send: ''
        }
    }
    const semicircle = {
        series: [employee.off_boarding_percentage],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: 0,
                sparkline: {
                    enabled: false
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            stroke: {
                lineCap: "round",
                curve: 'smooth',
            },
            fill: {
                type: 'solid',
                colors: [
                    employee.employment_details?.employment_type_id == 3 ?
                        (employee.off_boarding_percentage >= 20 && employee.off_boarding_percentage < 60 ? `${orange}` : employee.off_boarding_percentage >= 60 ? '#22C55E' : '') :
                        employee.employment_details.employment_type_id == 2 ?
                            (employee.off_boarding_percentage >= 16 && employee.off_boarding_percentage <= 33.33 ? `${orange}` : employee.off_boarding_percentage > 33.33 && employee.off_boarding_percentage <= 50 ? '#4ADE80' : employee.off_boarding_percentage > 50 ? '#22C55E' : '') :
                            (employee.off_boarding_percentage > 20 && employee.off_boarding_percentage < 75 ? `${orange}` : employee.off_boarding_percentage >= 75 && employee.off_boarding_percentage < 100 ? '#4ADE80' : employee.off_boarding_percentage == 100 ? '#22C55E' : '')]
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels    
                        borderRadius: 30,
                        startingShape: 'rounded',
                        endingShape: "rounded",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 0,
                            fontSize: '22px',
                            color: '#fff',
                            fontWeight: `${700} !important`,
                            fontFamily: 'Averta !important'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
    }

    useEffect(() => {
        if (location.state && location.state.from != undefined) {
            if (location.state.from == 'employmentDashboard') {
                setCurrent('General Details')
            }
        }
        else {
            setCurrent('General Details')
        }
        if (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() !== undefined && (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee-profile')) {
            setCurrent('General Details');
        }
        else {
            setCurrent('General Details')
        }
        if (page == 'placementAdd') {
            setCurrent('Bank Details');
        } else if (page == 'i94') {
            setdocumentPosition('work');
        } else if (page == 'visa') {
            setdocumentPosition('visa');
        }
        else {
            setCurrent('General Details')
        }
        if (stage === 'General Details' || stage === 'Documents') {
            setCurrent('Documents');
        } else if (stage == 'i94') {
            setdocumentPosition('work');
        } else if (stage == 'visa') {
            setdocumentPosition('visa');
        } else {
            setCurrent('General Details');
        }
        if (employee.complete_profile !== '') {
            setCurrent(employee.complete_profile);
        }
        else {
            setCurrent('General Details')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // getEmployeeApi();
        if (location.state && location.state.from != undefined) {
            if (location.state.from == 'employmentDashboard') {
                setCurrent('General Details')
            }
        }
        // eslint-disable-next-line
    }, [location.state])

    useEffect(() => {
        if (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() !== undefined && (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee-profile')) {
            setCurrent('General Details');
        }
        else {
            setCurrent('General Details')
        }
        // eslint-disable-next-line
    }, [LocalStorage.getRedirectedModule()])

    useEffect(() => {
        getEmployeeApi()
        // eslint-disable-next-line
    }, [current])

    useEffect(() => {
        props.setformEdit(formEdit)
        // eslint-disable-next-line
    }, [formEdit])

    useEffect(() => {
        getActivity()
        // eslint-disable-next-line
    }, [activityFilter])

    const [documentPosition, setdocumentPosition] = useState('education');

    const getActivity = (args) => {
        let data = {
            limit: activityFilter.limit,
            page: activityFilter.page,
            search: activityFilter.search,
            request_id: LocalStorage.uid(),
            employee_id: args ? args : employee.id ? employee.id : id
        }
        UserProfileApi.getActivity(data).then((response) => {
            if (response.data.statusCode == 1003) {
                // setLoading(false)

                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
            }
        });
    }

    const downloadActivity = () => {
        let data = {
            request_id: LocalStorage.uid(),
            employee_id: employee.id
        }
        UserProfileApi.downloadActivity(data).then((response) => {
            if (response.data.statusCode == 1003) {
                // setLoading(false)
                let url = response.data.path
                let fileName = url.split('/temp/')
                FileSaver.saveAs(response.data.path, fileName[1])
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }


    const getEmployeeOffboard = (last_working_date = '') => {
        OffboardApi.getOffBoardCheckList(location.state.id, last_working_date).then((response) => {
            if (response.data.statusCode == 1003) {
                setOffboardData(response.data.data[0])
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const getEmployeeApi = () => {
        setLoading(true);
        if (id !== '') {
            EmployeeAPI.getEmployee(id).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setLoading(false);
                        setEmployee({ ...response.data.data });
                        setEmployeeProfile(response.data.data.profile_picture_url);
                        if (jump) {
                            if (response.data.data.documents_pending) {
                                if (response.data.data.complete_profile == 'education_documents') {
                                    setCurrent('Documents'); setdocumentPosition('education')
                                }
                                else if (response.data.data.complete_profile == 'passport_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'i94_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'visa_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'personal_documents') {
                                    setCurrent('Documents'); setdocumentPosition('visa')
                                }
                            }
                            else if (response.data.data.bank_pending) {
                                setCurrent('Bank Details'); setdocumentPosition('education')
                            }
                            else if (response.data.data.skills_pending) {
                                setCurrent('Skills'); setdocumentPosition('education')
                            } else {
                                setCurrent("General");
                            }
                        }
                        if (response.data.data.is_off_boarding_initiated === true) {
                            getEmployeeOffboard(response.data.data.last_working_day);
                        }
                        setTimeout(() => {
                            getActivity(response.data.data.id)
                        }, 300)
                        // getOffBoardCheckList(response.data.data.id);
                    } else {
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }

    const disablePayroll = () => {
        setOpenPayroll(true);
    }

    const rehireHandler = () => {
        if (employee.enable_rehire) {
            const data = {
                request_id: LocalStorage.uid(),
                id: employee.id,
                email_id: employee.contact_details.email_id,
                contact_number: employee.contact_details.contact_number
            }
            EmployeesApi.checkDuplicate(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setLimitExceed(false);
                    if (res.data.valid) {
                        navigate('/rehire', { state: { full_name: employee.basic_details.full_name, id: employee.id, date: employee.last_working_day } })
                    } else {
                        setEmpExistis(true);
                    }
                } else {
                    setLimitExceed(false);
                    addErrorMsg(res.data.message);
                }
            })
        }
        else {
            setLimitExceed(true);
        }

    }

    const disablePayrollApi = () => {
        const data = {
            request_id: LocalStorage.uid(),
        }
        data['id'] = employee.id
        EmployeesApi.employeePayrollDisable(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(res.data.message);
                setOpenPayroll(false);
                if (employee.enable_payroll === true) {
                    setEmployee({ ...employee, enable_payroll: false })
                } else if (employee.enable_payroll === false) {
                    setEmployee({ ...employee, enable_payroll: true })
                }
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }


    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            const formData = new FormData();
            formData.append("files", e.target.files[0]);
            formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
            CommonApi.documentUpload("employee-profile", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        setEmployeeProfile(response.data.data.id);
                        ProfileUpdateApi(response.data.data.id)
                    } else {
                        setLoading(false)
                        addErrorMsg(response.data.message);
                    }
                });
        } else {
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const ProfileUpdateApi = (args) => {
        setLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            id: location.state.id,
            documents: [
                {
                    new_document_id: args
                }
            ]
        }
        EmployeeAPI.profileUpload(location.state.id, data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false);
                addSuccessMsg('Profile Uploaded Successfully');
                getEmployeeApi();
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (anchorEl) {
            handleClose()
        }
    };
    const handleClosePopUp = () => {
        setLastWorkingDate(lastWorkingDateCopy)
        setPopUpOpen(false);
        setError('');
    };

    const everifyEmployee = () => {
        const data = {
            request_id: LocalStorage.uid()
        }
        UserProfileApi.everifyEmp(id, data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg('Employee E-Verified Successfully');
                setEverifyPopUpOpen(false);
                getEmployeeApi();
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const dateChange = (e, name) => {
        let date = e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setLastWorkingDate(moment(date).format(dateFormat()))
        if (date == '') {
            setError('This field is required')
        } else {
            setError('');
        }
    }

    const handleSubmit = () => {
        if (!lastWorkingDate || lastWorkingDate === '') {
            setError('This field is required')
        } else {
            const last_working_day = moment(moment(lastWorkingDate, dateFormat()).format());
            const is_one_week_prior = last_working_day.diff(moment().startOf('day'), 'days') < 7;
            setError('');
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeSubModule();
            LocalStorage.removeNotificationEmpId();
            if (is_one_week_prior) {
                setPopUpOpen(false);
                navigate(`/employees/user-profile/${employee.basic_details.full_name === "" ? "" : employee.basic_details.full_name.trim().split(/ +/).join('-')}/offboarding`, { state: { full_name: employee.basic_details.full_name, reference_id: employee.employment_details.reference_id, enable_login: employee.enable_login, avatar_url: employee.profile_picture_url, id: id, acc: 1, visa: employee.employment_details.visa_type, last_date: lastWorkingDate, expand: '', min_date: employee.min_last_working_day } })
                return;
            }
            OffboardApi.getOffBoardCheckList(id, lastWorkingDate).then(response => {
                if (response.data.statusCode == 1003) {
                    setOffboardData(response.data.data[0])
                    handlefinishOffboard(response.data.data[0])
                } else {
                    addErrorMsg(response.data.message);
                }
            })

        }
    }

    const handlefinishOffboard = (finishOffboardData) => {
        setLoader(true)
        let data = { ...finishOffboardData, reference_id: LocalStorage.uid() }
        data.last_working_day.status = true
        data.last_working_day.date = lastWorkingDate
        OffboardApi.newstoreOffBoard(data).then((response) => {
            if (response.data.statusCode === 1003) {
                setLoader(false)
                setPopUpOpen(false)
                setTimeout(() => { setFinishPopup(true) }, 300)
            }
            else {
                setLoader(false)
                addErrorMsg(response.data.message)
            }
        })
    }

    const handleFinishPopup = () => {
        getEmployeeApi()
        setFinishPopup(false)
    }

    const navigateToOffboard = (args) => {
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        navigate(`/employees/user-profile/${employee.basic_details.full_name === "" ? "" : employee.basic_details.full_name.trim().split(/ +/).join('-')}/offboarding`, { state: { full_name: employee.basic_details.full_name, reference_id: employee.employment_details.reference_id, enable_login: employee.enable_login, avatar_url: employee.profile_picture_url, id: id, acc: 1, visa: employee.employment_details.visa_type, last_date: lastWorkingDate, expand: args, min_date: employee.min_last_working_day } })
    }

    const handleStatus = (args) => {
        if (args == 1) {
            setPopUpOpen(true)
            setShow(args)
            // const data = {
            //     request_id: LocalStorage.uid(),
            //     employee_id: id,
            // }
            // EmployeeAPI.employeeAccess(data).then((res) => {
            //     if (res.data.statusCode == 1003) {
            //         addSuccessMsg(res.data.message);
            //         handleClose();
            //         getEmployeeApi();
            //     } else {
            //         addErrorMsg(res.data.message);
            //     }
            // })
        }
        if (args == 0) {
            handleClose()
            setShow(args)
            setPopUpOpen(true)
        }
    }
    const [loaderOffboard, setLoaderOffboard] = useState(false);
    const handleAccess = () => {
        const data = {
            request_id: LocalStorage.uid(),
            employee_id: id,
        }
        setLoaderOffboard(true)
        EmployeeAPI.employeeAccess(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(res.data.message);
                // handleClose();
                setPopUpOpen(false);
                getEmployeeApi();
                setLoaderOffboard(false)

            } else {
                setLoaderOffboard(false)

                addErrorMsg(res.data.message);
            }
        })
    }
    //disable user access
    // eslint-disable-next-line
    const DeactiveApi = () => {
        const data = {
            request_id: LocalStorage.uid(),
            employee_id: id
        }
        UserProfileApi.InactiveEmp(data).then((res) => {

        })
    }

    const navigate = useNavigate();
    const [everifyPopUpOpen, setEverifyPopUpOpen] = useState(false);

    const handleEverifyPopUp = (args) => {
        if (args == 1) {
            setEverifyPopUpOpen(false);
        } else {
            // if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) {
            //     setEverifyPopUpOpen(true);
            // }
            const newWindow = window.open('https://everify.uscis.gov/home/', '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } 
    }
    const [successEverifyPopUpOpen, setsuccessEverifyPopUpOpen] = useState(false);

    const handleCloseSuccessEverifyPopUp = () => {
        setsuccessEverifyPopUpOpen(false);
        setEverifyPopUpOpen(false);
        navigate('/Dashboard');
    };

    const [activityAnchorEl, setActivityAnchorEl] = useState(null);

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };
    const activityOpen = Boolean(activityAnchorEl);

    const checkDocumentComplete = (item) => {
        if (employee.employment_details.employment_type_id == 1) {

        }
        else {
            if (item == 'Documents') {
                return employee.documents_pending
            }
            else if (item == 'Bank Details') {
                return employee.bank_pending
            }
            else if (item == 'Skills') {
                return employee.skills_pending
            }
        }
    }

    const navigateToComponent = () => {
        if (employee.documents_pending) {
            if (employee.complete_profile == 'education_documents') {
                setCurrent('Documents'); setdocumentPosition('education')
            }
            else if (employee.complete_profile == 'passport_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'i94_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'visa_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'personal_documents') {
                setCurrent('Documents'); setdocumentPosition('visa')
            }
        }
        else if (employee.bank_pending) {
            setCurrent('Bank Details'); setdocumentPosition('education')
        }
        else if (employee.skills_pending) {
            setCurrent('Skills'); setdocumentPosition('education')
        }

    }

    const upgradePlan = () => {
        let planDetails = LocalStorage.getPlanCheckLocal()
        LocalStorage.setlocationPath(location.pathname)
        navigate('/billing', {
            state: {
                renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                    ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                        (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
            }
        })
    }

    useEffect(() => {
        setLastWorkingDate(employee.last_working_day || '')
        setLastWorkingDateCopy(employee.last_working_day || '')
    }, [employee])

    const show_offboarding = useCallback(() => {
        if (!employee.is_off_boarding_initiated) {
            return false;
        }
        const last_working_day = moment(moment(employee.last_working_day, dateFormat()).format());
        return last_working_day.diff(moment().startOf('day'), 'days') < 7;
    }, [employee.is_off_boarding_initiated, employee.last_working_day])

    const show_rehire_button = useCallback(() => {
        if (!employee.is_off_boarding_initiated) {
            return false;
        }
        const last_working_day = moment(moment(employee.last_working_day, dateFormat()).format());
        return last_working_day.diff(moment().startOf('day'), 'days') < 0;
    }, [employee.is_off_boarding_initiated, employee.last_working_day])


    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box mx={2} pl={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography component={Link} onClick={() => {
                        LocalStorage.removeRedirectedModule();
                        LocalStorage.removeFromPage()
                    }} to={'/employees'} className={classes.breadcrumbsLink}>Employees</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>User Profile</Text>
                </Breadcrumbs>
            </Box>
            <Hidden lgDown>
                {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                    <Box my={2} mx={2}>
                        <Grid container lg={12} columnSpacing={4}>
                            <Grid item container lg={3.2} md={3} sm={3} xs={12}>
                                <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                                    <Box px={3} py={1} pt={4}>
                                        <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <CircularProgress
                                                percentage={employee.profile_progress}
                                                color={employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : ''}
                                                src={EmployeeProfile}
                                                name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee.basic_details && employee.basic_details.full_name[0] : ''}
                                                imgWidth='80px !important'
                                                imgHeight='80px !important'
                                                avatarWidth='80px !important'
                                                avatarHeight='80px !important'
                                                value={employee.profile_progress}
                                                size={88}
                                                edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) && employee.status == 'Active'}
                                                handleEdit={(e) => uploadProfile(e)}
                                                tooltip
                                                tooltipContent={
                                                    <Box p={'3px 0px'} width='100%'>
                                                        <Text mediumBoldWhite400>{`Profile Completion - ${employee.profile_progress}%`}</Text>
                                                        {
                                                            employee.profile_progress == 100 ? '' : <CustomButton addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToComponent() }}>Complete Profile</CustomButton>
                                                        }
                                                    </Box>
                                                }
                                            />
                                        </Box>
                                        <Grid item container lg={12} pt={1} spacing={0} alignItems='center'>
                                            <Grid item lg={12}>
                                                <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                                    <Text className={classes.profileName} >
                                                        <BlackToolTip arrow placement='top' title={employee.basic_details.full_name}>
                                                            {employee.basic_details.full_name.slice(0, 16)}{employee.basic_details.full_name.length > 16 ? "..." : ""}
                                                        </BlackToolTip>
                                                    </Text>
                                                    {employee.e_verified == 1 ? <img src={verified} alt='verified' /> : employee.e_verified == 0 ?
                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>This user is not E-Verified</Text>}>
                                                            <img src={eVerifyPending} alt='pending' />
                                                        </BlackToolTip>
                                                        : ''}
                                                </Box>
                                                {/* } */}
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} pt={0}>
                                            <Text className={classes.profileId}>{employee.employment_details.reference_id}</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} pt={0} p={1} textAlign='center' justifyContent='center'>
                                            <Box sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={handleActivityClick}>
                                                Activity log
                                            </Box>

                                        </Grid>
                                        <Grid item lg={12}>
                                            <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                                {
                                                    loading ?
                                                        <Skeleton width='150px' height='60px' animation='wave' /> :
                                                        show_rehire_button() ?
                                                            <LoaderButton loading={limitExceed} rehire onClick={() => rehireHandler()}>Re-hire</LoaderButton> :
                                                            <Button className={classes.eVerifyBtn} sx={{ border: employee.e_verified == 1 ? '1px solid #4ABE43 !important' : employee.e_verified == 0 ? '1px solid #0C75EB !important' : '', backgroundColor: (employee.e_verified == 0 ? "#ffffff !important" : employee.e_verified == 1 ? "#4ABE43 !important" : employee.e_verified == 2 ? "#F59E0B !important" : employee.e_verified == 3 ? "#E51A1A !important" : ''), color: '#ffffff' }} onClick={() => handleEverifyPopUp(employee.e_verified)}>
                                                                <Stack direction='row' gap={1} sx={{ color: (employee.e_verified == 0 ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                                    {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : '')}
                                                                    {employee.e_verified !== 0 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                                </Stack>
                                                            </Button>
                                                }
                                                {
                                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                                        (!show_rehire_button() && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    sx={{
                                                                        '& .MuiPaper-root': {
                                                                            border: '1px solid #EAECF0 !important',
                                                                            width: '150px !important',
                                                                            boxShadow: "#0000001F !important",
                                                                            borderRadius: '8px !important',
                                                                            padding: '0px 2px 0px 0px !important'
                                                                        },
                                                                    }}
                                                                >
                                                                    <EmployeeActions employee={employee} rolePermission={rolePermission} handleStatus={handleStatus} classes={classes} setCancelPopup={setCancelPopup} />
                                                                </Menu>
                                                            </Button>
                                                        )

                                                }
                                            </Stack>
                                        </Grid>

                                    </Box>
                                    <Box px={3}>
                                        <Divider />
                                    </Box>
                                    <Box py={3}>
                                        <Box px={3} sx={{
                                            height: "30vh",
                                            overflow: "auto",
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#C7CCD3',
                                                outline: '1px solid #C7CCD3',
                                                borderRadius: "4px",
                                            },
                                            "@media (min-height: 750px)": {
                                                height: '36vh'
                                            }
                                        }}>
                                            {
                                                dataArr.map((item, key) => (
                                                    <ListItemButton
                                                        key={key}
                                                        className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                        onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={10}>{item}</Grid>
                                                            {
                                                                checkDocumentComplete(item) ?
                                                                    <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                        <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                            <img src={eVerifyPending} alt='pending' />
                                                                        </BlackToolTip>
                                                                    </Grid> : ''
                                                            }
                                                        </Grid>
                                                    </ListItemButton>
                                                ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={.1}></Grid>
                            <Grid item lg={8.4} md={8.4} sm={8} xs={12}>
                                {
                                    true ? '' : <Grid item pb={2}>
                                        <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                            <Box pt={'4px'}>
                                                <img src={warning} alt='warning' />
                                            </Box>
                                            <Box>
                                                <Text mediumOrangeRehire> This employee has been re-hired recently, please re-confirm all the details </Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
                                <Box sx={{ width: '100%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto', overflowX: 'hidden' }}>
                                    <Box>
                                        {
                                            current === "General Details" && employee.basic_details.first_name !== '' &&
                                            <Box m={0.5} mr={0.1}>
                                                <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                                    <GeneralDetails rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} offBoardButton={offBoardButton} progress={progress} avatar_url={employee.profile_picture_url} active={active} grButn={grButn}
                                                        employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} loading={loading} setLoading={setLoading} offBoardButton_enable={employee.is_off_boarding_initiated} offboardPercentage={employee.off_boarding_percentage}
                                                    />
                                                </Box>
                                                <Box sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                                    <ViewEmergency rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            current === "Documents" ?
                                                <Box m={0.5} mr={0.1} mb={0} sx={{ width: '100% !important', overflowX: 'hidden' }}>
                                                    <Box mb={2} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                        <Documents rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} />
                                                    </Box>
                                                    <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        <AllSections rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            current === "Bank Details" ?
                                                <BankDetails rolePermission={rolePermission} getEmployeeApi={getEmployeeApi} getActivity={getActivity} employee={employee} formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} />
                                                : null
                                        }
                                        {
                                            current === "Immigration" ? <Immigration formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Timesheet" ? <Timesheet formEdit={formEdit} setformEdit={setformEdit} timesheet_enable={employee.is_configurable_timesheet_available} id={employee.id} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Skills" ? <Skills rolePermission={rolePermission} formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} getActivity={getActivity} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Dependents" ? <Dependents formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Vacation History" ? <VacationHistory formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                    </Box>
                                    <Box sx={{ height: '20vh', right: 100, bottom: 30, zIndex: 1000, position: 'fixed' }}>
                                        {
                                            show_offboarding() &&
                                            <Box display='flex' flexDirection='column' gap={2}>
                                                {
                                                    employee.enable_payroll === true ?
                                                        <CustomButton redPayroll onClick={disablePayroll}>Disable Payroll</CustomButton> :
                                                        <CustomButton disablePayroll>Disable Payroll</CustomButton>
                                                }
                                                <CustomButton offBoardButton onClick={() => setContinueOffboard(true)}>Off Boarding | {employee && employee.off_boarding_percentage}%</CustomButton>
                                            </Box>
                                        }
                                    </Box>
                                    <BootstrapDialog
                                        TransitionComponent={Transition}
                                        keepMounted
                                        aria-labelledby="customized-dialog-title"
                                        open={continueOffboard}
                                        onClose={() => setContinueOffboard(false)}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#3B4957" }} id="customized-dialog-title">
                                            <Text overViewAmount>Offboarding</Text>
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => setContinueOffboard(false)}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                boxShadow: 'none !important',
                                                "&:hover": {
                                                    boxShadow: 'none !important',
                                                }
                                            }}
                                        >
                                            <CloseRoundedIcon sx={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                        <Box className={classes.bodyBox}>
                                            <Grid container>
                                                <Grid item lg={12} md={12} sm={12} display={"flex"} justifyContent={"center"} textAlign='center'>
                                                    <Box
                                                        sx={{
                                                            height: 126,
                                                            // transform: "rotate(-360deg)"
                                                        }}
                                                        textAlign='center'
                                                    >
                                                        <ReactApexChart options={semicircle.options} series={semicircle.series} type="radialBar" height={250} width={250} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} mb={2} display={"flex"} justifyContent={"center"} textAlign={"center"}>
                                                    <Text mediumBoldWhite400>  Off <br />Boarding <br />
                                                        {employee.off_boarding_percentage == 100 ? "Completed" : "Progress"}</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Grid container p={'10px 15px 30px 15px'} sx={{ height: '330px', overflowY: 'scroll' }}>
                                            {
                                                (offboardData.type == 2 || offboardData.type == 3) &&
                                                <Grid item container lg={12} p={2} mt={1} className={offboardData.placement.status ? classes.activeRow : classes.disableRow}>
                                                    <Grid item lg={9} className={classes.headerRow}>
                                                        <Text BrowmnMnStepperText>{typeSno[offboardData.type].placement}. Placement End Dates</Text>
                                                    </Grid>
                                                    {
                                                        offboardData.placement.status ?
                                                            <>
                                                                <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                                <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                            </>
                                                            :
                                                            <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('placements')}>Start</CustomButton></Grid>
                                                    }


                                                </Grid>
                                            }

                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.disable_user_access.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].disable}. Disable User Access Across Apps</Text></Grid>
                                                {
                                                    offboardData.disable_user_access.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('disable')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            {
                                                offboardData.type == 2 &&
                                                <Grid item container lg={12} mt={1} p={2} className={offboardData.send_revocation.status ? classes.activeRow : classes.disableRow}>
                                                    <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].send}. Send Email to USCIS / University</Text></Grid>
                                                    {
                                                        offboardData.send_revocation.status ?
                                                            <>
                                                                <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                                <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                            </>
                                                            :
                                                            <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('recovation')}>Start</CustomButton></Grid>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item container lg={12} mt={1} p={2} className={offboardData.delete_mail.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].delete}. Delete Mail ID</Text></Grid>
                                                {
                                                    offboardData.delete_mail.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('delete')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.expense_settlement.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].expense}. Expense Settlement</Text></Grid>
                                                {
                                                    offboardData.expense_settlement.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('expenseOff')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.settle_balance.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].settle}. Settle Balance</Text></Grid>
                                                {
                                                    offboardData.settle_balance.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('settle')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>


                                        </Grid>
                                    </BootstrapDialog>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
                }
            </Hidden>
            <Hidden lgUp>
                <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                    <Grid item container md={11} xs={12} sx={{
                        border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                    }}>
                        <Grid item container md={9} xs={8} columnSpacing={2}>
                            <Grid item md={2} xs={3}>
                                <CircularProgress
                                    percentage={employee.profile_progress}
                                    color={employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : ''}
                                    src={EmployeeProfile}
                                    name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee.basic_details && employee.basic_details.full_name[0] : ''}
                                    imgWidth='80px !important'
                                    imgHeight='80px !important'
                                    avatarWidth='80px !important'
                                    avatarHeight='80px !important'
                                    value={employee.profile_progress}
                                    size={88}
                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) && employee.status == 'Active'}
                                    handleEdit={(e) => uploadProfile(e)}
                                    tooltip
                                    tooltipContent={
                                        <Box p={'3px 0px'} width='100%'>
                                            <Text mediumBoldWhite400>{`Profile Completion - ${employee.profile_progress}%`}</Text>
                                            {
                                                employee.profile_progress == 100 ? '' : <CustomButton addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToComponent() }}>Complete Profile</CustomButton>
                                            }
                                        </Box>
                                    }
                                />
                            </Grid>
                            <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                <Box alignItems='center'>
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                        <Text className={classes.profileName}>
                                            <BlackToolTip arrow placement='top' title={employee.basic_details.full_name}>
                                                {employee.basic_details.full_name.slice(0, 14)}{employee.basic_details.full_name.length > 14 ? "..." : ""}
                                            </BlackToolTip>
                                        </Text>
                                        {employee.e_verified == 1 ? <img src={verified} alt='verified' /> : employee.e_verified == 0 ?
                                            <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>This user is not E-Verified</Text>}>
                                                <img src={eVerifyPending} alt='pending' />
                                            </BlackToolTip>
                                            : ''}
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text className={classes.profileId}>{employee.employment_details.reference_id}</Text>
                                        <Box sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={handleActivityClick}>
                                            Activity log
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container md={3} xs={4} alignItems='center'>
                            <Stack my={1} direction="row" justifyContent={"center"} gap={1}>
                                {
                                    loading ?
                                        <Skeleton width='120px' height='60px' animation='wave' /> :
                                        show_rehire_button() ?
                                            <LoaderButton loading={limitExceed} rehire onClick={() => rehireHandler()}>Re-hire</LoaderButton> :
                                            <Button className={classes.eVerifyBtn} sx={{ border: employee.e_verified == 1 ? '1px solid #4ABE43 !important' : employee.e_verified == 0 ? '1px solid #0C75EB !important' : '', backgroundColor: (employee.e_verified == 0 ? "#ffffff !important" : employee.e_verified == 1 ? "#4ABE43 !important" : employee.e_verified == 2 ? "#F59E0B !important" : employee.e_verified == 3 ? "#E51A1A !important" : ''), color: '#ffffff' }} onClick={() => handleEverifyPopUp(employee.e_verified)}>
                                                <Stack direction='row' gap={1} sx={{ color: (employee.e_verified == 0 ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                    {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : '')}
                                                    {employee.e_verified !== 0 && <img style={{ marginTop: '3px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                </Stack>
                                            </Button>
                                }
                                {
                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                        (!show_rehire_button() && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            border: '1px solid #EAECF0 !important',
                                                            width: '150px !important',
                                                            boxShadow: "#0000001F !important",
                                                            borderRadius: '8px !important',
                                                            padding: '0px 2px 0px 0px !important'
                                                        },
                                                    }}
                                                >
                                                    <EmployeeActions employee={employee} rolePermission={rolePermission} handleStatus={handleStatus} classes={classes} setCancelPopup={setCancelPopup} />
                                                </Menu>
                                            </Button>
                                        )

                                }
                            </Stack>
                        </Grid>
                        <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                            {
                                dataArr.map((item, key) => (
                                    <>
                                        <Grid item md={3} xs={3}>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                {
                                                    current == item ?
                                                        <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}>{item}</Text> :
                                                        <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}>{item}</Text>
                                                }
                                                {
                                                    checkDocumentComplete(item) ?
                                                        // <Grid item container justifyContent={'flex-end'} xs={2}>
                                                        <BlackToolTip arrow placement='right' title={'Pending'}>
                                                            <img src={eVerifyPending} alt='pending' />
                                                        </BlackToolTip>
                                                        : ''
                                                }
                                            </Box>
                                        </Grid>
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                    <Grid item container md={11} xs={12}>
                        <Box className={classes.responsiveContainer}>
                            {
                                current === "General Details" && employee.basic_details.first_name !== '' &&
                                <Box m={0.5} mr={0.5}>
                                    <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                        <GeneralDetails rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} offBoardButton={offBoardButton} progress={progress} avatar_url={employee.profile_picture_url} active={active} grButn={grButn}
                                            employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} loading={loading} setLoading={setLoading} offBoardButton_enable={employee.is_off_boarding_initiated} offboardPercentage={employee.off_boarding_percentage}
                                        />
                                    </Box>
                                    <Box sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                        <ViewEmergency rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                    </Box>
                                </Box>
                            }
                            {
                                current === "Documents" ?
                                    <Box m={0.5} mr={0} mb={0}>
                                        <Box mb={2} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                            <Documents rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} />
                                        </Box>
                                        <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll' }}>
                                            <AllSections rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                        </Box>
                                    </Box>
                                    : null
                            }
                            {
                                current === "Bank Details" ?
                                    <BankDetails rolePermission={rolePermission} getEmployeeApi={getEmployeeApi} getActivity={getActivity} employee={employee} formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} />
                                    : null
                            }
                            {
                                current === "Immigration" ? <Immigration formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Timesheet" ? <Timesheet formEdit={formEdit} setformEdit={setformEdit} timesheet_enable={employee.is_configurable_timesheet_available} id={employee.id} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Skills" ? <Skills rolePermission={rolePermission} formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} getActivity={getActivity} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Dependents" ? <Dependents formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Vacation History" ? <VacationHistory formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                        </Box>
                        <Box sx={{ height: '20vh', right: 100, bottom: 30, zIndex: 1000, position: 'fixed' }}>
                            {
                                show_offboarding() &&
                                <Box display='flex' flexDirection='column' gap={2}>
                                    {
                                        employee.enable_payroll === true ?
                                            <CustomButton redPayroll onClick={disablePayroll}>Disable Payroll</CustomButton> :
                                            <CustomButton disablePayroll>Disable Payroll</CustomButton>
                                    }
                                    <CustomButton offBoardButton onClick={() => setContinueOffboard(true)}>Off Boarding | {employee && employee.off_boarding_percentage}%</CustomButton>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>

            <ReusablePopup white iconHide fullWidth openPopup={popUpOpen} setOpenPopup={setPopUpOpen}>
                {show == 1 &&
                    <>
                        <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <img src={OffBoardImg} alt="OffBoard" />
                        </Box>
                        <Box my={3} sx={{textAlign:'center'}}>
                            <Text my={1} popupHead1> Are You Sure?</Text>
                            <Text my={1} popupHead2>
                                The user access will be {employee.enable_login == 1 ? 'disabled' : 'enabled'}  for <span style={{ color: "#0C75EB" }}>{employee.basic_details.full_name}.</span>
                            </Text>
                        </Box>
                        <Box my={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                            <CustomButton popupCancel1 onClick={() => handleClosePopUp()}>
                                Cancel
                            </CustomButton>
                            <LoadingButton loading={loaderOffboard} popupSaveBlue onClick={() => handleAccess()} >
                                Yes
                            </LoadingButton>
                        </Box>
                    </>
                }
                {show == 0 &&
                    <UserDisable lastWorkingDate={lastWorkingDate} minDate={employee} dateChange={dateChange} error={error} handleSubmit={handleSubmit} handleClosePopUp={handleClosePopUp} setLastWorkingDate={setLastWorkingDate} loader={loader} />
                }
            </ReusablePopup>
            <SwipeableDrawer
                anchor={'right'}
                open={activityOpen}
                onClose={() => { handleActivityClose() }}
                // onOpen={toggleDrawer(true, state.view)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog
                    activityTotal={activityTotal}
                    activityData={activityData}
                    activityFilter={activityFilter}
                    setActivityFilter={setActivityFilter}
                    downloadActivity={downloadActivity}
                    employee={employee} setLoading={setLoading} loading={loading} />
            </SwipeableDrawer>
            <ReusablePopup openPopup={everifyPopUpOpen} setPopUpOpen={setEverifyPopUpOpen} white iconHide fullWidth>
                <Grid container p={3} textAlign='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to send the employees</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>information for E- verification.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <CustomButton blueBorderOutlined onClick={() => { setEverifyPopUpOpen(false) }}>
                                Cancel
                            </CustomButton>
                            <CustomButton finishFilledAverta onClick={() => {
                                everifyEmployee()
                                setEverifyPopUpOpen(false)
                            }} >
                                Yes
                            </CustomButton>
                        </Stack>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <BootstrapDialog
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={successEverifyPopUpOpen}
                sx={{
                    "& .MuiDialog-paper ": { borderRadius: "8px", width: "406px !important", height: "390px !important" },
                }}
            >
                <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={EverifySuccesPopUp} alt='Everify SuccessPopUp' style={{ marginTop: '35px' }} />
                    <Text largeBoldGreen sx={{ fontSize: '22px !important', marginTop: '20px' }}>Successfully Sent</Text>
                    <Text blackHeader sx={{ fontSize: '14px !important', marginTop: '18px' }}>Details has been Successfully Sent</Text>
                    <CustomButton popupSaveBlue sx={{ marginTop: '35px', color: '#F5F5F5 !important', fontSize: '16px !important', fontWeight: `${400} !important`, width: '122px !important', height: '39px !important' }} onClick={() => handleCloseSuccessEverifyPopUp()}>Go To Home</CustomButton>
                </DialogContent>
            </BootstrapDialog>
            <ReusablePopup openPopup={openPayroll} setopenPopup={setOpenPayroll} white iconHide fullWidth>
                <Grid container textAlign='center'>
                    <Grid item lg={12}>
                        <img src={OffBoardImg} alt='payroll' />
                    </Grid>
                    <Grid item lg={12} mt={2}>
                        <Text popupHead1> Are You Sure?</Text>
                    </Grid>
                    <Grid item lg={12} mt={2}>
                        <Text popupHead2> Do you want to disable the payroll for <span className={classes.blueText}>{employee.basic_details.full_name}</span></Text>
                    </Grid>
                    <Grid item lg={12} my={2} mt={3}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                            <CustomButton popupCancelRed onClick={() => setOpenPayroll(false)}>
                                No
                            </CustomButton>
                            <CustomButton popupSaveBlueHeight onClick={disablePayrollApi}>Yes</CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fullWidth>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <CustomButton smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                            Cancel
                        </CustomButton>
                        <CustomButton smallBlue redBackground onClick={() => { setformEdit(false); setCurrent(stepItem); setOpenpopup(false) }} >
                            Yes
                        </CustomButton>
                    </Stack>
                </Grid>
            </ReusablePopup>
            <ReusablePopup white iconHide openPopup={empExists} setOpenPopup={setEmpExistis} fixedWidth>
                <Grid container spacing={2} p={2} textAlign={'center'} justifyContent={'center'}>
                    <Grid item lg={12}>
                        <img src={contactExists} alt='rehire' />
                    </Grid>
                    <Grid item lg={12}>
                        <Text veryLargeLabel>Details already exist</Text>
                    </Grid>
                    <Grid item lg={12}>
                        <Text mediumOverView>The contact details already exist for<br /> another active user</Text>
                    </Grid>
                    <Grid item lg={9} mt={1}>
                        <CustomButton saveBtn400 onClick={() => setEmpExistis(false)}>Okay</CustomButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={limitExceed} setOpenPopup={setLimitExceed} iconHide white fixedWidth>
                <Grid container justifyContent='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={deleteIcon} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={3}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Limit Exceeded!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign='center' pt={2}>
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please upgrade<br /> to add more Employees.</Text> :
                                <Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please contact<br /> your super admin to upgrade.</Text>
                        }
                    </Grid>
                    <Grid container lg={11} justifyContent={'center'} textAlign='center' alignContent={'center'} mt={3}>
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item lg={8} container>
                                    <Grid item lg={6}>
                                        <CustomButton outlineBlue sx={{ width: '100px !important', height: '42px !important' }} onClick={() => { setLimitExceed(false) }}>Cancel</CustomButton>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <CustomButton sx={{ width: '100px !important' }} blueButton onClick={() => upgradePlan()}>Upgrade</CustomButton>
                                    </Grid>
                                </Grid> :
                                <CustomButton blueButton onClick={() => { setLimitExceed(false) }}>
                                    Cancel
                                </CustomButton>
                        }
                    </Grid>
                </Grid>
            </ReusablePopup>

            <ReusablePopup openPopup={finishPopup} setOpenPopup={setFinishPopup} white iconHide >
                <Box my={3} width={'450px'}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={Initiated} alt="completed" />
                    </Box>
                    <Grid container justifyContent={'center'} my={1}>
                        <Text my={1} offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>
                            Off boarding Initiated
                        </Text>

                    </Grid>
                    <Grid container justifyContent={'center'} my={1}>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', textAlign: "center" }}>
                            Offboarding for <span style={{ color: "#0C75EB" }}>{employee.basic_details.full_name}</span> has been initiated. The offboarding progress will be displayed one week prior to the last working day.
                        </Text>
                    </Grid>

                    <Box my={2} className={offboardChecklistClasses.popupHead1} mt={4}>
                        <CustomButton popupSaveBlue sx={{ width: "335px !important" }} onClick={handleFinishPopup}>
                            Done
                        </CustomButton>
                    </Box>
                </Box>
            </ReusablePopup>
            <CancelOffboarding cancelPopup={cancelPopup} setCancelPopup={setCancelPopup} employee={employee} offboardData={offboardData} getEmployeeApi={getEmployeeApi} />
        </Box >
    );
}