import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { MyContext } from "../../Context";
import Grid from "@mui/material/Grid";
import { Hidden, Link } from "@mui/material";
import { LoginStyles } from "./LoginStyles";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import CustomInput from "../../components/input/Input";
import Text from "../../components/customText/Text";
import { isValid, validates_emailId, validates_password_login } from "../../components/Validation";
import { addErrorMsg, addSuccessMsg } from "../../utils/utils"; // eslint-disable-next-line
import { domain, urlEnroll } from "../../config/Domain";
import LocalStorage from "../../utils/LocalStorage";
import LoginApi from "../../apis/LoginApi";
import CommonApi from "../../apis/CommonApi";
import Logo from '../../assets/logo.svg';
import Button from "../../components/customButton/Button";
import OtpInput from "react-otp-input-rc-17";
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import otpImg from "../../assets/svg/otpClose.svg"; // eslint-disable-next-line
import { dev_url, qa_url } from "../../config/development";
import LoadingButton from '../../components/customButton/LoadingButton';

function Login() {
  const classes = LoginStyles(); // eslint-disable-next-line
  const location = useLocation()
  const navigate = useNavigate();
  const [otpCount, setotpCount] = useState('')

  const { setGlobaldata } = useContext(MyContext);
  const [otp, setOtp] = useState("");
  const [eye, setEye] = useState({
    password: false,
    confirmpassword: false
  });
  const [formData, setFormData] = useState({
    email_id: '',
    email: '',
    login_mode: '',
    otp: '',
    password: '',
    subdomain_name: ''
  });
  const [error, setError] = useState({});
  const [status, setStatus] = useState('email');
  const [loading, setLoading] = React.useState(false);
  const [isVisible, setVisible] = useState(false); //For password hide and show  
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const urlToNavigate = urlEnroll

  const [otpLoader, setOTPLoader] = useState(false);
  const signUpUrl = (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) ? `/signup` : `https://${urlToNavigate}/signup`

  const changeHandler = (e) => {
    setFormData(
      {
        ...formData,
        [e.target.name]: e.target.value,
      },
      handleValidate(e)
    );
  };
  const handleChange = (data) => {
    setOtp(data);
    if (data.length == 6) {
      setError({
        ...error,
        otp: false
      })
    }
  };
  useEffect(() => {
    if (LocalStorage.getAccessToken()) {
      navigate('/dashboard')
    }
    // if (LocalStorage.getAccessToken()) { navigate('/dashboard') } else navigate('/login', { state: { data: location.state == null ? 'login' : 'create-password' } })
    // eslint-disable-next-line
  }, [])

  const handleValidate = (e) => {
    let input = e.target;
    switch (input.name || input.tagName) {
      case "email":
        error.email = validates_emailId(input.value);
        break;
      case "password":
        error.password = validates_password_login(input.value);
        break;
      default:
        break;
    }
    let err = { ...error };
    setError(err);
  };

  const formValidations = () => {
    let { email, password } = formData;
    let errors = {};
    errors.email = validates_emailId(email);
    errors.password = validates_password_login(password);
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const userData = {
      request_id: LocalStorage.uid(),
      email_id: formData.email.toLowerCase(),
      password: formData.password,
      login_mode: status == 'otp' ? 2 : 1,
      otp: otp,
      subdomain_name: formData.subdomain_name
    };
    let errors = formValidations();
    if (status == 'otp' && (otp.length != 6 || otp == '')) {
      error.otp = true
      setError({
        ...error,
        otp: true
      })
      return true
    }
    if (status == 'otp' && otp.length == 6 ? true : isValid(errors)) {
      setLoading(true);
      LoginApi.Login(userData).then((response) => {
        if (response.data.statusCode == 1003) {
          // if (response.data.data.change_password) {
          //   navigate('/changepassword', { state: { data: response.data.data } });
          // }
          // else {
          if (response.data.data.ignore_login_user == 1) {
            navigate('/account-deactivated')
            return true
          }
          setLoading(false);
          CommonApi.Roles(response.data.data.role_id, response.data.data.access_token).then((res) => {
            let a = {};
            a.role_permissions = res.data.data.permissions
            LocalStorage.setRolesData(a);
          }
          )
          // LocalStorage.setUserData(response.data.data);
          setGlobaldata((prev) => ({ ...prev, logo_url: response.data.data.logo_url, date_format: response.data.data.date_format }));
          // LocalStorage.setSettings(true);
          LocalStorage.setDateFormat(response.data.data.date_format);
          LocalStorage.setCurrencySymbol(response.data.data.currency_symbol);
          addSuccessMsg(`${response.data.data.full_name} successfully logged in`);
          LocalStorage.showProgress('display');
          if (response.data.data.plan_not_set) {
            let url = `https://${formData.subdomain_name}.${urlToNavigate}/check-user?token=${response.data.data.access_token}`
            let urlLocal = `check-user?token=${response.data.data.access_token}`
            if (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) {
              navigate(urlLocal);
            }
            else {
              window.location.replace(url)
            }
          }
          else if (response.data.data.org_config_set == false) {
            let url = `https://${formData.subdomain_name}.${urlToNavigate}/check-user?token=${response.data.data.access_token}`
            let urlLocal = `check-user?token=${response.data.data.access_token}`
            if (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) {
              navigate(urlLocal);
            }
            else {
              window.location.replace(url)
            }
          }
          else {
            let url = `https://${formData.subdomain_name}.${urlToNavigate}/check-user?token=${response.data.data.access_token}`
            let urlLocal = `check-user?token=${response.data.data.access_token}`
            if (LocalStorage.getURLNAME() == "localhost" || LocalStorage.getURLNAME().includes('192')) {
              navigate(urlLocal);
            }
            else {
              window.location.replace(url)
            }
            // navigate(url, {state: {data: response.data.data}, replace: true});
          }
          // window.location.reload();
          // }
        }
        else {
          addErrorMsg(response.data.message);
          setLoading(false);
          setError({
            ...error,
            otp: status == 'otp' ? true : false
          })
          if (status == 'otp') {
            setMinutes(0);
            setSeconds(0);
            setotpCount(response.data.data[0].failed_login_attempts)
          }
          if (response.data.data[0].failed_login_attempts >= 3) {
            // setMinutes(2);
            // setMinutes(59)
            setStatus('otpFailed')
            setError({
              ...error,
              otp: false
            })
            setotpCount('')
          }
        }
      });
    } else {
      setLoading(false);
      let err = { error };
      err = errors;
      setError(err);
    }
  };
  //forgot fun to navigate forgot-password
  const forgotPassword = () => {
    navigate("/forgot-password", { state: { domain: formData.subdomain_name } });
  };
  // eslint-disable-next-line
  const passwordVisible = () => {
    setVisible(!isVisible);
  };


  const getDomain = (e) => {
    setLoader(true)
    e.preventDefault()
    if (formData.email == '') {
      setError({
        ...error,
        email: 'This field is required'
      })
      setLoader(false)
      return true
    }
    else {
      if (error.email == '') {
        CommonApi.domainConfig(formData.email).then(
          (response) => {
            if (response.data.statusCode == 1003) {
              setLoader(false)
              if (response.data.subdomain_exists) {
                setStatus('verify')
                setError({
                  ...error,
                  email: ''
                })
                setFormData({
                  ...formData,
                  subdomain_name: response.data.subdomain_name
                })
              }
              else {
                setLoader(false)
                setError({
                  ...error,
                  email: 'Invalid Email ID'
                })
              }
            }
            else {
              setLoader(false)
              addErrorMsg(response.data.message)
            }
          }
        )
      }
      else {
        setLoader(false)
      }
    }
  }

  const getOtp = () => {
    setOTPLoader(true);
    let data = {
      request_id: LocalStorage.uid(),
      email_id: formData.email,
      subdomain_name: formData.subdomain_name
    }
    CommonApi.verifyOtp(data).then(
      (response) => {
        setOTPLoader(false);
        if (response.data.statusCode == 1003) {
          setStatus('otp')
          if (response.data.data[0].otp_failed) {
            setStatus('otpFailed')
          }
          else {
            addSuccessMsg('OTP sent successfully')
          }
          // setotpCount(response.data.data[0].otp_count)
        }
        else {
          addErrorMsg(response.data.message)
        }
      }
    )
  }

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [timerStart, setTimerStart] = useState(false);

  useEffect(() => {
    let interval;
    if (status == 'otp') {
      if (isTimerRunning && minutes >= 0 && seconds >= 0) {
        interval = setInterval(() => {
          if (minutes > 0 && seconds === 0) {
            if (minutes > 0) {
              setMinutes(prevMinutes => prevMinutes - 1);
            }
            setSeconds(59);
          } else if (minutes >= 0 && seconds > 0) {
            setSeconds(prevSeconds => prevSeconds - 1);
          }
        }, 1000);
      } else if (minutes === 0 && seconds === 0) {
        setIsTimerRunning(false);
        setTimerStart(false)
        // Here you can add code to handle what happens when the timer expires
      }
      return () => clearInterval(interval);
    }
    if (timerStart) {
      if (isTimerRunning && minutes >= 0 && seconds >= 0) {
        interval = setInterval(() => {
          if (minutes > 0 && seconds === 0) {
            if (minutes > 0) {
              setMinutes(prevMinutes => prevMinutes - 1);
            }
            setSeconds(59);
          } else if (minutes >= 0 && seconds > 0) {
            setSeconds(prevSeconds => prevSeconds - 1);
          }
        }, 1000);
      } else if (minutes === 0 && seconds === 0) {
        setIsTimerRunning(false);
        setTimerStart(false);
        // Here you can add code to handle what happens when the timer expires
      }
    }
    // eslint-disable-next-line
  }, [isTimerRunning, minutes, seconds, status, timerStart]);
  const ImagesList = [
    Image1,
    Image2,
    // Image3,
    Image4
  ];
  // eslint-disable-next-line
  const [images, setImages] = useState(ImagesList);
  const [currentImage, setCurrentImage] = useState(0);

  function changeBackgroundImage() {
    if (currentImage == 0) {
      setTimeout(() => {
        setCurrentImage(1)
      }, 3000)
    }
    else if (currentImage == 1) {
      setTimeout(() => {
        setCurrentImage(2)
      }, 3000)
    }
    else if (currentImage == 2) {
      setTimeout(() => {
        setCurrentImage(0)
      }, 3000)
    }
    // else if (currentImage == 3) {
    //   setTimeout(() => {
    //     setCurrentImage(0)
    //   }, 3000)
    // }
  }

  useEffect(() => {
    const intervalId = setInterval(() => changeBackgroundImage(), 2000);
    return () => clearInterval(intervalId);
  });

  const valueGetter = (args) => {
    let a = args
    return 3 - a
  }

  const loginOtpCheck = () => {
    if (error.email == '') {
      CommonApi.domainConfig(formData.email).then(
        (response) => {
          if (response.data.statusCode == 1003) {
            setLoader(false)
            if (response.data.subdomain_exists) {
              setStatus('otp');
              getOtp()
            }
            else {
              addErrorMsg(response.data.message)
            }
          }
          else {
            addErrorMsg(response.data.message)
          }
        }
      )

    }
    else {
      addErrorMsg('Email is required')
    }
  }

  return (
    <div>
      <Grid container alignItems='center' sx={{ height: '100vh', overflow: 'hidden' }}>
        <Hidden mdDown>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.rightBox}>
            {ImagesList.map((image, index) => {
              return (
                <img src={images[currentImage]} alt={'img'} width={'100%'} />
              )
            })}
          </Grid>
        </Hidden>
        <Grid item lg={6} md={6} sm={12} xs={12} p={2}>
          <Grid container>
            <Grid container>
              <Grid item lg={2.5} md={2} xs={12}></Grid>
              <Grid item lg={7} md={8} xs={12}>
                {
                  status == 'email' ?
                    <form onSubmit={(e) => getDomain(e)}>
                      <Grid container>
                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                          <Box p={1}>
                            <img src={Logo} alt='logo' />
                          </Box>
                        </Grid>
                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                          <Box p={1}>
                            <Text popupHead1>Welcome to Japfu</Text>
                          </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} pt={2}>
                          <Box p={1}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                name: "email",
                                value: formData.email,
                              }}
                              clientInput
                              handleChange={changeHandler}
                              labelText={'Email Address'}
                              error={error.email}
                            />
                            {error.email ? <Text red>{error.email}</Text> : ""}
                          </Box>

                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                          <Box className={classes.buttonStyles}>
                            <LoadingButton
                              className={classes.loginButton}
                              loading={loader}
                              variant="contained"
                              type="submit"
                              onClick={(e) => getDomain(e)}
                              fullWidth
                            >
                              Continue
                            </LoadingButton>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                          <Box p={1} pl={5}>
                            <Text sx={{
                              font: '14px Averta !important',
                              letterSpacing: '0px',
                              color: `#1A141F  !important`,
                              fontWeight: '400 !important', display: 'flex', flexDirection: 'row',
                              "@media (min-width: 1200px) and (max-width: 1420px)": {
                                font: '14px Averta !important',
                              }
                            }}>Don't have an account? &nbsp; <span onClick={() => { window.location.replace(signUpUrl); }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Sign-up</span>
                              <span style={{
                                height: '6px',
                                width: '6px',
                                borderRadius: '50%',
                                background: '#D9D9D9',
                                display: 'flex',
                                margin: '8px 5px 2px 0px'
                              }}> </span>
                              <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                    :
                    status == 'verify' ?
                      <Grid container lg={12}>
                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                          <Box p={1}>
                            <img src={Logo} alt='logo' />
                          </Box>
                        </Grid>
                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                          <Box p={1}>
                            <Text popupHead1 sx={{ color: '#090914 !important' }}>Welcome back to Japfu</Text>
                          </Box>
                        </Grid>
                        <Grid item container sm={12} xs={12} justifyContent={'center'}>
                          <Box p={1}>
                            <Text grey16 sx={{ fontWeight: "600 !important", color: '#525252 !important' }}>{formData.email}</Text>
                          </Box>
                        </Grid>
                        <Grid item container sm={12} xs={12} justifyContent={'center'} >
                          <Box p={1}>
                            <Text sx={{ color: '#1A141F !important', font: "14px Averta !important", fontWeight: '400 !important' }}>How do you wish to login?</Text>
                          </Box>
                        </Grid>
                        <Grid item container spacing={2} md={12} sm={12} xs={12} justifyContent='center' pt={2} pl={6}>
                          <Grid item xs={6} textAlign='end'>
                            <Button outlineBlueAuto onClick={() => { setStatus('password'); setError({ ...error, password: '' }) }}>Enter Password</Button>
                          </Grid>
                          <Grid item xs={6} textAlign='start'>
                            <LoadingButton outlineBlueAuto loading={otpLoader} onClick={() => { getOtp(); setError({ ...error, password: '' }) }}>Login with OTP</LoadingButton>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                          <Box p={1} pl={10}>
                            <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}><span onClick={() => { setStatus('email') }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Change E-mail ID</span>
                              <span style={{
                                height: '6px',
                                width: '6px',
                                borderRadius: '50%',
                                background: '#D9D9D9',
                                display: 'flex',
                                margin: '8px 5px 2px 0px'
                              }}> </span>
                              <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                          </Box>
                        </Grid>
                      </Grid>
                      :
                      status == 'otpFailed'
                        ?
                        <Grid container>
                          <Grid item container sm={12} xs={12} justifyContent={'center'}>
                            <Box p={1}>
                              <img src={Logo} alt='logo' />
                            </Box>
                          </Grid>
                          <Grid item container sm={12} xs={12} justifyContent={'center'}>
                            <Box p={1}>
                              <Text popupHead1 sx={{ color: '#090914 !important' }}>Out of attempts!</Text>
                            </Box>
                          </Grid>
                          <Grid item container sm={12} xs={12} justifyContent={'center'}>
                            <Box >
                              <Text boldGrey14 sx={{ fontWeight: '400 !important' }}>Looks like you have exceeded trying logging in with </Text>
                            </Box>
                          </Grid>
                          <Grid item container sm={12} xs={12} justifyContent={'center'}>
                            <Box >
                              <Text boldGrey14 sx={{ fontWeight: '400 !important' }}>OTP, You can try to login using password. </Text>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12} md={12} pt={2}>
                            <Box className={classes.buttonStyles}>
                              <LoadingButton
                                className={classes.loginButton}
                                loading={loader}
                                variant="contained"
                                type="submit"
                                onClick={(e) => setStatus('password')}
                                fullWidth
                              >
                                Login with password
                              </LoadingButton>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                            <Box p={1}>
                              <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}>
                                <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '155px', cursor: 'pointer' }}>Contact Support</a> </Text>
                            </Box>
                          </Grid>
                        </Grid>
                        :
                        status == 'otp' ?
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid container>
                              <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                  <img src={Logo} alt='logo' />
                                </Box>
                              </Grid>
                              <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                  <Text popupHead1 sx={{ color: '#090914 !important' }}>Enter OTP</Text>
                                </Box>
                              </Grid>
                              <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box >
                                  <Text boldGrey14 sx={{ fontWeight: '400 !important' }}>Please enter OTP sent to your registered email </Text>
                                </Box>
                              </Grid>
                              {/* <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box >
                                  <Text boldGrey14 sx={{ fontWeight: '400 !important' }}>mobile number  </Text>
                                </Box>
                              </Grid> */}
                              <Grid item sm={12} xs={12} pt={2}>
                                <Box p={1} pl={1}>
                                  <OtpInput
                                    value={otp}
                                    onChange={handleChange}
                                    placeholder="000000"
                                    numInputs={6}
                                    separator={<span className={classes.span}></span>}
                                    inputStyle={{
                                      borderRadius: 8,
                                      border: error.otp ? "1px solid red" : "1px solid #C7CCD3",
                                      width: "calc(100% - 16px)",
                                      height: "52px",
                                    }}
                                  />
                                </Box>

                              </Grid>
                              <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Box className={classes.buttonStyles}>
                                  <LoadingButton
                                    className={classes.loginButton}
                                    loading={loader}
                                    variant="contained"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e)}
                                    fullWidth
                                  >
                                    Submit
                                  </LoadingButton>
                                </Box>
                              </Grid>
                              {
                                error.otp || otpCount > 0 ?
                                  <Grid item sm={12} xs={12} p={1.2}>
                                    <Box sx={{ background: '#FFFAF2', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                      <img src={otpImg} alt='otp' /> &nbsp;
                                      <Text mediumRed sx={{ fontSize: '12px !important' }}> Enter valid OTP, you have {valueGetter(otpCount)} more attempts.</Text>
                                    </Box>
                                  </Grid> : ''
                              }
                              {
                                (minutes.toString().padStart(2, '0') == '00') && (seconds.toString().padStart(2, '0') == '00') ?
                                  ""
                                  :
                                  <Grid item container sm={12} xs={12} justifyContent={'center'} pt={3}>
                                    <Text sx={{
                                      textAlign: 'center', font: '16px Averta !important',
                                      color: `#7F1D1D  !important`,
                                      fontWeight: `${500} !important`
                                    }}>
                                      {minutes.toString().padStart(2, '0')}:
                                      {seconds.toString().padStart(2, '0')}
                                    </Text>

                                  </Grid>
                              }
                              {/* {
                                error.otp == false && (otpCount != '' && otpCount != 1)
                                  ?
                                  <Grid item sm={12} xs={12}>
                                    <Box sx={{ background: '#FFFAF2', height: '50px', borderRadius: '8px', padding: '15px 0px 15px 0px', textAlign: 'center' }}>
                                      <Text mediumOrange sx={{ fontSize: '12px !important' }}>Verification email sent, you have {otpCount != '' ? valueGetter(otpCount) : ''} more attempt</Text>
                                    </Box>
                                  </Grid>
                                  : ''
                              } */}
                              {
                                (minutes.toString().padStart(2, '0') == '00') && (seconds.toString().padStart(2, '0') == '00') ?
                                  <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1}>
                                    <Box p={1}>
                                      <Text grey16 sx={{ fontWeight: '500 !important', color: '#AEAEAE !important' }} >Haven’t received the verification code <span onClick={() => { getOtp(); setTimerStart(true); setMinutes(2); setSeconds(59); setIsTimerRunning(true) }} style={{ color: '#0C75EB', fontWeight: '500', fontSize: '16px', cursor: 'pointer' }}>Resend</span>  </Text>
                                    </Box>
                                  </Grid>
                                  : ''
                              }

                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                              <Box p={1} pl={8}>
                                <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}> &nbsp; <span onClick={() => { setStatus('password') }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Login with password</span>
                                  <span style={{
                                    height: '6px',
                                    width: '6px',
                                    borderRadius: '50%',
                                    background: '#D9D9D9',
                                    display: 'flex',
                                    margin: '8px 5px 2px 0px'
                                  }}> </span>
                                  <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                              </Box>
                            </Grid>
                          </form>
                          :
                          <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid container>
                              <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                  <img src={Logo} alt='logo' />
                                </Box>
                              </Grid>
                              <Grid item container sm={12} xs={12} justifyContent={'center'}>
                                <Box p={1}>
                                  <Text popupHead1>Welcome to Japfu</Text>
                                </Box>
                              </Grid>
                              <Grid container pt={1}>
                                <Grid item sm={12} xs={12}>
                                  <Box p={1}>
                                    <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        name: "email",
                                        value: formData.email,
                                      }}
                                      onClick={() => { setStatus('email') }}
                                      clientInputReadOnly
                                      handleChange={changeHandler}
                                      labelText={'Email ID'}
                                      error={error.email}
                                    />
                                    {error.email ? <Text red>{error.email}</Text> : ""}
                                  </Box>

                                </Grid>
                                <Grid item lg={12} sm={12} xs={12} mt={'8px'}>
                                  <Box p={1}>
                                    <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        name: "password",
                                        value: formData.password,
                                        type: eye.password ? 'text' : 'password'
                                      }}
                                      eyeIcon={eye.password}
                                      eyeCloseIcon={eye.password == false}
                                      eyeHandleChange={() => {
                                        setEye({
                                          ...eye,
                                          password: !eye.password
                                        })
                                      }}
                                      iconText
                                      handleChange={changeHandler}
                                      labelText={'Enter Password'}
                                      error={error.password}
                                    />
                                    {
                                      error.password ?
                                        <Text red>{error.password ? error.password : ''}</Text> : ''
                                    }
                                  </Box>
                                </Grid>

                                <Grid item sm={12} xs={12} textAlign={"right"} pt={1}>
                                  <Box p={1}>
                                    <Link
                                      component="button"
                                      variant="body2"
                                      type="button"
                                      onClick={() => forgotPassword()}
                                      className={classes.forgotPassword}
                                    >
                                      Forgot Password?
                                    </Link>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} lg={12} md={12} className={classes.buttonStyles}>
                                <Box>
                                  <LoadingButton
                                    className={classes.loginButton}
                                    loading={loading}
                                    onClick={(e) => handleSubmit(e)}
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                  >
                                    Login
                                  </LoadingButton>
                                </Box>
                              </Grid>
                              <Grid item container xs={12} sm={12} lg={12} md={12} pt={2} justifyContent={'center'} >
                                <Box>
                                  <Button
                                    sx={{ textTransform: 'capitalize !important', color: '#9FA3A8 !important', font: '16px Averta !important', fontWeight: '700' }}
                                    onClick={() => { loginOtpCheck() }}
                                  >
                                    Login with OTP
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                              <Box p={1} ml={-8}>
                                <Text sx={{
                                  font: '14px Averta !important',
                                  letterSpacing: '0px',
                                  color: `#1A141F  !important`,
                                  fontWeight: '400 !important', display: 'flex', flexDirection: 'row',
                                  "@media (min-width: 200px) and (max-width: 1420px)": {
                                    font: '11px Averta !important',
                                  }
                                }}>
                                  <span style={{ paddingRight: '5px' }}>By logging in you agree to the <a href='https://www.japfu.ai/terms-of-use' rel="noreferrer" target='_blank' style={{ textDecoration: 'underline', color: "#0C75EB" }}>terms of services</a> and <a href='https://www.japfu.ai/privacy-policy' rel="noreferrer" target='_blank' style={{ textDecoration: 'underline', color: "#0C75EB" }}>privacy policy</a></span>
                                  <span style={{
                                    height: '6px',
                                    width: '6px',
                                    borderRadius: '50%',
                                    background: '#D9D9D9',
                                    display: 'flex',
                                    margin: '6px 5px 2px 0px'
                                  }}> </span>
                                  <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                              </Box>
                            </Grid>
                          </form>
                }

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

export default Login;


