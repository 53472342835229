import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Text from '../../../../components/customText/Text';
import Button from '../../../../components/customButton/Button';
import Input from '../../../../components/input/Input';
import moment from 'moment';
import Date from '../../../../components/datePicker/Date';
import CommonApi from '../../../../apis/CommonApi';
import { addErrorMsg, addLoader, addWarningMsg, BlackToolTip, capitalize, dateFormat, removeLoader } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import Select from '../../../../components/select/Select';
import { address_validation, empty_Email_id, empty_name, empty_usContact, isValid, validate_charWithSpace, validate_city, validate_empty_address, validate_emptyField, validate_ssn_empty, validate_usContact, validate_zipcode, validates_emailId } from '../../../../components/Validation';
import OnBoardApi from '../../../../apis/admin/employees/OnBoardApi';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg'
import changesSent from '../../../../assets/svg/changesSent.svg'
import info from '../../../../assets/svg/fi_info.svg'
import FileInput from '../../../../components/muiFileInput/FileInput';

export default function EditGeneralDetails(props) {
    // eslint-disable-next-line
    const [error, setError] = useState({})// eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
    const genderList = require('../../../../utils/jsons/Gender.json');
    const maritalList = require('../../../../utils/jsons/MaritalStatus.json');
    const bloodGroups = require('../../../../utils/jsons/BloodGroup.json');
    const IsEmpUSA = [
        {
            id: 1,
            value: 'Yes'
        },
        {
            id: 0,
            value: 'No'
        }
    ]

    // console.log(props.employee, 'xxxxxx')// eslint-disable-next-line
    const [state, setState] = useState(props.employee)// eslint-disable-next-line
    const [visaList, setVisaList] = useState([]);
    const [states, setStates] = useState([]);// eslint-disable-next-line
    const [Department, setDepartment] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);// eslint-disable-next-line
    const [rolesList, setRolesList] = useState([]);

    const [category, setCategory] = useState([]);// eslint-disable-next-line
    const [empTeam, setEmpTeam] = useState([]);// eslint-disable-next-line
    const [employees, setEmployees] = useState([]);// eslint-disable-next-line
    const [relation, setRelation] = useState([]);
    const [countries, setCountries] = useState([]);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [clearPopup1, setclearPopup1] = useState(false);
    const [clearPopup2, setclearPopup2] = useState(false);
    const [w4Show, setw4Show] = useState(false);
    const [clearPopup4, setclearPopup4] = useState(false);
    const [vendorTypes, setVendorTypes] = useState([]);

    useEffect(() => {
        getRolesDropdown();
        countriesList();
        employmentList();
        relationList();
        employeesList();
        visaListApi();
        vendorListApi();
        statesList(state.country_id)
        categoryList(state.employment_type_id)
        setTimeout(() => {
            EmployeesApi.getEmployeeConsultant(props.employee.id).then((response) => {
                setState({ ...response.data.data });
                if (response.data.data.deposit_form_documents == undefined || response.data.data.deposit_form_documents == null) {
                    state.deposit_form_documents = [{
                        id: '',
                        new_document_id: '',
                        document_url: '',
                        document_name: ''
                    }]
                    setState({ ...state })
                }
            }
            )
        }, 500)
        if (state.deposit_form_documents == undefined || state.deposit_form_documents == null) {
            state.deposit_form_documents = [{
                id: '',
                new_document_id: '',
                document_url: '',
                document_name: ''
            }]
            setState({ ...state })
        }
        // eslint-disable-next-line
    }, [])

    const getRolesDropdown = () => {
        let search = "";
        CommonApi.rolesDropdown(search).then((response) => {
            if (response.data.statusCode == 1003) {
                setRolesList(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }
    const countriesList = () => {
        CommonApi.getCountryList('', LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCountries(res.data.data);
            }
        })
    }

    const statesList = (id, index) => {
        CommonApi.getStatesList(id, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setStates(res.data.data);
            }
        })
    }

    const employmentList = () => {
        CommonApi.employmentTypesList(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmploymentType(res.data.data);
            }
        })
    }
    // eslint-disable-next-line
    const categoryList = (args) => {
        CommonApi.CategoryList(LocalStorage.uid(), args, LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCategory(res.data.data);
            }
        })
    }

    const relationList = () => {
        CommonApi.relation(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setRelation(res.data.data);
            }
        })
    }

    const employeesList = () => {
        CommonApi.employeesConsultant(LocalStorage.uid(), LocalStorage.getAccessToken(), 1).then((res) => {
            if (res.data.statusCode === 1003) {
                setEmployees(res.data.data);
            }
        })
    }

    const visaListApi = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaList(res.data.data);
            }
        })
    }

    const handleChange = (e) => {
        if (e.target.name == 'contact_number' || e.target.name === 'ssn' || e.target.name === 'alternate_contact_number') {
            convertFormat(e);
            if (e.target.name == 'contact_number') {
                checkEmail(e.target.value, 'mobile')
            }
            else {
                checkEmail(e.target.value, 'ssn')
            }
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else if (e.target.name == 'company_id') {
            state.company_id = e.target.value;
            setState({ ...state })
            handleValidate(e)
        }
        else if (e.target.name == 'is_usc') {
            if (e.target.value == 1) {
                setclearPopup(true)
            }
            else {
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                }, handleValidate(e))
            }
        }
        else {
            if (e.target.name === "marital_status") {
                if (props.employee.marital_status == "Single" && e.target.value == "Married") {
                    if (props.employee.bank_pending) {
                        setclearPopup4(true)
                        return true
                    } else {
                        setw4Show(true)
                    }
                }
                else {
                    setw4Show(false);
                    state.deposit_form_documents[0].new_document_id = ''
                    state.deposit_form_documents[0].id = ''
                    state.deposit_form_documents[0].document_url = ''
                    state.deposit_form_documents[0].document_name = ''
                }
            }
            if (e.target.name === 'country_id') {
                statesList(e.target.value)
                state.state_id = ''
                state.city = ''
                state.zip_code = ''
            }
            if (e.target.name === 'employment_type_id') {
                categoryList(e.target.value)
                state.employment_category_id = ''
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e))
            if (e.target.name === 'email_id') {
                if (props.employee.email_id !== e.target.value) {
                    checkEmail(e.target.value, 'email')
                }
            }
        }

    }

    const deleteDoc = (index, args) => {
        state.deposit_form_documents[0].new_document_id = ''
        state.deposit_form_documents[0].document_name = ''
        setState({ ...state })
    }

    const checkEmail = (args, val) => {
        const delayDebounceFn = setTimeout(() => {
            var obj = {
                request_id: LocalStorage.uid(),
                email_id: args,
                id: props.employee.id
            }
            var Mobileobj = {
                request_id: LocalStorage.uid(),
                contact_number: args,
                id: props.employee.id
            }
            var ssnObj = {
                request_id: LocalStorage.uid(),
                ssn: args,
                id: props.employee.id
            }
            EmployeesApi.checkDuplicateConsultant(val == 'mobile' ? Mobileobj : val == 'email' ? obj : ssnObj).then((res) => {
                if (res.data.statusCode === 1003) {
                    if (res.data.valid) {

                    } else {
                        if (val == 'email') {
                            setError({
                                ...error,
                                email_id: "Email ID already exists"
                            })

                        }
                        else if (val == 'mobile') {
                            setError({
                                ...error,
                                contact_number: "Mobile Number already exists"
                            })
                        }
                        else {
                            setError({
                                ...error,
                                ssn: "SSN already exists"
                            })
                        }
                    }
                }
                else {
                    addErrorMsg(res.data.message)
                }
            });
        }, 300)

        return () => clearTimeout(delayDebounceFn)
    }

    const convertFormat = (e) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);

        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'contact_number' || name == 'alternate_contact_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'contact_number' || name == 'alternate_contact_number' ? 6 : 5)) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}-${last}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (last == '0000') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length > 3) {
            setState(
                {
                    ...state,
                    [e.target.name]: `${first}-${middle}`
                }, handleValidate(e));
            if (name == 'ssn') {
                if (middle == '00') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
        else if (input.length >= 0) {
            setState(
                {
                    ...state,
                    [e.target.name]: input
                }, handleValidate(e));
            if (name == 'ssn') {
                if (first == '000' || first == '666' || first == '900') {
                    error.ssn = 'Invalid SSN, please check the format '
                    setError(error)
                    return true
                }
            }
        }
    }

    const dateChange = (e, name) => {
        let date = e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setState({
            ...state,
            [name]: moment(date).format(dateFormat())
        }, handleValidate(event))
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "middle_name":
                error.middle_name = empty_name(input.value, 'middle ');
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case 'dob':
                error.dob = validate_emptyField(input.value)
                break
            case 'gender':
                error.gender = validate_emptyField(input.value)
                break
            case 'blood_group':
                error.blood_group = ''
                break
            case 'marital_status':
                error.marital_status = validate_emptyField(input.value)
                break
            case 'contact_number':
                if ((input.value == state.alternate_contact_number) && input.value != "") {
                    error.contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                } else {
                    error.contact_number = input.value == '' ? 'This field is required' : usMobileNumberValidator.test(input.value) ? '' : validate_usContact(input.value, 'Mobile Number')
                    error.alternate_contact_number = usMobileNumberValidator.test(state.alternate_contact_number) ? '' : empty_usContact(state.alternate_contact_number, 'Alternate Mobile Number')
                }
                break
            case 'alternate_contact_number':
                if (input.value == state.contact_number && input.value != "") {
                    error.alternate_contact_number = "Mobile Number and Alternate Mobile Number Not be Same"
                } else {
                    error.alternate_contact_number = usMobileNumberValidator.test(input.value) ? '' : empty_usContact(input.value, 'Alternate Mobile Number')
                    error.contact_number = error.contact_number != 'Mobile Number already exists' ? validate_usContact(state.contact_number, 'Mobile Number') : error.contact_number
                }
                break
            case 'email_id':
                if (input.value != "" && (input.value.toLowerCase()) == (state.alternate_email_id.toLowerCase())) {
                    error.email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.email_id = validates_emailId(input.value, 'Email ID')
                    error.alternate_email_id = empty_Email_id(state.alternate_email_id, 'Alternate Email ID')
                }
                break
            case 'alternate_email_id':
                if (input.value !== '' && ((input.value.toLowerCase()) == (state.email_id.toLowerCase()))) {
                    error.alternate_email_id = "Email ID and Alternate Email ID Not be Same"
                } else {
                    error.alternate_email_id = empty_Email_id(input.value, 'Alternate Email ID')
                    error.email_id = error.email_id != 'Email ID already exists' ? validates_emailId(state.email_id, 'Email ID') : error.email_id
                }

                break
            case 'address_line_one':
                error.address_line_one = address_validation(input.value)
                break
            case 'address_line_two':
                error.address_line_two = validate_empty_address(input.value)
                break
            case 'zip_code':
                error.zip_code = validate_zipcode(input.value, state.country_id);
                break
            case 'city':
                error.city = validate_city(input.value)
                break
            case 'country_id':
                error.country_id = validate_emptyField(input.value)
                break
            case 'state_id':
                error.state_id = validate_emptyField(input.value)
                break
            case 'reference_id':
                error.reference_id = validate_emptyField(input.value)
                break
            case 'date_of_joining':
                error.date_of_joining = validate_emptyField(input.value)
                break
            case 'employment_type_id':
                error.employment_type_id = validate_emptyField(input.value)
                error.role_id = "";
                break
            case 'employment_category_id':
                error.employment_category_id = validate_emptyField(input.value)
                break
            case 'department_id':
                error.department_id = validate_emptyField(input.value);
                break;
            case 'team_id':
                error.team_id = validate_emptyField(input.value);
                break;
            case 'ssn':
                error.ssn = usMobileNumberValidator.test(input.value) ? '' : validate_ssn_empty(input.value)
                break
            case 'is_usc':
                error.is_usc = validate_emptyField(input.value)
                break
            case 'visa_type_id':
                error.visa_type_id = validate_emptyField(input.value)
                break
            case 'role_id':
                error.role_id = validate_emptyField(input.value)
                break
            default:
                break
        }
        setError({ ...error })
    }

    const validateAll = () => {
        // eslint-disable-next-line
        const { first_name, last_name, dob, gender, marital_status, contact_number, employment_type_id, email_id, address_line_one, zip_code, city, country_id, state_id, date_of_joining, employment_category_id, ssn, // eslint-disable-next-line
            is_usc, visa_type_id, address_line_two } = state
        let belowDOB = moment().subtract(18, "years")
        let actualDOB = moment(dob, dateFormat()).format('YYYY-MM-DD');
        error.first_name = validate_charWithSpace(first_name, 'first ')
        error.last_name = validate_charWithSpace(last_name, 'last ');
        error.visa_type_id = state.is_usc == 0 ? validate_emptyField(visa_type_id) : ''
        error.dob = dob !== '' ? moment(actualDOB).isBefore(belowDOB) ? '' : 'Minimum age should be greater than 18' : validate_emptyField(dob);
        error.gender = validate_emptyField(gender);
        // error.blood_group = validate_emptyField(blood_group);
        error.marital_status = validate_emptyField(marital_status);
        error.contact_number = error.contact_number == 'Mobile Number already exists' ? 'Mobile Number already exists' : validate_usContact(contact_number, 'Mobile Number');
        error.email_id = error.email_id == 'Email ID already exists' ? 'Email ID already exists' : validates_emailId(email_id);
        error.address_line_one = address_validation(address_line_one);
        error.address_line_two = validate_empty_address(address_line_two)
        error.zip_code = validate_zipcode(zip_code, state.country_id)
        error.city = validate_city(city)
        error.country_id = validate_emptyField(country_id)
        error.state_id = validate_emptyField(state_id)
        // error.reference_id = value == 4 ? validate_emptyField(reference_id)
        error.date_of_joining = validate_emptyField(date_of_joining)
        error.employment_type_id = validate_emptyField(employment_type_id)
        error.employment_category_id = employment_type_id != 1 ? validate_emptyField(employment_category_id) : ''
        error.ssn = error.ssn == 'SSN already exists' ? 'SSN already exists' : validate_ssn_empty(ssn)
        error.is_usc = validate_emptyField(is_usc)
        error.deposit_form_documents = (props.employee.marital_status == "Single" && state.marital_status == "Married") ? validate_emptyField(state.deposit_form_documents[0].new_document_id) : ''
        if (props.employee.email_id !== email_id) {
            checkEmail(email_id, 'email');
        }
        checkEmail(contact_number, 'mobile');
        checkEmail(state.ssn, 'ssn');
        setError({ ...error })
    }

    const popupOpen = () => {
        validateAll();
        if (isValid(error)) {
            setclearPopup2(true)
        }
        else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const uploadDocs = (value, index, fieldName) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        if (((value.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
            CommonApi
                .documentUpload("bank-document", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        removeLoader();
                        let docInfo = response.data.data;

                        removeLoader()
                        state.deposit_form_documents[0].new_document_id = docInfo.id;
                        state.deposit_form_documents[0].document_url = docInfo.document_url;
                        state.deposit_form_documents[0].document_name = value.target.files[0].name;
                        error.deposit_form_documents = ''
                        setError({ ...error })
                        setState({ ...state });
                        removeLoader();
                    } else {
                        addErrorMsg(response.data.message);
                        removeLoader();
                    }
                });
        }
        else {
            removeLoader()
            addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
        }
    };

    const saveForm = () => {
        validateAll();
        if (isValid(error)) {
            state['request_id'] = LocalStorage.uid()
            state.entity = 'general-details'
            OnBoardApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                if (res.data.statusCode === 1003) {
                    setclearPopup1(true)
                    // props.setEdit(false);
                    // props.setformEdit(false);
                    setclearPopup2(false);
                    setclearPopup1(true)
                }
                else {
                    addErrorMsg(res.data.message);
                    props.getIndex()
                    setclearPopup2(false)
                }
            })
        }
        else {
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }

    }
    const cancelForm = () => {
        props.setEdit(false)
        props.setformEdit(false)
        setState(props.employee)
        setError({})
    }

    const vendorListApi = () => {
        CommonApi.getVendorList('Active').then((res) => {
            if (res.data.statusCode === 1003) {
                // console.log('vendors:', res.data.data)
                setVendorTypes(res.data.data);
            }
        })
    }
    return (
        <Grid container lg={12} p={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'first_name',
                            value: state.first_name,
                            inputProps: { maxLength: 33 }
                        }}
                        clientInput
                        disabled={true}
                        handleChange={handleChange}
                        labelText={'First Name'}
                        error={error.first_name}
                    />
                    {
                        error.first_name ?
                            <Text red>{error.first_name ? error.first_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'middle_name',
                            value: state.middle_name,
                            inputProps: { maxLength: 33 }
                        }}
                        disabled={true}
                        handleChange={handleChange}
                        clientInput
                        error={error.middle_name}
                        labelText='Middle Name (Optional)'
                    // labelText={error.middle_name ? 'Middle Name (Optional)' : <span className={classes.optional}>(Optional)</span>}
                    />
                    {
                        error.middle_name ?
                            <Text red>{error.middle_name ? error.middle_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'last_name',
                            value: state.last_name,
                            inputProps: { maxLength: 33 }
                        }}
                        disabled={true}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Last Name'}
                        error={error.last_name}
                    />
                    {
                        error.last_name ?
                            <Text red>{error.last_name ? error.last_name : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        labelText={'Date of Birth'}
                        name='dob'
                        height='54px'
                        value={state.dob}
                        disabled={true}
                        onChange={(value => dateChange(value, 'dob'))}
                        maxDate={moment().subtract(18, "years")}
                        error={error.dob}
                    />
                    {
                        error.dob ?
                            <Text red>{error.dob ? error.dob : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='gender'
                        value={state.gender}
                        onChange={handleChange}
                        options={genderList}
                        label={'Gender'}
                        disabled={true}
                        helperText={
                            error.gender ?
                                <Text red>{error.gender ? error.gender : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='blood_group'
                        value={state.blood_group}
                        onChange={handleChange}
                        options={bloodGroups}
                        label={'Blood Group (Optional)'}
                        disabled={true}
                        helperText={error.blood_group ? <Text red>{error.blood_group ? error.blood_group : ''}</Text> : ''}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='marital_status'
                        value={state.marital_status}
                        onChange={handleChange}
                        options={maritalList}
                        label={'Marital Status'}
                        helperText={
                            error.marital_status ?
                                <Text red>{error.marital_status ? error.marital_status : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            {
                w4Show
                    ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box p={1} pt={2}>
                            <FileInput
                                name={"deposit_form_documents"}
                                FileName={state.deposit_form_documents[0].document_name}
                                handleChange={(e) => uploadDocs(e, 0, "deposit_form_documents")}
                                label={"W-4 Form"} isDisabled={false}
                                handleDelete={() => deleteDoc()}
                                actionState={state.deposit_form_documents[0].document_name ? 1 : ''}
                                documentUrl={state.deposit_form_documents[0].document_url}
                                error={error.deposit_form_documents ? error.deposit_form_documents : ""}
                                state={state.deposit_form_documents[0]}
                            />
                            <Text errorText> {error.deposit_form_documents ? error.deposit_form_documents : ""}</Text>
                            {/* <Text errorText>{error.void_cheque_documents ? error.void_cheque_documents : ""}</Text> */}
                        </Box>
                    </Grid>
                    : ''
            }
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'contact_number',
                            value: state.contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Mobile Number'}
                        error={error.contact_number}
                    />
                    {
                        error.contact_number ?
                            <Text red>{error.contact_number ? error.contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_contact_number',
                            value: state.alternate_contact_number,
                            inputProps: { maxLength: 12 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_contact_number}
                        labelText={'Alternate Mobile Number(Optional)'}
                    />
                    {
                        error.alternate_contact_number ?
                            <Text red>{error.alternate_contact_number ? error.alternate_contact_number : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={0} sm={0} xs={12}></Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'email_id',
                            value: state.email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.email_id}
                        labelText={'Email ID'}
                    />
                    {
                        error.email_id ?
                            <Text red>{error.email_id ? error.email_id : ''}</Text>
                            : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'alternate_email_id',
                            value: state.alternate_email_id
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.alternate_email_id}
                        labelText={'Alternate Email ID (Optional)'}
                    />
                    {
                        error.alternate_email_id ?
                            <Text red>{error.alternate_email_id ? error.alternate_email_id : ''}</Text> : ''
                    }
                </Box>
            </Grid>
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'reference_id',
                            value: state.reference_id,
                            disabled: true
                        }}
                        disabled={true}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Employee ID'}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Date
                        name='date_of_joining'
                        value={state.date_of_joining}
                        onChange={(value => dateChange(value, 'date_of_joining'))}
                        height='54px'
                        error={error.date_of_joining}
                        labelText='Joining Date'
                        minDate={state.dob}
                        disabled={true}
                    />
                    {error.date_of_joining && <Text red>{error.date_of_joining ? error.date_of_joining : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='employment_type_id'
                        value={state.employment_type_id}
                        disabled={true}
                        onChange={handleChange}
                        options={employmentType}
                        label={'Employment Type'}
                    />
                </Box>
            </Grid>
            {
                state.employment_type_id !== 1 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            disabled={true}
                            name='employment_category_id'
                            value={state.employment_category_id}
                            onChange={handleChange}
                            options={category}
                            label={'Employment Category'}
                            helperText={error.employment_category_id && <Text red>{error.employment_category_id ? error.employment_category_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            {
                state.employment_type_id == 3 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            disabled={true}
                            name='company_id'
                            value={state.company_id}
                            onChange={handleChange}
                            options={vendorTypes}
                            label={'Vendor'}
                            helperText={error.company_id && <Text red>{error.company_id ? error.company_id : ''}</Text>}
                        />
                    </Box>
                </Grid>
            }
            {
                state.employment_type_id === 1 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            disabled={true}
                            name='role_id'
                            value={state.role_id}
                            onChange={handleChange}
                            options={rolesList}
                            label={'Role'}
                            helperText={
                                error.role_id ?
                                    <Text red>{error.role_id ? error.role_id : ''}</Text> : ''
                            }
                        />
                    </Box>
                </Grid>
            }
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'ssn',
                            value: state.ssn,
                            inputProps: { maxLength: 11 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        disabled={true}
                        error={error.ssn}
                        labelText='SSN (Optional)'
                    />
                    {error.ssn && <Text red>{error.ssn ? error.ssn : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='is_usc'
                        value={state.is_usc}
                        onChange={handleChange}
                        options={IsEmpUSA}
                        label={'Is the Employee USC?'}
                        disabled={true}
                        helperText={
                            error.is_usc ?
                                <Text red>{error.is_usc ? error.is_usc : ''}</Text> : ''
                        }
                    />
                </Box>
            </Grid>
            {
                state.is_usc === 0 &&
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Select
                            name='visa_type_id'
                            value={state.visa_type_id}
                            onChange={handleChange}
                            options={visaList}
                            label={'Visa Type'}
                            helperText={
                                error.visa_type_id ?
                                    <Text red>{error.visa_type_id ? error.visa_type_id : ''}</Text> : ''
                            }
                        />
                    </Box>
                </Grid>
            }
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                        <BlackToolTip arrow placement='bottom' title='The changes in address will be sent to employer for approval. Once they are approved then the new details are displayed here.'>
                            <img src={info} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px" }} />
                        </BlackToolTip>
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_one',
                            value: state.address_line_one,
                            inputProps: { maxLength: 225 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        labelText={'Address Line 1'}
                        error={error.address_line_one}
                    />
                    {error.address_line_one && <Text red>{error.address_line_one ? error.address_line_one : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'address_line_two',
                            value: state.address_line_two,
                            inputProps: { maxLength: 225 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.address_line_two}
                        labelText='Address Line 2 (Optional)'
                    />
                    {error.address_line_two && <Text red>{error.address_line_two ? error.address_line_two : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='country_id'
                        value={state.country_id}
                        onChange={handleChange}
                        options={countries}
                        label={'Country'}
                        helperText={error.country_id && <Text red>{error.country_id ? error.country_id : ''}</Text>}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Select
                        name='state_id'
                        value={state.state_id}
                        onChange={handleChange}
                        options={states}
                        label={'State'}
                        helperText={error.state_id && <Text red>{error.state_id ? error.state_id : ''}</Text>}
                    />
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'city',
                            value: state.city,
                            inputProps: { maxLength: 50 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        error={error.city}
                        labelText='City'
                    />
                    {error.city && <Text red>{error.city ? error.city : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Input
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            name: 'zip_code',
                            value: state.zip_code,
                            inputProps: { maxLength: state.country_id == 1 ? 6 : 5 }
                        }}
                        handleChange={handleChange}
                        clientInput
                        // onKeyPress={onNumberOnlyChange}
                        error={error.zip_code}
                        labelText={state.country_id !== 1 ? 'Zipcode' : 'Pincode'}
                    />
                    {error.zip_code && <Text red>{error.zip_code ? error.zip_code : ''}</Text>}
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Button cancelOutlineAverta onClick={() => cancelForm()}>Cancel</Button>
                </Box>
            </Grid>
            <Grid item container justifyContent={'flex-end'} lg={6} md={6} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Button finishFilledAverta onClick={() => { popupOpen() }}>Save</Button>
                </Box>
            </Grid>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to change the citizenship status for </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>employee? By changing the status previous visa</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>documents for this employee will be cleared.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledAverta onClick={() => {
                                setState({
                                    ...state,
                                    is_usc: 1
                                })
                                setError({
                                    ...error,
                                    is_usc: ''
                                })
                                setclearPopup(false)
                            }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup2} setOpenPopup={setclearPopup2} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to make this changes?</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup2(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledAverta onClick={() => { saveForm(); setclearPopup2(false) }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup1} setOpenPopup={setclearPopup1} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changesSent} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Changes Sent For Approval</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>"Thank you for updating your details. Your changes</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>have been submitted for approval to your employer. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Once approved, the new details will be displayed here. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Click 'OK' to proceed."</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledAverta onClick={() => { setclearPopup1(false); props.getIndex(); props.setEdit(false); props.setformEdit(false); }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup4} setOpenPopup={setclearPopup4} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Please add bank details before adding W4</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>document to the employee</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledAverta onClick={() => { setclearPopup4(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}