import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import ChartStyles from './ChartsStyles'
import Text from '../../../components/customText/Text'
import ReactApexChart from 'react-apexcharts';
import { ReactComponent as SendIcon } from '../../../assets/svg/messagesendIcon.svg';
import { btnBgGrey } from '../../../theme';
import EmployeesApi from '../../../apis/admin/employees/EmployeesApi';
import { addErrorMsg } from '../../../utils/utils';
import LocalStorage from '../../../utils/LocalStorage';

function Charts() {
    const classes = ChartStyles()

    const chartsAPI = () => {
        const data = {
            request_id:LocalStorage.uid(),
            question: message
        }
        EmployeesApi.OCRCharts(data).then((res) => {
            if (res.status == 200) {
                setResponseData(res.data.data);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const [message, setMessage] = useState('');
    const [responseData, setResponseData] = useState({});

    const changeHandler = (e, args) => {
        if (args) {
            setMessage(args)
        } else {
            setMessage(e.target.value);
        }
    }

    return (
        <Grid container spacing={2} pl={{ lg: 16, md: 9, sm: 4, xs: 9 }} pt={2} alignItems='flex-start' justifyContent='center' mb={2}>
            <Grid item container lg={12} md={12} sm={12} xs={12} pt={3}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: '30px' }} >
                    <Text mediumBlack14>AI Generative Charts</Text>
                </Grid>
                <Grid item container lg={12} justifyContent='center'>
                    <Grid item lg={4} textAlign='center'>
                        <Box className={classes.leftcard}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Text BlackExtraDark>Total Contractor/Consultant Employees</Text>
                            </Grid>
                            <Grid display='flex' justifyContent="center" mt={5} className={classes.monochrome}>
                                <ReactApexChart options={responseData} height={300} />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container lg={12} justifyContent='center' mt={2}>
                    <Grid container lg={7} xs={12} justifyContent='center'>
                        <Grid item lg={12}>
                            <Text mediumGrey>Suggestions</Text>
                        </Grid>
                        <Grid item lg={12} p={'10px 0px'}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <Box className={classes.suggestions}>
                                    <Text mediumBlue className={classes.suggestionText} onClick={(e) => changeHandler(e, 'How many employees have been onboarded in past one week')}> How many employees have been onboarded in past one week</Text>
                                </Box>
                                <Box className={classes.suggestions}>
                                    <Text mediumBlue className={classes.suggestionText} onClick={(e) => changeHandler(e, 'Show my Employees')}> Show my Employees</Text>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={12} mt={1} sx={{ display: 'flex', flexDirection: 'row', background: '#FFFFFF', padding: '15px 10px', border: `1px solid ${btnBgGrey.shade4}`, borderRadius: '10px' }}>
                            <input className={classes.chatInput} placeholder='Ask Something...' name='message' value={message} onChange={changeHandler} autoFocus='on' autoComplete='off' />
                            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => chartsAPI()}><SendIcon /></Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Charts
