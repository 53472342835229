import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Divider } from '@mui/material';
import Text from '../../../../components/customText/Text';
import timelineIcon from '../../../../assets/svg/timelineIcon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DashboardStyles from '../../DasboardStyles';
import ClearIcon from '@mui/icons-material/Clear';
import Search from '../../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../../assets/svg/download-cloud-new.svg';
import LocalStorage from '../../../../utils/LocalStorage';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BlackToolTip } from '../../../../utils/utils';
import Nodata from '../../../../assets/svg/nodataActivity.svg';
import Loader from '../../../../assets/gif/japfuLoader.gif';

function ActivityLog(props) {
    const classes = DashboardStyles();// eslint-disable-next-line
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const activityRef = useRef(null); // eslint-disable-next-line
    const [activeStep, setActiveStep] = useState(0);// eslint-disable-next-line
    const [activityData, setActivityData] = useState(props.activityData)// eslint-disable-next-line
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: "",
        request_id: LocalStorage.uid(),
        employee_id: props.employee.id
    })
    const [expand, setExpand] = useState('')// eslint-disable-next-line
    const [activityTotal, setActivityTotal] = useState(props.activityTotal) // eslint-disable-next-line
    const [data, setData] = useState(props.activityData) // eslint-disable-next-line

    useEffect(() => {
        setLoading(props.loading);
        setActivityData(props.activityData)
    }, [props])

    return (
        <Grid container lg={12} md={12} xs={12} width={'48vw'} p={4} >
            <Grid item lg={12} md={12} xs={12} pb={2}>
                <Text largeBlack> Activity log</Text>
            </Grid>
            <Grid item lg={10} md={10} xs={10}>
                <div style={{ height: "44px", border: "1px solid #E2E5E6", padding: '12px 0px 12px 10px', borderRadius: "8px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <input
                        type="text"
                        className={classes.EmployeesSearchInput}
                        placeholder={props.placeholder ? props.placeholder : "Search by Timesheet / Documents / Task"}
                        value={props.activityFilter.search}
                        onChange={(e) => { props.setActivityFilter({ ...props.activityFilter, search: e.target.value }) }}
                    />
                    <button
                        type="button"
                        style={{ all: "unset", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", textAlign: "center", fontSize: "16px", fontFamily: "Averta CY, sans-serif", width: "24px", height: "24px", border: "none", backgroundColor: "#FFFFFF", borderRadius: "8px", paddingRight: "10px" }}
                    >
                        {
                            props.activityFilter.search != '' ?
                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => { props.setActivityFilter({ ...props.activityFilter, search: '' }) }} />
                                :
                                <img src={Search} alt="Search" />
                        }
                    </button>
                </div>
            </Grid>
            <Grid item lg={2} md={2} xs={2} pl={4}>
                <button title='Export' onClick={() => props.downloadActivity()}
                    type="button"
                    style={{ all: "unset", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", textAlign: "center", fontSize: "16px", fontFamily: "Averta CY, sans-serif", width: "52px", height: "43px", border: "1.5px solid rgba(199, 204, 211, 1)", backgroundColor: "#ffffff", borderRadius: "8px" }}
                >
                    <img src={NewDownloadcloud} alt="Userplus" />
                </button>
            </Grid>
            <Grid item container lg={12} md={12} xs={12} pt={3} >
                {
                    loading ?
                        <Box width={"100%"} height={'76vh'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
                        </Box>
                        :
                        <Box height={'76vh'} overflow={'scroll'} sx={{ width: '100% !important' }}>
                            {
                                activityData.length == 0 ?
                                    <Grid item lg={12} md={12} xs={12} container justifyContent={'center'} alignContent={'center'} textAlign={'center'}>
                                        <Grid item lg={12} md={12} xs={12} pl={5} container justifyContent={'center'} alignContent={'center'}>
                                            <img src={Nodata} alt='nodata' />
                                        </Grid>
                                        <Grid item lg={12} md={12} xs={12} pl={5}>
                                            <Text popupHead1>No Logs Found Yet!</Text>
                                        </Grid>
                                    </Grid>
                                    :
                                    activityData.map((item, index) => (
                                        <Grid container lg={12} md={12} xs={12}>
                                            <Grid item lg={2.75} md={2.75} xs={2.75}>
                                                <Grid item>
                                                    <Box p={1} pt={1.9}>
                                                        <Text smallBlue sx={{ fontWeight: '600 !important' }}>{item.created_at}</Text>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box pl={1}>
                                                        <Text verySmallBlack sx={{ color: "#737373 !important" }}>{item.action_by}</Text>
                                                    </Box>
                                                </Grid>
                                            </Grid >
                                            <Grid item lg={1} md={1} xs={1}>
                                                <Box p={1}>
                                                    <img src={timelineIcon} alt='ic' />
                                                </Box>
                                            </Grid>
                                            <Grid item lg={7} md={7} xs={7}>
                                                <Grid item>
                                                    <Box p={1}>
                                                        <Text mediumBlack>{item.activity_name}</Text>
                                                    </Box>
                                                </Grid>
                                                {/* {
                                expand !== index &&
                                <Grid item>
                                    <Box pl={1}>
                                        <Text greysmallLabel>Label Name is updated from from value to To value</Text>
                                    </Box>
                                </Grid>
                            } */}

                                            </Grid>
                                            <Grid item lg={1} md={1} xs={1}>
                                                <Box p={1}>
                                                    {expand === index ?
                                                        <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} /> :
                                                        <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => { if (expand === index) { setExpand('') } else { setExpand(index) } }} />
                                                    }
                                                </Box>
                                            </Grid>
                                            {
                                                expand === index ?
                                                    <Grid item container pb={2}>
                                                        <Grid item lg={3.43} md={3.43} xs={3.43}></Grid>
                                                        <Grid item class="tree" >
                                                            <ul>
                                                                {
                                                                    item.change_log.map((i) => (
                                                                        <li>
                                                                            <Text greysmallLabel>
                                                                                {i.length > 70 ?

                                                                                    <BlackToolTip arrow placement='top' title={
                                                                                        <Box p={1}>{i}</Box>
                                                                                    }>
                                                                                        {i.slice(0, 70)} ...
                                                                                    </BlackToolTip>

                                                                                    : i}
                                                                            </Text>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </Grid>
                                                    </Grid>
                                                    :

                                                    ((activityData.length - 1) == index) ? '' :
                                                        <Grid item container pb={2}>
                                                            <Grid item lg={3.15} md={3.15} xs={3.15}></Grid>
                                                            <Grid item mt={-0.5} md={-0.5} xs={-0.5}>
                                                                <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                            </Grid>
                                                        </Grid>
                                            }
                                        </Grid>
                                    ))}
                        </Box>
                }

            </Grid>

        </Grid >
    )
}

export default ActivityLog
