import React from "react";
import { Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import Text from "../../../components/customText/Text";
import { dateFormat } from "../../../utils/utils";


export default function TimesheetTable(props) {


    const { hoursArr, handleChangeHours, tableIndex, editscreen, formData, location, edit } = props;
    const getTotalHours = (target) => {
        var start = "00:00";
        for (var i = 0; i < hoursArr.length; i++) {
            start = sumOfHours(start, hoursArr[i][target]);
        }
        return start;
    }

    function sumOfHours(time1, time2) {
        // Split time into hours and minutes
        if (time1 === "") {
            return time2
        }
        if (time2 === "") {
            return time1
        }
        var [hours1, minutes1] = time1.split(':');
        var [hours2, minutes2] = time2.split(':');
        if (hours1 == '' || hours1 == undefined) {
            hours1 = 0;
        } else if (hours2 == '' || hours2 == undefined) {
            hours2 = 0;
        } else if (minutes1 == '' || minutes1 == undefined) {
            minutes1 = 0;
        } else if (minutes2 == '' || minutes2 == undefined) {
            minutes2 = 0;
        }
        // Convert time to minutes
        var totalMinutes = parseInt(hours1) * 60 + parseInt(minutes1) + parseInt(hours2) * 60 + parseInt(minutes2);

        // Convert minutes back to HH:MM format
        var hours = isNaN(totalMinutes) ? 0 : Math.floor(totalMinutes / 60);
        var minutes = isNaN(totalMinutes) ? 0 : totalMinutes % 60;

        // Add leading zeros if necessary
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }


        return hours + ':' + minutes;
    }

    const TableInput = (data, target, key) => {
        return (
            <input
                type="text"
                name={target}
                value={data[target]}
                onChange={(e) => handleChangeHours(e, key, tableIndex)}
                style={{ color: "#404040", fontWeight: 400, font: "12px Averta", width: "100%", height: "48px", textAlign: "center", border: "none", }}
            />
        );
    }

    // border: "2px solid #B0B0B0",

    // borderRadius: "8px"

    return (
        <Box sx={{ border: "1px solid #B0B0B0", borderRadius: "8px" }}>
            <Table size="small" sx={{ borderRadius: "8px" }} >
                <TableHead>
                    <TableRow
                        sx={{ height: '48px' }}
                    >
                        <TableCell sx={{ width: '140px', textAlign: "center" }}><Text largeBlack sx={{ fontWeight: `${700} !important` }}>Timesheet {tableIndex + 1}</Text></TableCell>
                        {
                            hoursArr.map((timesheet, key) => (
                                <TableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", textAlign: "center", }} key={key}>
                                    <Text largeBlack sx={{ font: `14px Averta !important`, fontWeight: `${600} !important` }}>{timesheet.date === "" ? null : moment(timesheet.date, dateFormat()).format('ddd')}</Text>
                                    <p style={{ font: "12px Averta !important", color: "#404040", fontWeight: `${400} !important`  }}>{moment(timesheet.date, dateFormat()).format('DD-MMM-YYYY')}</p>
                                </TableCell>
                            ))
                        }
                        <TableCell sx={{ width: '140px', textAlign: "center" }}> <Text largeBlack sx={{ fontWeight: `${700} !important` }}>Total</Text></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>                 

                    <TableRow sx={{ height: '48px' }}>
                        <TableCell sx={{ padding: 0, textAlign: "center"}}><Text largeBlack sx={{ font: `14px Averta !important`, fontWeight: `${600} !important` }}>Working Hours</Text></TableCell>
                        {hoursArr.map((i, key) => (
                            <TableCell key={key} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Averta" }}>
                                {editscreen ? formData.is_editable && (location.state.name == 'Invoice Ready Timesheet' || edit) ? location.state.name == 'Invoice Ready Timesheet' && edit ? i.billable_hours : TableInput(i, "billable_hours", key) : i.billable_hours : TableInput(i, "billable_hours", key)}
                            </TableCell>
                        ))}
                        <TableCell sx={{ padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Averta" }}>
                            {getTotalHours("billable_hours")}
                        </TableCell>
                    </TableRow>

                    <TableRow sx={{ height: '48px' }} >
                        <TableCell sx={{ padding: 0, textAlign: "center" }}><Text largeBlack sx={{ font: `14px Averta !important`, fontWeight: `${600} !important` }}>OT Hours</Text></TableCell>
                        {hoursArr.map((i, key) => (
                            <TableCell key={key} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Averta" }}>
                                {editscreen ? formData.is_editable && (location.state.name == 'Invoice Ready Timesheet' || edit) ? location.state.name == 'Invoice Ready Timesheet' && edit ? i.ot_hours : TableInput(i, "ot_hours", key) : i.ot_hours : TableInput(i, "ot_hours", key)}
                            </TableCell>
                        ))}
                        <TableCell sx={{ padding: 0, textAlign: "center", color: "#404040", fontWeight: 400, font: "12px Averta" }}>
                            {getTotalHours("ot_hours")}
                        </TableCell>
                    </TableRow>


                    <TableRow sx={{ height: '48px' }} >
                        <TableCell sx={{ textAlign: "center"}}><Text largeBlack sx={{ font: `14px Averta !important`, fontWeight: `${600} !important` }}>Total Hours</Text></TableCell>
                        {hoursArr.map((i, key) => (
                            <TableCell key={key} sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", backgroundColor: "#DCFCDC", color: "#404040", fontWeight: 400, font: "12px Averta" }}>
                                {i.total_hours}
                            </TableCell>
                        ))}
                        <TableCell sx={{ textAlign: "center", backgroundColor: "#037847", color: "#FFFFFF", borderRadius: "0px 0px 8px 0px" }}>
                            {getTotalHours("total_hours")}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
};