import { makeStyles } from "@mui/styles";


const OffboardingChecklistStyles = makeStyles(() => ({

    customAccordion: {
        backgroundColor: "#fff !important",
        boxShadow: "0px 2px 24px 0px #919EAB1F !important",
        cursor: 'pointer !important',
        padding: "6px 12px !important",
        margin: "15px 0px !important",
        '&:before': { display: "none" },
        // boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05) !important",
    },
    AccordionSummary: {
        backgroundColor: "#fff",
        cursor: 'pointer !important',
    },
    popupHead: {
        width: "100% !important",
        isplay: 'flex !important',
        justifyContent: 'center !important'
    },
    popupHead1: {
        width: '100% !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        gap: '20px !important'
    }
}))
export default OffboardingChecklistStyles;


