import { useEffect } from "react";
import CommonApi from "../apis/CommonApi";
import { useSearchParams, useNavigate } from "react-router-dom";
import LocalStorage from "../utils/LocalStorage";
import { domain } from "../config/Domain";
import { addErrorMsg } from "../utils/utils";
import Grid from "@mui/material/Grid";
import loader from "../assets/gif/japfuLoader.gif"

function CheckUser() {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    useEffect(() => {
        const token = searchParams.get("token")
        if (token) {
            let data = {
                request_id: LocalStorage.uid(),
                access_token: token,
                subdomain_name: domain
            }
            CommonApi.getLoginDetails(data).then(
                (response) => {
                    if (response.data.statusCode == 1003) {
                        if (response.data.data.role_id != null) {
                            CommonApi.Roles(response.data.data.role_id, response.data.data.access_token).then((res) => {
                                if (res.data.statusCode == 1003) {
                                    let a = {};
                                    a.role_permissions = res.data.data.permissions
                                    LocalStorage.setRolesData(a);
                                    LocalStorage.setUserData(response.data.data);
                                    LocalStorage.setDateFormat(response.data.data.date_format);
                                    LocalStorage.setCurrencySymbol(response.data.data.currency_symbol);
                                    navigate('/dashboard')
                                    window.location.reload()
                                }
                                else {
                                    addErrorMsg(res.data.message)
                                }
                            }
                            )
                        }
                        else {
                            LocalStorage.setUserData(response.data.data);
                            LocalStorage.setDateFormat(response.data.data.date_format);
                            LocalStorage.setCurrencySymbol(response.data.data.currency_symbol);
                            navigate('/dashboard')
                            window.location.reload()
                        }

                    }
                    else {
                        addErrorMsg(response.data.message)
                    }
                }
            )
        }
        else {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Grid container justifyContent={'center'} alignContent={'center'} height={'100vh'}>
            <img src={loader} alt='loader' width={'100px'} height={'100px'} />
        </Grid>
    )
}
export default CheckUser