import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material"

const BillingStyles = makeStyles(() => ({
    loginButton: {
        width: "400px !important",
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "16px Averta !important",
        fontWeight: '600',
        [useTheme().breakpoints.down("xl")]: {
            font: "12px Averta !important",
            width: "250px !important",
        },
        [useTheme().breakpoints.down("md")]: {
            font: "12px Averta !important",
            width: "150px !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },
    tabBg: {
        display: "flex",
        background: "#F4F8FF",
        height: '51px',
        width: '66%',
        borderRadius: "12px !important",
        alignItems: 'center',
        textAlign: 'center',
        padding: "12px 10px !important",
        gap: 3,
        [useTheme().breakpoints.down('md')]: {
            width: '90%'
        }
    },
    ActiveBg: {
        background: "#0C75EB",
        height: '35px',
        width: '227px',
        padding: "6px 15px",
        borderRadius: "8px",
        cursor: "pointer",
        alignItems: 'center',
        textAlign: 'center',
    },
    inactiveBg: {
        background: "transparent", width: '227px', padding: "12px 12px 12px 10px", borderRadius: "8px", cursor: "pointer"
    },
    blueText: {
        font: '64px Averta !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '56px Averta !important',
        }
    },
    lightBlue: {
        font: '22px Averta !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '18px Averta !important',
        }
    },
    extension: {
        color: '#0C75EB', font: '40px Averta !important', fontWeight: '400',
        [useTheme().breakpoints.down('xl')]: {
            font: '35px Averta !important',
        }
    },
    monthly: {
        color: '#737373', font: '15px Averta !important', fontWeight: '400',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
        }
    },
    margin: {
        marginTop: '-14px !important',
        [useTheme().breakpoints.down('xl')]: {
            marginTop: '0px !important'
        }
    },
    Active: {
        font: '16px Averta !important',
        fontWeight: `${400} !important`,
        borderRadius: '8px 0px 0px 8px',
        height: '35px !important',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '8px 12px',      
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${400} !important`,
            padding: '8px 12px',
        },
    },
    InActive: {
        font: '16px Averta !important',
        fontWeight: `${400} !important`,
        borderRadius: '0px 8px 8px 0px',
        height: '35px !important',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '8px 12px',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${400} !important`,
            padding: '8px 12px',
        }
    },
    contactSupport: {
        border: '1px solid #0C75EB', borderRadius: '8px', padding: '0px 24px', minHeight: '43px !important', alignItems: 'center', cursor: 'pointer'
    }

}))

export default BillingStyles;
