import { makeStyles } from "@mui/styles";
import { blue } from "../../../../theme";
import { useTheme } from "@mui/material";
const ViewStyles = makeStyles(() => ({
    btnBox: {
        width: "278px !important", padding: "8px 10px !important", borderRadius: "12px !important", backgroundColor: "#F4F8FF !important", display: "flex"
    },
    btnBox1: {
        width: "100% !important", padding: "8px 10px !important", borderRadius: "12px !important", backgroundColor: "#F4F8FF !important", display: "flex"
    },
    activeButton: {
        font: "16px Averta !important", minWidth: "124px !important", borderRadius: "4px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
        }
    },
    inActiveButton: {
        font: "16px Averta !important", minWidth: "124px !important", borderRadius: "4px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
        }
    },
    activeButton1: {
        font: "16px Averta !important", minWidth: "100% !important", borderRadius: "8px !important", height: "35px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
        }
    },
    inActiveButton1: {
        font: "16px Averta !important", minWidth: "100% !important", borderRadius: "8px !important", height: "35px !important", background: "transparent !important", color: "#000000 !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
        }
    },
    addBillBtn: {
        font: "16px Averta !important", fontWeight: `${700} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`
        }
    },
    profileTooltipText: {
        fontSize: "11px !important",
        fontFamily: "Averta !important",
        fontWeight: "500 !important",
        color: "#FFFFFF !important",
    },
    dropzoneMainBox: {
        width: '100%',
        height: "28vh",
    },
    dropBox: {
        width: '100%',
        height: "28vh",
        display: 'inline-block',
        backgroundColor: "#F2F7FF",
        border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
    },
    dropZone: {
        background: '#F0F7FF',
        // border: `dashed 2px #0C75EB`,
        padding: '20px',
        borderRadius: '12px'
    },
    dropzoneText: {
        font: "16px Averta !important",
        fontWeight: "600 !important",
        color: '#171717 !important'
    },
    removeIcon: {
        color: "#E51A1A !important",
        cursor: "pointer"
    },
    smallBlueButton: {
        width: "122px !important", height: "44px !important", font: "14px Averta !important", fontWeight: `${700} !important`
    },
    error: {
        font: '16px Averta !important',
        opacity: 1,
        "&.MuiInputBase-input": {
            padding: '25.41px 12px 10px 12px !important',
            height: '17px',
            color: '#262626 !important',
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        },
        "&:disabled": {
            background: "#FAFAFA !important",
            borderRadius: '8px !important',
            border: '1px solid #FAFAFA !important',
            '-webkit-text-fill-color': "#525252 !important",
        },
    },
    error1: {
        font: '16px Averta !important',
        opacity: 1,
        "&.MuiInputBase-input": {
            padding: '25.41px 12px 10px 0px !important',
            height: '17px',
            color: '#262626 !important',
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        },
        "&:disabled": {
            background: "#FAFAFA !important",
            borderRadius: '8px !important',
            border: '1px solid #FAFAFA !important',
            '-webkit-text-fill-color': "#525252 !important",
        },
    },

    clientInput: {
        font: '14px Averta !important',
        background: "#FFFFFF !important",
        opacity: 1,
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        "&:focus": {
            "&.MuiFormLabel-root.MuiInputLabel-root": {
                color: `${blue} !important`
            },
        },
    },
    payrateNote: {
        minHeight: '60px', width: '100%', borderRadius: '8px', background: '#F0F5FF', textAlign: 'center', alignItems: 'center', padding: '14px 12px'
    },
    clickHereText: {
        color: '#0C75EB', font: '14px Averta', fontWeight: '400px', cursor: 'pointer'
    }
}))

export default ViewStyles;