import { Grid, Box, Tabs, Tab, Badge, Checkbox, SwipeableDrawer, FormControlLabel, Chip, Divider, ListItem, ListItemText, List } from "@mui/material";
import Text from "../../components/customText/Text";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import TimesheetEmployeePending from "./TimesheetEmployeePending";
import json from "../../utils/jsons/actions.json"
import { useLocation } from "react-router-dom";
import Styles from "./profile/UserProfileStyles";
import PendingTimesheets from './PendingTimesheets';
import LocalStorage from "../../utils/LocalStorage";
import ClearIcon from '@mui/icons-material/Clear';
import Search from '../../assets/svg/search1.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import CircleIcon from '@mui/icons-material/Circle';
import Button from "../../components/customButton/Button";
import { ReactComponent as CloseIcon } from '../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../assets/svg/CheckedBorderIcon.svg';
import nofilterplacement from '../../assets/svg/nofilterplacement.svg';
import { BlackToolTip, NoDataFound, addErrorMsg, dateFormat } from "../../utils/utils";
import TimesheetApi from "../../apis/admin/timesheets/TimesheetApi";
import moment from "moment";
import Date from "../../components/datePicker/Date";
import Loader from '../../assets/gif/japfuLoader.gif';
import { validate_emptyField } from "../../components/Validation";
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EmployeePendingActions() {
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [value, setValue] = useState(0);
    const location = useLocation();
    const classes = Styles();
    const from = location && location.state && location.state.from
    const placementId = location && location.state && location.state.placementId
    const id = location && location.state && location.state.id
    const timesheet_id = location && location.state && location.state.timesheet_id
    const employee = location && location.state && location.state.employee
    const dataJSon = json
    const [filterData, setFilterData] = useState({
        limit: 100,
        page: 1,
        search: "",
        status: 'Draft',
        placement_ids: [],
        timesheet_cycles: [],
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        sort_column: "ts.created_at",
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
        sort_order: "desc",
        employee_status: []
    });

    const [filterState, setFilterState] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
        placement_ids: [],
        timesheet_cycles: [],
        status: [],
        employee_status: []
    })

    const handleChange = (event, newValue) => {

        setValue(newValue);
        setFilterData({
            limit: 100,
            page: 1,
            search: "",
            status: 'Pending Approval',
            employee_status: [],
            placement_ids: [],
            timesheet_cycles: [],
            start_date_from: "",
            start_date_to: "",
            end_date_from: "",
            end_date_to: "",
            sort_column: "ts.created_at",
            employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
            sort_order: "desc"
        })
        setSearchId('');
        setFilterState({
            ...filterState,
            employee_status: []
        })
    };
    const [newRequests, setNewRequests] = useState('');
    const [tsNewRequests, setTSNewRequests] = useState('');
    const [placementsDropdown, setPlacementDropdown] = useState([]);
    const timeSheetCycles = require('../../utils/jsons/Cycle.json'); // eslint-disable-next-line
    const [cycleDropdown, setCycleDropdown] = useState(timeSheetCycles);
    const employeeStatus = [
        {
            "id": "Pending",
            "value": "Pending"
        },
        {
            "id": "Approved",
            "value": "Approved"
        },
        {
            "id": "Rejected",
            "value": "Rejected"
        }
    ]
    const [placementSearch, setPlacementSearch] = useState("");
    const [placementLoading, setPlacementLoading] = useState(false);

    useEffect(() => {
        handleGetAllPlacements('');
        if (from == 'view') {
            setValue(1);
            return true
        }
        if (from == 'viewAll') {
            if (LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => (item.slug == "timesheet_edit") && item.is_allowed == true) && rolePermission.some(item => (item.slug == "employee_edit") && item.is_allowed == true))) {
                setValue(0);
            }
            else if (LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_edit" && item.is_allowed == true))) {
                setValue(0);
            }
            else {
                setValue(1);
            }
            return true
        }
        if (from == 'employee') {
            setValue(1);
            return true
        }
        if (from == 'dashboard') {
            setValue(0);
        }
        else if (placementId == '' || placementId == null || placementId == undefined) {
            setValue(1);
        } else {
            setValue(0);
        }
        // eslint-disable-next-line
    }, [])

    const handleGetAllPlacements = (search) => {
        setPlacementLoading(true);
        let data = {
            request_id: LocalStorage.uid(),
            employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
            search: search,
            timesheet_status: ['Pending Approval']
        }
        TimesheetApi.getAllPlacementsEmployee(data).then((res) => {
            setPlacementLoading(false);
            if (res.data.statusCode === 1003) {
                setPlacementDropdown(res.data.data)
            }
        })
    }

    const handlePlacementSearch = (e) => {
        setPlacementSearch(e.target.value);
        handleGetAllPlacements(e.target.value);
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'start_date_to':
                datesError.start_date_to = validate_emptyField(input.value)
                break;
            case 'end_date_to':
                datesError.end_date_to = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handlePlacementClear = () => {
        setPlacementSearch("");
        handleGetAllPlacements("");
    }

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [searchId, setSearchId] = useState('');
    const [drawer, setDrawer] = useState(false);
    const [datesError, setDatesError] = useState({
        start_date_from: "",
        start_date_to: "",
        end_date_from: "",
        end_date_to: "",
    })

    const handleFilterDrawerClose = () => {
        filterState.timesheet_cycles = [];
        filterState.employee_status = []
        filterState.placement_ids = []
        filterState.start_date_from = '';
        filterState.start_date_to = '';
        filterState.end_date_from = '';
        filterState.end_date_to = '';
        filterState.employee_status = []
        setFilterState({ ...filterState });
        setDrawer(null);
    }

    const handleSearch = (e) => {
        setSearchId(e.target.value);
        setFilterData({
            ...filterData,
            search: e.target.value
        })
    }

    const handleClear = () => {
        setSearchId('');
        setFilterData({
            ...filterData,
            search: ''
        })
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleChangeCheckBox = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }



    const handleApplyFilters = () => {
        if (filterState.start_date_from != "" && filterState.start_date_to == "" && filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project Start/End Date");
            return true;
        }
        if (filterState.start_date_from != "" && filterState.start_date_to == "") {
            datesError.start_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project Start Date");
            return true;
        }
        if (filterState.end_date_from != "" && filterState.end_date_to == "") {
            datesError.end_date_to = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project End Date");
            return true;
        }
        if (filterState.start_date_from !== "" && filterState.start_date_to !== "") {
            if (moment(filterState.start_date_from, dateFormat()).isSameOrBefore(moment(filterState.start_date_to, dateFormat()))) {
                filterData.start_date_from = filterState.start_date_from;
                filterData.start_date_to = filterState.start_date_to;
            } else {
                addErrorMsg("Please select valid start dates");
                return true;
            }
        } else {
            filterData.start_date_from = '';
            filterData.start_date_to = '';
        }
        if (filterState.end_date_from !== "" && filterState.end_date_to !== "") {
            if (moment(filterState.end_date_from, dateFormat()).isSameOrBefore(moment(filterState.end_date_to, dateFormat()))) {
                filterData.end_date_from = filterState.end_date_from;
                filterData.end_date_to = filterState.end_date_to;
            } else {
                addErrorMsg("Please select valid end dates");
                return true;
            }
        } else {
            filterData.end_date_from = '';
            filterData.end_date_to = '';
        }
        filterData.placement_ids = filterState.placement_ids;
        filterData.timesheet_cycles = filterState.timesheet_cycles;
        filterData.employee_status = filterState.employee_status
        filterData.limit = 100;
        filterData.page = 1;
        setDrawer(null)
        setFilterData({ ...filterData });
    }

    const handleOpenDrawer = () => {
        if (filterData.start_date_from !== "" && filterData.start_date_to !== "") {
            filterState.start_date_from = filterData.start_date_from;
            filterState.start_date_to = filterData.start_date_to;
            setSelectedFilter(1);
        } else {
            filterState.start_date_from = '';
            filterState.start_date_to = '';
        }
        if (filterData.end_date_from !== "" && filterData.end_date_to !== "") {
            filterState.end_date_from = filterData.end_date_from;
            filterState.end_date_to = filterData.end_date_to;
            setSelectedFilter(2);
        } else {
            filterState.end_date_from = '';
            filterState.end_date_to = '';
        }
        if (filterData.placement_ids.length > 0) {
            setSelectedFilter(3);
        }
        if (filterData.timesheet_cycles.length > 0) {
            setSelectedFilter(4);
        }
        if (filterData.employee_status.length > 0) {
            setSelectedFilter(5)
        }
        if (filterData.start_date_from == "" && filterData.start_date_to == "" && filterData.end_date_from == "" && filterData.end_date_to == "" && filterData.timesheet_cycles.length == 0 && filterData.placement_ids.length == 0 && filterData.employee_status.length == 0) {
            setSelectedFilter(null);
        }
        filterState.placement_ids = filterData.placement_ids;
        filterState.timesheet_cycles = filterData.timesheet_cycles;
        filterState.employee_status = filterData.employee_status
        setFilterState({ ...filterState });
        datesError.start_date_to = "";
        datesError.end_date_to = "";
        setDatesError({ ...datesError });
        setSearchId("");
        setDrawer("filter");
    }

    const dateChange = (e, keyName) => {
        let date = e.$d;
        let event = { target: { name: keyName, value: date } }
        filterState[keyName] = moment(date).format(dateFormat());
        setFilterState({ ...filterState }, handleValidate(event));
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
        setPlacementSearch("");
    }

    const clearAllFilter = () => {
        datesError.start_date_from = '';
        datesError.start_date_to = '';
        datesError.end_date_from = '';
        datesError.end_date_to = '';
        setDatesError({ ...datesError });
        // filterState.timesheet_cycles = [];
        // filterState.status = [];
        // filterState.placement_ids = []
        // filterState.start_date_from = '';
        // filterState.start_date_to = '';
        // filterState.end_date_from = '';
        // filterState.end_date_to = '';
        // setFilterState({ ...filterState });
        // filterData.timesheet_cycles = [];
        // filterState.status = [];
        // filterData.placement_ids = []
        // filterData.start_date_from = '';
        // filterData.start_date_to = '';
        // filterData.end_date_from = '';
        // filterData.end_date_to = '';
        // setFilterData({ ...filterData });
        // setstatusEmployee([])
        const updatedArray = [...filterState['placement_ids']]; // Create a new array

        const index = updatedArray.findIndex(item => item === false);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(false); // Add item if it doesn't exist
        }


        const updatedArray1 = [...filterState['timesheet_cycles']]; // Create a new array

        const index1 = updatedArray1.findIndex(item => item === false);
        if (index1 !== -1) {
            updatedArray1.splice(index1, 1); // Remove item if it exists
        } else {
            updatedArray1.push(false); // Add item if it doesn't exist
        }

        if (value == 1) {
            setFilterState(prevState => ({
                ...prevState,
                employee_status: [] // Update the state with the new array
            }));
        }
        else {
            setFilterState(prevState => ({
                ...prevState,
                placement_ids: [] // Update the state with the new array
            }));
            setFilterState(prevState => ({
                ...prevState,
                timesheet_cycles: [] // Update the state with the new array
            }));
            setFilterState(prevState => ({
                ...prevState,
                start_date_from: '' // Update the state with the new array
            }));
            setFilterState(prevState => ({
                ...prevState,
                start_date_to: '' // Update the state with the new array
            }));
            setFilterState(prevState => ({
                ...prevState,
                end_date_from: '' // Update the state with the new array
            }));
            setFilterState(prevState => ({
                ...prevState,
                end_date_to: '' // Update the state with the new array
            }));
        }

        // setDrawer(null);
    }

    const handleDeleteChip = (id, name) => {
        if (name === "start_date" || name === "end_date") {
            const updatedFilterState = { ...filterState }; // Create a copy of the filterState object

            // Update the start_date or end_date fields
            updatedFilterState[name + "_from"] = "";
            updatedFilterState[name + "_to"] = "";

            // Update the state with the modified filterState object
            setFilterState(updatedFilterState);
        } else {
            const newFilterState = { ...filterState };
            const updatedArray = newFilterState[name].filter(item => item !== id);
            newFilterState[name] = updatedArray;
            setFilterState(newFilterState);
        }
    };




    return (
        <Grid container lg={12} md={12} sm={12} xs={12} sx={{
            "@media (min-width: 968px)": {
                paddingLeft: '130px'
            }
        }}>
            <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent={'start'}>
                <Text popupHead1>Pending {LocalStorage.getUserData().admin_login ? 'Actions' : 'Requests'}</Text>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} alignItems='center'>
                <Grid item lg={8} md={8} sm={12} xs={12} pt={2}>
                    {LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => (item.slug == "timesheet_edit") && item.is_allowed == true) && rolePermission.some(item => (item.slug == "employee_edit") && item.is_allowed == true))
                        ?
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '43%' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        {
                                            value == 0 ? <Text analyticsViewAll>Timesheet</Text> : <Text headerText14>Timesheet</Text>
                                        }
                                        {tsNewRequests == '' ? '' : <Box className={classes.requestCount}>{tsNewRequests}</Box>}
                                    </Box>
                                } {...a11yProps(0)} className={value == 0 ? classes.activeText : classes.tabText} />
                                <Tab label={
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        {
                                            value == 1 ? <Text analyticsViewAll>Employee</Text> : <Text headerText14>Employee</Text>
                                        }
                                        {newRequests == '' ? '' : <Box className={classes.requestCount}>{newRequests}</Box>}
                                    </Box>
                                } {...a11yProps(1)} className={value == 1 ? classes.activeText : classes.tabText} />
                            </Tabs>
                        </Box>
                        :
                        LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_edit" && item.is_allowed == true)) ?
                            <Box sx={{ width: '33%' }}>
                                <Tabs value={0} onChange={() => { setValue(0) }} aria-label="basic tabs example" sx={{ '& .MuiTabs-indicator': { display: 'none' } }}>
                                    <Tab label={
                                        <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                            {
                                                value == 0 ? <Text headerText14>Timesheet</Text> : <Text headerText14>Timesheet</Text>
                                            }
                                            {tsNewRequests == '' ? '' : <Box className={classes.requestCount}>{tsNewRequests}</Box>}
                                        </Box>
                                    } {...a11yProps(0)} className={value == 0 ? classes.tabText : classes.tabText} />
                                </Tabs>
                            </Box> :
                            LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true)) ?
                                <Box sx={{ width: '33%' }}>
                                    <Tabs value={1} onChange={() => { setValue(1) }} aria-label="basic tabs example" sx={{ '& .MuiTabs-indicator': { display: 'none' } }}>
                                        <Tab label={
                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                {
                                                    value == 1 ? <Text headerText14>Employee</Text> : <Text headerText14>Employee</Text>
                                                }
                                                {newRequests == '' ? '' : <Box className={classes.requestCount}>{newRequests}</Box>}
                                            </Box>
                                        } {...a11yProps(1)} className={value == 1 ? classes.tabText : classes.tabText} />
                                    </Tabs>
                                </Box> : ''
                    }
                </Grid>
                {
                    LocalStorage.getUserData().super_admin || !LocalStorage.getUserData().admin_login || (rolePermission !== "" && rolePermission.some(item => (item.slug == "timesheet_edit") && item.is_allowed == true) && rolePermission.some(item => (item.slug == "employee_edit") && item.is_allowed == true)) ?
                        <Grid item container lg={4} md={4} sm={12} xs={12} pt={2} alignItems='center' columnSpacing={2}>
                            <Grid item lg={8}>
                                <Box className={classes.tsinput}>
                                    <input
                                        type="text"
                                        value={searchId}
                                        className={classes.searchInput}
                                        onChange={handleSearch}
                                        placeholder="Search by Name / ID"
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            searchId !== "" ?
                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleClear()} />
                                                :
                                                <img src={Search} alt="Search" />
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4}>
                                <Box>
                                    <button title="Filter" type="button" className={classes.borderButton1} onClick={() => { handleOpenDrawer() }}>
                                        <Badge badgeContent={filterData.employee_status.length + filterData.timesheet_cycles.length + filterData.placement_ids.length + ((filterData.start_date_from !== "" && filterData.start_date_to !== "") && 1) + ((filterData.end_date_from !== "" && filterData.end_date_to !== "") && 1)} color="error">
                                            <FilterListIcon sx={{ color: filterData.employee_status.length || filterData.timesheet_cycles.length || filterData.placement_ids.length || (filterData.start_date_from !== "" && filterData.start_date_to !== "") || (filterData.end_date_from !== "" && filterData.end_date_to !== "") ? "#2BEA2B" : "#737373" }} />
                                        </Badge>
                                    </button>
                                </Box>
                            </Grid>
                        </Grid> : ''
                }
                <Box sx={{ width: '100%' }}>
                    <CustomTabPanel value={value} index={0}>
                        {/* Timesheet changes */}
                        <PendingTimesheets filterData={filterData} employee={employee} fetchId={timesheet_id} setTSNewRequests={setTSNewRequests} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {/* Employee changes */}
                        <TimesheetEmployeePending searchId={searchId} filterState={filterData.employee_status} data={dataJSon} fetchId={id} placementId={placementId} employee={employee} setNewRequests={setNewRequests} />
                    </CustomTabPanel>
                </Box>
            </Grid>
            <SwipeableDrawer
                anchor={'right'}
                open={drawer}
                onClose={() => handleFilterDrawerClose()}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                {
                    drawer === "filter" ?
                        <Box width={'660px'} height={'100vh'} >
                            <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                <Text headerBlack>Filters</Text>
                            </Box>

                            <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>

                                    {
                                        value == 0 && filterState.start_date_from !== "" && filterState.start_date_to !== "" &&
                                        <>
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={1}
                                                icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{filterState.start_date_from} - {filterState.start_date_to}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip('', "start_date")}
                                                deleteIcon={<CloseIcon />} />
                                        </>
                                    }

                                    {
                                        value == 0 && filterState.end_date_from !== "" && filterState.end_date_to !== "" &&
                                        <>
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={1}
                                                icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{filterState.end_date_from} - {filterState.end_date_to}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip('', "end_date")}
                                                deleteIcon={<CloseIcon />} />
                                        </>
                                    }
                                    {
                                        value == 0 && placementsDropdown.map((item, key) => (
                                            filterState.placement_ids.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={key}
                                                icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.reference_id} (  {item.client_name === "" ? "--" : item.client_name && item.client_name.length > 16 ?
                                                    <BlackToolTip title={item.client_name} placement="right" arrow>
                                                        {item.client_name.slice(0, 16) + (item.client_name.length > 16 ? "..." : "")}
                                                    </BlackToolTip>
                                                    :
                                                    item.client_name
                                                } )</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "placement_ids")}
                                                deleteIcon={<CloseIcon />} />
                                        ))
                                    }
                                    {
                                        value == 0 && cycleDropdown.map((item, key) => (
                                            filterState.timesheet_cycles.includes(item.id) &&
                                            <Chip key={key}
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "timesheet_cycles")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                    {
                                        value == 1 && employeeStatus.map((item, key) => (
                                            filterState.employee_status.includes(item.id) &&
                                            <Chip key={key}
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "employee_status")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                </Box>
                                {
                                    ((filterState.start_date_from !== "" && filterState.start_date_to !== "") || (filterState.end_date_from !== "" && filterState.end_date_to !== "") || filterState.placement_ids.length > 0 || filterState.timesheet_cycles.length > 0 || filterState.employee_status.length > 0) ?
                                        <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                        :
                                        ''
                                }
                            </Box>

                            <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        {
                                            value == 0 &&
                                            <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.start_date_from !== "" && filterState.start_date_to ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.start_date_from !== "" && filterState.start_date_to ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 1 ? <Text smallBlue1 ml={3}>Timesheet Start Date</Text> : <Text mediumBlack ml={3}>Timesheet Start Date</Text>} />
                                            </ListItem>
                                        }
                                        {
                                            value == 0 &&
                                            <Divider />
                                        }
                                        {
                                            value == 0 &&
                                            <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{(filterState.end_date_from !== "" && filterState.end_date_to !== "") ? "1" : ""}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Timesheet End Date</Text> : <Text ml={3} mediumBlack>Timesheet End Date</Text>} />
                                            </ListItem>
                                        }
                                        {
                                            value == 0 &&
                                            <Divider className={classes.divider} />
                                        }
                                        {
                                            value == 0 &&
                                            <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterState.placement_ids.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.placement_ids.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Placements</Text> : <Text ml={3} mediumBlack>Placements</Text>} />
                                            </ListItem>
                                        }
                                        {
                                            value == 0 &&
                                            <Divider />
                                        }
                                        {
                                            value == 0 &&
                                            <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={filterState.timesheet_cycles.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.timesheet_cycles.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Timesheet Cycle</Text> : <Text ml={3} mediumBlack>Timesheet Cycle</Text>} />
                                            </ListItem>
                                        }
                                        {
                                            value == 0 &&
                                            <Divider />
                                        }
                                        {
                                            value == 1 &&
                                            <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={filterState.employee_status.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7653A3" }}><Text mediumWhite400>{filterState.employee_status.length}</Text></Box> : ""}>
                                                <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Status</Text> : <Text ml={3} mediumBlack>Status</Text>} />
                                            </ListItem>
                                        }
                                        {
                                            value == 1 &&
                                            <Divider />
                                        }
                                    </List>

                                </Box>
                                <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                    {
                                        selectedFilter == 1 ?
                                            <Grid container alignItems={"center"}>
                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`From`}
                                                        name='start_date_from'
                                                        value={filterState.start_date_from}
                                                        onChange={(value) => dateChange(value, 'start_date_from')}
                                                        height='56px'
                                                    />
                                                </Grid>
                                                <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                    &#8594;
                                                </Grid>
                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`To`}
                                                        name='start_date_to'
                                                        value={filterState.start_date_to}
                                                        onChange={(value) => dateChange(value, 'start_date_to')}
                                                        height='56px'
                                                        minDate={moment(filterState.start_date_from).format(dateFormat())}
                                                        disabled={filterState.start_date_from == ""}
                                                        error={datesError.start_date_to}
                                                    />
                                                    <Box sx={{ height: "0px" }}>{datesError.start_date_to ? <Text red>{datesError.start_date_to ? datesError.start_date_to : ''}</Text> : ''}</Box>
                                                </Grid>
                                            </Grid> :
                                            selectedFilter == 2 ?
                                                <Grid container alignItems={"center"}>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`From`}
                                                            name='end_date_from'
                                                            value={filterState.end_date_from}
                                                            onChange={(value) => dateChange(value, 'end_date_from')}
                                                            height='56px'
                                                        />
                                                    </Grid>
                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                        &#8594;
                                                    </Grid>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`To`}
                                                            name='end_date_to'
                                                            value={filterState.end_date_to}
                                                            onChange={(value) => dateChange(value, 'end_date_to')}
                                                            height='56px'
                                                            minDate={moment(filterState.end_date_from).format(dateFormat())}
                                                            disabled={filterState.end_date_from == ""}
                                                            error={datesError.end_date_to}
                                                        />
                                                        <Box sx={{ height: "0px" }}>{datesError.end_date_to ? <Text red>{datesError.end_date_to ? datesError.end_date_to : ''}</Text> : ''}</Box>
                                                    </Grid>
                                                </Grid> :
                                                selectedFilter == 3 ?
                                                    <>
                                                        <Box sx={{ margin: "10px 0px", minHeight: "50px", border: "1.5px solid #E2E5E6", width: "100%", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                            <input
                                                                type="text"
                                                                value={placementSearch}
                                                                className={classes.searchInput}
                                                                onChange={(e) => handlePlacementSearch(e)}
                                                                placeholder="Search by Client Name / Placement ID"
                                                            />
                                                            <Box sx={{ paddingRight: "15px !important" }}>
                                                                {
                                                                    filterData.search !== "" ?
                                                                        <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handlePlacementClear()} />
                                                                        :
                                                                        <img src={Search} alt="Search" />
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ height: "60vh", overflow: "auto" }}>
                                                            {
                                                                placementLoading ?
                                                                    <Box width={"100%"} height={'76vh'} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                                        <img src={Loader} alt="Loader" width={'20px'} height={'20px'} />
                                                                    </Box>
                                                                    :
                                                                    <>
                                                                        {
                                                                            placementSearch !== "" && placementsDropdown.length === 0 ?
                                                                                <Box >
                                                                                    {NoDataFound("To fetch the data", "No Data Found", "40%")}
                                                                                </Box> :
                                                                                <>

                                                                                    {
                                                                                        placementsDropdown.length > 0 && placementsDropdown.map((item, index) => (
                                                                                            <Grid container alignItems={"center"} pb={2} pl={1}>
                                                                                                <FormControlLabel
                                                                                                    key={index}
                                                                                                    control={
                                                                                                        <Checkbox
                                                                                                            size="small"
                                                                                                            name={"placement_ids"}
                                                                                                            value={item.id}
                                                                                                            onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                                            icon={<CheckBorderIcon />}
                                                                                                            checkedIcon={<CheckedIcon />}
                                                                                                            checked={filterState.placement_ids.includes(item.id)} />}
                                                                                                    label={<Text mediumBlack nowrap>{item.reference_id} (  {item.client_name === "" ? "--" : item.client_name && item.client_name.length > 16 ?
                                                                                                        <BlackToolTip title={item.client_name} placement="right" arrow>
                                                                                                            {item.client_name.slice(0, 16) + (item.client_name.length > 16 ? "..." : "")}
                                                                                                        </BlackToolTip>
                                                                                                        :
                                                                                                        item.client_name
                                                                                                    } )</Text>}
                                                                                                />
                                                                                            </Grid>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                        </Box>
                                                    </>
                                                    : selectedFilter == 4 ?
                                                        <>
                                                            {
                                                                cycleDropdown.length > 0 && cycleDropdown.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        {
                                                                            item.id != 5 &&
                                                                            <>
                                                                                <FormControlLabel
                                                                                    key={index}
                                                                                    control={
                                                                                        <Checkbox
                                                                                            size="small"
                                                                                            name={"timesheet_cycles"}
                                                                                            value={item.id}
                                                                                            onChange={(e) => handleChangeCheckBox(e)}
                                                                                            icon={<CheckBorderIcon />}
                                                                                            checkedIcon={<CheckedIcon />}
                                                                                            checked={filterState.timesheet_cycles.includes(item.id)} />}
                                                                                    label={<Text mediumBlack noWrap>{item.value}</Text>}
                                                                                />
                                                                            </>
                                                                        }

                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        selectedFilter == 5 ?
                                                            <>
                                                                {
                                                                    employeeStatus.length > 0 && employeeStatus.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            {
                                                                                item.id != 5 &&
                                                                                <>
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"employee_status"}
                                                                                                value={item.id}
                                                                                                onChange={(e) => handleChangeCheckBox(e)}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterState.employee_status.includes(item.id)} />}
                                                                                        label={<Text mediumBlack noWrap>{item.value}</Text>}
                                                                                    />
                                                                                </>
                                                                            }

                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </>

                                                            :
                                                            <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                <Grid container>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                        <Text sx={{ font: "18px Averta !important", fontWeight: `${700} !important`, color: "#262626 !important" }}>Gear Up!</Text>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <Text sx={{ font: "14px Averta !important", fontWeight: `${400} !important`, color: "#737373 !important" }}>Select filters to scale our data peaks</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                        // nofilterplacement
                                    }
                                </Box>
                            </Box>

                            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                            </Box>
                        </Box >
                        : null
                }

            </SwipeableDrawer>
        </Grid >
    )
}