import { Autocomplete, Backdrop, Box, Breadcrumbs, Card, Checkbox, Chip, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../../../components/customText/Text'
import warning from '../../../../../assets/employee/info.svg';
import Date from '../../../../../components/datePicker/Date';
import moment from 'moment';
import { NoPermission, addErrorMsg, addWarningMsg, capitalizeAndAddSpace, dateFormat } from '../../../../../utils/utils';
import { ReactComponent as CheckedIcon } from '../../../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../../../assets/svg/CheckedBorderIcon.svg';
import Button from '../../../../../components/customButton/Button';
import CommonApi from '../../../../../apis/CommonApi';
import LocalStorage from '../../../../../utils/LocalStorage';
import Dropzone from 'react-dropzone';
import RehireStyles from './RehireStyles';
import download from '../../../../../assets/employee/filedownload.svg';
import { blue } from '../../../../../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import EmployeesApi from '../../../../../apis/admin/employees/EmployeesApi';
import ReusablePopup from '../../../../../components/reuablePopup/ReusablePopup';
import successRehire from '../../../../../assets/employee/offboarding/rehire-success.svg';
import Loader from '../../../../../assets/gif/japfuLoader.gif';
import InputSearchSelect from '../../../../../components/customSelect/InputSearchSelect';
import contactExists from '../../../../../assets/employee/offboarding/contact-exists.svg';
import LoaderButton from '../../../../../components/customButton/LoadingButton'

function Rehire() {
    const classes = RehireStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const full_name = location && location.state && location.state.full_name
    const id = location && location.state && location.state.id
    const lastWorkingDate = location && location.state && location.state.date
    const [state, setState] = useState({
        rejoin_date: '',
        enable_login: false,
        visa_dummy: null,
        visa_type_id: '',
        support_documents: [],
        valid_from: '',
        valid_till: '',
        is_usc: null,
        is_usc_name: null
    });
    const [workAuth, setWorkAuth] = useState(false);
    const [visaList, setVisaList] = useState([]);
    const [rehireSuccess, setRehireSuccess] = useState(false);
    const [empExists, setEmpExistis] = useState(false);
    const [openBacKLoader, setOpenBackDrop] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState({
        rejoin_date: '',
        visa_type_id: '',
        valid_from: '',
        valid_till: ''
    })

    useEffect(() => {
        visaListApi();
    }, [])

    const visaListApi = () => {
        CommonApi.visaTypes(LocalStorage.uid(), LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setVisaList(res.data.data);
            }
        })
    }

    const dateChange = (e, name) => {
        let date = e == null ? '' : e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        if (name == 'valid_from' || name == 'valid_till') {
            state[event.target.name] = date == '' ? '' : moment(date).format(dateFormat())
            setState({ ...state })
            setError({ ...error, [event.target.name]: '' })
        } else {
            state.rejoin_date = date == '' ? '' : moment(date).format(dateFormat())
            setState(state);
            setError({ ...error, rejoin_date: '' })
        }
    }

    const changeHandler = (e, newValue, name) => {
        if (e.target.name == 'enable_login') {
            state[e.target.name] = e.target.checked
            setState({ ...state })
        } else if (name === 'visa_type_id') {
            state['visa_dummy'] = newValue !== null ? newValue : ''
            state[name] = newValue !== null ? newValue.id : ''
            setState({ ...state })
            setError({ ...error, visa_type_id: '' });
        } else {
            state[e.target.name] = e.target.value
            setState({ ...state })
            setError({ ...error, visa_type_id: '' });
        }
    }

    const changeHandlerUSC = (e, newValue) => {
        state['is_usc_name'] = newValue !== null ? newValue : null
        state['is_usc'] = newValue !== null ? newValue.id : null
        state['visa_type_id'] = ''
        state['visa_dummy'] = ''
        state.support_documents = []
        state.valid_from = ''
        state.valid_till = ''
        setState({ ...state })
        setError({
            visa_type_id: '',
            valid_from: '',
            valid_till: '',
        });
    }

    const cancel = () => {
        if (workAuth) {
            setWorkAuth(false);
        } else {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeSubModule();
            LocalStorage.removeNotificationEmpId();
            navigate(`/employees/user-profile/${full_name === "" ? "" : full_name.trim().split(/ +/).join('-')}`, { state: { id: id, from: 'employmentDashboard' } })
        }
    }

    const handleSave = () => {
        if (state.rejoin_date == '') {
            error['rejoin_date'] = 'This field is required';
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
        else if (moment(state.rejoin_date, LocalStorage.getDateFormat()).isBefore(lastWorkingDate) || moment(state.rejoin_date, LocalStorage.getDateFormat()).isSame(lastWorkingDate)) {
            error['rejoin_date'] = 'Please ensure that the start date is later than last working day';
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
        else {
            setWorkAuth(true);
            error['visa_type_id'] = ''
            error['is_usc'] = ''
            setError({ ...error });
        }
    }

    const handleSubmit = () => {
        if (state.is_usc == false) {
            error['is_usc'] = '';
            setError({ ...error });
            if (state.visa_type_id === '') {
                error['visa_type_id'] = 'This field is required';
                setError({ ...error });
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
            else if (state.visa_type_id !== '' || state.support_documents.length > 0) {
                let keys = Object.keys(error);
                let count = keys.reduce((acc, curr) => (error[curr] ? acc + 1 : acc), 0);
                if (count === 0) {
                    if (moment(state.valid_till).isBefore(state.valid_from)) {
                        error['valid_till'] = 'Please ensure that the date of expiry is later than the date of issue.';
                        setError({ ...error });
                    }
                    else {
                        setLoadingButton(true)
                        state['request_id'] = LocalStorage.uid();
                        EmployeesApi.RehireUpdate(id, state).then((res) => {
                            if (res.data.statusCode == 1003) {
                                setRehireSuccess(true);
                                setLoadingButton(false);
                            } else if (res.data.statusCode == 1012) {
                                if (res.data.employee_already_exists == true) {
                                    setEmpExistis(true);
                                    setLoadingButton(false);
                                }
                            }
                            else {
                                setLoadingButton(false);
                                addErrorMsg(res.data.message);
                            }
                        })
                    }

                }
            }
        } else if (state.is_usc == null || state.is_usc == '') {
            error['is_usc'] = 'This field is required';
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        } else if (state.is_usc == true) {
            error['is_usc'] = '';
            error['visa_type_id'] = '';
            setError({ ...error });
            state['request_id'] = LocalStorage.uid();
            setLoadingButton(true)
            EmployeesApi.RehireUpdate(id, state).then((res) => {
                if (res.data.statusCode == 1003) {
                    setRehireSuccess(true);
                    setLoadingButton(false);
                } else if (res.data.statusCode == 1012) {
                    if (res.data.employee_already_exists == true) {
                        setEmpExistis(true);
                        setLoadingButton(false);
                    }
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        }
    }

    const handleDrop = (e) => {
        if (e.length < 11) {
            setOpenBackDrop(true);
            for (let i = 0; i < e.length; i++) {
                if (e[i].path.split('.').some(r => ['pdf', 'doc', 'docx'].includes(r))) {
                    if (((e[i].size / (1024 * 1024)).toFixed(2)) < 25 || ((e[i].size / (1024 * 1024)).toFixed(2)) < '25') {
                        const formData = new FormData();
                        // e.map((i) => formData.append("files", i))
                        formData.append("files", e[i]);
                        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                        CommonApi.documentUpload("visa-document", formData, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode == 1003) {
                                if (state.support_documents.length < 10) {
                                    state.support_documents.push({
                                        id: '',
                                        visa_document_upload_id: res.data.data.id,
                                        document_url: res.data.data.document_url,
                                        document_name: res.data.data.document_name,
                                    });
                                    if (i === e.length - 1) {
                                        setOpenBackDrop(false);
                                    }
                                }
                                else {
                                    setOpenBackDrop(false);
                                    addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
                                }
                                setState({ ...state })
                            } else {
                                setOpenBackDrop(false);
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                    else {
                        setOpenBackDrop(false);
                        addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                    }
                }
                else {
                    setOpenBackDrop(false);
                    addErrorMsg('Please upload files in PDF or DOC format only.')
                }
            }
        } else {
            setOpenBackDrop(false);
            addErrorMsg('You can upload a maximum of 10 files at a time. Please remove excess files and try again')
        }
    }

    const deleteChip = (args) => {
        state.support_documents.splice(args, 1)
        setState({ ...state });
        if (state.support_documents.length == 0) {
            state['valid_from'] = ''
            state['valid_till'] = ''
            setState({ ...state })
        }
    }
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    return (
        <Grid container pl={'130px'}>
            <Grid item lg={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Text mediumGrey onClick={() => {
                        LocalStorage.removeRedirectedModule();
                        LocalStorage.removeFromPage(); navigate('/employees')
                    }} className={classes.cursor}>Empoyees</Text>
                    <Text mediumGrey onClick={() => {
                        LocalStorage.removeRedirectedModule();
                        LocalStorage.removeSubModule();
                        LocalStorage.removeNotificationEmpId();
                        navigate(`/employees/user-profile/${full_name === "" ? "" : full_name.trim().split(/ +/).join('-')}`, { state: { id: id, from: 'employmentDashboard' } })
                    }
                    } className={classes.cursor}>User Profile</Text>
                    <Text mediumBlack>Rehire</Text>
                </Breadcrumbs>
            </Grid>
            {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) ?
                <Grid item container lg={12} justifyContent='center' pt={3}>
                    <Grid item container lg={6.5}>
                        <Grid item lg={12}>
                            <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                <Box pt={'4px'}>
                                    <img src={warning} alt='warning' />
                                </Box>
                                <Box>
                                    <Text mediumOrangeRehire>
                                        {
                                            workAuth ? `Please fill the active work authorization details below to rehire ${full_name ? full_name.length > 16 ? capitalizeAndAddSpace(full_name.slice(0, 16)) + '...' : capitalizeAndAddSpace(full_name) : ''}` : `Please fill the below information to rehire ${full_name ? full_name.length > 16 ? capitalizeAndAddSpace(full_name.slice(0, 16)) + '...' : capitalizeAndAddSpace(full_name) : ''}`
                                        }
                                    </Text>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container lg={12} pt={2}>
                            <Card sx={{ width: '100%', borderRadius: '12px', boxShadow: '0px 0px 20px 1px #0000000D !important' }}>
                                {
                                    workAuth ?
                                        <Grid item container lg={12} p={4} spacing={3}>
                                            <Grid item lg={12}>
                                                <Text largeBlack>Work Authorization Type</Text>
                                            </Grid>
                                            <Grid item lg={5} mt={1}>
                                                <InputSearchSelect
                                                    name='is_usc'
                                                    value={state.is_usc_name}
                                                    onChange={(e, val) => { changeHandlerUSC(e, val, 'is_usc') }}
                                                    options={[{ id: true, value: "Yes" }, { id: false, value: "No" }]}
                                                    label='Is this Employee USC?'
                                                    helperText={error.is_usc}
                                                />
                                            </Grid>
                                            {
                                                state.is_usc == false ?
                                                    <>
                                                        <Grid item lg={6} mt={1}>
                                                            <InputSearchSelect
                                                                name='visa_type_id'
                                                                value={state.visa_dummy}
                                                                onChange={(e, val) => { changeHandler(e, val, 'visa_type_id') }}
                                                                options={visaList}
                                                                label='Visa type'
                                                                helperText={error.visa_type_id}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={12}>
                                                            <Dropzone onDrop={acceptedFiles => { handleDrop(acceptedFiles) }}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className={classes.dropZone}>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <Grid container spacing={1} textAlign='center'>
                                                                                <Grid item lg={12} marginTop={'-8px'}>
                                                                                    <img src={download} alt='download' />
                                                                                </Grid>
                                                                                <Grid item lg={12} textAlign='center'>
                                                                                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' textAlign='center' gap={1}>
                                                                                        <Text largeBlack>Drag & Drop Documents</Text>
                                                                                        <Text smallGrey>or</Text>
                                                                                        <Text smallBlue1 sx={{ textDecoration: `1px underline ${blue} !important`, cursor: 'pointer' }}>Click Here</Text>
                                                                                        <Text largeBlack>To Upload</Text>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid item lg={12} mt={1}>
                                                                                    <Text mediumOrange>*Add visa documents like EAD, I20's, I983, I-129, I-797,<br /> GC, GC EAD, etc</Text>
                                                                                </Grid>
                                                                                <Grid item lg={12} mt={1}>
                                                                                    <Text smallGreyFont>Document Size 25MB, Format - PDF, Docs</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </Grid>
                                                        <Grid item lg={12}>
                                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "center", border: "1px solid #C7CCD3", borderRadius: "8px", }}>
                                                                <div style={{ width: '100%', paddingTop: state.support_documents.length > 0 ? '12px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center", }}>
                                                                    <Autocomplete
                                                                        clearIcon={false}
                                                                        options={[]}
                                                                        freeSolo
                                                                        multiple
                                                                        onKeyPress={(e) => { e.preventDefault() }}
                                                                        value={state.support_documents}
                                                                        renderTags={(value, props) =>
                                                                            value.map((option, index) => (
                                                                                <Chip label={option.document_name} {...props({ index })}
                                                                                    onClick={() => window.open(option.document_url)}
                                                                                    onDelete={() => deleteChip(index)}
                                                                                    className={classes.chipLabel}
                                                                                />
                                                                            ))
                                                                        }
                                                                        renderInput={(params) => <TextField label="Uploaded Documents" {...params} />}
                                                                        sx={{
                                                                            width: '100%',
                                                                            "& .MuiInputBase-root": {
                                                                                cursor: "pointer",
                                                                            },
                                                                            "& .MuiInputBase-input": {
                                                                                cursor: "pointer",
                                                                            },
                                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                                border: "none"
                                                                            },
                                                                            "& .MuiAutocomplete-endAdornment": {
                                                                                display: "none"
                                                                            },
                                                                            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                                                                transform: "translate(10px, 16px) scale(1);"
                                                                            },
                                                                            "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                color: "#737373",
                                                                                fontSize: "14px",
                                                                                fontFamily: "Averta !important",
                                                                                fontWeight: 400,
                                                                            },
                                                                            "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                                                                color: "#737373",
                                                                                fontSize: "16px",
                                                                                fontFamily: "Averta !important",
                                                                                fontWeight: 500,
                                                                            },
                                                                            "&.Mui-focused .MuiInputLabel-outlined": {
                                                                                color: "#737373",
                                                                                fontSize: "10px",
                                                                                fontFamily: "Averta !important",
                                                                                fontWeight: 400,
                                                                                transform: state.support_documents.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item container lg={12} spacing={2}>
                                                            <Grid item lg={6}>
                                                                <Date
                                                                    name='valid_from'
                                                                    value={state.valid_from}
                                                                    labelText={'Date of issue'}
                                                                    height='54px'
                                                                    disabled={state.visa_type_id === '' && state.support_documents.length == 0}
                                                                    maxDate={moment().subtract(1, 'days')}
                                                                    onChange={(value => dateChange(value, 'valid_from'))}
                                                                    error={error.valid_from}
                                                                />
                                                                {error.valid_from && <Text red>{error.valid_from ? error.valid_from : ''}</Text>}
                                                            </Grid>
                                                            <Grid item lg={6}>
                                                                <Date
                                                                    name='valid_till'
                                                                    value={state.valid_till}
                                                                    labelText={'Date of Expiry'}
                                                                    height='54px'
                                                                    disabled={state.valid_from === ''}
                                                                    minDate={state.valid_from}
                                                                    onChange={(value => dateChange(value, 'valid_till'))}
                                                                    error={error.valid_till}
                                                                />
                                                                {error.valid_till && <Text red>{error.valid_till ? error.valid_till : ''}</Text>}
                                                            </Grid>
                                                        </Grid>
                                                    </> : ''
                                            }

                                        </Grid>
                                        :
                                        <Grid item container lg={12} p={4} spacing={2}>
                                            <Grid item lg={12}>
                                                <Text largeBlack>Additional Details</Text>
                                            </Grid>
                                            <Grid item lg={5} mt={2}>
                                                <Date
                                                    name='rejoin_date'
                                                    value={state.rejoin_date}
                                                    labelText={'Start Date'}
                                                    height='54px'
                                                    minDate={moment(lastWorkingDate, LocalStorage.getDateFormat()).add(1, 'days')}
                                                    onChange={(value => dateChange(value, 'rejoin_date'))}
                                                    error={error.rejoin_date}
                                                />
                                                {error.rejoin_date && <Text red>{error.rejoin_date ? error.rejoin_date : ''}</Text>}
                                            </Grid>
                                            <Grid item lg={12} mt={2}>
                                                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                                                    <Checkbox name='enable_login' value={state.enable_login} checked={state.enable_login}
                                                        onChange={changeHandler} sx={{ padding: '0px !important' }} icon={<CheckBorderIcon />}
                                                        checkedIcon={<CheckedIcon />} />
                                                    <Text smallBlack14>Enable User access</Text>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                }
                            </Card>
                        </Grid>
                        <Grid item container lg={12} mt={4}>
                            <Grid item lg={6}>
                                <Button blackCancel onClick={cancel}>{workAuth ? 'Back' : 'Cancel'}</Button>
                            </Grid>
                            <Grid item lg={6} textAlign='end'>
                                <LoaderButton saveNcontinueSmall loading={loadingButton} onClick={() => workAuth ? handleSubmit() : handleSave()}>{workAuth ? 'Finish' : 'Save & Continue'}</LoaderButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ReusablePopup white iconHide openPopup={rehireSuccess} setOpenPopup={setRehireSuccess}>
                        <Grid container spacing={2} p={2} textAlign={'center'} justifyContent={'center'}>
                            <Grid item lg={12}>
                                <img src={successRehire} alt='rehire' />
                            </Grid>
                            <Grid item lg={12}>
                                <Text veryLargeLabel>Employee Re-Hired</Text>
                            </Grid>
                            <Grid item lg={12}>
                                <Text largeLabel><span style={{ color: "#0C75EB" }}>{full_name}</span> has been Re-hired Successfully</Text>
                            </Grid>
                            <Grid item lg={9} mt={1}>
                                <Button blueButton onClick={() => {
                                    LocalStorage.removeRedirectedModule();
                                    LocalStorage.removeSubModule();
                                    LocalStorage.removeNotificationEmpId();
                                    navigate(`/employees/user-profile/${full_name === "" ? "" : full_name.trim().split(/ +/).join('-')}`, { state: { id: id, from: 'employmentDashboard' } })
                                }}>Done</Button>
                            </Grid>
                        </Grid>
                    </ReusablePopup>
                    <ReusablePopup white iconHide openPopup={empExists} setOpenPopup={setEmpExistis} fixedWidth>
                        <Grid container spacing={2} p={2} textAlign={'center'} justifyContent={'center'}>
                            <Grid item lg={12}>
                                <img src={contactExists} alt='rehire' />
                            </Grid>
                            <Grid item lg={12}>
                                <Text veryLargeLabel>Details already exist</Text>
                            </Grid>
                            <Grid item lg={12}>
                                <Text mediumOverView>The contact details already exist for<br /> another active user</Text>
                            </Grid>
                            <Grid item lg={9} mt={1}>
                                <Button saveBtn400 onClick={() => {
                                    LocalStorage.removeRedirectedModule();
                                    LocalStorage.removeSubModule();
                                    LocalStorage.removeNotificationEmpId();
                                    navigate(`/employees/user-profile/${full_name === "" ? "" : full_name.trim().split(/ +/).join('-')}`, { state: { id: id, from: 'employmentDashboard' } })
                                }}>Okay</Button>
                            </Grid>
                        </Grid>
                    </ReusablePopup>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openBacKLoader}
                    // onClick={handleClose}
                    >
                        <img src={Loader} alt="Loader" width='100px' height='100px' />
                    </Backdrop>
                </Grid>
                :
                <>
                    {NoPermission()}
                </>
            }
        </Grid>
    )
}

export default Rehire
