import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { blue } from "../../../theme";

const AITimesheetStyles = makeStyles((theme) => ({
    greyDot: {
        height: '10px !important',
        width: '10px !important',
        borderRadius: '50%',
        background: '#CBD5E0 !important'
    },
    boxContainer: {
        border: '1px solid #CACACA',
        borderRadius: '4px',
        paddingTop: '28px',
        "&:hover": {
            transform: 'translateY(-25px)'
        }
    },
    boxContainerActive: {
        border: '1px solid #CACACA',
        borderRadius: '4px',
        paddingTop: '28px',
        transform: 'translateY(-25px)',
        boxShadow: '0px 0px 15px 1px rgba(12, 117, 235, 0.30) !important',
        [useTheme().breakpoints.down('sm')]: {
            marginTop: '20px'
        }
    },
    basic: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#F59E0B',
        backgroundImage: "linear-gradient(180deg, #FFEAC1 0%, #F9C96C 100%)",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    basicMivi: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#F59E0B',
        backgroundImage: "linear-gradient(135deg, #FFC803, #FFDCA7 )",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    pro: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#B2059E',
        backgroundImage: "linear-gradient(180deg, #FFC0EA 0%, #ED5EDE 100%)",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    proMivi: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#B2059E',
        backgroundImage: "linear-gradient(135deg, #FFC0EA, #ED5EDE )",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    elite: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#3044A9',
        backgroundImage: "linear-gradient(180deg, #C6EAFF 0%, #8186FF 100%)",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    eliteMivi: {
        height: '36px',
        width: '64px',
        borderRadius: '41px',
        padding: '8px 10px',
        font: '14px Averta',
        fontWeight: `${700}`,
        color: '#3044A9',
        backgroundImage: "linear-gradient(135deg, #8186FF, #C6EAFF )",
        alignItems: 'center',
        textAlign: "center",
        alignContent: 'center',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`,
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
            padding: '6px 8px',
            height: '30px',
            width: '50px',
        }
    },
    greyButton: {
        height: '70px',
        background: '#CACACA',
        border: '1px solid #CACACA',
        color: '#2D3748',
        font: '14px Averta',
        fontWeight: `${700}`,
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center',
        "&:hover": {
            color: '#fff',
            background: '#0C75EB',
            border: '1px solid #0C75EB',
            cursor: 'pointer'
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta',
            fontWeight: `${700}`,
        }
    },
    paddingLeft: {
        paddingLeft: '120px',
        marginTop: '15px',
        [useTheme().breakpoints.down('lg')]: {
            paddingLeft: '20px',
        }
    },
    blueText: {
        font: '16px Averta', color: '#0C75EB', fontWeight: `${600}`
    },
    mail: {
        font: "16px Averta !important", color: "#525252 !important", fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: "14px Averta !important", fontWeight: `${600} !important`
        }
    },
    Active: {
        font: '16px Averta !important',
        fontWeight: `${600} !important`,
        borderRadius: '8px 0px 0px 8px',
        height: '35px',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '8px 12px',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${600} !important`,
        },
    },
    InActive: {
        font: '16px Averta !important',
        fontWeight: `${600} !important`,
        borderRadius: '0px 8px 8px 0px',
        height: '35px',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        padding: '8px 12px',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${600} !important`,
        }
    },
    blueButton: {
        height: '70px',
        background: `${blue}`,
        border: '1px solid #CACACA',
        color: `#fff`,
        font: '14px Averta',
        fontWeight: `${700}`,
        textAlign: 'center',
        alignItems: 'center',
        alignContent: 'center',
        cursor: 'pointer',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta',
            fontWeight: `${700}`,
        }
    },
}))

export default AITimesheetStyles