import { makeStyles } from "@mui/styles";

const DocumentStyles = makeStyles(() => ({
    optional: {
        font: '14px Averta !important',
        color: 'rgb(194 188 188) !important',
        marginLeft: '4px !important'
    },
    ViewContainer: {
        height: '45vh',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
    },
    dropzoneMainBox: {
        width: '100%',
        height: "31vh",
    },
    dropBox: {
        width: '100%',
        height: "31vh",
        display: 'inline-block',
        backgroundColor: "#F2F7FF",
        border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
    },
    dropBox1: {
        width: '100%',
        height: "31vh",
        display: 'inline-block',
        // backgroundColor: "#F2F7FF",
        // border: 'dashed 2px #0C75EB',
        borderRadius: "8px",
    },
    dropZone: {
        background: '#F0F7FF',
        border: `dashed 2px #0C75EB`,
        padding: '20px',
        borderRadius: '12px'
    },
    dropZoneDisable: {
        background: '#ECECEC',
        border: `dashed 2px #525252`,
        padding: '20px',
        borderRadius: '12px'
    },
    dropzoneText: {
        font: "16px Averta !important",
        fontWeight: "600 !important",
        color: '#171717 !important'
    },
}))

export default DocumentStyles;