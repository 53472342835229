import React, { useState } from 'react'
import FileInput from '../../../../../components/muiFileInput/FileInput';
import { Box, Grid } from '@mui/material';
import Input from '../../../../../components/input/Input'; // eslint-disable-next-line
import Date from '../../../../../components/datePicker/Date';
import Button from '../../../../../components/customButton/Button';
import Text from '../../../../../components/customText/Text'; // eslint-disable-next-line
import { dateFormat } from '../../../../../utils/utils'; // eslint-disable-next-line
import moment from 'moment';
import ReusableClearAll from '../../../../../components/reuablePopup/ReusableClearAll';
import ReusableDelete from '../../../../../components/reuablePopup/ReusableDelete';
import LoadingButton from '../../../../../components/customButton/LoadingButton';

function SsnForm(props) { // eslint-disable-next-line
    const {personalDocLoader, state, changeHandler, dateChange, edit, error, handleSubmit, setState, uploadDocs, handleClear, inviteData, handleCancelSsn } = props;

    const cancel = () => {
        handleCancelSsn();
    }

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const deleteDoc = (args) => {
        if (state.id !== '' && state.documents[args].id != "") {
            state['documents_deleted_ids'] = [state.documents[args].id];
        }
        if (inviteData != null) {
            state.documents[args].slug = '';
            state.documents[args].document_slug = '';
        }
        state.documents[args].new_document_id = ''
        state.documents[args].document_name = ''
        state.documents[args].document_url = ''
        state.documents[args].id = '';
        setState({ ...state });
        setDeleteOpen(false);
        error['document_number'] = "";
    }

    const clearAll = () => {
        handleClear();
        setOpen(false);
    }

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} spacing={2} columnSpacing={3} pt={2}>
            {
                state.documents.map((item, index) => (
                    <Grid item lg={12} md={12} sm={12} xs={12} pb={1}>
                        <Box pt={'9px'}>
                            <FileInput
                                name='new_document_id'
                                handleChange={(e) => uploadDocs(e, index, 'ssn')}
                                FileName={item.document_name ? item.document_name : ''}
                                actionState={item.document_name ? 1 : ''}
                                handleDelete={() => setDeleteOpen(true)}
                                label={`SSN Document ${item.document_name ? "" : " Size 25MB, Format - PDF,  IMG (Jpeg, PNG)"}`}
                                isDisabled={edit}
                                disabled={edit}
                                // error={docError[index] ? docError[index].new_document_id : ''}
                                documentUrl={item.document_url}
                            />
                        </Box>
                    </Grid>
                ))
            }

            <Grid item lg={6} md={6} sm={6} xs={12}>
                <Input
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        name: 'document_number',
                        value: state.document_number,
                        inputProps: { maxLength: 11 }
                    }}
                    disabled={edit}
                    handleChange={changeHandler}
                    clientInput
                    error={error.document_number}
                    labelText={`SSN Number`}
                />
                <Text red>{error.document_number ? error.document_number : ''}</Text>
            </Grid>
            <Grid item lg={4}></Grid>
            {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box pt={'9px'}>
                    <Date
                        labelText={`Date Of Issue`}
                        name='valid_from'
                        maxDate={moment().format(dateFormat())}
                        value={state.valid_from}
                        onChange={(value) => dateChange(value, 'valid_from')}
                        height='56px'
                        disabled={edit}
                    // error={error.valid_from}
                    />
                </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box pt={'9px'}>
                    <Date
                        labelText={`Date Of Expiry`}
                        name='valid_till'
                        value={state.valid_till}
                        minDate={state.valid_from}
                        onChange={(value) => dateChange(value, 'valid_till')}
                        height='56px'
                        disabled={edit}
                    // error={error.valid_till}
                    />
                </Box>
            </Grid> */}
            {
                !edit &&
                <Grid item container m={'25px 0px 10px 0px'}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button blackCancel onClick={cancel}>Cancel</Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
                        <Button onClick={() => { setOpen(true) }} clearall sx={{ marginRight: '10px' }}>Clear all</Button>
                        <LoadingButton loading={personalDocLoader} brownMnSave onClick={() => handleSubmit()}>Save</LoadingButton>
                    </Grid>
                </Grid>
            }
            {open && <ReusableClearAll open={open} setOpen={setOpen} onClick={() => { clearAll() }} />}
            {deleteOpen && <ReusableDelete open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deleteDoc(0) }} />}
        </Grid >
    )
}

export default SsnForm