import React, { useEffect, useState } from 'react'
import { Box, Typography, Tab, Tabs, Dialog, Paper, Grid } from '@mui/material'
import MainStyles from './MainStyles'
import { styled } from "@mui/styles";
import LeftNavigateArrow from '../../../assets/svg/LeftNavigateArrow.svg';
import Text from '../../../components/customText/Text';
import Employee from './employeeConfig/EmployeeConfig';
import Placement from './placementConfig/PlacementConfig';
import TimeSheet from './timesheetConfig/TimeSheetConfig';
import Invoice from './invoiceConfig/InvoiceConfig';
import EmployeeSelfService from './employeeSelfServiceConfig/EmployeeSelfServiceConfig';
import Clients from './clientConfig/ClientConfig';
import ExpenseManagement from './expenseConfig/ExpenseConfig';
import Templates from './templatesConfig/TemplatesConfig';
import Remainders from './remindersConfig/RemindersConfig';
import Group from './groupsConfig/GroupsConfig';
import Notifications from './notificationConfig/NotificationConfig';
import Organization from './organization/OrganizationConfig'
import GroupsConfig from './roleConfig/RoleConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage';
import Button from '../../../components/customButton/Button';
import { domain } from '../../../config/Domain';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { addLoaderPlanExpired, removeExpiredLoader } from '../../../utils/utils';


const ConfigurationTab = ['Organization', "Role", 'Employee', 'Placement',
  //  'Timesheet',
  // 'Invoices',
  // 'Employee Self Service', 
  // 'Clients',
  'Templates',
  //  'Groups',
  //   'Reminders',
  // 'Notifications',
];


// custom Tabs container
// eslint-disable-next-line
const TabsContainer = styled(Box)({
  borderBottom: '1px solid #C7CCD3',
  display: 'inline-block', // Display as inline-block
  width: 'auto', // Set width to fit content
  // maxWidth:'100/%',
  overflowX: 'auto', // Enable horizontal scrolling if needed
  whiteSpace: 'nowrap', // Prevent line breaks
});

// custom Tabs
const CustomTabs = styled(Tabs)({
  // overflowX: 'auto',
  '& .MuiTabs-indicator': {
    borderBottom: '1px solid #0C75EB',
  },
  // '& .MuiTabs-scrollable': {
  //   overflowX: 'hidden',
  // },
});

// custom Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontFamily: "Averta !important",
  color: "#737373 !important",
  fontSize: "16px !important",
  fontWeight: `${500} !important`,
  padding: "8px 10px !important",
  textTransform: 'none !important',
  // minWidth: 0,
  '&.Mui-selected': {
    color: '#0C75EB !important',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff !important',
  },
  "@media (min-width: 900px) and (max-width: 1400px)": {
    fontSize: "12px !important",
    fontWeight: "500 !important"
  }
}));


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ width: "100%" }}
        // sx={{ 
        //   '@media (max-width: 1000px)': {
        //     width: 'fit-content'
        //   },
        //  }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function ConfigurationPanel(props) {

  const classes = MainStyles();
  const [tab, setTab] = useState(0);
  const [internal, setInternal] = useState(false);
  const [current, setCurrent] = useState("Organization Details");
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const handleChangeTab = (event, newValue) => {
    if (LocalStorage.getUserData().org_config_set == false) {
      setOpen(true)
    }
    else {
      setInternal(false);
      setTab(newValue);
      setOpen(false)
    }
  };// eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState(null);// eslint-disable-next-line
  const location = useLocation();
  const from = location && location.state && location.state.from

  useEffect(() => {
    // console.log(from, "from");
    if (from == 'notification') {
      setTab(2);
      setInternal('Internal Employees');
    }
    if (from == 'placement') {
      setCurrent('Placements')
    }// eslint-disable-next-line
  }, [])

  useEffect(() => {
    getPlanCheck();
        // eslint-disable-next-line
}, [])

const getPlanCheck = () => {
    let data = {
        request_id: LocalStorage.uid(),
        subdomain_name: domain
    }
    DashboardAPI.planCheck(data).then((res) => {
        if (res.data.statusCode === 1003) {
            let data = LocalStorage.getUserData()
            data.plan_name = res.data.plan_name
            data.plan_not_set = res.data.plan_not_set
            LocalStorage.setDateFormat(res.data.date_format)
            LocalStorage.setUserData(data);
            LocalStorage.setPlanCheckLocal(res.data)
            if (res.data.plan_not_set) {
                navigate('/billing', { state: { renew: true } })
            }
            if (res.data.plan_expired) {
                if (location.pathname != '/billing' || location.pathname != '/plan-sucess') {
                    addLoaderPlanExpired()
                }
                else {
                    removeExpiredLoader()
                }
            }
        }
    })
}

  return (
    <Box className={classes.mainBox}  >
      <Box display={"flex"} gap={1} >
        {
          LocalStorage.getUserData().org_config_set ?
            <img onClick={() => { if (LocalStorage.getUserData().org_config_set == false) { setOpen(true) } else { navigate('/dashboard') } }} style={{ cursor: "pointer" }} src={LeftNavigateArrow} alt="LeftNavigateArrow"></img>
            : ''
        }
        <Text sx={{
          fontSize: "22px",
          fontFamily: "Averta",
          fontWeight: "600", color: "#092333",
          "@media (min-width: 900px) and (max-width: 1400px)": {
            fontSize: "18px",
            fontWeight: "600"
          }
        }}>Organization Configuration</Text>
      </Box>
      <Box className={classes.mainPanel}>
        <Box sx={{ borderBottom: '1px', borderColor: 'divider', width: "100%" }}>
          {/* <TabsContainer> */}
          <CustomTabs
            value={tab}
            onChange={handleChangeTab}
            // variant="scrollable"\
            variant='fullWidth'
            // scrollButtons="auto"
            sx={{ borderBottom: '1px solid #C7CCD3' }}
          >
            {
              ConfigurationTab.map((item, key) => (
                <CustomTab key={key} label={item} {...a11yProps(0)} />
              ))
            }
          </CustomTabs>
          {/* </TabsContainer> */}
        </Box>
        <Dialog
          // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
          sx={{
            zIndex: 1200, top: '-155px !important', left: '-980px !important'
          }}
          open={open}
          anchorEl={anchorEl}
          transition
          Backdrop
          PaperProps={{
            style: {
              backdropFilter: "blur(10px)",
            },
          }}
        >

          <Paper>
            <Grid container p={1}>
              <Text popupHead1>Org Config</Text>
            </Grid>
            <Grid container p={1}>
              <Typography sx={{ p: 2 }}>"Set the stage! Configure your <br /> organization's details to ensure <br /> everything runs smoothly."</Typography>
            </Grid>
            <Grid container justifyContent={'flex-end'} p={1}>
              <Button blueBtnSave onClick={() => { setOpen(false) }}>Next</Button>
            </Grid>
          </Paper>
        </Dialog>
        <CustomTabPanel value={tab} index={0} >
          <Organization setLogoUrl={props.setLogoUrl} setCurrent={setCurrent} current={current} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <GroupsConfig setTab={setTab} setInternal={setInternal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2} >
          <Employee internal={internal} setInternal={setInternal} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
          <Placement />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={4}>
          <Templates />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={5}>
          <Notifications />
        </CustomTabPanel>

        <CustomTabPanel value={tab} index={6}>
          <TimeSheet />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={7}>
          <Invoice />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={8}>
          <EmployeeSelfService />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={9}>
          <Clients />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={10}>
          <ExpenseManagement />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={11}>
          <Group />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={12}>
          <Remainders />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={13}>
          <Notifications />
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default ConfigurationPanel