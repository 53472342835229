import { Dialog, DialogContent, DialogTitle, Grid, Slide, Stack } from '@mui/material';
import React from 'react';
import Button from '../../components/customButton/Button';
import { makeStyles } from '@mui/styles';
import Sure from '../../assets/svg/placementPop.svg'
import Text from '../customText/Text';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={"left"} ref={ref} {...props} />;
});

const deleteStyles = makeStyles(() => ({
    paper: {
        position: 'absolute',
        borderRadius: '8px 8px 8px 8px !important',
        background: '#FFFFFF !important',
        width: "400px !important"
    },
}));

function ReusableClearAll(props) {
    const { open, setOpen,onClick } = props;

    const classes = deleteStyles();
    return (
        <Dialog classes={{ paper: classes.paper }} onClose={() => { setOpen(false) }} open={open} TransitionComponent={Transition}>
            <DialogTitle >
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
                <Grid container>
                    <Grid item md={12} sm={12}>
                        <img src={Sure} alt="deleteAlert" />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setOpen(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledAverta onClick={onClick} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default ReusableClearAll