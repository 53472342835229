import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const PurchasePlanStyles = makeStyles(() => ({
    paddingLeft: {
        paddingLeft: '120px',
        [useTheme().breakpoints.down('lg')]: {
            paddingLeft: '20px',
        }
    },
    loginButton: {
        width: '28.8vw !important',
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "16px Averta !important",
        fontWeight: '600',
        
        '&:disabled': {
            opacity: '0.8'
        },
        [useTheme().breakpoints.down("lg")]: {
            font: "16px Averta !important",
            width: "100% !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },
}))

export default PurchasePlanStyles