import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Sidebar from '../layouts/sidebar/Sidebar';
import Navbar from '../layouts/navbar/Navbar';
import { Box, Grid } from "@mui/material";
import LocalStorage from "../utils/LocalStorage";
import { DefaultRolePermissions } from '../layouts/DefaultRoles';
import NotAllowedPage from "../layouts/NotAllowedPage";
import List from './AdminRoute';
import EmployeeList from './EmployeeRoute';
import { makeStyles } from "@mui/styles";
import ReleaseNotes from "../views/releaseNotes";
import Icon from '../assets/svg/aiChatIconGreen.svg'
import { Drawer } from "@mui/material";
import ChatBot from "./ChatBot";
import UserProfile from '../views/admin/employees/userprofile/UserProfile';
import Dashboard from "../views/admin/Dashboard";
import EmployeeDashboard from "../views/employee/Dashboard";
import PlacementView from "../views/admin/placements/viewAndEditPlacement/PlacementView";
import ClientUserProfile from '../views/admin/clients/userProfile/clients/UserProfile';
import VendorUserProfile from '../views/admin/clients/vendor/VendorUserProfile';
import EndClientUserProfile from '../views/admin/clients/userProfile/endClients/UserProfile';
import SidebarEmployee from "../layouts/sidebar/SidebarEmployee";
import EmployeeUserProfile from "../views/employee/profile/EmployeeUserProfile";
import EmployeePendingActions from "../views/employee/EmployeePendingActions";
import PlacementViewEmployee from "../views/employee/placements/PlacementView"
// import LocalStorage from "./utils/LocalStorage";
// import PrivateRoute from "./routes/routes";
// import { MyContext } from "./Context";
// import { useEffect } from "react";
// import Login from "./layouts/login/Login"
// import ForgotPassword from "./layouts/forgotPassword/ForgotPassword"
// import SignUp from "./layouts/signup/SignUp"
// import LandingPage from "./layouts/LandingPage"
// import ValidateOtp from "./layouts/otp/ValidateOtp"
// import InvoiceView from "./layouts/InvoiceView"
// import Changepasswordnotoken from "./layouts/Changepassword-notoken"
// import OrganizationName from "./layouts/organizationName/OrganizationName"
// import DocumentsBrowsePage from "./views/admin/timeSheets/timesheetDocumentsUpload/DocumentsBrowsePage"
// import VerificationForm from "./views/immigration/verificationForm/VerificationForm";
// import Verification from "./views/immigration/verification/Verification";
import cross from "../assets/svg/dashboard/blackCross.svg";
import { LinearProgressZoho, addLoader, addLoaderPlanExpired, removeExpiredLoader, removeLoader } from "../utils/utils";
import { domain } from "../config/Domain";
import DashboardAPI from "../apis/admin/DashboardAPI";
import ConfigurationPanel from "../views/settings/configurations/Main";
import MyProfile from "../views/admin/myProfile/MyProfile";
import closeTag from '../assets/svg/cross-mark-zoho.svg';
import Text from "../components/customText/Text";
import InstalledAppsApi from "../apis/InstalledAppsApi";
import rightArrow from '../assets/timeSheets/white-arrow.svg';
import Button from "../components/customButton/Button";
import emptyPrompts from '../assets/timeSheets/zero-prompts.svg';
import ReusablePopup from "../components/reuablePopup/ReusablePopup";
import OnboardEmployeeInvite from "../views/employeeonBoard/OnboardEmployeeInvite";
import OnboardEmployeeUpload from "../views/employeeonBoard/OnboardEmployeeUpload";
import { CheckBilling } from "../layouts/signUp/CheckBilling";
import AppIntegrations from '../views/admin/appIntegrations/AppIntegrations';
// eslint-disable-next-line
var drawerWidth = 260;
const useStyles = makeStyles(() => ({
    box: {
        // display: "flex",
        width: '100% !important',
    },
    mainBox: {
        // flexGrow: 1,
        width: '100% !important',
        paddingLeft: '20px !important'
        //width: { sm: `calc(100% - ${drawerWidth}px)` },
    },
    chatInput: {
        width: '100%',
        border: 'none',
        font: '16px Averta',
        fontWeight: `${400}`,
        color: '#4F4F4F',
        paddingLeft: '15px'
    },
    miviDrawer: {
        "&.MuiDrawer-paper": {
            overflow: "visible",
            overflowY: "visible"
        }
    },
    progressContainer: {
        border: '1px solid #C7CCD3', borderRadius: '8px', padding: '10px', position: 'relative', display: 'inline-flex'
    },
    closeIcon: {
        textAlign: 'center', alignItems: 'center', position: 'absolute', top: '-10%', left: '98%', cursor: 'pointer'
    }
}));

export default function PrivateRoutes() {

    const [openPopUp, setOpenPopup] = useState(false);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [planDetails, setplanDetails] = useState({
        prompt_access_available_credits: ''
    });
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : DefaultRolePermissions.role_permissions.permissions;

    const classes = useStyles();
    const navigate = useNavigate();
    const [formEdit, setformEdit] = useState(false);
    let url = LocalStorage.getUserData().logo_url
    let profile_url_local = LocalStorage.getUserData().profile_picture_url
    const [logoUrl, setLogoUrl] = useState(url ? url : '');
    const [profile_url, setprofile_url] = useState(profile_url_local ? profile_url_local : '');
    const [countData, setCountData] = useState({
        migrated_count: 0,
        total_count: 0,
        show_progress: false
    })

    const countApi = () => {
        InstalledAppsApi.getCountList().then((res) => {
            if (res.data.statusCode == 1003) {
                // LocalStorage.showProgress(res.data.data.show_progress);
                setCountData(res.data.data);
            }
        })
    }

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setplanDetails(res.data);
                LocalStorage.setPlanCheckLocal(res.data)
                LocalStorage.setDateFormat(res.data.date_format);
            }
        })
    }

    useEffect(() => {
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        removeLoader();
        if (location.pathname != "/billing" && location.pathname != '/employees/onboard' && location.pathname != '/plan-sucess') {
            DashboardAPI.planCheck(data).then((res) => {
                if (res.data.statusCode === 1003) {
                    let data = LocalStorage.getUserData()
                    data.plan_name = res.data.plan_name
                    data.plan_not_set = res.data.plan_not_set
                    LocalStorage.setDateFormat(res.data.date_format)
                    LocalStorage.setUserData(data);
                    setplanDetails(res.data);
                    LocalStorage.setPlanCheckLocal(res.data)
                    if (res.data.plan_not_set) {
                        navigate('/billing', { state: { renew: true } })
                    }
                    if (res.data.plan_expired) {
                        if (location.pathname != '/billing' || location.pathname != '/plan-sucess') {
                            addLoaderPlanExpired()
                        }
                        else {
                            removeExpiredLoader()
                        }
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        LocalStorage.showProgress(true);
        countApi();
    }, [])

    useEffect(() => {
        if (countData.show_progress) {
            setInterval(() => {
                countApi();
            }, 400000)
        }
    },)

    function percentage(percent, total) {
        let value = ((percent / total) * 100)
        return value ? value.toFixed(2) : 0
    }

    const creditsHandler = () => {
        if (planDetails.prompt_access_available_credits > 0) {
            navigate('/ai_timesheet', { state: { stage: 'mivi' } });
            setOpenPopup(false)
        } else {
            setOpen(true);
        }
    }

    const getChecktheUrl = (args) => {
        let path = location.pathname
        let url = path.includes(args)
        return url
    }

    return (
        <div>
            {LocalStorage.getAccessToken() ? (
                LocalStorage.getUserData().admin_login == false ?
                    <Box className={classes.box}>
                        {location.pathname != '/404' && location.pathname !== "/release-notes" && getChecktheUrl("/onboard-invite-link") == false && getChecktheUrl("/onboard-employee-upload") == false ? <Navbar logoUrl={logoUrl} profile_url={profile_url} setprofile_url={setprofile_url} /> : null}
                        {
                            getChecktheUrl("/onboard-invite-link") ? null : getChecktheUrl("/onboard-employee-upload") ? null : location.pathname == "/release-notes" ? null : location.pathname == "/ocr/bulk-upload-timesheets" ? null : location.pathname == "/employee/verify" ? null : <SidebarEmployee logoUrl={logoUrl} formEdit={formEdit} setformEdit={setformEdit} />
                        }
                        <Box component="main" className={classes.mainBox}>
                            <Routes>
                                <Route path="*" element={<EmployeeDashboard />} />
                                <Route path="/" element={<EmployeeDashboard />} />
                                <Route path="/*" element={<EmployeeDashboard />} />
                                {EmployeeList.map((route, key) => (
                                    <>
                                        {route.slug != null && route.access == true ?
                                            <>
                                                {LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == route.slug && item.is_allowed)) ?
                                                    <Route key={key} path={route.path} element={route.element} /> :
                                                    <Route key={key} path={route.path} element={route.element} />
                                                }
                                                {/* <Route path={route.path} element={<NotAllowedPage />} />} */}
                                            </>
                                            :
                                            <Route key={key} path={route.path} element={route.element} />}
                                    </>
                                ))}
                                <Route path="/404" element={<NotAllowedPage />} />
                                <Route path="/employees/user-profile/*" element={<EmployeeUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/user-profile" element={<EmployeeUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/pending-actions" element={<EmployeePendingActions formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/clients/end-clients-user-profile" element={<EndClientUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/vendor/user-profile" element={<VendorUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/placements/view-placement" element={<PlacementViewEmployee formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/release-notes" element={<ReleaseNotes />} />
                                <Route path="/onboard-invite-link/:id" element={<OnboardEmployeeInvite />} />
                                <Route path="/onboard-employee-upload/:id" element={<OnboardEmployeeUpload />} />
                                <Route path="/check-billing" element={<CheckBilling />} />
                            </Routes>
                        </Box>
                    </Box>
                    :
                    <Box className={classes.box}>
                        {location.pathname != '/404' && location.pathname !== "/release-notes" && location.pathname != "/billing" && location.pathname != '/plan-purchase' && location.pathname != '/plan-upgrade' && location.pathname != '/mivi-upgrade' && location.pathname != '/plan-sucess' && location.pathname != '/ai_timesheet' && getChecktheUrl("/onboard-invite-link") == false && getChecktheUrl("/onboard-employee-upload") == false ? <Navbar logoUrl={logoUrl} profile_url={profile_url} setprofile_url={setprofile_url} planDetails={planDetails} /> : null}
                        {
                            getChecktheUrl("/onboard-invite-link") ? null : getChecktheUrl("/onboard-employee-upload") ? null : location.pathname == '/plan-purchase' ? null : location.pathname == '/plan-sucess' ? null : location.pathname == '/plan-upgrade' ? null : location.pathname == "/billing" ? null : location.pathname == "/release-notes" ? null : location.pathname == "/ocr/bulk-upload-timesheets" ? null : location.pathname == "/employee/verify" ? null : location.pathname == '/mivi-upgrade' ? null : location.pathname == '/ai_timesheet' ? null : <Sidebar logoUrl={logoUrl} formEdit={formEdit} setformEdit={setformEdit} />
                        }
                        <Box className={classes.mainBox}>
                            <Routes>
                                <Route path="*" element={<Dashboard />} />
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/*" element={<Dashboard />} />
                                {List.map((route, key) => (
                                    <>
                                        {route.slug != null && route.access == true ?
                                            <>
                                                {LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == route.slug && item.is_allowed)) ?
                                                    <Route key={key} path={route.path} element={route.element} /> :
                                                    <Route key={key} path={route.path} element={route.element} />
                                                }
                                                {/* <Route path={route.path} element={<NotAllowedPage />} />} */}
                                            </>
                                            :
                                            <Route key={key} path={route.path} element={route.element} />}
                                    </>
                                ))}
                                <Route path="/404" element={<NotAllowedPage />} />
                                <Route path="/myProfile" element={<MyProfile setLogoUrl={setLogoUrl} profile_url={profile_url} setprofile_url={setprofile_url} />} />
                                <Route path="/configuration" element={<ConfigurationPanel setLogoUrl={setLogoUrl} formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/employees/user-profile/:employee" element={<UserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/employees/user-profile/*" element={<UserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/employees/user-profile" element={<UserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/clients/clients-user-profile" element={<ClientUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/clients/end-clients-user-profile" element={<EndClientUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/vendor/user-profile" element={<VendorUserProfile formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/placements/view-placement" element={<PlacementView formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/pending-actions" element={<EmployeePendingActions formEdit={formEdit} setformEdit={setformEdit} />} />
                                <Route path="/release-notes" element={<ReleaseNotes />} />
                                <Route path="/onboard-invite-link/:id" element={<OnboardEmployeeInvite />} />
                                <Route path="/onboard-employee-upload/:id" element={<OnboardEmployeeUpload />} />
                                <Route path="/check-billing" element={<CheckBilling />} />
                                <Route path="/app-integrations" element={<AppIntegrations countData={countData} setCountData={setCountData} />} />

                            </Routes>
                        </Box>
                    </Box>
            ) : navigate("/")}
            {
                ((LocalStorage.getUserData().plan_not_set == false && LocalStorage.getUserData().org_config_set && LocalStorage.getUserData().super_admin) || (rolePermission !== "" && rolePermission.some(item => item.slug == "mivi_chat_bot_view" && item.is_allowed == true))) &&
                location.pathname != "/employee/verify" && location.pathname != "/ocr/bulk-upload-timesheets" && location.pathname != '/billing' && location.pathname != '/plan-sucess' && !openPopUp && LocalStorage.getUserData().org_config_set &&
                <Box sx={{ position: 'fixed', right: -3, bottom: "calc(100vh - 350px)", display: 'flex', zIndex: 1 }}>
                    <img src={Icon} alt="suggestion" onClick={() => { setOpenPopup(true); getPlanCheck() }} style={{ cursor: 'pointer' }} />
                </Box>
            }
            <Drawer
                anchor={"right"}
                open={openPopUp}
                className={classes.miviDrawer}
                onClose={() => { setOpenPopup(false) }}
            >
                <Box display='flex' flexDirection='row' justifyContent='end' alignItems='center'>
                    <Box sx={{ position: 'absolute', right: 40, top: 10, zIndex: 10 }}>
                        <Button CreditsButton onClick={() => { if (planDetails.mivi?.available_credits == 0) { creditsHandler() } }}>
                            <Box display='flex' flexDirection='row' alignItems='center' gap={planDetails.prompt_access_available_credits == 0 ? 0 : 5}>
                                {
                                    planDetails.prompt_access_available_credits == 0 ?
                                        <Box textAlign='center'>
                                            <Text largeWhite20>0 Credits Left</Text>
                                        </Box> :
                                        <Box textAlign='start'>
                                            <Text largeWhite20>{planDetails.mivi?.available_credits}&nbsp;<span style={{ font: '12px Averta', fontWeight: '400' }}>Left</span></Text>
                                            <Text mediumWhite400>AI Prompts</Text>
                                        </Box>
                                }
                                <Box display='flex' flexDirection='row' alignItems='center' textAlign='end'>
                                    <img src={rightArrow} alt="arrow" style={{ padding: '2px 0px 0px 2px' }} />
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                    <Box sx={{ position: 'absolute', right: 10, top: 25, display: 'flex', zIndex: 10000 }}>
                        <img src={cross} alt="suggestion" onClick={() => { setOpenPopup(false) }} style={{ cursor: 'pointer' }} />
                    </Box>
                </Box>

                {/* <Box sx={{ position: 'absolute', right: "950px", bottom: "calc(100vh - 180px)", display: 'flex', zIndex: 10000 }}>
                    <img src={Icon} alt="suggestion" onClick={() => setOpenPopup(true)} style={{ cursor: 'pointer' }} />
                </Box> */}
                <ChatBot openPopUp={openPopUp} setOpenPopup={setOpenPopup} setplanDetails={setplanDetails} />
            </Drawer>
            {
                ((LocalStorage.getUserData().super_admin && countData.show_progress) && location.pathname != '/billing') ?
                    <Grid item container xl={8} lg={8} md={8} sm={8} xs={10} justifyContent='end' sx={{ position: 'fixed', bottom: 35, zIndex: 10, paddingLeft: 4 }}>
                        <Grid item lg={6} md={7} sm={7} xs={7} justifyContent='center' sx={{ background: '#fff', borderRadius: '8px' }}>
                            <Box width='100%' className={classes.progressContainer}>
                                <Box className={classes.closeIcon}>
                                    <img src={closeTag} alt='' onClick={() => {
                                        setCountData({ ...countData, show_progress: false })
                                        LocalStorage.showProgress(false)
                                    }} />
                                </Box>
                                <Box width='100%'>
                                    <Text mediumBoldBlack2>Integration progress</Text>
                                    <Box p={'8px 0px'}>
                                        <LinearProgressZoho variant="determinate" value={percentage(countData.migrated_count, countData.total_count)} />
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={6}>
                                        <Text mediumGreyHeader>Data Remaining {countData.migrated_count} of {countData.total_count}</Text>
                                        <Text mediumBlack14>{percentage(countData.migrated_count, countData.total_count)}%</Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid> : LocalStorage.getProgress() == '' ? '' : ''
            }
            <ReusablePopup openPopup={open} setOpenPopup={setOpen} white iconHide statusWidth>
                <Grid container alignItems='center' textAlign='center' spacing={2}>
                    <Grid item lg={12} xs={12} pb={2}>
                        <img src={emptyPrompts} alt="empty" />
                    </Grid>
                    <Grid item lg={12} xs={12} pb={2}>
                        <Text largeLabel>
                            {
                                LocalStorage.getUserData().super_admin ?
                                    'Your prompt credits are zero. Please upgrade your plan!.' :
                                    'Please reach out to Super Admin to get more MIVI AI credits to continue.'
                            }
                        </Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        {
                            LocalStorage.getUserData().super_admin
                                ?
                                <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                    <Button closeButtonMivi onClick={() => setOpen(false)}>Cancel</Button>
                                    <Button upgradePlan onClick={() => {
                                        navigate('/mivi-upgrade', { state: { stage: 'mivi' } });
                                        setOpenPopup(false);
                                        setOpen(false);
                                    }}>Go to plans</Button>
                                </Box>
                                : <Button upgradePlan onClick={() => setOpen(false)}>Go Back</Button>
                        }
                    </Grid>
                </Grid>
            </ReusablePopup>
        </div>
    );
}
