import { Box, Breadcrumbs, Grid, Skeleton, Typography, Table, TableBody, TableHead, TableRow, TableContainer, Dialog, Slide, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text'
import AppIntegrationsStyles from './AppIntegrationsStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import Component87 from '../../../assets/svg/Component87.svg';
import deleteAlert from "../../../assets/svg/placementPop.svg";
import { NoPermission, addErrorMsg, addLoader, addWarningMsg, removeLoader } from '../../../utils/utils';
import Select from '../../../components/select/Select';
import LocalStorage from '../../../utils/LocalStorage';
import Button from '../../../components/customButton/Button';
import { isValid, validate_emptyField } from '../../../components/Validation';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '../../../assets/svg/newEdit.svg';
import DeleteIcon from '../../../assets/svg/deleteSquare.svg';
// import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
// import { ReactComponent as CheckedDisabled } from '../../../assets/svg/CheckedDisabled1.svg';
// import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import LoadingButton from '../../../components/customButton/LoadingButton';
import ReusableDelete from '../../../components/reuablePopup/ReusableDelete';
// import settings from '../../../assets/svg/settings.svg';
import settings from '../../../assets/svg/zoho-updated.svg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "orange",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#EAF9FF",
    },
    '&:nth-of-type(2n)': {
        backgroundColor: "#CAF0FF", // Background color for even rows
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function SelectedAppConfigure() {


    const classes = AppIntegrationsStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [selected, setSelected] = useState("");
    //const param = new URLSearchParams(window.location.search);
    //const getdomain = param.get('state');
    const [step, setStep] = useState(1);
    const [error, setError] = useState({});
    const [fieldsData, setFieldsData] = useState([]); // eslint-disable-next-line
    const [checkedAll, setCheckedAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteLoad, setDeleteLoad] = useState(false);
    const [finishOpen, setFinshOpen] = useState(false);
    // const getParam = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('location');
    const locationData = key == 'in' ? '.in' : (key == 'com' ? '.com' : (key == 'us' ? '.com' : key));
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    useEffect(() => {
        setTimeout(() => {
            if (location.state != null && location.state != undefined && location.state.id != "") {
                getAppData(location.state.id);
                setLoading(true);
                getOrganizations({ authorization_code: "", status: "not_registered", domain: locationData })
            }
            else {
                // Parse the URL and extract the value of the 'code' parameter
                const params = new URLSearchParams(window.location.search);
                const code = params.get('code');
                const error = params.get('error');
                if (error) {
                    navigate("/app-integrations");
                }
                if (code) {
                    if (LocalStorage.getAppIntegrationId()) {
                        getAppData(LocalStorage.getAppIntegrationId());
                    }
                    var data = {
                        authorization_code: code,
                        status: "not_registered",
                        domain: locationData
                    }
                    getOrganizations(data);
                    // Make an API call, etc.
                }
            }
            getConnectedOrganizations()
        }, 1000)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        let initialURL = window.location.href
        let divideURL2 = initialURL.split('/app-integrations/selected-app/configure?app=japfu&')[1]
        if (divideURL2 != undefined) {
            const url = new URL(window.location.href);
            const searchParams3 = new URLSearchParams(url.search);
            const newDomain = searchParams3.get('state');
            const generateHost = [`${newDomain}/app-integrations/selected-app/configure?`]
            const combineURLS = generateHost.concat(divideURL2)
            let finalURL = `${combineURLS[0]}${combineURLS[1]}`
            if (initialURL != finalURL) {
                window.location.replace(finalURL)
            }
        }
        // decodeURIComponent('')
    }, [])

    const getOrganizations = (param) => {
        setLoading(true);
        InstalledAppsApi.getZohoOrganizationList(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setOrgList(res.data.data);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const [connectedList, setConnectedList] = useState([])

    const getConnectedOrganizations = () => {
        InstalledAppsApi.getZohoOrganizationList({ authorization_code: "", status: "registered" }).then((res) => {
            if (res.data.statusCode === 1003) {
                setConnectedList(res.data.data);
            }
        })
    }

    const getAppData = (param) => {
        setLoading(true);
        InstalledAppsApi.getSingleApp(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setData(res.data.data[0]);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const changeHandler = (e) => {
        setSelected(e.target.value);
        handleValidate(e)
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "selected":
                error.selected = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const handleContinue = () => {
        let errors = validateAll();
        if (isValid(errors)) {
            getOrganizationFields(selected);
        } else {
            let s1 = { error }
            s1 = errors
            setError(s1);
            addWarningMsg('Invalid results, please select organization to proceed further.');
        }
    }

    const getOrganizationFields = (param) => {
        setLoading(true);
        InstalledAppsApi.getOrganizationFields(param).then((res) => {
            setLoading(false);
            removeLoader();
            if (res.data.statusCode === 1003) {
                const allChecked = res.data.data.every(item => item.checked === true);
                if (allChecked) {
                    setCheckedAll(true);
                } else {
                    setCheckedAll(false);
                }
                setFieldsData(res.data.data);
                setStep(2);
            } else {
                addErrorMsg(res.data.message)
            }
        })
    }

    const handleEdit = (param) => {
        addLoader(true);
        getOrganizationFields(param);
        setSelected(param);
    }
    const validateAll = () => {
        let errors = {};
        errors.selected = validate_emptyField(selected);
        setError(errors);
        return errors;
    }

    const handleReturnOrg = () => {
        const selectedOrg = orgList.find(org => org.id === selected);
        return selectedOrg ? selectedOrg.value : null;
    }
    // eslint-disable-next-line
    const handleChangeCheckBox = (e, index) => {
        fieldsData[index].checked = !fieldsData[index].checked;
        setFieldsData([...fieldsData]);

        const filterData = fieldsData.some((item) => item.checked != true);
        if (filterData) {
            setCheckedAll(false);
        } else {
            setCheckedAll(true);
        }
    }

    // const handelCheckAll = (e) => {
    //     setCheckedAll(e.target.checked);
    //     for (var i in fieldsData) {
    //         if (!fieldsData[i].disabled) {
    //             fieldsData[i].checked = e.target.checked
    //         }
    //     }
    //     setFieldsData([...fieldsData])
    // }

    // eslint-disable-next-line
    const handleSave = () => {
        const filterData = fieldsData.some((item) => item.checked === true);
        if (filterData) {
            addLoader(true);
            var data = {
                organization_id: selected,
                request_id: LocalStorage.uid(),
                finish: false,
                fields_list: fieldsData,
            }
            InstalledAppsApi.storeOrganizationFields(data).then((res) => {
                removeLoader();
                if (res.data.statusCode === 1003) {
                    getOrganizations({ authorization_code: "", status: "not_registered" });
                    getConnectedOrganizations();
                    setStep(1);
                    setSelected("");
                    setCheckedAll(false);
                } else {
                    addErrorMsg(res.data.message)
                }
            })
        } else {
            addErrorMsg("Invalid response.please select any checkbox to continue...");
        }
    }

    const [finishLoad, setFinishLoad] = useState(false);

    const handleFinish = () => {
        setFinishLoad(true);
        if (step == 1) {
            var data = {
                // organization_id: selected,
                request_id: LocalStorage.uid(),
                finish: true,
                // fields_list: fieldsData,
            }
        } else { // eslint-disable-next-line
            var data = {
                organization_id: selected,
                request_id: LocalStorage.uid(),
                finish: true,
                fields_list: fieldsData,
            }
        }
        InstalledAppsApi.storeOrganizationFields(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setFinishLoad(false);
                setOpen(false);
                if (step == 2) {
                    setFinshOpen(true);
                } else {
                    navigate("/app-integrations");
                }
            } else {
                setFinishLoad(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const [deleteId, setDeleteId] = useState("")
    const handleDeleteOpen = (item) => {
        setDeleteId(item);
        setDeleteOpen(true);
    }

    const deRegister = () => {
        setDeleteLoad(true);
        var data = {
            request_id: LocalStorage.uid(),
            organization_ids: [deleteId],
            // de_register:true,
        }
        InstalledAppsApi.deregisterOrganization(data).then((res) => {
            setDeleteLoad(false);
            if (res.data.statusCode === 1003) {
                setDeleteId("");
                setDeleteOpen(false);
                getConnectedOrganizations();
                getOrganizations({ authorization_code: "", status: "not_registered" });
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }


    return (
        <Box className={classes.mainBox1}>
            {step == 1 ?
                <Box mx={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>All Apps</Typography>
                        <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Configure</Text>
                    </Breadcrumbs>
                </Box> :
                <Box mx={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                        <Typography onClick={() => { navigate("/app-integrations") }} className={classes.breadcrumbsLink}>App Integrations</Typography>
                        <Typography onClick={() => { navigate("/app-integrations", { state: { from: 'Installed' } }) }} className={classes.breadcrumbsLink}>Installed Apps</Typography>
                        <Typography onClick={() => { navigate("/app-integrations/selected-app") }} className={classes.breadcrumbsLink}>Select Organization</Typography>
                        <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Edit</Text>
                    </Breadcrumbs>
                </Box>
            }
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true))) ?
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <Box className={classes.logoBox}>
                                        <img src={data.logo_url ? data.logo_url : ''} alt="logo" height={"75px"} width={"198px"} />
                                        <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                        <Box >
                                            <Text mediumOverView pt="5px">Connected</Text>
                                            {step == 2 && <Text mediumOverView sx={{ font: "18px Averta !important", fontWeight: `${600} !important`, }}>{handleReturnOrg()}</Text>}
                                        </Box>
                                    </Box>
                            }
                        </Grid>
                        <Grid container alignItems={"center"} justifyContent={"end"} item lg={6} md={6} sm={6} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={70} width={300} />
                                    :
                                    <a rel="noopener noreferrer" style={{ color: "#0C75EB" }} href={data && data.help_documentation_url && data.help_documentation_url} target="_blank" className={classes.helpText}>Help Documentation</a>
                            }
                        </Grid>
                        {step == 1 && <Grid item lg={2.5} md={3} sm={4} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={45} width={250} />
                                    :
                                    <Select
                                        name='selected'
                                        value={selected}
                                        onChange={(e) => { changeHandler(e) }}
                                        options={orgList}
                                        label={'Select Organization'}
                                        helperText={error.selected ? <Text red>{error.selected}</Text> : ""}
                                    />
                            }
                        </Grid>}
                        {step == 1 && <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                            {
                                loading ?
                                    <Skeleton variant="rounded" height={30} width={500} />
                                    :
                                    <Text mediumOverView sx={{ font: "15px Averta !important" }}>Select the organization you wish to configure</Text>

                            }    </Grid>}

                        {step == 1 &&
                            <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                {
                                    <>
                                        {
                                            (location && location.state && location.state.from == 'state') && connectedList.length > 0 ?
                                                <>
                                                    {
                                                        connectedList.map((item) => (
                                                            <Box sx={{ width: "100%", height: "74px !important", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", display: "flex", alignItems: "center" }} mt={2}>
                                                                <Grid container item lg={12} md={12} alignItems='center' padding={"0px 40px"}>
                                                                    <Grid item lg={9} md={9} sm={9} xs={7}>
                                                                        <Text mediumBoldBlack>{item.value}</Text>
                                                                        <Text greyLabel pt={"5px"}>Configured on: {item.configured_date}</Text>
                                                                    </Grid>
                                                                    <Grid item lg={3} md={3} sm={3} xs={5} textAlign={"end"}>
                                                                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                                                                            <img onClick={() => { handleEdit(item.id) }} src={EditIcon} alt={"icon"} style={{ marginRight: "10px", cursor: "pointer" }} />
                                                                            <img onClick={() => { handleDeleteOpen(item.id) }} src={DeleteIcon} alt="DeleteIcon" style={{ cursor: "pointer" }} />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box >
                                                        ))
                                                    }
                                                </> : ""
                                        }
                                    </>
                                }
                            </Grid>
                        }
                        {step == 1 &&
                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                <Box className={classes.continueBox}>
                                    {
                                        loading ?
                                            <Skeleton variant="rounded" height={40} width={150} />
                                            :
                                            <>
                                                {orgList.length > 0 && connectedList.length == 0 && <Button onClick={() => { handleContinue() }} saveBtn className={classes.configBtn} >Continue</Button>}
                                                {connectedList.length > 0 &&
                                                    <>
                                                        {/* <Button onClick={() => { handleContinue() }} cancelOutlineBlue className={classes.configBtn} sx={{ marginRight: "10px !important" }}>Continue</Button> */}
                                                        <LoadingButton onClick={() => { handleFinish() }} saveBtn className={classes.configBtn} loading={finishLoad} >Finish</LoadingButton>
                                                    </>
                                                }
                                            </>
                                    }
                                </Box>
                            </Grid>
                        }
                        {step == 2 &&
                            <Grid container >
                                <Grid item lg={12} md={12} sm={12} xs={12}>

                                    <Box sx={{ height: "60vh", overflow: "auto" }}>
                                        {
                                            fieldsData.length > 0 ?
                                                <Box sx={{ marginTop: "20px" }}>
                                                    <TableContainer >
                                                        <Table size="small" sx={{ borderRadius: "8px !important" }}>

                                                            <TableHead>
                                                                <TableRow sx={{ height: '47px' }} key={1}>
                                                                    <TableCell sx={{ width: '50px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: "#ffffff", borderTopLeftRadius: "8px" }}>
                                                                        <Text mediumBlack>Sno</Text>
                                                                    </TableCell>
                                                                    {['Module in Zoho', 'Fields in Zoho Books', 'Field Type', 'Module in Japfu', 'Fields in Japfu'].map((header) => (
                                                                        <TableCell sx={{ borderBottom: " 1px solid #B0B0B0", textAlign: "center", backgroundColor: "#ffffff", }} key={header}>
                                                                            <Text mediumBlack >{header}</Text>
                                                                        </TableCell>
                                                                    ))}
                                                                    {/* <TableCell sx={{ width: '140px', textAlign: "center", borderBottom: " 1px solid #B0B0B0", background: "#ffffff", borderTopRightRadius: "8px" }}>
                                                                        <FormControlLabel
                                                                            key={1}
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    name={"checkedAll"}
                                                                                    value={checkedAll}
                                                                                    onChange={(e) => { handelCheckAll(e) }}
                                                                                    icon={<CheckBorderIcon />}
                                                                                    checkedIcon={<CheckedIcon />}
                                                                                    checked={checkedAll} />}
                                                                            label={<Text mediumBlack>Merge</Text>}
                                                                        />
                                                                    </TableCell> */}
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody style={{ borderRadius: '8px' }}>
                                                                {fieldsData.map((item, index) => (
                                                                    <StyledTableRow sx={{ height: '47px' }} key={index}>
                                                                        <StyledTableCell sx={{ padding: 0, textAlign: "center", borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : "1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{index + 1}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0", padding: 0, textAlign: "center", }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{item.module_in_zoho}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{item.fields_in_zoho}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{item.field_type}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0", padding: 0, textAlign: "center", }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{item.module_in_japfu}</Text>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell sx={{ borderLeft: " 1px solid #B0B0B0", borderRight: " 1px solid #B0B0B0", padding: 0, textAlign: "center", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>
                                                                            <Text mediumGrey2 sx={{ font: "12px Averta !important" }}>{item.fields_in_japfu}</Text>
                                                                        </StyledTableCell>
                                                                        {/* <StyledTableCell sx={{ padding: 0, textAlign: "center", borderRight: " 1px solid #B0B0B0", borderBottom: (index == fieldsData.length - 1) ? "" : " 1px solid #B0B0B0" }}>

                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"placement_ids"}
                                                                                        value={item.checked}
                                                                                        disabled={item.disabled}
                                                                                        onChange={(e) => { handleChangeCheckBox(e, index) }}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={item.disabled ? <CheckedDisabled /> : <CheckedIcon />}
                                                                                        checked={item.checked} />}
                                                                                label={""}
                                                                            />
                                                                        </StyledTableCell> */}
                                                                    </StyledTableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                                :
                                                ""
                                        }
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12} pt={1}>
                                    <Button onClick={() => { setStep(1); setSelected("") }} cancelOutlineBlue className={classes.configBackBtn}>Back</Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12} pt={1} textAlign={"end"}>
                                    {/* <Button onClick={() => { handleSave() }} cancelOutlineBlue className={classes.configSaveBtn}>Save and Add more configuration</Button> */}
                                    <LoadingButton saveBtn loading={finishLoad} className={classes.configSave} onClick={() => { handleFinish() }}>Finish</LoadingButton>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                    :
                    <>
                        {NoPermission()}
                    </>
            }

            {deleteOpen && <ReusableDelete loading={deleteLoad} open={deleteOpen} setOpen={setDeleteOpen} onClick={() => { deRegister() }} />}

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
            // maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "25px" }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={deleteAlert} alt='deleteAlert' />
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text popupHead1>Are you sure?</Text>
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text offBoardBodyFont>Do you want to deregister the organization from the application?</Text>
                        </Grid>
                        <Grid container item md={12} sm={12} justifyContent={"center"} columnGap={2} pt={5}>
                            <Button cancelOutlineBlue onClick={() => { setOpen(false) }} className={classes.popupButtons}>Cancel</Button>
                            <LoadingButton onClick={() => { handleFinish() }} saveBtn className={classes.popupButtons} loading={finishLoad} >Deregister</LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="customized-dialog-title"
                open={finishOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogContent sx={{ margin: "50px" }}>
                    <Grid container>
                        <Grid item md={12} sm={12} textAlign={"center"}>
                            <img src={settings} alt='settings' />
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text popupHead1>Integration Initiated</Text>
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={2}>
                            <Text offBoardBodyFont>Integration process has started. Please wait until the process is complete.</Text>
                        </Grid>
                        <Grid item md={12} sm={12} textAlign={"center"} pt={3}>
                            <Button saveBtn onClick={() => { setFinshOpen(false); navigate('/app-integrations') }} className={classes.doneBtn}>Done</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog >
        </Box >
    )
}

export default SelectedAppConfigure