import React, { useEffect, useState } from "react";
import TimeSheetDashboardStyles from "./TimeSheetDashboardStyles";
import Text from '../../../../components/customText/Text';
import { useNavigate, useLocation } from 'react-router-dom';
// import Avatarsvg from '../../../../assets/svg/avatar.svg';
import TimesheetApi from "../../../../apis/admin/timesheets/TimesheetApi";
import { BlackToolTip, addErrorMsg, capitalizeAndAddSpace, dateFormat, openDocumentInNewtab } from '../../../../utils/utils';
import roundpurple from '../../../../assets/svg/roundpurple.svg';
import roundblue from '../../../../assets/svg/roundblue.svg';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Skeleton from '@mui/material/Skeleton';
import CustomCalendars from "../../../../components/customCalendar/CustomCalendars" // eslint-disable-next-line
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';
import makeStyles from '@mui/styles/makeStyles';
import {
    Grid, Typography, Breadcrumbs, Box,
    Autocomplete,
    TextField,
    Chip
} from "@mui/material";
import CircularProgress from "../../../../components/progressbar/CircularProgress";
import Verified from '../../../../assets/svg/Component87.svg';
import Select from "../../../../components/select/Select";
import moment from "moment";
// import Input from "../../../../components/input/Input";
// import Date from "../../../../components/datePicker/Date";
import LocalStorage from "../../../../utils/LocalStorage";

// Extend dayjs with the isoWeek plugin to handle weeks correctly
dayjs.extend(isoWeek);
// eslint-disable-next-line
const useStyles = makeStyles({
    globalSearchInput: {
        border: "none !important",
        padding: "0px 0px 0px 10px !important",
        height: "100% !important",
        background: "transparent !important",
        color: "rgba(38, 38, 38, 1) !important",
        fontFamily: "Averta !important",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        transition: "all .3s ease !important",
        '&::-webkit-input-placeholder': {
            color: "rgba(199, 204, 211, 1) !important",
        },
        '&:focus': {
            outline: "none !important"
        }
    },
    endAdornment: {
        position: 'inherit !important',
    },
    option: {
        '&[data-focus="true"]': {
            backgroundColor: 'transparent',
        },
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
    },
    inputRoot: {
        height: "40px",
        border: "1.5px solid rgba(199, 204, 211, 1)",
        borderRadius: "6px",
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        padding: '0px 0px 5px 5px !important',
        fontFamily: "Averta !important",
        fontSize: "14px !important",
    },
    inputYear: {
        padding: '0px 0px 0px 0px !important',
        fontFamily: "Averta !important",
        fontSize: "14px !important",
        color: "#333333 !important",
        fontWeight: "600 !important"
    },
    popupIndicator: {
        display: 'none',
    },
    clearIndicator: {
        display: 'none',
    }
});

export default function Timesheetview() {
    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location, 'location')// eslint-disable-next-line
    const [startDate, setstartDate] = useState(dayjs(location.state.start_date, dateFormat())); // eslint-disable-next-line
    const [endDate, setendDate] = useState(dayjs(location.state.end_date));
    // console.log(startDate, 'value value value')
    const classes = TimeSheetDashboardStyles();// eslint-disable-next-line
    const [loading, setLoading] = useState(false);// eslint-disable-next-line
    const [drop, setDrop] = useState(1);// eslint-disable-next-line
    const [clientName, setclientName] = useState('');
    const [cardData, setcardData] = useState({
        client: '',
        documents: []
    });// eslint-disable-next-line
    const [employeeIDSelected, setEmployeeIDSelected] = useState(null);// eslint-disable-next-line
    const [placementID, setPlacementID] = useState(null);
    const [calendarDetails, setCalendarDetails] = useState([]);
    const [filterData, setFilterData] = useState({
        month: moment(moment(location.state.start_date).format(dateFormat())).month(),
        year: moment(moment(location.state.start_date).format(dateFormat())).year(),
        start_date: location.state.start_date,
        end_date: location.state.end_date
    })// eslint-disable-next-line
    const [weekStartDate, setweekStartDate] = useState('');// eslint-disable-next-line
    const [weekEndDate, setweekEndDate] = useState('');
    useEffect(() => {
        getCalendraView()
        let valueDate = dayjs(location.state.start_date, dateFormat())
        const startOfMonth = valueDate.startOf('month').startOf('isoWeek');
        const endOfMonth = valueDate.endOf('month').endOf('isoWeek');
        if (location.state.weekly) {
            // Generate the days for the calendar view
            let days = [
                // {}
            ];
            let day_of = startOfMonth;
            while (day_of.isBefore(endOfMonth)) {
                days.push(day_of);
                // days.push({});
                day_of = day_of.add(1, 'day');
            }
            let numStart = location.state.weekStart == 8 ? location.state.weekStart - 1 : location.state.weekStart == 16 ? location.state.weekStart - 2 : location.state.weekStart == 24 ? location.state.weekStart - 3 : location.state.weekStart == 32 ? location.state.weekStart - 4 : location.state.weekStart == 40 ? location.state.weekStart - 5 : location.state.weekStart
            setweekStartDate(moment(days[numStart].$d).format(dateFormat()))
            let data = {
                from_date: moment(days[numStart].$d).format(dateFormat()),
                to_date: '',
                id: location.state.id,
                year: filterData.year,
                month: filterData.month
            };
            if (location.state.weekEnd == 47) {
                setweekEndDate(moment(endOfMonth.$d).format(dateFormat()))
                data.to_date = moment(endOfMonth.$d).format(dateFormat())
            }
            else if (location.state.weekEnd == 39 && moment(location.state.start_date).day() != 0) {
                setweekEndDate(moment(endOfMonth.$d).format(dateFormat()))
                data.to_date = moment(endOfMonth.$d).format(dateFormat())
            }
            else {
                let num = location.state.weekStart == 8 ? location.state.weekEnd - 2 : location.state.weekStart == 16 ? location.state.weekEnd - 3 : location.state.weekStart == 24 ? location.state.weekEnd - 4 : location.state.weekStart == 32 ? location.state.weekEnd - 5 : location.state.weekEnd - 1
                setweekEndDate(moment(days[num].$d).format(dateFormat()))
                data.to_date = moment(days[num].$d).format(dateFormat())
            }
            let payLoadData = data
            payLoadData.from_date = moment(payLoadData.from_date, dateFormat()).format(dateFormat())
            payLoadData.to_date = moment(payLoadData.to_date, dateFormat()).format(dateFormat())
            TimesheetApi.timesheetcalendarViewConsultant(payLoadData).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setTimeout(() => {
                            setCalendarDetails(response.data.data[0].timesheet);
                            setcardData({ ...response.data.data[0], end_client_name: response.data.data[0].end_client_name })
                            setclientName(response.data.data[0].client_name)
                            setLoading(false);
                        }, 1000)
                    } else {
                        addErrorMsg(response.data.message);
                        setLoading(false);
                    }
                }, 400);
            })
        }
        // eslint-disable-next-line  
    }, [filterData])

    const getCalendraView = (args, employeeID) => {
        if (drop !== 3 || (drop === 3 && (placementID !== null && employeeIDSelected !== null))) {
            setLoading(true);
        }
        let data = {
            from_date: filterData.start_date,
            to_date: filterData.end_date,
            id: location.state.id,
            year: filterData.year,
            month: filterData.month
        };// eslint-disable-next-line
        var calendarDetails1 = [];

        TimesheetApi.timesheetcalendarViewConsultant(data).then((response) => {
            setTimeout(() => {
                if (response.data.statusCode == 1003) {
                    setTimeout(() => {
                        setCalendarDetails(response.data.data[0].timesheet);
                        setcardData({ ...response.data.data[0], end_client_name: response.data.data[0].end_client_name })
                        setclientName(response.data.data[0].client_name)
                        setLoading(false);
                    }, 1000)
                } else {
                    addErrorMsg(response.data.message);
                    setLoading(false);
                }
            }, 400);
        })
    }

    const TableRowSkeletonLoader = ({ rowsNum }) => {
        return [...Array(rowsNum)].map((row, index) => (
            <Box key={index} sx={{ width: "100%", display: "flex", alignItems: "center", borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                <TableRow key={index}>
                    <TableCell component="th" scope="row">
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "19rem" }} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "19rem" }} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "19rem" }} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "19rem" }} />
                    </TableCell>
                </TableRow>
            </Box>
        ));
    };

    const years = [{
        id: 2023,
        value: '2023'
    },
    {
        id: 2024,
        value: '2024'
    }]

    const monthsOptions = [
        {
            id: 0,
            value: 'January'
        },
        {
            id: 1,
            value: 'Febraury'
        },
        {
            id: 2,
            value: 'March'
        },
        {
            id: 3,
            value: 'April'
        },
        {
            id: 4,
            value: 'May'
        },
        {
            id: 5,
            value: 'June'
        },
        {
            id: 6,
            value: 'July'
        },
        {
            id: 7,
            value: 'August'
        }, {
            id: 8,
            value: 'September'
        },
        {
            id: 9,
            value: 'October'
        },
        {
            id: 10,
            value: 'November'
        },
        {
            id: 11,
            value: 'December'
        }
    ]

    const getDateValue = (year, month) => {
        if (month == '') {
            month = moment().month()
        }
        return moment([year, month, 1]).format(dateFormat())
    }
    const getendDateValue = (year, month) => {
        if (month == '') {
            month = moment().month()
        }
        let a = moment([year, month, 1]).format(dateFormat())
        return moment(a).endOf('month').format(dateFormat());
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    return (
        <Grid container component={'main'} className={classes.main} pl={{ lg: 15, md: 11, sm: 12, xs: 11 }} pr={5}>
            <Grid item container >
                <Grid item lg={9} md={9} xs={9}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography sx={{ cursor: "pointer" }} onClick={() => { navigate('/timesheet'); }} className={classes.breadcrumbsLink}>Timesheet</Typography>
                        <Typography className={classes.breadcrumbsLink} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/timesheet/summary') }}>Timesheet Summary</Typography>
                        <Typography className={classes.breadcrumbsName}>{location.state.weekly ? 'Weekly' : 'Monthly'} View</Typography>
                    </Breadcrumbs>
                </Grid>
                {
                    location.state.weekly ? ''
                        :
                        <Grid item container lg={3} md={3} xs={3} display={'flex'} spacing={2} justifyContent={'end'}>
                            <Grid item xs={6}>
                                <Select
                                    options={years}
                                    label={'Select Year'}
                                    value={filterData.year}
                                    disableClearable
                                    onChange={(e) => { setstartDate(dayjs(getDateValue(e.target.value, filterData.month))); setFilterData({ ...filterData, year: e.target.value, start_date: getDateValue(e.target.value, filterData.month), end_date: getendDateValue(e.target.value, filterData.month) }) }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    options={monthsOptions}
                                    label={'Select Month'}
                                    disableClearable
                                    value={filterData.month}
                                    onChange={(e) => { setstartDate(dayjs(getDateValue(filterData.year, e.target.value))); setFilterData({ ...filterData, month: e.target.value, start_date: getDateValue(filterData.year, e.target.value), end_date: getendDateValue(filterData.year, e.target.value) }) }}
                                />
                            </Grid>
                        </Grid>
                }
            </Grid>
            <Grid item container lg={12} xs={12} sx={{
                padding: "12px 16px !important",
                background: "#fff !important",
                margin: "5px 0px !important",
                borderRadius: "20px !important",
                boxShadow: '0px 2px 24px 0px #919EAB1F',
                alignItems: 'center',
                height: '88px',
                cursor: 'pointer',
                '&:before': { display: "none" },
            }}>
                <Grid item lg={4}>
                    <Box display='flex' alignItems='center' gap={2}>
                        <CircularProgress
                            percentage={cardData.profile_progress}
                            color={cardData.profile_progress >= 76 ? 'green' : cardData.profile_progress <= 75 && cardData.profile_progress >= 51 ? 'yellow' : cardData.profile_progress <= 50 ? "#FFBF00" : ''}
                            src={cardData.profile_picture_url}
                            name={cardData.profile_picture_url == undefined || cardData.profile_picture_url == null || cardData.profile_picture_url == '' ? cardData.employee_name && cardData.employee_name[0] : ''}
                            imgWidth='50px !important'
                            imgHeight='50px !important'
                            avatarWidth='50px !important'
                            avatarHeight='50px !important'
                            value={cardData.profile_progress}
                            size={58}
                            tooltip
                            tooltipContent={
                                <Box p={'3px 0px'} width='100%'>
                                    <Text mediumBoldWhite400>{`Profile Completion - ${cardData.profile_progress}%`}</Text>
                                </Box>
                            }
                        />
                        <Box>
                            <Text mediumBoldBlack>
                                {cardData.employee_name ? cardData.employee_name.length > 16 ?
                                    <BlackToolTip title={cardData.employee_name} placement="top" arrow>
                                        {cardData.employee_name.slice(0, 16) + capitalizeAndAddSpace(cardData.employee_name.length > 16 ? "..." : "")}
                                    </BlackToolTip>
                                    : capitalizeAndAddSpace(cardData.employee_name) : '-'
                                } <img src={Verified} alt='Verified' style={{ marginBottom: '-3px' }} />
                            </Text>
                            <Text mediumGreybold noWrap sx={{ paddingTop: '8px' }}>{cardData.employee_reference_id ? cardData.employee_reference_id : '-'}</Text>
                        </Box>
                    </Box>
                </Grid>
                <Grid item container spacing={2} lg={8} xs={8}>
                    {
                        location.state.weekly == false
                            ?
                            <Grid item lg={3} xs={3}>
                                <Text largeLabel noWrap>Client</Text>
                                <Text mediumBoldBlack sx={{ paddingTop: '8px' }}>
                                    {cardData.client_name ? cardData.client_name.length > 16 ?
                                        <BlackToolTip title={cardData.client_name} placement="top" arrow>
                                            {cardData.client_name.slice(0, 16) + capitalizeAndAddSpace(cardData.client_name.length > 16 ? "..." : "")}
                                        </BlackToolTip>
                                        : capitalizeAndAddSpace(cardData.client_name) : '-'
                                    }</Text>
                            </Grid>
                            : ''}
                    {
                        location.state.weekly == false
                            ?
                            <Grid item lg={3} xs={3}>
                                <Text largeLabel noWrap>Total Logged Hours</Text>
                                <Text mediumBoldBlack sx={{ paddingTop: '8px' }}>{location.state.weekly == false && cardData.total_logged_hours ? `${cardData.total_logged_hours} hrs` : location.state.weekly ? `${location.state.hours} hrs` : '-'}</Text>
                            </Grid>
                            :
                            <Grid item lg={9} xs={9} container justifyContent={'flex-end'}>
                                <Box>
                                    <Text largeLabel noWrap>Total Logged Hours</Text>
                                    <Text mediumBoldBlack sx={{ paddingTop: '8px' }}>{location.state.weekly == false && cardData.total_logged_hours ? `${cardData.total_logged_hours} hrs` : location.state.weekly ? `${location.state.hours} hrs` : '-'}</Text>
                                </Box>
                            </Grid>
                    }
                    {
                        location.state.weekly == false
                            ?
                            <Grid item lg={3} xs={3}>
                                <Text largeLabel noWrap>Approved Hours</Text>
                                <Text mediumBoldBlack sx={{ paddingTop: '8px' }}>{cardData.approved_hours ? `${cardData.approved_hours} hrs` : '-'}</Text>
                            </Grid>
                            : ''
                    }
                    {
                        location.state.weekly == false
                            ?
                            <Grid item lg={3} xs={3}>
                                <Text largeLabel noWrap>Approved Pay</Text>
                                <Text mediumBoldBlack sx={{ paddingTop: '8px' }}>{LocalStorage.getCurrencySymbol()} {cardData.approved_pay ? `${cardData.approved_pay}` : '-'}</Text>
                            </Grid>
                            : ''
                    }
                </Grid>
            </Grid>
            {/* {
                location.state.weekly
                    ?
                    <Grid item container lg={12} xs={12} sx={{
                        padding: "12px 16px !important",
                        background: "#FFFFFF !important",
                        margin: "5px 0px !important",
                        borderRadius: "12px !important",
                        border: '1px solid #EAECF0',
                        alignItems: 'center',
                        height: '88px',
                        cursor: 'pointer',
                        '&:before': { display: "none" },
                    }}>
                        <Grid item container spacing={2} lg={12} xs={12}>
                            <Grid item lg={4} xs={3}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={clientName}
                                    disabled={true}
                                    clientInput
                                    labelText={'Client'}
                                />
                            </Grid>
                            <Grid item lg={4} xs={3}>
                                <Input
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        name: 'client_name',
                                        value: cardData.end_client_name,
                                    }}
                                    disabled={true}
                                    clientInput
                                    labelText={'End Client (Optional)'}
                                />
                            </Grid>
                            <Grid item lg={2} xs={3}>
                                {
                                    location.state.weekly
                                        ?
                                        <Date
                                            labelText={"Start Date"}
                                            name={"start_date"}
                                            value={weekStartDate}
                                            disabled={true}
                                        />
                                        :
                                        <Date
                                            labelText={"Start Date"}
                                            name={"start_date"}
                                            value={cardData.start_date}
                                            disabled={true}
                                        />
                                }

                            </Grid>
                            <Grid item lg={2} xs={3}>
                                {location.state.weekly
                                    ?
                                    <Date
                                        labelText={"End Date"}
                                        name={"end_date"}
                                        value={weekEndDate}
                                        disabled={true}
                                    />
                                    :
                                    <Date
                                        labelText={"End Date"}
                                        name={"end_date"}
                                        value={cardData.end_date}
                                        disabled={true}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    : ''
            } */}
            {
                location.state.weekly
                    ?
                    <Grid container mt={2}>
                        <div style={{ width: '100%', paddingTop: cardData.documents.length > 0 ? '16px' : '0px', minHeight: '59px', display: 'flex', alignItems: "center", background: '#FAFAFA', padding: '10px' }}>
                            <Autocomplete
                                clearIcon={false}
                                options={[]}
                                freeSolo
                                multiple
                                onKeyPress={(e) => { e.preventDefault() }}
                                value={cardData.documents}
                                disableClearable={true}
                                renderTags={(value, props) =>
                                    value.map((option, index) => (
                                        <Chip label={option.document_name}
                                            onClick={() => { openInNewTab(option) }}
                                            sx={{ color: '#0C75EB !important', font: '12px Averta !important', fontWeight: '600 !important' }}
                                        />
                                    ))
                                }
                                renderInput={(params) => <TextField label={<p style={{ color: '#0C75EB' }}> Timesheet Documents </p>} {...params} />}
                                sx={{
                                    width: '100%',
                                    "& .MuiInputBase-root": {
                                        cursor: "pointer",
                                    },
                                    "& .MuiInputBase-input": {
                                        cursor: "pointer",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none"
                                    },
                                    "& .MuiAutocomplete-endAdornment": {
                                        display: "none"
                                    },
                                    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                                        transform: "translate(10px, 16px) scale(1);"
                                    },
                                    "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: "#737373",
                                        fontSize: "14px",
                                        fontFamily: "Averta !important",
                                        fontWeight: 400,
                                    },
                                    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
                                        color: "#737373",
                                        fontSize: "16px",
                                        fontFamily: "Averta !important",
                                        fontWeight: 500,
                                    },
                                    "&.Mui-focused .MuiInputLabel-outlined": {
                                        color: "#737373",
                                        fontSize: "10px",
                                        fontFamily: "Averta !important",
                                        fontWeight: 400,
                                        transform: cardData.documents.length === 0 ? "translate(12px, 0px) scale(1);" : "translate(12px, -8px) scale(1);"
                                    },
                                }}
                            />
                        </div>
                    </Grid>
                    : ''}
            <Grid container mt={2}>
                <Grid item lg={12} sx={{ height: '580px !important', overflowY: 'auto' }}>
                    {false ? (
                        <TableRowSkeletonLoader rowsNum={10} />
                    ) : (
                        <>

                            <CustomCalendars calendarDetails={calendarDetails} location={location.state} value={startDate} />
                            <Box mt={2} display={'flex'} gap={2}>
                                <div style={{ display: "flex", gap: "inherit" }}><img alt="roundblue" src={roundblue} /><p>Actual Hours</p></div>
                                <div style={{ display: "flex", gap: "inherit" }}><img alt="roundpurple" src={roundpurple} /><p>OT Hours</p></div>
                            </Box>

                        </>
                    )}
                </Grid>

            </Grid>


        </Grid >
    );

}