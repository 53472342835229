import React, { useEffect, useState } from 'react'
import { Grid, Skeleton } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PayRateConfigurationForm from './PayRateConfigurationForm';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import LocalStorage from '../../../../utils/LocalStorage';


function PayRateConfiguration(props) {

  const placementID = props.id ? props.id : "";
  const [edit, setEdit] = useState(false);// eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
  const [state, setState] = useState({
    pay_type: "",
    annual_pay: "",
    payroll_pay: "",
    // payroll_cycle: "",
    pay_config_type: '',
    ot_pay_config_type: '',
    ot_pay_config_value: "",
    pay_rate: "",
    pay_details: [],
    pay_details_deleted_ids: []
  })

  useEffect(() => {
    getPlacementPayRateData(placementID)// eslint-disable-next-line
  }, []);

  const getPlacementPayRateData = (id) => {
    setLoading(true);
    PlacementApi.getPlacementIndex("payrate-details", id).then((res) => {
      if (res.data.statusCode === 1003) {
        // if (res.data.data.pay_rate_details.pay_details.length > 0) {
        //   res.data.data.pay_rate_details.pay_details = res.data.data.pay_rate_details.pay_details.reverse();
        // }
        res.data.data.pay_rate_details['pay_details_deleted_ids'] = [];
        setState({ ...res.data.data.pay_rate_details })
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
  }

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} >
        {
          edit ?
            <PayRateConfigurationForm employeeType={props.employeeType} placementID={placementID} getPlacementPayRateData={getPlacementPayRateData} state={state} setState={setState} edit={edit} setEdit={setEdit} setformEdit={props.setformEdit} />
            :
            <Grid container p={3} alignItems={"center"}>
              <Grid item lg={6} md={6} sm={6}>
                <Text largeBlue>Pay Rate Configuration</Text>
              </Grid>
              <Grid item lg={6} md={6} sm={6} textAlign={"end"}>
                {
                  props.status !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) ?
                    <>
                      {!loading && <img onClick={() => { setEdit(true); props.setformEdit(true); }} src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} />}
                    </>
                    : ""
                }
              </Grid>

              <Grid item lg={12} md={12} sm={12} pt={4}>
                {
                  loading ?
                    <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                    :
                    <>
                      <Text mediumGreyHeader1>Pay Type</Text>
                      <Text mediumBlack14 pt={0.8}>{state.pay_type == 2 ? "Salary" : state.pay_type === 1 ? "Hourly" : '--'}</Text>
                    </>
                }
              </Grid>
              {
                state.pay_type == 2 ?
                  <>
                    <Grid item lg={3.5} md={4} sm={6} pt={4}>
                      {
                        loading ?
                          <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                          :
                          <>
                            <Text mediumGreyHeader1>Annual Pay</Text>
                            <Text mediumBlack14 pt={0.8}>{LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {state.annual_pay ? state.annual_pay : "--"}</Text>
                          </>
                      }
                    </Grid>
                    {/* <Grid item lg={3.5} md={4} sm={6} pt={4}>
                      {
                        loading ?
                          <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                          :
                          <>
                            <Text mediumGreyHeader1>Payroll Pay</Text>
                            <Text mediumBlack14 pt={0.8}>${state.payroll_pay ? state.payroll_pay : "--"}</Text>
                          </>
                      }
                    </Grid> */}
                    {/* <Grid item lg={3.5} md={4} sm={6} pt={4}>
                      <Text mediumGreyHeader1>Payroll Cycle</Text>
                      <Text mediumBlack14 pt={0.8}>{state.payroll_cycle ? state.payroll_pay : "--"}</Text>
                    </Grid> */}
                  </> :
                  <>
                    <Grid item lg={12} md={12} sm={12} pt={4}>
                      {
                        loading ?
                          <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                          :
                          <>
                            <Text mediumGreyHeader1>Pay Configuration Type</Text>
                            <Text mediumBlack14 pt={0.8}>{state.pay_config_type == 1 ? "Percentage" : state.pay_config_type === 2 ? "Value" : '--'}</Text>
                          </>
                      }
                    </Grid>
                    {
                      state.pay_details.length > 0 && state.pay_details.map((item, index) => (
                        <>
                          <Grid item lg={4} md={4} sm={4} pt={4}>
                            <Text mediumGreyHeader1>From Hours</Text>
                            <Text mediumBlack14 pt={0.8}>{item.from_hour}</Text>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} pt={4}>
                            <Text mediumGreyHeader1>To Hours</Text>
                            <Text mediumBlack14 pt={0.8}>{item.to_hour ? item.to_hour : "Remainder"}</Text>
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} pt={4}>
                            <Text mediumGreyHeader1>{state.pay_config_type == 1 ? "Percentage" : "Value"}</Text>
                            {
                              state.pay_config_type == 2 ?
                                <Text mediumBlack14 pt={0.8}>{state.pay_config_type == 2 && LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.value}</Text>
                                :
                                <Text mediumBlack14 pt={0.8}>{item.value} {state.pay_config_type == 1 && "%"}</Text>
                            }
                            {/* <Text mediumBlack14 pt={0.8}>{state.pay_config_type == 2 && LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} {item.value} {state.pay_config_type == 1 && "%"}</Text> */}
                          </Grid>
                        </>
                      ))
                    }
                    {
                      props.employeeType == 2 ?
                        <>
                          <Grid item lg={12} md={12} sm={12} pt={4}>
                            <Text largeBlue >OT Pay Rate Config Type</Text>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} pt={1}>
                            {
                              loading ?
                                <Skeleton variant="circular" width={20} height={20} animation="wave" />
                                :

                                <FormControl>
                                  <RadioGroup
                                    name="controlled-radio-buttons-group"
                                    value={state.ot_pay_config_type != "" ? true : false}
                                    disable={true}
                                  >
                                    <FormControlLabel disabled value="true" control={<Radio />} label={<Text mediumGreyHeader1 sx={{ font: "14px Averta !important" }}>{state.ot_pay_config_type != "" && (state.ot_pay_config_type == 1 ? "Same as Payrate" : state.ot_pay_config_type == 2 ? "Fixed Value" : state.ot_pay_config_type == 3 ? "Variable" : "")}</Text>} />
                                  </RadioGroup>
                                </FormControl>
                            }
                          </Grid>
                          <Grid item lg={4} md={4} sm={4} pt={3}>
                            {
                              loading ?
                                <Skeleton variant='rounded' animation="wave" width={210} height={40} />
                                :
                                <>
                                  {
                                    state.ot_pay_config_type !== 1 &&
                                    <>
                                      <Text mediumGreyHeader1>{state.ot_pay_config_type == 3 ? "OT Pay Rate Multiplier" : "OT Pay Rate"}</Text>
                                      <Text mediumBlack14 pt={0.8}>{state.ot_pay_config_value ? state.ot_pay_config_type == 2 ? `${LocalStorage.getUserData() ? LocalStorage.getUserData().currency_symbol : '$'} ${state.ot_pay_config_value}` : state.ot_pay_config_value : "--"}</Text>
                                    </>
                                  }
                                </>
                            }
                          </Grid>
                        </>
                        : ""
                    }
                  </>
              }
            </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default PayRateConfiguration