import { Grid, Stack, Divider, Box, Avatar, Skeleton } from "@mui/material";
import Text from "../../components/customText/Text";
import LocalStorage from "../../utils/LocalStorage";
import Verified from '../../assets/svg/Component87.svg';
import { useEffect, useRef, useState } from "react";
import DashboardAPI from "../../apis/admin/DashboardAPI";
import { BlackToolTip, NoPermission, addErrorMsg, addLoader, capitalizeAndAddSpace, openDocumentInNewtab, removeLoader } from "../../utils/utils";
import Styles from "./profile/UserProfileStyles";
import LoadingScreen from "../admin/employees/userprofile/document/LoadingScreen";
import FileSvg from '../../assets/svg/File.svg';
import Button from '../../components/customButton/Button';
import ReusablePopup from '../../components/customPopup/CustomPopups';
import approveImg from '../../assets/timeSheets/pending-approval.svg';
import emptyEmployee from '../../assets/employee/emp-employee.svg';
import noDataFound from '../../assets/svg/noDataFound.svg';
import LoadingButton from "../../components/customButton/LoadingButton";
import disableVerified from '../../assets/client/disableVerified.svg';

export default function TimesheetEmployeePending(props) {
    const { fetchId, employee, setNewRequests } = props;
    const calculateColumnSize = (length) => {
        let a = 12 / length;
        return a
    }
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [list, setList] = useState([]);
    const classes = Styles();
    const [id, setId] = useState(fetchId);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [status, setStatus] = useState('');
    const scrollBox = useRef(null);
    const [profile, setProfile] = useState('');
    const [rejectLoad, setRejectLoad] = useState(false);
    const [approveLoad, setApproveLoad] = useState(false);

    const [filterData, setFilterData] = useState({
        limit: 100,
        page: 1,
        total: "",
        employee_id: LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id,
        request_id: LocalStorage.uid(),
        search: '',
        employee_status: []
    });

    useEffect(() => {
        if (employee == 'employee') {
            indexApi(id);
        }  // eslint-disable-next-line
    }, [])

    useEffect(() => {
        filterData.employee_id = LocalStorage.getUserData().admin_login ? '' : LocalStorage.getUserData().login_id
        filterData.request_id = LocalStorage.uid()
        filterData.search = props.searchId
        filterData.employee_status = props.filterState
        listingApi(filterData);
        // eslint-disable-next-line
    }, [props.searchId, props.filterState])

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(filterData.total) >= filterData.limit) {
                    loadMore();
                }
            }
        }
    };

    const loadMore = () => {
        addLoader(true)
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        setLoader(true);
        listingApi(filterData);
    }

    const listingApi = (args, limit) => {
        setLoading(true);
        args.request_id = LocalStorage.uid()
        DashboardAPI.pendingemployeeListing(args, limit).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                removeLoader();
                setList(res.data.data);
                if (res.data.data.length > 0) {
                    setFilterData({
                        ...res.data.pagination,
                        limit: res.data.pagination.perPage,
                        page: res.data.pagination.currentPage,
                        total: res.data.pagination.total,
                    });
                    setNewRequests(res.data.pagination.new_requests)
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].id == id) {
                            setProfile(res.data.data[i].profile_picture_url);
                        }
                    }
                } else {
                    setNewRequests('');
                }
            } else {
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const indexApi = (args) => {
        setLoader(true);
        DashboardAPI.pendingEmployeeIndex(args).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false);
                setData(res.data.data);
            } else {
                setLoader(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleClick = (args) => {
        indexApi(args.id);
        setId(args.id);
        setProfile(args.profile_picture_url);
    }

    const openInNewTab = (args) => {

        openDocumentInNewtab(args.document_key, args.document_path)

        // const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
    }

    const approveUpdate = (args) => {
        addLoader(true)
        const getId = data[0].id
        const updatedata = {
            request_id: LocalStorage.uid(),
            employee_id: data[0].employee_id,
            id: data[0].id,
            status: args
        }
        setStatus(args);
        if (args == 'Rejected') {
            setRejectLoad(true);
        }
        else {
            setApproveLoad(true);
        }
        DashboardAPI.updateApprove(updatedata).then((res) => {
            removeLoader()
            setRejectLoad(false);
            setApproveLoad(false)
            if (res.data.statusCode == 1003) {
                setOpenApprove(true);
                indexApi(getId);
                filterData.employee_status = [];
                setFilterData({ ...filterData });
                listingApi(filterData);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const okayHandler = () => {
        setOpenApprove(false);
    }

    return (
        <>
            {
                LocalStorage.getRolesData() == null || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true)) ?
                    <>
                        {list.length == 0 && !loading && !loader ?
                            <Box justifyContent='center' width='100%' alignItems='center'>
                                <img src={noDataFound} alt="noDataFound" className={classes.nodata} />
                            </Box>
                            :
                            <Grid container lg={12} md={12} sm={12} xs={12} spacing={1} columnSpacing={2}>
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    {
                                        loading ?
                                            <Box className={classes.sidecard} width='100%'>
                                                <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                                                        <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                                                        <Stack gap='8px'>
                                                            <Skeleton animation='wave' width='100px' />
                                                            <Skeleton animation='wave' width='100px' />
                                                        </Stack>
                                                    </Stack>
                                                    <Stack alignContent={'center'} alignItems={'center'}>
                                                        <Skeleton animation='wave' width='50px' />
                                                    </Stack>
                                                </Stack>
                                                <Divider sx={{ width: '100%', border: '1px solid #F5F5F5' }} />
                                                <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'} p={'10px'}>
                                                    <Stack direction={'column'} justifyContent={'space-between'}>
                                                        <Skeleton animation='wave' width='100px' />
                                                        <Skeleton animation='wave' width='100px' />
                                                    </Stack>
                                                    <Skeleton animation='wave' width='100px' />
                                                </Stack>
                                            </Box>
                                            :
                                            <Box className={classes.sidecardScroll} pt={1} width='100%'>
                                                {
                                                    list.map((item) => (
                                                        <Grid item container lg={11} md={12} sm={12} xs={12}
                                                            sx={{ border: item.id == id ? '1px solid #0C75EB' : '1px solid #fff' }} onClick={() => handleClick(item)}
                                                            className={classes.sidecard}
                                                        >
                                                            <Grid item container lg={12} md={12} xs={12} p={2}>
                                                                <Grid item xl={2} lg={3} md={3} sm={12} xs={12}>
                                                                    <Avatar variant="rounded" sx={{ width: 40, height: 40, borderRadius: '50%' }} src={item.profile_picture_url} alt={item.display_name ? capitalizeAndAddSpace(item.display_name[0]) : ''} />
                                                                </Grid>
                                                                <Grid item container lg={9} md={9} sm={12} xs={12}>
                                                                    <Grid item container lg={12} md={12}>
                                                                        <Grid item xl={9.5} lg={12} md={12} pl={1} sm={12}>
                                                                            <Text dashboardblack14>
                                                                                {
                                                                                    item.display_name ? item.display_name.length > 16 ?
                                                                                        <BlackToolTip title={capitalizeAndAddSpace(item.display_name)} placement="top" arrow>
                                                                                            {capitalizeAndAddSpace(item.display_name.slice(0, 16)) + capitalizeAndAddSpace(item.display_name.length > 16 ? "..." : "")}
                                                                                        </BlackToolTip>
                                                                                        : capitalizeAndAddSpace(item.display_name) : '--'
                                                                                }
                                                                                {
                                                                                    item.employee_e_verified != 0 ?
                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has Been E-Verified</Text>}>
                                                                                            <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                        </BlackToolTip>
                                                                                        :
                                                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has not E-Verified</Text>}>
                                                                                            <img src={disableVerified} alt='svg' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                        </BlackToolTip>
                                                                                }
                                                                            </Text>
                                                                            <Text greysmallLabel>{item.reference_id}</Text>
                                                                        </Grid>
                                                                        <Grid item xl={2.5} lg={12} md={12}>
                                                                            <Box textAlign='center' className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : ''}>
                                                                                <Text sx={{ paddingTop: '2px' }} mediumWhite400>{item.status}</Text>
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                                                <Grid item lg={7} md={6} sm={12} xs={12}>
                                                                    <Text greysmallLabel>Employee Type</Text>
                                                                    <Text dashboardblack14>{item.employee_type ? item.employee_type : '-'}</Text>
                                                                </Grid>
                                                                <Grid item container lg={5} md={6} sm={12} xs={12} className={classes.textCenter} alignItems='center' justifyContent='end'>
                                                                    <Text mediumBlue600>Profile Update</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </Box>
                                    }
                                </Grid>
                                <Grid item container lg={9} md={9} sm={9} xs={12}>
                                    {
                                        (id == '' || id == null | id == undefined) ?
                                            <Box justifyContent='center' width='100%' alignContent='center' textAlign='center'>
                                                <img src={emptyEmployee} alt='empty' className={classes.nodata} />
                                                <Text mediumBlack700>Select any employee</Text>
                                            </Box> :
                                            <Box className={classes.sidecardScroll} ref={scrollBox} onScroll={handleScroll} pt={1} pl={1}>
                                                <Box sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D !important", borderRadius: "8px", }}>
                                                    <Grid item container lg={12} justifyContent={'center'}>
                                                        <Grid item lg={10} container justifyContent={'center'} spacing={1} p={1}>
                                                            <Grid item xs={12} container justifyContent={'center'} mt={2}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} /> :
                                                                        <Avatar
                                                                            src={profile}
                                                                            alt={data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name[0]) : ''}
                                                                            sx={{
                                                                                width: '75px',
                                                                                height: '75px'
                                                                            }}
                                                                        />
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ?
                                                                        <Skeleton animation='wave' width='100px' />
                                                                        :
                                                                        data.length > 0 &&
                                                                        <Text dashboardblack14>{data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name) : '-'}
                                                                            {data.length > 0 && data[0].employee_e_verified != 0 ?
                                                                                <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has Been E-Verified</Text>}>
                                                                                    <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                </BlackToolTip>
                                                                                :
                                                                                <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has not E-Verified</Text>}>
                                                                                    <img src={disableVerified} alt='svg' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                </BlackToolTip>
                                                                            }
                                                                        </Text>


                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text greysmallLabel>{data.length > 0 ? data[0].employee_reference_id : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text mediumBoldBlack>{data.length > 0 ? data[0].request_type : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12} container justifyContent={'center'}>
                                                                {
                                                                    loader ? <Skeleton animation='wave' width='100px' /> :
                                                                        <Text greysmallLabel>{data.length > 0 ? data[0].status_approval : '-'}</Text>
                                                                }
                                                            </Grid>
                                                            {
                                                                loader ?
                                                                    <LoadingScreen />
                                                                    :
                                                                    <>
                                                                        <Grid item xs={12} container justifyContent={'center'} sx={{ background: '#F9F9F9', borderRadius: '8px', padding: '10px', overflow: 'hidden' }} mt={4}>
                                                                            {
                                                                                data.length > 0 && data[0].old_data.map((ele, i) => (
                                                                                    ele.map((item, index) => (
                                                                                        <Grid item xs={calculateColumnSize(ele.length)} container justifyContent={item.header ? 'left' : ''} >
                                                                                            <Box p={1}>
                                                                                                {
                                                                                                    item.header ?
                                                                                                        <Text popupHead1>{item.name}</Text>
                                                                                                        :
                                                                                                        <Text mediumLabel>{item.name}</Text>
                                                                                                }

                                                                                                <Text mediumBlack14 >
                                                                                                    {item.url ?
                                                                                                        <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item) }}>
                                                                                                            <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />

                                                                                                            <Text mediumBlue>
                                                                                                                <BlackToolTip arrow placement='top' title={item.value}>
                                                                                                                    {item.value}
                                                                                                                </BlackToolTip>
                                                                                                            </Text>

                                                                                                        </Stack>
                                                                                                        :
                                                                                                        item.value && item.value.length > 22 ?
                                                                                                            <BlackToolTip title={item.value} placement="top" arrow>
                                                                                                                {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            :
                                                                                                            item.value
                                                                                                    }
                                                                                                </Text>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent={'center'} sx={{ border: '1px solid #0C75EB', borderRadius: '8px', padding: '10px', overflow: 'hidden' }} mt={4}>
                                                                            {
                                                                                data.length > 0 && data[0].new_data.map((ele, i) => (
                                                                                    ele.map((item, index) => (
                                                                                        <Grid item xs={calculateColumnSize(ele.length)} container justifyContent={item.header ? 'left' : item.status ? 'end' : ''} style={{
                                                                                            overflow: 'hidden'
                                                                                        }}>
                                                                                            <Box p={1}>
                                                                                                {
                                                                                                    item.status ?
                                                                                                        <Box className={data[0].status == 'Approved' ? classes.statusBox : data[0].status == 'Requested' ? classes.orangeBox : data[0].status == 'Rejected' ? classes.redBox : ''}>
                                                                                                            {item.name}
                                                                                                        </Box>
                                                                                                        :
                                                                                                        item.header ?
                                                                                                            <Text largeBlue>{item.name}</Text>
                                                                                                            :
                                                                                                            <Text mediumLabel>{item.name}</Text>
                                                                                                }

                                                                                                <Text mediumBlack14>
                                                                                                    {
                                                                                                        item.url ?
                                                                                                            <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(item) }}>
                                                                                                                <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />

                                                                                                                <Text mediumBlue>
                                                                                                                    <BlackToolTip arrow placement='top' title={item.value}>
                                                                                                                        {item.value}
                                                                                                                    </BlackToolTip>
                                                                                                                </Text>

                                                                                                            </Stack>
                                                                                                            :
                                                                                                            item.value && item.value.length > 22 ?
                                                                                                                <BlackToolTip title={item.value} placement="top" arrow>
                                                                                                                    {item.value.slice(0, 22) + (item.value.length > 22 ? "..." : "")}
                                                                                                                </BlackToolTip>
                                                                                                                :
                                                                                                                item.value
                                                                                                    }
                                                                                                </Text>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    ))
                                                                                ))
                                                                            }
                                                                        </Grid>
                                                                        {
                                                                            LocalStorage.getUserData().admin_login && (data.length > 0 && data[0].status == 'Requested') ?
                                                                                <Grid item lg={12} ms={12} sm={12} xs={12} textAlign='center'>
                                                                                    <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                                                                                        <LoadingButton loading={rejectLoad} smallBorderBlue onClick={() => approveUpdate('Rejected')}>Reject</LoadingButton>
                                                                                        <LoadingButton scrollBtn loading={approveLoad} onClick={() => approveUpdate('Approved')}>Approve</LoadingButton>
                                                                                    </Box>
                                                                                </Grid> : ''
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <ReusablePopup openPopup={openApprove} setOpenPopup={setOpenApprove} iconHide fixedWidth white>
                                                    <Grid container lg={12} xs={12} textAlign='center' spacing={2}>
                                                        <Grid item lg={12} xs={12}>
                                                            <img src={approveImg} alt="approve" />
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Text largeBlack22>Changes {status}</Text>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Text offBoardBodyFont>You have successfully {status} the profile <br />updating of <span className={classes.boldName}>{data.length > 0 ? capitalizeAndAddSpace(data[0].employee_name) : '-'}</span>.</Text>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12}>
                                                            <Button saveBtn400 onClick={okayHandler}>Okay</Button>
                                                        </Grid>
                                                    </Grid>
                                                </ReusablePopup>
                                            </Box>
                                    }
                                </Grid>
                            </Grid >
                        }
                    </> :
                    <>
                        {NoPermission()}
                    </>
            }
        </>
    )
}