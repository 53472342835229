import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, Divider } from '@mui/material'
import logo from '../../../assets/svg/dashboard/New_Logo_Black.png';
import LocalStorage from '../../../utils/LocalStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowLeft from '../../../assets/svg/fi_arrow-left.svg';
import Text from '../../../components/customText/Text';
import BillingStyles from '../../../layouts/signUp/BillingStyles';
import CheckIcon from '@mui/icons-material/Check';
import RadioGroup from '../../../components/customButton/RadioGroup';
import LoadingButton from '../../../components/customButton/LoadingButton';// eslint-disable-next-line
import vector from '../../../assets/Vector.svg';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { domain } from '../../../config/Domain'; // eslint-disable-next-line
import { addErrorMsg, addLoader, addSuccessMsg, removeLoader } from '../../../utils/utils';
import AnalyticsApiTimesheet from '../../../apis/admin/timesheets/AnalyticsApiTimesheet';
import LoadingScreen from '../employees/userprofile/document/LoadingScreen';

function MIVIPricing() {
    const navigate = useNavigate();
    const classes = BillingStyles();
    const [loader, setLoader] = useState(false);
    const [active, setActive] = useState('basic');
    const [basic, setBasic] = useState(1); // eslint-disable-next-line
    const [currentPlan, setcurrentPlan] = useState(1);// eslint-disable-next-line
    const location = useLocation()
    const handleChangeTab = (args) => {
        setActive(args);
        if (args == 'basic') {
            setBasic(1);
        } else if (args == 'pro') {
            setBasic(2);
        } else {
            setBasic(3);
        }
    }

    const freePlan = [
        {
            label: 'Credits',
            value: active == 'pro' ? '250' : active == 'elite' ? '500' : '150'
        }
    ]

    const basicItems1 = [
        { id: 1, value: 1, title: <Text label></Text> }
    ]

    const basicItems2 = [
        { id: 2, value: 2, title: <Text label></Text> }
    ]

    const basicItems3 = [
        { id: 3, value: 3, title: <Text label></Text> }
    ]

    const handlechange = (e, args) => {
        setBasic(args)
        setActive(args == 1 ? 'basic' : args == 2 ? 'pro' : 'elite')
    }

    useEffect(() => {
        getPlanCheck()
    }, [])

    const getPlanCheck = () => {
        addLoader(true)
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        setLoader(true)
        addLoader(true)
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                setLoader(false)
                removeLoader()
                setcurrentPlan(res.data?.ai.plan_slug == 'ai-timesheet-elite' ? 3 : res.data?.ai.plan_slug == 'ai-timesheet-pro' ? 2 : 1)
                setBasic(res.data?.ai.plan_slug == 'ai-timesheet-elite' ? 3 : res.data?.ai.plan_slug == 'ai-timesheet-pro' ? 2 : 1)
                setActive(res.data?.ai.plan_slug == 'ai-timesheet-elite' ? 'elite' : res.data?.ai.plan_slug == 'ai-timesheet-pro' ? 'pro' : 'basic')
            }
            else {
                setLoader(false)
                removeLoader()
            }
        })
    }

    const PlanSubmit = () => {
        let data = {
            request_id: LocalStorage.uid(),
            plan_slug: basic == 1 ? 'ai-timesheet-basic' : basic == 2 ? 'ai-timesheet-pro' : 'ai-timesheet-elite'
        }
        addLoader(true);
        AnalyticsApiTimesheet.upgradeAIPlan(data).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                // addSuccessMsg(res.data.message)
                if (res.data?.data?.redirection == true) {
                    window.open(res.data.data.payment_url);
                }
                else if (res.data?.data?.redirection == false) {
                    navigate('/plan-sucess')
                }
                else if (res.data?.data?.payment_processing == true && res.data?.data?.redirection == false) {
                    navigate('/myprofile');
                } else {
                    setTimeout(() => {
                        getPlanCheck();
                    }, 500)
                    navigate('/plan-upgrade');
                }
            } else {
                removeLoader();
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Grid container pt={0} p={4}>
            <Grid item xs={1}></Grid>
            {/* {
                loading ? <LoadingScreen /> : */}
            <>
                <Grid container xs={11} alignItems='start'>
                    <Grid item container xs={12} spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                            <img src={logo} alt='logo' style={{ height: '51px', width: '173px' }} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} pt={2} spacing={2}>
                        {
                            LocalStorage.getUserData().plan_not_set ? '' :
                                <Grid item container xs={12} spacing={1} my={2}>
                                    <Grid item xs={9.5}>
                                        <Stack direction={'row'}>
                                            <img src={ArrowLeft} alt='ArrowLeft' style={{ cursor: 'pointer' }} onClick={() => { navigate('/myprofile',{state:{redirection: true}}) }} />
                                            <Text BlackExtraDark sx={{paddingLeft: '15px'}}>Back</Text>
                                        </Stack>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    <Grid item container xs={7} pt={2}>
                        {
                            loader ?
                                <LoadingScreen />
                                :
                                <Grid item container xs={12}>
                                    <Grid item container xs={12}>
                                        <Grid item container xs={11} alignItems='center'>
                                            <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => handleChangeTab('basic')}>
                                                <Box sx={{
                                                    color: active == 'basic' ? '#fff' : '#737373',
                                                    background: active == 'basic' ? '#0C75EB' : '#EAECF0',
                                                }} className={classes.Active}>
                                                    Basic
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => handleChangeTab('pro')}>
                                                <Box className={classes.Active} sx={{
                                                    color: active == 'pro' ? '#fff' : active == 'elite' ? '#737373' : '#181A18',
                                                    background: active == 'pro' ? '#0C75EB' : '#EAECF0',
                                                    borderRadius: '0px'
                                                }}>
                                                    Pro
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={4} onClick={() => handleChangeTab('elite')}>
                                                <Box sx={{
                                                    color: active == 'elite' ? '#fff' : '#181A18',
                                                    background: active == 'elite' ? '#0C75EB' : '#EAECF0',
                                                    borderRadius: '0px 8px 8px 0px'
                                                }} className={classes.Active}>
                                                    Elite
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} pt={4}>
                                            <Box pl={2}>
                                                <Text largeBlue className={classes.lightBlue}> Benefits </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box p={2} pt={0}>
                                                {
                                                    freePlan.map((i) => (
                                                        <Grid container xs={12} pt={2} alignItems='center'>
                                                            <Grid item xs={7} pt={2}>
                                                                <Stack direction={'row'} gap={3}>
                                                                    <CheckIcon sx={{ color: 'green !important', marginTop: '1px' }} />
                                                                    <Text grey18px>  {i.label} </Text>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={1} pt={2}>
                                                                <Text greyLabel> - </Text>
                                                            </Grid>
                                                            <Grid item xs={2} pt={2} container justifyContent={'flex-end'}>
                                                                <Text black18px> {i.value} </Text>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        }

                    </Grid>
                    <Grid item p={3} container xs={4.5} sx={{ boxShadow: "0px 2px 24px 0px #919EAB1F", borderRadius: "8px" }}>
                        <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                            <Box display='flex' flexDirection='column' gap={1}>
                                <Text popupHead1 sx={{ fontWeight: '600 !important' }}>Affordable & Powerful</Text>
                                <Text mediumOverView>Feature-rich pricing that scales with your needs.</Text>
                            </Box>
                        </Grid>
                        {
                            loader ?
                                <LoadingScreen />
                                :
                                <Grid item container sm={12} xs={12} justifyContent={'center'} pt={3} spacing={2}>
                                    <Grid item xs={4} sm={4}>
                                        <Box sx={{ minHeight: '143px', border: basic == 1 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                            <Grid container xs={12} pt={3}>
                                                <Grid item container xs={12} justifyContent={'center'}>
                                                    <Box pl={3}>
                                                        <RadioGroup
                                                            row
                                                            name="basic"
                                                            value={basic}
                                                            items={basicItems1}
                                                            onChange={(e) => handlechange(e, 1)}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Basic</Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blue14 sx={{ fontSize: '18px !important' }}>{LocalStorage.getCurrencySymbol()}4<span style={{ color: '#0C75EB', font: '14px Averta', fontWeight: '400' }}>.99</span></Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                    <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <Box sx={{ minHeight: '143px', border: basic == 2 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                            <Grid container xs={12} pt={3}>
                                                <Grid item container xs={12} justifyContent={'center'}>
                                                    <Box pl={4}>
                                                        <RadioGroup
                                                            row
                                                            name="basic"
                                                            value={basic}
                                                            items={basicItems2}
                                                            onChange={(e) => handlechange(e, 2)}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Pro</Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blue14 sx={{ fontSize: '18px !important' }}>{LocalStorage.getCurrencySymbol()}7<span style={{ font: '14px Averta', fontWeight: '400' }}>.99</span></Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                    <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <Box sx={{ minHeight: '143px', border: basic == 3 ? '1px solid #0C75EB' : '1px solid #C7CCD3', padding: '24px, 10px, 24px, 10px', borderRadius: '10px' }}>
                                            <Grid container xs={12} pt={3}>
                                                <Grid item container xs={12} justifyContent={'center'}>
                                                    <Box pl={4}>
                                                        <RadioGroup
                                                            row
                                                            name="basic"
                                                            value={basic}
                                                            items={basicItems3}
                                                            // disabled={currentPlan == 'starter-yearly' ? true : false}
                                                            onChange={(e) => handlechange(e, 3)}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blackFont14 sx={{ color: '#171717 !important', fontWeight: '400 !important' }}>Elite</Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5}>
                                                    <Text blue14 sx={{ fontSize: '18px !important' }}>{LocalStorage.getCurrencySymbol()}13<span style={{ font: '14px Averta', fontWeight: '400' }}>.99</span></Text>
                                                </Grid>
                                                <Grid item container xs={12} justifyContent={'center'} pt={0.5} pb={2}>
                                                    <Text greysmallLabel sx={{ color: '#949494 !important' }}>Per month</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item container my={2}>
                                        <Divider sx={{ width: '99%', border: '1px solid #D9D9D9' }} />
                                    </Grid>
                                    <Grid item container sm={12} xs={12} pt={2} justifyContent='start'>
                                        <Box sx={{ background: '#F0F5FF', borderRadius: '8px', width: '100%' }}>
                                            <Grid item container xs={12} alignItems='center' sx={{ padding: '14px' }}>
                                                <Grid item xs={6}>
                                                    <Text mediumBoldBlack>Bill Today</Text>
                                                </Grid>
                                                <Grid item xs={6} textAlign='end'>
                                                    <Text mediumBoldBlack>{LocalStorage.getCurrencySymbol()} {basic == 1 ? 4.99 : basic == 2 ? 7.99 : 13.99}</Text>
                                                </Grid>
                                            </Grid>
                                            <Grid item pb={2}>
                                                <Divider sx={{ width: '100%', borderColor: '#D9D9D9' }} />
                                            </Grid>
                                            {

                                                <Grid item container xs={12} spacing={1} justifyContent={'center'}>
                                                    <Grid item container xs={12} justifyContent={'center'} p={2}>
                                                        <Text offBoardBodyFont>Auto-renews for {LocalStorage.getCurrencySymbol()} {basic == 1 ? 4.99 : basic == 2 ? 7.99 : 13.99} every month including taxes.</Text>
                                                    </Grid>
                                                </Grid>

                                            }

                                        </Box>
                                    </Grid>
                                    <Grid item container sm={12} xs={12} justifyContent={'center'} pt={1}>
                                        <LoadingButton
                                            // className={classes.loginButton}
                                            // sx={{ width: '27vw !important' }}
                                            blueButton
                                            // loading={loader}
                                            variant="contained"
                                            onClick={() => { PlanSubmit() }}
                                        >
                                            Complete Payment
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item container xs={12} justifyContent={'center'}>
                                        <Text offBoardBodyFont>All transactions charged in USD</Text>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </>
            {/* } */}
        </Grid>
    )
}

export default MIVIPricing