import { Avatar, AvatarGroup, Box, Divider, Grid, Hidden, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Text from "../../components/customText/Text";
import Chart from "react-apexcharts";
import DashboardStyles from './DasboardStyles';
import Button from "../../components/customButton/Button";
import LocalStorage from "../../utils/LocalStorage"
import CustomSelect from "../../components/customSelect/CustomSelect";
import ReactApexChart from "react-apexcharts";
import { BlackToolTip, BorderLinearProgress, NoDataFound, NoPermission, addErrorMsg, addLoader, addLoaderPlanExpired, capitalizeAndAddSpace, removeExpiredLoader, removeLoader } from "../../utils/utils";
import ReusablePopup from "../../components/reuablePopup/ReusablePopup";
import successImg from '../../assets/svg/dashboard/remainder-success.svg';
import { useLocation, useNavigate } from "react-router-dom";
import DashboardAPI from "../../apis/admin/DashboardAPI";
import billed from '../../assets/svg/dashboard/billed.svg';
import pay from '../../assets/svg/dashboard/pay.svg';
import margin from '../../assets/svg/dashboard/margin.svg';
import banner from '../../assets/svg/dashboard/dashboard-banner.svg';
import Verified from '../../assets/svg/Component87.svg';
import empty from '../../assets/svg/dashboard/empty-dashboard.svg';
import Userplus from '../../assets/svg/user-plus.svg';
import { domain } from "../../config/Domain";
import CommonApi from "../../apis/CommonApi";
import LoadingScreen from "../admin/employees/userprofile/document/LoadingScreen"
import disableVerified from '../../assets/client/disableVerified.svg';

export default function Dashboard() {
    const classes = DashboardStyles();
    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const navigate = useNavigate();
    const currency = LocalStorage.getCurrencySymbol();
    const [success, setSuccess] = useState(false);
    const [totalEmp, setTotalEmp] = useState({
        consultant: '',
        contractor: '',
        total_employees: '',
        series: [],
        labels: []
    })
    const [financial, setFinancial] = useState({
        total_billed: '',
        total_pay: '',
        margin: '',
        financialData: {
            dates: [],
            labels: [],
            series: []
        }
    })
    const [years, setYears] = useState([]);
    const [yearDropdwn, setYearDropdwn] = useState({
        year: '2024',
        placementYear: '2024'
    })
    const [placementsAdded, setPlacementsAdded] = useState([]);
    const [placementsCompleted, setPlacementsCompleted] = useState([]);
    const [placementDetails, setPlacementDetails] = useState({});
    const [planDetails, setplanDetails] = useState({
        plan_not_set: '',
        plan_expired: '',
        free_trail: '',
        is_subscription_cancelled_expired: '',
        plan_expires_on: '',
        plan_banner: '',
        employee_exists: ''
    });
    const [tsData, setTsData] = useState({
        drafted: '',
        pending_approval: '',
        approved: '',
        rejected: ''
    })
    const [companies, setCompanies] = useState([
        {
            id: '',
            name: 'sdf',
            placement_count: 'sd',
            percentage: '3'
        }
    ])
    const scrollBox = useRef(null);
    const [Reminders, setReminders] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderAction, setLoaderAction] = useState(false);

    const linearProgress = [
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
        {
            bgColor: '#0095FF',
            barColor: '#CDE7FF'
        },
        {
            bgColor: '#00E096',
            barColor: '#D4FFEB'
        },
        {
            bgColor: '#884DFF',
            barColor: '#E7DBFF'
        },
        {
            bgColor: '#FF8F0D',
            barColor: '#FFEBD4'
        },
        {
            bgColor: '#D34040',
            barColor: '#FFC8C8'
        },
    ]

    const [filterData, setFilterData] = useState({
        limit: 6,
        page: 1
    });

    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 6,
            totalPages: ""
        }
    );

    useEffect(() => {
        if (LocalStorage.getUserData().plan_not_set) {
            navigate('/billing');
        }
        else if (LocalStorage.getUserData().org_config_set == false) {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeFromPage();
            navigate('/configuration');
        }
        else {
            navigate('/dashboard');
        }
        addLoader(true);
        getApi(yearDropdwn.year);
        getPlacementApi(yearDropdwn.placementYear);
        listingApi();
        getPlanCheck();
        defaultYears();
        // eslint-disable-next-line
    }, [])

    const defaultYears = () => {
        CommonApi.defaultYearsList().then((res) => {
            if (res.data.statusCode == 1003) {
                setYears(res.data.data);
            }
        })
    }

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setplanDetails(res.data);
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-sucess') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const listingApi = () => {
        if (Reminders.length < 6) {
            setLoaderAction(true);
        }
        DashboardAPI.pendingRequestListing(filterData.limit, filterData.page).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoaderAction(false);
                removeLoader()
                setReminders(res.data.data);
                setPagination(res.data.pagination);
            } else {
                setLoaderAction(false);
                removeLoader()
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleScroll = () => {
        removeLoader()
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    loadMore();
                }
            }
        }
    };

    const loadMore = () => {
        setLoaderAction(true)
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        setLoaderAction(true);
        listingApi();
    }

    const getApi = (args) => {
        addLoader(true);
        setLoader(true)
        DashboardAPI.dashboardGetApi(args).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false)
                removeLoader();
                if (res.data.data.employeesData.series.length > 0) {
                    totalEmp['consultant'] = res.data.data.employeesData.series[0]
                    totalEmp['contractor'] = res.data.data.employeesData.series[1]
                }
                totalEmp['total_employees'] = res.data.data.employeesData.total_employees
                totalEmp['series'] = res.data.data.employeesData.series
                totalEmp['labels'] = res.data.data.employeesData.labels
                setCompanies(res.data.data.topCompanies);
                setTotalEmp({ ...totalEmp });
                setTsData(res.data.data.timesheetData[0]);
                setFinancial(res.data.data);
                tsSeries.tsColors = res.data.data.timesheetColors
                for (let i = 0; i < res.data.data.timesheetSeries.length; i++) {
                    tsSeries.tsSeriesData.push(Number(res.data.data.timesheetSeries[i]));
                }
                setTsSeries({ ...tsSeries })
            }
            else {
                setLoader(false)
            }
        })
    }

    const getPlacementApi = (args) => {
        addLoader(true);
        DashboardAPI.dashboardPlacementGetApi(args).then((res) => {
            if (res.data.statusCode == 1003) {
                removeLoader();
                setPlacementDetails(res.data.data);
                setPlacementsAdded(res.data.data.distinct_placements_added)
                setPlacementsCompleted(res.data.data.distinct_placements_completed)
                cashflowData['addition'] = res.data.data.placement_details.series[0].data
                cashflowData['attrition'] = res.data.data.placement_details.series[1].data
                cashflowData['labels'] = res.data.data.placement_details.labels
                setCashflowData({ ...cashflowData })
            }
        })
    }

    const dropDownChangeHandler = (e, args) => {
        yearDropdwn[e.target.name] = e.target.value
        setYearDropdwn({ ...yearDropdwn });
        if (args == 'financial') {
            getApi(e.target.value);
        } else {
            getPlacementApi(e.target.value);
        }
    }

    const pieData = {
        options: {
            dataLabels: {
                enabled: false
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false,
            },
            labels: totalEmp.labels,
            colors: ['#9BDFC4', '#F99BAB'],
            stroke: {
                width: 0
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: function (w) {
                                    // w is the chart context containing various properties including series
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                },
                            },
                        },
                    },
                },
            },
        },
        series: totalEmp.series,
        labels: totalEmp.labels
    };

    const [cashflowData, setCashflowData] = useState({
        addition: [],
        attrition: [],
        labels: []
    })

    const monthsOptions = [
        {
            id: 1,
            value: 'January'
        },
        {
            id: 2,
            value: 'Febraury'
        },
        {
            id: 3,
            value: 'March'
        },
        {
            id: 4,
            value: 'April'
        },
        {
            id: 5,
            value: 'May'
        },
        {
            id: 6,
            value: 'June'
        },
        {
            id: 7,
            value: 'July'
        },
        {
            id: 8,
            value: 'August'
        }, {
            id: 9,
            value: 'September'
        },
        {
            id: 10,
            value: 'October'
        },
        {
            id: 11,
            value: 'November'
        },
        {
            id: 12,
            value: 'December'
        }
    ]

    const financialOptions = {
        series: financial.financialData.series,
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#0C75EB', '#F6BA1E', '#8763FC'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            x: {
                formatter: function (val) {
                    return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
                }
            },
            y: {
                formatter: function (val) {
                    return "$" + val;
                }
            },
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: financial.financialData.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return "$ " + val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#0C75EB', '#F6BA1E', '#8763FC']
    }

    const areaOptions = {
        series: [
            {
                name: 'Addition',
                data: cashflowData.addition
            },
            {
                name: 'Attrition',
                data: cashflowData.attrition
            }
        ],
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            show: true,
            colors: ['#14C9C9', '#F7BA1E'],
            width: 2
        },
        fill: {
            type: 'gradient',
            colors: ['#14C9C9', '#F7BA1E'],
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            x: {
                formatter: function (val) {
                    return monthsOptions[val - 1].value + ' ' + yearDropdwn.year;
                }
            },
            colors: ['#14C9C9', '#F7BA1E'],
        },
        zoom: {
            enabled: false
        },
        xaxis: {
            type: 'datatype',
            categories: cashflowData.labels
        },
        yaxis: {
            type: 'datatype',
            labels: {
                formatter: function (val) {
                    return val.toFixed(0);
                }
            },
            categories: ['20M', '40M', '60M', '80M', '100M']
        },
        interaction: {
            zoomView: false
        },
        layout: {
            hierarchical: false
        },
        colors: ['#14C9C9', '#F7BA1E'],
        // tooltip: {
        //     x: {
        //         format: 'dd/MM/yy HH:mm'
        //     },
        // },
    }

    const [tsSeries, setTsSeries] = useState({
        tsSeriesData: [],
        tsColors: []
    })

    const timesheetOptions = {
        series: tsSeries.tsSeriesData,
        options: {
            chart: {
                width: '100%',
                type: 'pie',
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            labels: ["Drafted", "Pending Approval", "Approved", "Rejected"],
            colors: tsSeries.tsColors,
            stroke: {
                width: 0,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: 0
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
    }

    const navigatePending = (args) => {
        if (args.placement_id == '' || args.placement_id == null || args.placement_id == undefined) {
            navigate('/pending-actions', { state: { id: args.id, empId: args.employee_id, placementId: '', from: 'employee', employee: 'employee' } })
        } else {
            navigate('/pending-actions', { state: { id: '', timesheet_id: args.id, empId: args.employee_id, placementId: args.placement_id, from: 'dashboard', employee: '' } })
        }
    }
    // eslint-disable-next-line
    const RenewPlan = () => {
        let dataSubmit = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        CommonApi.renewInitiation(dataSubmit).then(
            (res) => {
                if (res.data.statusCode == 1003) {
                    window.open(res.data.data.url, '_blank');
                }
                else {
                    addErrorMsg(res.data.message)
                }
            }
        )
    }

    const viewAnalytics = () => {
        LocalStorage.setAnalyticsRoute('dashboard')
        navigate('/placement/timesheets-analytics', { state: { from: 'dashboard' } })
    }

    return (
        <Grid container lg={12} md={12} sm={12} xs={12} pl={2} sx={{
            "@media (min-width: 968px)": {
                paddingLeft: '130px'
            }
        }}>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true))) ?
                    <Grid container lg={12} sm={12} md={12} xs={12}>
                        {
                            loader ?
                                <Grid container lg={12} sm={12} md={12} xs={12} spacing={2}>
                                    <LoadingScreen />
                                    <LoadingScreen />
                                </Grid>
                                :
                                planDetails.employee_exists == false && !loader ?
                                    <>
                                        <Grid item lg={12} md={12} sm={12} xs={12} mb={2} textAlign='start' alignContent='start'>
                                            <Text boldblack22>Hi, {LocalStorage.getUserData().full_name}</Text>
                                            <Text mediumBlack14 sx={{ paddingTop: '6px' }}>Start your journey by adding "Employee"</Text>
                                        </Grid>
                                        <Grid item container lg={12} md={12} sm={12} xs={12} justifyContent='center' textAlign='center'>
                                            <Box textAlign='center' alignItems='center' sx={{ height: '70vh !important', alignContent: 'center' }}>
                                                <img src={empty} alt="nodata" style={{ marginBottom: '20px' }} />
                                                <Text smallLabelblack16 sx={{ marginBottom: '20px' }}>The trail is clear, but the journey has yet to begin.</Text>
                                                <Button addButton startIcon={<img src={Userplus} alt="Userplus" style={{ padding: "0px 4px" }} />} onClick={() => {
                                                    LocalStorage.removeRedirectedModule();
                                                    LocalStorage.removeFromPage();
                                                    navigate('/employees', { state: { from: 'dashboard' } })
                                                }}>
                                                    Add Employee
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </> :
                                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} justifyContent={'center'} pt={2} alignItems='flex-start'>
                                        <Grid item container lg={8} md={8} sm={12} xs={12} pt={3} pr={3} className={classes.container}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                                                <Text boldblack22>Hi, {LocalStorage.getUserData().full_name}</Text>
                                                <Text black400 sx={{ paddingTop: '6px' }}>Here's how your dashboard looking today</Text>
                                            </Grid>
                                            {
                                                LocalStorage.getUserData().super_admin && planDetails.plan_banner ?
                                                    <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
                                                        <Box className={classes.bannerbg}>
                                                            <Grid item container lg={12} md={12} sm={12} xs={12}>
                                                                <Grid item container xl={9} lg={8} md={7} sm={12} xs={12}>
                                                                    <Grid item lg={12} xs={12}>
                                                                        <Text largeWhite18 sx={{ marginBottom: '8px' }}>Plan Expiring Soon!</Text>
                                                                        <Text mediumBoldWhite400>It looks like your plan will expire on {planDetails.plan_expires_on}, Please renew or<br /> upgrade your plan to use our application</Text>
                                                                        <Box pt={3} sx={{ width: '190px' }}>
                                                                            <Button manageSubscription onClick={() => {
                                                                                LocalStorage.setlocationPath(location.pathname)
                                                                                navigate('/billing', {
                                                                                    state: {
                                                                                        renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                                                                                            ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                                                                                                (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                                                                                        renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                                                                                        autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                                                                                        autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                                                                                        autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
                                                                                    }
                                                                                })
                                                                            }}>Manage Subscription</Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                                <Hidden mdDown>
                                                                    <Grid item container xl={3} lg={4} md={5} textAlign='end'>
                                                                        <img src={banner} alt="banner" />
                                                                    </Grid>
                                                                </Hidden>
                                                            </Grid>
                                                        </Box>
                                                    </Grid> : ''
                                            }
                                            {
                                                ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                                                    <>
                                                        <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} pt={3}>
                                                            <Grid item container lg={12} md={12} sm={12} xs={12} alignItems='center'>
                                                                <Grid item lg={10} md={9} sm={6} xs={6}>
                                                                    <Text smallLabelblack16>Financial Performance</Text>
                                                                </Grid>
                                                                <Grid item lg={2} md={3} sm={6} xs={6}>
                                                                    <CustomSelect
                                                                        name='year'
                                                                        value={yearDropdwn.year}
                                                                        viewDrop
                                                                        scrollTrue={true}
                                                                        options={years}
                                                                        onChange={(e) => dropDownChangeHandler(e, 'financial')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} spacing={2} columnSpacing={2}>
                                                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                                                    <Box alignItems='center' className={classes.bluebg}>
                                                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                                                            <Grid item lg={2} md={2} xs={2}>
                                                                                <img src={billed} alt='clients' />
                                                                            </Grid>
                                                                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                                                                <Text mediumBoldBlack>Total Billed</Text>
                                                                                <Text boldblack22>{currency}&nbsp;
                                                                                    {financial.total_billed ? financial.total_billed.length > 10 ?
                                                                                        <BlackToolTip title={financial.total_billed}>
                                                                                            {financial.total_billed.slice(0, 10) + financial.total_billed.length > 10 ? '...' : ''}
                                                                                        </BlackToolTip> : financial.total_billed : financial.total_billed == 0 ? 0 : '--'
                                                                                    }
                                                                                </Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                                                    <Box alignItems='center' className={classes.yellowbg}>
                                                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                                                            <Grid item lg={2} md={2} xs={2}>
                                                                                <img src={pay} alt='clients' />
                                                                            </Grid>
                                                                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                                                                <Text mediumBoldBlack>Total Pay</Text>
                                                                                <Text boldblack22>{currency}&nbsp;
                                                                                    {financial.total_pay ? financial.total_pay.length > 10 ?
                                                                                        <BlackToolTip title={financial.total_pay}>
                                                                                            {financial.total_pay.slice(0, 10) + financial.total_pay.length > 10 ? '...' : ''}
                                                                                        </BlackToolTip> : financial.total_pay : financial.total_pay == 0 ? 0 : '--'
                                                                                    }
                                                                                </Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={4} md={12} sm={12} xs={12}>
                                                                    <Box alignItems='center' className={classes.purplebg}>
                                                                        <Grid item container lg={12} xs={12} alignItems='center' columnSpacing={4}>
                                                                            <Grid item lg={2} md={2} xs={2}>
                                                                                <img src={margin} alt='clients' />
                                                                            </Grid>
                                                                            <Grid item lg={9} md={9} xs={9} ml={2}>
                                                                                <Text mediumBoldBlack>Margin</Text>
                                                                                <Text boldblack22>{currency}&nbsp;
                                                                                    {financial.margin ? financial.margin.length > 10 ?
                                                                                        <BlackToolTip title={financial.margin}>
                                                                                            {financial.margin.slice(0, 10) + financial.margin.length > 10 ? '...' : ''}
                                                                                        </BlackToolTip> : financial.margin : financial.margin == 0 ? 0 : '--'
                                                                                    }
                                                                                </Text>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </> : ''
                                            }
                                            <Grid container>
                                                {
                                                    ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                                                        <>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={3}>
                                                                <ReactApexChart
                                                                    options={financialOptions}
                                                                    series={financialOptions.series}
                                                                    type="area"
                                                                    height='300px'
                                                                />
                                                            </Grid>
                                                            <Grid item container lg={12} md={12} sm={10} xs={10} pt={2} justifyContent='end'>
                                                                <Box minWidth='70px' textAlign='end'>
                                                                    <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={viewAnalytics}>View More</Text>
                                                                </Box>
                                                            </Grid>
                                                            <Divider sx={{ width: '100%', margin: '20px 0px 30px 0px', borderColor: '#F5F5F5' }} />
                                                        </> : ''
                                                }
                                                <Grid container spacing={2} pt={2} alignItems='flex-start'>
                                                    {
                                                        ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                                                            <>
                                                                <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center' className={classes.borderRight}>
                                                                    <Grid item lg={12} md={6} sm={12} xs={12} mb={2}>
                                                                        <Text smallLabelblack16>Employees Data</Text>
                                                                    </Grid>
                                                                    {
                                                                        (totalEmp.consultant == 0 && totalEmp.contractor == 0) ?
                                                                            <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                                                                {NoDataFound('')}
                                                                            </Grid> :
                                                                            <Grid item xl={10} lg={12} md={12} sm={12} xs={12} pt={2} container justifyContent={'center'} textAlign='center' sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                                                                <Chart
                                                                                    options={pieData.options}
                                                                                    series={pieData.series}
                                                                                    type="donut"
                                                                                    height='210px'
                                                                                    width='210px'
                                                                                />
                                                                                <Grid item container lg={12} justifyContent='center' textAlign='center' pt={2}>
                                                                                    <Grid item container lg={6} sm={6} xs={12}>
                                                                                        <Box display='flex' flexDirection='row' gap={1}>
                                                                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                <Box className={classes.greenDot} />
                                                                                                <Text BlackExtraDark>Consultants</Text>
                                                                                            </Box>
                                                                                            <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                                                                                LocalStorage.removeRedirectedModule();
                                                                                                LocalStorage.removeFromPage();
                                                                                                navigate('/employees', { state: { from: 'consultant' } })
                                                                                            }}
                                                                                            >{totalEmp.consultant}</Text>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item container lg={6} sm={6} xs={12} textAlign='start'>
                                                                                        <Box display='flex' flexDirection='row' gap={1}>
                                                                                            <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                                                <Box className={classes.pinkDot} />
                                                                                                <Text BlackExtraDark>Contractor</Text>
                                                                                            </Box>
                                                                                            <Text largeLightBlue sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => {
                                                                                                LocalStorage.removeRedirectedModule();
                                                                                                LocalStorage.removeFromPage();
                                                                                                navigate('/employees', { state: { from: 'contractor' } })
                                                                                            }}>{totalEmp.contractor}</Text>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                    }
                                                                </Grid>
                                                                <Grid item container lg={6} md={12} sm={12} xs={12} justifyContent='center'>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} pl={2} mb={2}>
                                                                        <Text smallLabelblack16>Clients</Text>
                                                                    </Grid>
                                                                    {
                                                                        companies.length == 0 ?
                                                                            <Grid item container lg={12} md={8} sm={12} xs={12} pt={2} className={classes.nodata} textAlign='center' justifyContent='center'>
                                                                                {NoDataFound('')}
                                                                            </Grid> :
                                                                            <Grid item container textAlign='center' alignItems='center' lg={12} md={12} sm={12} xs={12} spacing={2} mt={1} pl={2} sx={{ maxHeight: '280px', overflowY: 'scroll', "&::-webkit-scrollbar": { display: 'none !important' } }}>
                                                                                {
                                                                                    companies.length > 0 ? companies.map((item, index) => (
                                                                                        <>
                                                                                            <Grid item lg={1} md={1} sm={1} xs={1}><Text black400>{index + 1}</Text></Grid>
                                                                                            <Grid item lg={3} md={3} sm={3} xs={3} textAlign='start'>
                                                                                                <Text largeBlack>
                                                                                                    {
                                                                                                        item.name ? item.name.length > 8 ?
                                                                                                            <BlackToolTip title={capitalizeAndAddSpace(item.name)} placement="top" arrow>
                                                                                                                {capitalizeAndAddSpace(item.name.slice(0, 8)) + capitalizeAndAddSpace(item.name.length > 8 ? "..." : "")}
                                                                                                            </BlackToolTip>
                                                                                                            : capitalizeAndAddSpace(item.name) : '--'
                                                                                                    }
                                                                                                </Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} justifyContent='center'>
                                                                                                <BorderLinearProgress variant="determinate" value={item.percentage} barColor={linearProgress[index].bgColor} bgColor={linearProgress[index].barColor} />
                                                                                            </Grid>
                                                                                            <Grid item lg={4} md={4} sm={4} xs={4} textAlign='right' sx={{ display: 'block' }}>
                                                                                                <AvatarGroup total={item.placement_count} spacing={18} renderSurplus={(surplus) => <span>{surplus.toString()}</span>}
                                                                                                    sx={{
                                                                                                        justifyContent: 'flex-end',
                                                                                                        "& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                                                                            color: '#0C75EB !important',
                                                                                                            backgroundColor: '#D1E1FF !important',
                                                                                                            font: '12px Averta !important',
                                                                                                            fontWeight: `${600} !important`,
                                                                                                        },
                                                                                                        "& .MuiAvatar-root": {
                                                                                                            position: "static !important",
                                                                                                            border: "none !important",
                                                                                                            color: '#0C75EB !important',
                                                                                                            backgroundColor: '#D1E1FF !important',
                                                                                                            font: '12px Averta !important',
                                                                                                            fontWeight: `${600} !important`,
                                                                                                            // marginX: '-10px'
                                                                                                        },
                                                                                                        "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                                                                                                            color: '#0C75EB !important',
                                                                                                            backgroundColor: '#D1E1FF !important',
                                                                                                            font: '12px Averta !important',
                                                                                                            fontWeight: `${600} !important`,
                                                                                                            // marginX: '-10px'
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    {item && item.employees && item.employees.map((avt, avtIndex) =>
                                                                                                        <Avatar alt={`Avatar ${avtIndex + 1}`} src={avt ? avt.profile_picture_url : ''} sx={{ width: "35px", height: "35px" }} />)}
                                                                                                </AvatarGroup>
                                                                                            </Grid>
                                                                                        </>
                                                                                    )) : ''
                                                                                }
                                                                            </Grid>
                                                                    }
                                                                    {
                                                                        companies.length > 1 &&
                                                                        <Grid item container lg={10} md={10} sm={10} xs={10} pt={3} justifyContent='end'>
                                                                            <Box minWidth='55px' textAlign='end'>
                                                                                <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/clients')}>View All</Text>
                                                                            </Box>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </> : ''
                                                    }
                                                </Grid>
                                                <Hidden lgDown>
                                                    <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
                                                </Hidden>
                                            </Grid>
                                            {
                                                ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                                                    <>
                                                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} alignItems='center'>
                                                            <Grid item lg={10} md={9} sm={8} xs={6}>
                                                                <Text smallLabelblack16>Addition & Attrition Rate</Text>
                                                            </Grid>
                                                            <Grid item lg={2} md={3} sm={4} xs={6}>
                                                                <CustomSelect
                                                                    name='placementYear'
                                                                    value={yearDropdwn.placementYear}
                                                                    viewDrop
                                                                    scrollTrue={true}
                                                                    options={years}
                                                                    onChange={(e) => dropDownChangeHandler(e, '')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={12} xs={12} spacing={2} pt={2}>
                                                            <ReactApexChart
                                                                options={areaOptions}
                                                                series={areaOptions.series}
                                                                type="area"
                                                                height='300px'
                                                            />
                                                        </Grid>
                                                        <Grid item container lg={12} xs={12}>
                                                            <Grid item container lg={6} md={12} sm={12} xs={12} className={classes.borderRight}>
                                                                <Grid item lg={12} xs={12}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.greenDot} />
                                                                        <Text overViewLable>Placement Added</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={12} xs={12} py={1}>
                                                                    <Text largeblack40>{placementDetails.total_placements_added}</Text>
                                                                </Grid>
                                                                <Grid item container lg={12} xs={12}>
                                                                    {
                                                                        placementsAdded.length > 0 && placementsAdded.map((item) => (
                                                                            <Grid item container lg={12} xs={12} py='6px'>
                                                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                    <Text smallBlack400>{item.name}</Text>
                                                                                </Grid>
                                                                                <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                                                                <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                                                                    <Text black12>{item.count}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={6} className={classes.paddingLeft}>
                                                                <Grid item lg={12} xs={12}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.yellowDot} />
                                                                        <Text overViewLable>Placement Completed</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={12} xs={12} py={1}>
                                                                    <Text largeblack40>{placementDetails.total_placements_completed}</Text>
                                                                </Grid>
                                                                <Grid item container lg={12} xs={12}>
                                                                    {
                                                                        placementsCompleted.map((item) => (
                                                                            <Grid item container lg={12} xs={12} py='6px'>
                                                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                                    <Text smallBlack400>{item.name}</Text>
                                                                                </Grid>
                                                                                <Grid item lg={1} md={1} sm={1} xs={1}>-</Grid>
                                                                                <Grid item lg={4.5} md={4.5} sm={4.5} xs={4.5} textAlign='end'>
                                                                                    <Text black12>{item.count}</Text>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2} justifyContent='end'>
                                                                <Box minWidth='55px' textAlign='end'>
                                                                    <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/placements')}>View All</Text>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider sx={{ width: '100%', margin: '30px 0px 20px 0px', borderColor: '#F5F5F5' }} />
                                                    </> : ''
                                            }
                                            {
                                                ((LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "dashboard_view" && item.is_allowed == true)))) ?
                                                    <Grid item container lg={12} xs={12} pb={5} justifyContent='center'>
                                                        <Grid item lg={5} className={classes.borderRight}>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Text smallLabelblack16>Timesheets</Text>
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                                <ReactApexChart
                                                                    options={timesheetOptions.options}
                                                                    series={timesheetOptions.series}
                                                                    type="pie"
                                                                    height='250px'
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container lg={7} md={12} sm={12} xs={12} textAlign='center' justifyContent='center' alignItems='center' className={classes.pendingTSheight}>
                                                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.blueDot} />
                                                                        <Text blackHeader1>Drafted</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigate('/timesheet', { state: { status: 'Draft' } })}>{tsData.drafted}</Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.yellowDot} />
                                                                        <Text blackHeader1>Pending for approval</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigate('/timesheet', { state: { status: 'Pending Approval' } })}>{tsData.pending_approval}</Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.greenDot} />
                                                                        <Text blackHeader1>Approved</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigate('/timesheet', { state: { status: 'Approved' } })}>{tsData.approved}</Text>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container lg={9} md={12} xs={12} className={classes.noHeight}>
                                                                <Grid item lg={9} md={8} sm={9} xs={7}>
                                                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center' textAlign='end'>
                                                                        <Box className={classes.pinkDot} />
                                                                        <Text blackHeader1>Rejected</Text>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item lg={3} md={4} sm={3} xs={5} textAlign='end'>
                                                                    <Text largeLightBlue className={classes.tsCount} onClick={() => navigate('/timesheet', { state: { status: 'Rejected' } })}>{tsData.rejected}</Text>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> : ''
                                            }
                                        </Grid>
                                        <Grid item container lg={4} md={4} sm={12} xs={12} p={'0px 10px 0px 20px !important'}>
                                            <Grid item lg={12} sm={12} xs={12} pt={2}>
                                                <Text mediumBoldBlack600>Actions</Text>
                                            </Grid>
                                            <Grid item container lg={12} sm={12} xs={12} pt={3}>
                                                <Box className={classes.cardContainer}>
                                                    {
                                                        loaderAction ?
                                                            [0, 1, 2, 3].map((i) => (
                                                                <Box className={classes.sidecard}>
                                                                    <Stack direction={'row'} justifyContent={'space-between'} p={'10px'} alignContent={'center'} alignItems={'center'}>
                                                                        <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'}>
                                                                            <Skeleton animation='wave' width='40px' height='65px' style={{ borderRadius: '50%' }} />
                                                                            <Stack gap='8px'>
                                                                                <Skeleton animation='wave' width='100px' />
                                                                                <Skeleton animation='wave' width='100px' />
                                                                            </Stack>
                                                                        </Stack>
                                                                        <Stack alignContent={'center'} alignItems={'center'}>
                                                                            <Skeleton animation='wave' width='50px' />
                                                                        </Stack>
                                                                    </Stack>
                                                                    <Divider sx={{ width: '100%', border: '1px solid #F5F5F5' }} />
                                                                    <Stack direction={'row'} justifyContent={'space-between'} gap={2} alignContent={'center'} alignItems={'center'} p={'10px'}>
                                                                        <Stack direction={'column'} justifyContent={'space-between'}>
                                                                            <Skeleton animation='wave' width='100px' />
                                                                            <Skeleton animation='wave' width='100px' />
                                                                        </Stack>
                                                                        <Skeleton animation='wave' width='100px' />
                                                                    </Stack>
                                                                </Box>
                                                            ))
                                                            :
                                                            Reminders.length == 0 ?
                                                                NoDataFound('') :
                                                                <Grid item lg={12} xs={12} className={classes.cardContainer} ref={scrollBox} onScroll={handleScroll}>
                                                                    {
                                                                        Reminders.length > 0 ? Reminders.map((item) => (
                                                                            <Grid item container ml={.5} lg={11} xs={12}
                                                                                onClick={() => navigatePending(item)}
                                                                                className={classes.sideCardbg}
                                                                            >
                                                                                <Grid item container lg={12} xs={12} p={2}>
                                                                                    <Grid item lg={2} md={3} sm={1} xs={4}>
                                                                                        {/* <img src={item.profile_picture_url} alt="profile" style={{ height: '40px', width: '40px', borderRadius: '50%' }} /> */}                                                                                        
                                                                                        <Avatar src={item.profile_picture_url} alt={capitalizeAndAddSpace(item.display_name[0])} sx={{ height: '40px', width: '40px', borderRadius: '50%' }} />
                                                                                    </Grid>
                                                                                    <Grid item lg={7} md={8} sm={7} xs={8}>
                                                                                        <Text dashboardblack14>
                                                                                            {
                                                                                                item.display_name ? item.display_name.length > 16 ?
                                                                                                    <BlackToolTip title={capitalizeAndAddSpace(item.display_name)} placement="top" arrow>
                                                                                                        {capitalizeAndAddSpace(item.display_name.slice(0, 16)) + capitalizeAndAddSpace(item.display_name.length > 16 ? "..." : "")}
                                                                                                    </BlackToolTip>
                                                                                                    : capitalizeAndAddSpace(item.display_name) : '--'
                                                                                            }
                                                                                            {
                                                                                                item.employee_e_verified != 0 ?
                                                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has Been E-Verified</Text>}>
                                                                                                        <img src={Verified} alt='Verified' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                                    </BlackToolTip>
                                                                                                    :
                                                                                                    <BlackToolTip arrow placement='right' title={<Text smallWhite>Profile Has not E-Verified</Text>}>
                                                                                                        <img src={disableVerified} alt='svg' style={{ margin: '0px 0px -3px 3px' }} />
                                                                                                    </BlackToolTip>
                                                                                            }

                                                                                        </Text>
                                                                                        {
                                                                                            (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                                                                <Text greysmallLabel>{item.reference_id}</Text> :
                                                                                                <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                                                                                    <Text greysmallLabel>{item.placement_reference_id}</Text>
                                                                                                    <Box className={classes.greyDot} />
                                                                                                    <Text greysmallLabel>{item.reference_id ? item.reference_id : '-'}</Text>
                                                                                                </Box>
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid item lg={2.5} md={12} sm={4} xs={12}>
                                                                                        <Box textAlign='center' className={item.status == 'Approved' ? classes.statusBox : item.status == 'Requested' || item.status == 'Pending Approval' ? classes.orangeBox : item.status == 'Rejected' ? classes.redBox : classes.drafted}>
                                                                                            <Text mediumWhite400>{item.status == 'Pending Approval' ? 'Pending' : item.status}</Text>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Divider sx={{ width: '100%', margin: '10px 0px', border: '1px solid #F5F5F5' }} />
                                                                                    {
                                                                                        (item.placement_id == '' || item.placement_id == null || item.placement_id == undefined) ?
                                                                                            <>
                                                                                                <Grid item lg={7} md={6} sm={6} xs={6}>
                                                                                                    <Text greysmallLabel>Employee Type</Text>
                                                                                                    <Text dashboardblack14>{item.employee_type ? item.employee_type : '-'}</Text>
                                                                                                </Grid>
                                                                                                <Grid item container lg={5} md={6} sm={6} xs={6} className={classes.textCenter} alignItems='center' justifyContent='end'>
                                                                                                    <Text mediumBlue600>Profile Update</Text>
                                                                                                </Grid>
                                                                                            </> :
                                                                                            <>
                                                                                                <Grid item lg={6.5} md={6} sm={6} xs={12}>
                                                                                                    <Text greysmallLabel>Client Name</Text>
                                                                                                    <Text dashboardblack12>
                                                                                                        {
                                                                                                            item.client_name ? item.client_name.length > 16 ?
                                                                                                                <BlackToolTip title={capitalizeAndAddSpace(item.client_name)} placement="top" arrow>
                                                                                                                    {capitalizeAndAddSpace(item.client_name.slice(0, 16)) + capitalizeAndAddSpace(item.client_name.length > 16 ? "..." : "")}
                                                                                                                </BlackToolTip>
                                                                                                                : capitalizeAndAddSpace(item.client_name) : '--'
                                                                                                        }
                                                                                                    </Text>
                                                                                                </Grid>
                                                                                                <Grid item container lg={5.5} md={6} sm={6} xs={12} alignContent={'center'} justifyContent='end'>
                                                                                                    <Text mediumBlue600>Timesheet</Text>
                                                                                                </Grid>
                                                                                            </>
                                                                                    }
                                                                                </Grid>

                                                                            </Grid>
                                                                        )) : ''
                                                                    }
                                                                </Grid>

                                                    }
                                                </Box>
                                                {/* {
                                                    Reminders.length == 0 ? '' : */}
                                                <Grid item container lg={11} md={10} sm={10} xs={10} pt={2} justifyContent='center'>
                                                    <Box minWidth='55px' textAlign='end'>
                                                        <Text analyticsViewAll sx={{ textDecoration: '1px underline #0C75EB', cursor: 'pointer' }} onClick={() => navigate('/pending-actions', { state: { from: 'viewAll', placementId: '' } })}>View All</Text>
                                                    </Box>
                                                </Grid>
                                                {/* } */}
                                            </Grid>
                                        </Grid>
                                        <ReusablePopup openPopup={success} setOpenPopup={setSuccess} white fixedWidth iconHide>
                                            <Grid container spacing={2} textAlign='center'>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <img src={successImg} alt="success" />
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Text mediumGreen>Reminder Added Successfully</Text>
                                                </Grid>
                                                <Grid item container spacing={2} lg={12} md={12} sm={12} xs={12} mt={1}>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Button blackCancel onClick={() => navigate('/self-Remainder')}>View All Reminders</Button>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Button blueButton onClick={() => { setSuccess(false) }}>Go To Dashboard</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </ReusablePopup>
                                    </Grid>
                        }
                    </Grid> :
                    <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} pl={15} pt={2} alignItems='flex-start'>
                        <Text boldblack22>Hi, {LocalStorage.getUserData().full_name}</Text>
                        {NoPermission()}
                    </Grid>
            }
        </Grid >
    )
};
