import APIURL from "../../../config/development";
import LocalStorage from "../../../utils/LocalStorage";
import BaseApi from "../../BaseApi";

class TimesheetApi {

    timesheetStore(data) {
        return BaseApi.postWithToken(APIURL.timesheet_URL + `timesheets/store`, data, LocalStorage.getAccessToken())
    }
    timesheetUpdate(id, data) {
        return BaseApi.putWithToken(APIURL.timesheet_URL + `timesheets/update/${id}`, data, LocalStorage.getAccessToken())
    }
    timesheetExistsCheck(data) {
        const { placement_id, start_date } = data
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheet/duplicate-check?request_id=${LocalStorage.uid()}&placement_id=${placement_id}&start_date=${start_date}`, LocalStorage.getAccessToken());
    }
    timesheetDelete(timesheet_id) {
        return BaseApi.deleteWithToken(APIURL.timesheet_URL + `timesheet/delete/${timesheet_id}`, { "request_id": LocalStorage.uid(), "id": timesheet_id }, LocalStorage.getAccessToken())
    }
    timesheetChangeStatus(id, data) {
        return BaseApi.putWithToken(APIURL.timesheet_URL + `timesheets/status/${id}`, data, LocalStorage.getAccessToken())
    }
    getCardListingDetails() {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/dashboard?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }
    timesheetListView(data) {
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.timesheet_URL + 'timesheets/listing', data, LocalStorage.getAccessToken())
    }
    timesheetListViewConsultant(data) {
        data.request_id = LocalStorage.uid();
        return BaseApi.postWithToken(APIURL.timesheet_URL + 'consultant/timesheets/listing', data, LocalStorage.getAccessToken())
    }
    exportTimesheets(status) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/export?request_id=${LocalStorage.uid()}&status=${status}`, LocalStorage.getAccessToken())
    }
    exportTimesheetsConsultant(status) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/export?request_id=${LocalStorage.uid()}&status=${status}&employee_id=${LocalStorage.getUserData().login_id}`, LocalStorage.getAccessToken())
    }
    exportTimesheetsSummary(status) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/summary/export?request_id=${LocalStorage.uid()}&year=${status.year}&month=${status.month}`, LocalStorage.getAccessToken())
    }
    exportTimesheetsSummaryConsultant(status) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/summary/export?request_id=${LocalStorage.uid()}&year=${status.year}&month=${status.month}&employee_id=${status.employee_id}`, LocalStorage.getAccessToken())
    }
    timesheetsActivityTrack(search) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheet/activity/listing?request_id=${LocalStorage.uid()}&search=${search}`, LocalStorage.getAccessToken())
    }
    timesheetsActivityTrackEmployee(search, employee_id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheet/activity/listing?request_id=${LocalStorage.uid()}&search=${search}&employee_id=${employee_id}`, LocalStorage.getAccessToken())
    }
    timesheetsActivityTrackEmployeeConsultant(search, employee_id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheet/activity/listing?request_id=${LocalStorage.uid()}&search=${search}&employee_id=${employee_id}`, LocalStorage.getAccessToken())
    }
    getAllPlacements(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/dropdown`,data, LocalStorage.getAccessToken())
    }
    getAllPlacementsEmployee(data) {
        return BaseApi.postWithToken(APIURL.placement_URL + `placement/dropdown`,data, LocalStorage.getAccessToken())
    }
    getTimesheet(id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    getTimesheetConsultant(id) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/index?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken());
    }
    downLoadActivity() {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/activity/export?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken())
    }

    // OCR API'S

    getTimesheetOcrData(urls) {
        return BaseApi.ocrPost(APIURL.org_URL + `ocr/timesheet`,{urls}, LocalStorage.getAccessToken())
    }

    storeOcrActivtyData(data){
        return BaseApi.ocrPost(APIURL.Chatbot_URL + `ocr/timesheet/activity_log`,data, LocalStorage.getAccessToken())
    }

    // Old APIS



    timesheetweeklyView(data, paginationData) {
        data.request_id = LocalStorage.uid();
        data.page = paginationData.currentPage;
        data.limit = paginationData.perPage;

        return BaseApi.postWithToken(APIURL.API_URL + 'timesheet/weeks-view', data, LocalStorage.getAccessToken())
    }

    timesheetcalendarView(data) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/summary/index?request_id=${LocalStorage.uid()}&year=${data.year}&month=${data.month}&placement_id=${data.id}&start_date=${data.from_date}&end_date=${data.to_date}`, LocalStorage.getAccessToken())
    }

    timesheetcalendarViewConsultant(data) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/summary/index?request_id=${LocalStorage.uid()}&year=${data.year}&month=${data.month}&placement_id=${data.id}&start_date=${data.from_date}&end_date=${data.to_date}`, LocalStorage.getAccessToken())
    }

    //table Api when we click on months in cards
    getCardListingDateDetails(obj) {
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/listing?request_id=${LocalStorage.uid()}&ts_status=${obj.path}&status=${obj.status}&placement_id=${obj.placementId}&employee_id=${obj.employeeId}&client_id=${obj.clientId}&end_client_id=${obj.endClientId}&employee_name=${obj.employeeName}&client_name=${obj.clientName}&end_client_name=${obj.endClientName}&search=${obj.search}&from_date=${obj.startDate}&to_date=${obj.endDate}`, LocalStorage.getAccessToken())
    }

    // getListingDetails(status, placementId, employeeId,clientId,endClientId,employeeName,clientName,endClientName,search) {
    //search Api 
    getListingDetails(obj) {

        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/listing?request_id=${LocalStorage.uid()}&ts_status=${obj.path}&status=${obj.status}&placement_id=${obj.placementId}&employee_id=${obj.employeeId}&client_id=${obj.clientId}&end_client_id=${obj.endClientId}&employee_name=${obj.employeeName}&client_name=${obj.clientName}&end_client_name=${obj.endClientName}&search=${obj.search}`, LocalStorage.getAccessToken())

    }
    //count corresponding to the dates
    getFilterDetails(obj) {
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/dashboard/cards/${obj.path}?request_id=${LocalStorage.uid()}&from_date=${obj.startDate}&to_date=${obj.endDate}`, LocalStorage.getAccessToken())
    }
    //send a remainder
    remainderUpdate(data, token) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/reminder`, data, token)
    }

    getTimesheetListing(params, pagination) {
        let { slug, from_date, to_date, search, } = params;
        let { currentPage, perPage } = pagination;
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/listing?request_id=${LocalStorage.uid()}&ts_status=${slug == "total_timesheets" ? "" : slug}&search=${search}&from_date=${from_date}&to_date=${to_date}&page=${currentPage}&limit=${perPage}`, LocalStorage.getAccessToken())
    }


    getTimesheetViewData(data, view) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheet/${view}`, data, LocalStorage.getAccessToken());
    }

    getFilterTimesheet(params) {
        // return BaseApi.getWithParams(APIURL.API_URL + `timesheets/dashboard/cards/${params.slug}?request_id=${LocalStorage.uid()}&from_date=${params.from_date}&to_date=${params.to_date}`, LocalStorage.getAccessToken());
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/dashboard/cards/?request_id=${LocalStorage.uid()}&from_date=${params.from_date}&to_date=${params.to_date}`, LocalStorage.getAccessToken());

    }

    getTimesheetCount() {
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/dashboard/cards/?request_id=${LocalStorage.uid()}&from_date=${""}&to_date=${""}`, LocalStorage.getAccessToken());
    }

    storeTimesheet(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/store`, data, LocalStorage.getAccessToken());
    }

    updateTimesheet(id, data) {
        return BaseApi.putWithToken(APIURL.API_URL + `timesheets/update/${id}`, data, LocalStorage.getAccessToken())
    }

    updateTimesheetStatus(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/status`, data, LocalStorage.getAccessToken());
    }

    sendTimesheetReminder(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/reminder`, data, LocalStorage.getAccessToken());
    }

    sendTimesheetNotification(data) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/notification`, data, LocalStorage.getAccessToken());
    }

    //delete timeSheet
    deleteTimesheet(id, data) {
        return BaseApi.deleteWithToken(APIURL.API_URL + `timesheet/delete/${id}`, data, LocalStorage.getAccessToken())

    }
    //Approve or reject
    approvalUpdate(id, data) {
        return BaseApi.putWithToken(APIURL.timesheet_URL + `timesheets/status/${id}`, data, LocalStorage.getAccessToken())
    }

    getInvoice(timesheet_id) {
        return BaseApi.getWithParams(APIURL.API_URL + `timesheets/invoice-ready-data/${timesheet_id}?request_id=${LocalStorage.uid()}`, LocalStorage.getAccessToken());
    }

    // /api/v1 / placement / timesheets / approved - users ? request_id = {{ $guid }}& id=3baf54c1 -0818 - 496f - b4db - 961fdd13c3c7


    getApprovedByData(id) {
        return BaseApi.getWithParams(APIURL.API_URL + `placement/timesheets/approved-users?request_id=${LocalStorage.uid()}&id=${id}`, LocalStorage.getAccessToken())
    }
    employeesDropdown() {
        return BaseApi.getWithParams(APIURL.employee_URL + `employee/dropdown?request_id=${LocalStorage.uid()}&timesheet_available=true`, LocalStorage.getAccessToken());
    }

    getActivity(params) {
        let { page, limit } = params
        return BaseApi.getWithParams(APIURL.API_URL + `activity/timesheet/listing?request_id=${LocalStorage.uid()}&page=${page}&limit=${limit}`, LocalStorage.getAccessToken());
    }

    storeTimesheetOcr(data, token) {
        return BaseApi.postWithToken(APIURL.API_URL + `timesheets/ocr/store`, data, token)
    }
    summaryListing(data) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `timesheets/summary/listing?request_id=${LocalStorage.uid()}&year=${data.year}&month=${data.month}&limit=${data.limit}&search=${data.search}`, LocalStorage.getAccessToken())
    }
    summaryListingEmployee(data) {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/summary/listing?request_id=${LocalStorage.uid()}&year=${data.year}&month=${data.month}&limit=${data.limit}&search=${data.search}&employee_id=${data.employee_id}`, LocalStorage.getAccessToken())
    }
    downloadActivityConsultant() {
        return BaseApi.getWithParams(APIURL.timesheet_URL + `consultant/timesheets/activity/export?request_id=${LocalStorage.uid()}&employee_id=${LocalStorage.getUserData().login_id}`, LocalStorage.getAccessToken())
    }
}
// eslint-disable-next-line
export default new TimesheetApi();