import { makeStyles } from "@mui/styles";
import { blue, btnBgBlue, btnBgGrey, btnBgRed, btnStroke, btnTxtBlack, btnTxtBlue, btnTxtWhite, cancelRed, grey, greyButton, greyColors, lightGrey, lightGreyText, orange, redButton } from "../../theme";
import { useTheme } from "@mui/material";

const ButtonStyles = makeStyles((theme) => ({
    mediumInvite: {
        height: '40px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        minWidth: '135px !important',
        borderRadius: '14px !important',
        border: `1px solid ${blue} !important`,
        fontSize: "13px !important",
        fontFamily: "Averta",
        fontWeight: "400",
        width: "150px",
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        }, '&:disabled': {
            opacity: '0.8'
        }
    },
    onboardBtn: {
        height: '40px !important',
        // background: `${blue} !important`,
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '120px',
        borderRadius: '12px !important',
        fontSize: "13px !important",
        fontFamily: "Averta",
        fontWeight: "400",
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },
    outlineBlueMedium1: {
        all: "unset",
        cursor: "pointer",
        textAlign: "center",
        fontSize: "16px !important",
        fontFamily: "Averta  !important",
        fontWeight: `${600} !important`,
        width: "150px",
        height: "40px",
        border: "1.5px solid #0C75EB !important",
        borderRadius: "12px !important",
        color: "#0C75EB !important",
        transition: "all .3s ease",
        textTransform: 'capitalize !important',
        // '&:hover': {
        //     backgroundColor: '#0C75EB !important', color: '#FFFFFF !important'
        // },
    },
    blackCancel: {
        font: "14px Averta !important",
        color: `${btnTxtBlack.shade4} !important`,
        fontWeight: `${600} !important`,
        textTransform: "none !important",
        border: `1px solid ${greyColors.shade1} !important`,
        borderRadius: "8px !important",
        // minWidth: "85px !important",
        padding: '0px 40px !important',
        height: "42px !important",
        background: '#FFFFFF !important',
        variant: "outlined",
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${600} !important`,
        }
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    borderlessSkip: {
        font: "14px Averta !important",
        color: `${btnTxtBlack.shade8} !important`,
        fontWeight: `${600} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        // minWidth: "85px !important",
        height: "42px !important",
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    brownMnSave: {
        font: "15px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "85px !important",
        height: "42px !important",
        variant: "outlined",
        fontWeight: '500px !important',
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${500} !important`
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    blueButton: {
        font: "14px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "42px !important",
        width: '100% !important',
        variant: "outlined",
        fontWeight: `${700} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
        }
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    addButton: {
        font: "15px Averta !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "150px !important",
        height: "45px !important",
        fontWeight: `${700} !important`,
        variant: "outlined",
        "&:hover": {
            transform: "scale3d(1.05, 1.05, 1)",
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '10px Averta !important',
            fontWeight: `${700} !important`,
            height: "45px !important",
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${700} !important`,
        }
    },
    addButtonDisable: {
        font: "14px Averta !important",
        background: `#FFFFFF !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        color: `${btnBgGrey.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "150px !important",
        height: "45px !important",
        variant: "outlined",
        cursor: 'not-allowed !important'

    },
    addButtonmd: {
        font: "14px Averta !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "95px !important",
        height: "45px !important",
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    // Full Width
    lightBlue: {
        font: "14px Averta !important",
        background: `#F5F9FF !important`,
        color: `${blue} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "35px !important",
        width: '100% !important',
        fontWeight: `${600} !important`,
        variant: "outlined",
        "&:hover": {
            // transform: "scale3d(1.05, 1.05, 1)",
            background: `${btnBgBlue.shade3} !important`
        }
    },
    save: {
        font: "15px Averta !important",
        height: '40px !important',
        color: '#FFFFFF !important',
        textTransform: 'capitalize !important',
        minWidth: '120px !important',
        borderRadius: '8px !important',
        background: `${blue} !important`,
        fontWeight: "400",
        width: "150px",
        // '&:hover': {
        //     border: `1px solid ${blue} !important`,
        //     backgroundColor: '#FFFFFF !important',
        //     color: `${blue} !important`
        // },
    },

    addNew: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "4px !important",
        minWidth: "159px !important",
        height: "42px !important",
        variant: "outlined",
    },
    addNewDisable: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: `#fff !important`,
        color: `${btnBgGrey.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "159px !important",
        height: "42px !important",
        variant: "outlined",
        border: `1px solid ${btnBgGrey.shade4} !important`,
        cursor: 'not-allowed !important'
    },
    rejectDisable: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: `#fff !important`,
        color: `${btnBgGrey.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "100px !important",
        height: "42px !important",
        variant: "outlined",
        border: `1px solid ${btnBgGrey.shade4} !important`,
        cursor: 'not-allowed !important'
    },
    iconRemoveBtn: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: ` #E51A1A !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "159px !important",
        height: "42px !important",
        variant: "outlined",
    },

    EditBlue: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "105px !important",
        height: "45px !important",
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${600} !important`,
            height: "35px !important",
            minWidth: "95px !important",
        },
    },

    outlineBlue: {
        height: '40px !important',
        font: '16px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '120px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        // '&:hover': {
        //     background: `${blue} !important`,
        //     color: '#FFFFFF !important'
        // },
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${600} !important`
        }
    },
    outlineBlueSmall: {
        height: '33px !important',
        font: '14px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '100px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },

    saveBtn: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "89px !important",
        height: "42px !important",
        variant: "outlined",
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    saveBtnDisable: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: `#fff !important`,
        color: `${btnBgGrey.shade4} !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "121px !important",
        height: "48px !important",
        variant: "outlined",
        cursor: 'default !important'
    },

    cancelBtn: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` #525167 !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        // border: `1px solid ${btnBgRed.shade2} !important`,
        minWidth: "121px !important",
        height: "48px !important",
        variant: "outlined",
        // '&:hover': {
        //     background: `${btnBgRed.shade4} !important`,
        //     color: '#FFFFFF !important',
        // },
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`
        }
    },
    cancelBtnBorder: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` #525167 !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #525167 !important`,
        // border: 1px solid #525167
        minWidth: "121px !important",
        height: "48px !important",
        variant: "outlined",
        // '&:hover': {
        //     border: `1px solid #FFFFFF !important`,
        //     background: `${btnBgRed.shade4} !important`,
        //     color: '#FFFFFF !important',
        // },
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`
        }
    },

    browseBtn: {
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${btnBgBlue.shade3} !important`,
        color: `${btnStroke.blue} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "111px !important",
        height: "34px !important",
        variant: "outlined",
        stroke: `${btnStroke.blue} !important`,
        '&:hover': {
            // background: `${btnTxtWhite} !important`,
            // border: `1px solid ${blue} !important`,
            // color: `${blue} !important`,
        },
        '&:disabled': {
            font: "16px Averta !important",
            fontWeight: `${400} !important`,
            background: ` ${btnBgGrey.shade1} !important`,
            color: `#FFFFFF !important`,
            textTransform: "none !important",
            borderRadius: "8px !important",
            minWidth: "111px !important",
            height: "34px !important",
            variant: "outlined",
            stroke: '#FFFFFF !important',
        }

    },

    browseBtnUpload: {
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${btnBgBlue.shade3} !important`,
        color: `#5DA5F5 !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "111px !important",
        height: "34px !important",
        variant: "outlined",
        stroke: `${btnStroke.blue} !important`,
        '&:hover': {
            // background: `${btnTxtWhite} !important`,
            // border: `1px solid ${blue} !important`,
            // color: `${blue} !important`,
        },
        '&:disabled': {
            font: "16px Averta !important",
            fontWeight: `${400} !important`,
            background: ` ${btnBgGrey.shade1} !important`,
            color: `#FFFFFF !important`,
            textTransform: "none !important",
            borderRadius: "8px !important",
            minWidth: "111px !important",
            height: "34px !important",
            variant: "outlined",
            stroke: '#FFFFFF !important',
        }

    },


    saveLoader: {
        background: `${blue} !important`,
        borderRadius: "8px !important",
        height: "48px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "140px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    saveLoaderDisable: {
        background: `#fff !important`,
        borderRadius: "8px !important",
        height: "48px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: `${btnBgGrey.shade4} !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        minWidth: "140px !important",
        cursor: 'default !iportant',
        "& .MuiLoadingButton-loadingIndicator": {
            color: `${btnBgGrey.shade4} !important`,
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "default !important",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    disable: {
        height: '33px !important',
        font: '14px Averta !important',
        color: `${lightGrey} !important`,
        border: `1px solid ${lightGrey} !important`,
        textTransform: 'capitalize !important',
        minWidth: '100px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        cursor: 'default !important'
    },
    add: {
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        borderRadius: "8px !important",
        minWidth: "42px !important",
        height: "42px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: 'center !important',
        margin: "0px !important"
    },

    remove: {
        fontWeight: `${600} !important`,
        background: ` ${btnBgRed.shade2} !important`,
        borderRadius: "8px !important",
        minWidth: "42px !important",
        height: "42px !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: 'center !important',
        margin: "0px !important"
    },



    clearAll: {
        justifySelf: 'end',
        background: `${btnBgGrey.shade5} !important`,
        borderRadius: "8px !important",
        height: '28px !important',
        opacity: 1,
        textTransform: "capitalize !important",
        font: "12px Averta !important",
        fontWeight: `${400} !important`,
        color: "#181A18 !important",
        minWidth: "92px !important",
    },

    saveSmall: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "125px !important",
        height: "35px !important",
        variant: "outlined",
        '&:hover': {
            // background: `${btnTxtWhite} !important`,
            // border: `1px solid ${blue} !important`,
            // color: `${blue} !important`,
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    cancelSmall: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnTxtBlack.shade2} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        // border: `1px solid ${btnBgRed.shade2} !important`,
        minWidth: "91px !important",
        height: "35px !important",
        variant: "outlined",
        '&:hover': {
            background: `${btnBgRed.shade2} !important`,
            color: '#FFFFFF !important',
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },

    exportSmall: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "91px !important",
        height: "35px !important",
        variant: "outlined",

    },

    BorderBlueButton: {
        height: '35px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        minWidth: '120px !important',
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "14px Averta !important",
        fontWeight: `${500} !important`,
    },
    popupSaveBlue: {
        font: "14px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "36px !important",
        width: '100px !important',
        variant: "outlined",
        fontWeight: `${700} !important`,
        '&:disabled': {
            opacity: '0.8'
        }
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    popupSaveBlueHeight: {
        font: "16px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "43px !important",
        width: '100px !important',
        variant: "outlined",
        fontWeight: `${700} !important`,
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    popupSaveBlueDisable: {
        font: "14px Averta !important",
        background: `#fff !important`,
        color: `${btnBgGrey.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "36px !important",
        width: '100px !important',
        variant: "outlined",
        fontWeight: `${500} !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        cursor: 'default !important'
    },
    popupCancel: {
        font: "15px Averta !important",
        color: `${blue} !important`,
        textTransform: "none !important",
        border: `1px solid ${blue} !important`,
        borderRadius: "8px !important",
        width: '100px !important',
        height: "36px !important",
        fontWeight: `${500} !important`,
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    popupCancelRed: {
        font: "15px Averta !important",
        color: `${redButton} !important`,
        textTransform: "none !important",
        border: `1px solid ${redButton} !important`,
        borderRadius: "8px !important",
        height: "43px !important",
        width: '100px !important',
        fontWeight: `${700} !important`,
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    addHeightButton: {
        font: "16px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "150px !important",
        height: "53px !important",
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    saveAsDraft: {
        color: `${blue} !important`,
        height: "42px !important",
        font: "14px Averta !important",
        background: '#FFFFFF !important',
        textTransform: 'capitalize !important',
        fontWeight: `${500} !important`,
        borderRadius: "8px !important",
        // "&:hover": {
        //     background: `${blue} !important`,
        //     color: '#FFFFFF !important'
        // }
    },
    greyButton: {
        font: "16px Averta !important",
        background: `${greyButton} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "85px !important",
        height: "42px !important",
        variant: "outlined",
    },
    groupBtn: {
        font: "16px Averta !important",
        textTransform: 'capitalize !important',
        fontWeight: "600 !important",
        borderColor: `#E2E5E6 !important`,
        color: `${greyButton} !important`,
        "&:focus": {
            color: `#ffffff !important`,
            boxShadow: 'none',
            backgroundColor: `${blue} !important`,
            borderColor: `${blue} !important`,
        },
        "&:active": {
            color: `#ffffff !important`,
            boxShadow: 'none',
            backgroundColor: `${blue} !important`,
            borderColor: `${blue} !important`,
        },
        // "& .makeStyles-groupBtn-163": {
        //     '&:active': {
        //         color: `red !important`,
        //         boxShadow: 'none',
        //         backgroundColor: `${blue} !important`,
        //         borderColor: `${blue} !important`,
        //     },
        // }
    },
    clearall: {
        font: "16px Averta !important",
        fontWeight: `${580} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnBgRed.shade2} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        // border: `1px solid ${btnBgRed.shade2} !important`,
        // minWidth: "121px !important",
        minWidth: "85px !important",
        height: "42px !important",
        // height: "48px !important",
        variant: "outlined",
        // '&:hover': {
        //     background: `${btnBgRed.shade4} !important`,
        //     color: '#FFFFFF !important',
        // },
    },
    texutalCancel: {
        font: "18px Averta !important",
        fontWeight: `${590} !important`,
        color: ` ${btnTxtBlack.shade1} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "85px !important",
        height: "42px !important",
        variant: "outlined",
        '&:hover': {
            background: `none !important`,
        },
    },
    no: {
        height: '40px !important',
        font: '16px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        // width: '120px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },

    deleteBtn: {
        background: `#F85036 !important`,
        borderRadius: "8px !important",
        height: "40px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "168px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    popupDelete: {
        background: `${blue} !important`,
        borderRadius: "8px !important",
        height: "42px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: '16px Averta !important',
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "168px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    outlinedSmallAdd: {
        height: '36px !important',
        font: '14px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '80px',
        // borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },
    popupCancelHeight: {
        font: "15px Averta !important",
        color: `${blue} !important`,
        textTransform: "none !important",
        border: `1px solid ${blue} !important`,
        borderRadius: "8px !important",
        width: '100px !important',
        height: "46px !important",
        fontWeight: `${500} !important`,
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    editButton: {
        font: "14px Averta !important",
        textTransform: "capitalize !important",
        height: "28px",
        width: "100px",
        padding: "0px 18px !important",
        background: `${blue} !important`,
        color: '#fff !important',
    },
    editButtonDisable: {
        font: "14px Averta !important",
        textTransform: "capitalize !important",
        height: "28px",
        width: "100px",
        padding: "0px 18px !important",
        background: `#fff !important`,
        color: `${btnBgGrey.shade4} !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        cursor: 'not-allowed !important'
    },
    viewButton: {
        font: "14px Averta !important",
        textTransform: "capitalize !important",
        height: "28px",
        width: "100px",
        padding: "0px 18px !important",
        border: `1px solid ${blue} !important`,
        color: `${blue} !important`,
        background: '#FFFFFF !important'
    },

    outlineLoader: {
        background: ` ${btnBgRed.shade4} !important`,
        borderRadius: "8px !important",
        height: "40px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "110px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '0px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    smallSaveLoader: {
        background: `${blue} !important`,
        borderRadius: "8px !important",
        height: "46px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "110px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '0px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },

    approveLoader: {
        background: `${blue} !important`,
        borderRadius: "8px !important",
        height: "40px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "110px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '0px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },

    reopenBtn: {
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnTxtBlack.shade2} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid ${btnBgGrey.shade2} !important`,
        minWidth: "192px !important",
        height: "42px !important",
        variant: "outlined",
    },
    outlineBlueAuto: {
        height: '42px !important',
        minWidth: '180px !important',
        font: '16px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        borderRadius: '8px !important',
        fontWeight: `700 !important`,
        padding: "12px, 191.52px, 12px, 191.92px !important",
        cursor: 'pointer !important',
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
        "@media (min-width: 300px) and (max-width: 1420px)": {
            minWidth: '130px !important',
            font: '13px Averta !important',
            fontWeight: `700 !important`,
        }
    },

    outlineBlueAutoAdd: {
        height: '42px !important',
        minWidth: '76px !important',
        font: '18px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        borderRadius: '4px !important',
        fontWeight: `500 !important`,
        padding: "4px 20px !important",
        cursor: 'pointer !important',
        // '&:hover': {
        //     background: `${blue} !important`,
        //     color: '#FFFFFF !important'
        // },
    },

    saveBtnSmall: {
        height: "42px !important",
    },
    saveLarge: {
        height: "52px !important",
    },
    disablebutton: {
        height: '36px !important',
        font: '14px Averta !important',
        background: `#EAECF0 !important`,
        // border: `1px solid ${lightGrey} !important`,
        textTransform: 'capitalize !important',
        width: '80px',
        cursor: 'not-allowed !important',
        color: '#FFFFFF !important',
        // borderRadius: '8px !important',
        fontWeight: `${600} !important`,
    },
    popupBack: {
        font: "15px Averta !important",
        color: `${blue} !important`,
        textTransform: "none !important",
        border: `1px solid ${blue} !important`,
        borderRadius: "8px !important",
        width: '85px !important',
        height: "42px !important",
        fontWeight: `${500} !important`,
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },

    saveLoaderAutoWidth: {
        background: `${blue} !important`,
        borderRadius: "8px !important",
        height: "42px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "200px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    cancelOutline: {
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnBgRed.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid ${btnBgRed.shade4} !important`,
        minWidth: "95px !important",
        height: "40px !important",
        variant: "outlined",
        '&:hover': {
            background: `${btnBgRed.shade4} !important`,
            color: '#FFFFFF !important',
        },
    },
    cancelOutline18: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnBgRed.shade4} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid ${btnBgRed.shade4} !important`,
        minWidth: "108px !important",
        height: "42px !important",
        variant: "outlined",
        // '&:hover': {
        //     background: `${btnBgRed.shade4} !important`,
        //     color: '#FFFFFF !important',
        // },
    },
    cancelOutlineBlue: {
        font: "18px Averta !important",
        fontWeight: `${700} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: `#0C75EB !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #0C75EB !important`,
        minWidth: "95px !important",
        height: "42px !important",
        variant: "outlined",
        // '&:hover': {
        //     background: `${btnBgRed.shade4} !important`,
        //     color: '#FFFFFF !important',
        // },
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${700} !important`,
        }
    },

    approveBtn: {
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "95px !important",
        height: "40px !important",
        variant: "outlined",
        '&:hover': {
            // background: `${btnTxtWhite} !important`,
            // border: `1px solid ${blue} !important`,
            // color: `${blue} !important`,
        },
    },

    blueOutlineBtn: {
        height: '40px !important',
        font: '14px Averta !important',
        color: `${blue} !important`,
        stroke: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '182px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        // '&:hover': {
        //     background: `${blue} !important`,
        //     color: '#FFFFFF !important',
        //     stroke: '#FFFFFF !important',
        // },
    },

    sendBtn: {
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "157px !important",
        height: "40px !important",
        variant: "outlined",
    },


    blueBtnSave: {
        font: "18px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "88px !important",
        height: "42px !important",
        variant: "outlined",
        fontWeight: '500px !important',
    },


    blackCancelBtn: {
        font: "16px Averta !important",
        color: `${lightGreyText} !important`,
        textTransform: "none !important",
        border: `1px solid ${grey} !important`,
        borderRadius: "8px !important",
        width: "100px !important",
        height: "42px !important",
        background: '#FFFFFF !important',
        variant: "outlined",
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        },
    },

    selectButton: {
        border: '1px solid #C7CCD3 !important',
        borderRadius: '4px !important',
        textTransform: "none !important",
        height: '30px !important',
        minWidth: '155px !important',
        color: '#737373 !important',
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        justifyContent: 'center !important',
        "&:hover": {
            background: '#FFFF !important',
        }
    },
    closeBtn: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` #171717 !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #525167 !important`,
        // border: 1px solid #525167
        minWidth: "84px !important",
        height: "35px !important",
        variant: "outlined",
        '&:hover': {
            border: `1px solid #FFFFFF !important`,
            background: `${btnBgRed.shade4} !important`,
            color: '#FFFFFF !important',
        },
    },

    addComment: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "138px !important",
        height: "35px !important",
        variant: "outlined",
    },

    //Popup cancel button with red border and red text
    popupCancel1: {
        font: "14px Averta !important",
        color: `${btnBgRed.shade5} !important`,
        textTransform: "none !important",
        border: `1px solid ${btnBgRed.shade5} !important`,
        borderRadius: "8px !important",
        width: '100px !important',
        height: "36px !important",
        fontWeight: `${700} !important`,
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    //OffBoard Button
    offBoardButton: {
        background: `${orange} !important`,
        borderRadius: "50px !important",
        // shadow: "0px 2px 24px -4px #919EAB1F , 0px 0px 2px 0px #919EAB4D ",
        boxShadow: '0px 0px 10px rgba(247, 159, 45, 2)', // Modify the shadow properties as needed
        height: "48px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "300px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },






    placementDisable: {
        font: "14px Averta !important",
        textTransform: "capitalize !important",
        height: "28px",
        width: "100px",
        padding: "0px 18px !important",
        color: `${btnBgGrey.shade7} !important`,
        border: `1px solid ${btnBgGrey.shade7} !important`
    },
    offBoardDisable: {
        font: "16px Averta !important",
        textTransform: "capitalize !important",
        height: "42px",
        width: "100px",
        padding: "0px 18px !important",
        color: `${btnBgGrey.shade7} !important`,
        border: `1px solid ${btnBgGrey.shade7} !important`,
        fontWeight: `${600} !important`,
        borderRadius: '8px !important'
    },
    smallBlueOutlineBtn: {
        font: "15px Averta !important",
        color: `${blue} !important`,
        textTransform: "none !important",
        border: `1px solid ${blue} !important`,
        borderRadius: "8px !important",
        minWidth: "85px !important",
        height: "42px !important",
        fontWeight: '500px !important',
        background: '#FFFFFF !important',
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
        '&:disabled': {
            opacity: '0.8'
        }
    },
    addHeighDisabletButton: {
        font: "16px Averta !important",
        background: `${btnBgGrey.shade1} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "150px !important",
        height: "53px !important",
        variant: "outlined",
        cursor: 'not-allowed !important'
    },
    disableSaveBtn: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnBgGrey.shade1} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "121px !important",
        height: "48px !important",
        variant: "outlined"
    },

    saveLoadersmall: {
        width: "120px !important",
        height: "42px !important"
    },

    largeCancelText: {
        fontSize: " 18px !important"
    },

    yesNoBtn: {
        width: "80px !important",
    },

    outlineBlueMedium: {
        height: '42px !important',
        font: '18px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '120px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },

    //organization config add new button styles
    blueHoverBtn: {
        width: '100%',
        textTransform: "none !important",
        font: "14px Averta !important",
        fontWeight: "600 !important",
        color: `${blue} !important`,
        background: "#F5F9FF !important",
        borderRadius: "8px !important",
        height: "33px !important",
        "&:hover": {
            background: "#d1e1ff !important"
        }
    },

    //Configurations
    deleteButton: {
        background: `#F85036 !important`,
        borderRadius: "8px !important",
        height: "45px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        color: "#FFFFFF !important",
        minWidth: "128px !important",
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
            margin: '10px !important'
        },
        '&:hover': {
            cursor: "pointer",
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    closeButton: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` #0C75EB !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #0C75EB !important`,
        minWidth: "70px !important",
        height: "45px !important",
        variant: "outlined",
        '&:hover': {
            border: `1px solid #FFFFFF !important`,
            background: `${btnBgRed.shade4} !important`,
            color: '#FFFFFF !important',
        },
    },
    closeButtonMivi: {
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` #0C75EB !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #0C75EB !important`,
        minWidth: "129px !important",
        height: "45px !important",
        variant: "outlined",
    },

    saveVerySmall: {
        font: "15px Averta !important",
        height: '40px !important',
        color: '#FFFFFF !important',
        textTransform: 'capitalize !important',
        minWidth: '80px !important',
        borderRadius: '8px !important',
        background: `${blue} !important`,
        fontWeight: "400",
        width: "95px",
        '&:hover': {
            border: `1px solid ${blue} !important`,
            backgroundColor: '#FFFFFF !important',
            color: `${blue} !important`
        }
    },
    //configuration
    addButtoConfig: {
        // height: '42px !important',
        // font: '18px Averta !important',
        color: `${blue} !important`,
        // border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        background: '#F5F9FF !important',
        // width: '120px',
        borderRadius: '8px !important',
        // fontWeight: `${600} !important`,
        '&:hover': {
            background: '#d3ddf5 !important',
            // color: '#FFFFFF !important'
        },
    },

    redHover: {
        '&:hover': {
            border: `1px solid ${btnBgRed.shade4} !important`,
            background: `${btnBgRed.shade4} !important`,
            color: '#FFFFFF !important',
        },
    },
    DashboardAdd: {
        height: '38px !important',
        font: '14px Averta !important',
        color: `${btnTxtBlue.shade2} !important`,
        border: `1px solid #DDE4F0 !important`,
        textTransform: 'capitalize !important',
        width: '120px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        background: `${btnTxtBlue.shade3} !important`
    },
    //onboard changed button style
    saveNcontinue: {
        font: "16px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "156px !important",
        height: "42px !important",
        fontWeight: `${600} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`
        },
        '&:disabled': {
            opacity: '0.8 !important'
        }
    },
    saveNcontinueSmall: {
        font: "16px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        // width: "93px !important",
        padding: '0px 25px !important',
        height: "42px !important",
        fontWeight: `${600} !important`,
        '&:disabled': {
            opacity: '0.8'
        }
    },
    cancelText: {
        font: "15px Averta !important",
        background: `#FFFFFF !important`,
        color: `${btnStroke.grey} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "42px !important",
        width: '100% !important',
        variant: "outlined",
        fontWeight: '500px !important',
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    borderCancel: {
        font: "14px Averta !important",
        background: `#FFFFFF !important`,
        color: `${btnStroke.grey} !important`,
        border: `1px solid ${btnStroke.grey}`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "42px !important",
        width: '100% !important',
        fontWeight: `${500} !important`,
        variant: "outlined",
        "&:hover": {
            // transform: "scale3d(1.05, 1.05, 1)",
            background: `#FFFFFF !important`,
        }
    },
    bluesmallHeight: {
        font: "14px Averta !important",
        textTransform: "capitalize !important",
        height: "30px",
        width: "120px",
        padding: "0px 18px 2px 18px !important",
        background: `${blue} !important`,
        color: '#fff !important',
    },

    outlineRedCancel: {
        height: '42px !important',
        font: '18px Averta !important',
        color: '#E51A1A !important',
        border: '1px solid #E51A1A !important',
        width: '103px',
        borderRadius: '8px !important',
        fontWeight: `${500} !important`,
        textTransform: 'none !important'
    },

    saveExtraSmall: {
        height: '42px !important',
        font: '18px Averta !important',
        backgroundColor: '#0C75EB !important',
        width: '103px',
        borderRadius: '8px !important',
        fontWeight: `${500} !important`,
        textTransform: 'none !important',
        color: '#FFFFFF !important'
    },

    scrollBtn: {
        height: '42px !important',
        font: '16px Averta !important',
        backgroundColor: '#0C75EB !important',
        width: '110px',
        borderRadius: '8px !important',
        fontWeight: `${700} !important`,
        textTransform: 'none !important',
        color: '#FFFFFF !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${700} !important`,
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },

    addTitleBtn: {
        height: '33px',
        width: '100%',
        textTransform: 'none !important',
        font: '14px Averta !important',
        color: '#0C75EB !important',
        fontWeight: '600 !important',
        backgroundColor: '#F5F9FF !important',
        "&:hover": {
            background: "#d1e1ff !important"
        }
    },
    addTitleBtnDisable: {
        height: '33px',
        width: '100%',
        textTransform: 'none !important',
        font: '14px Averta !important',
        color: `${btnBgGrey.shade4} !important`,
        border: `1px solid ${btnBgGrey.shade4} !important`,
        fontWeight: '500 !important',
        backgroundColor: '#fff !important',
        cursor: 'default !important',
    },
    noButton: {
        height: '44px !important',
        width: '61px !important',
        font: '16px Averta !important',
        color: `#0C75EB !important`,
        border: `1px solid #0C75EB !important`,
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontWeight: `${400} !important`,
    },

    // deleteButton: {
    //     height: '44px !important',
    //     width: '120px !important',
    //     font: '16px Averta !important',
    //     color: `#FFFFFF !important`,
    //     textTransform: 'none !important',
    //     borderRadius: '8px !important',
    //     fontWeight: `${400} !important`,
    //     backgroundColor: '#F85036 !important'
    // }
    cancelLg: {
        font: "14px Averta !important",
        fontWeight: `${500} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: ` ${btnBgGrey.shade6} !important`,
        textTransform: "none !important",
        borderRadius: "6px !important",
        minWidth: "220px !important",
        height: "38px !important",
        variant: "outlined",
    },
    blackCancel1: {
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        background: `#FFFFFF  !important`,
        border: `1px solid ${btnBgGrey.shade6} !important`,
        color: `${btnTxtBlack.shade1}  !important`,
        textTransform: "none !important",
        borderRadius: "10px !important",
        minWidth: "108px !important",
        height: "42px !important",
        variant: "outlined",
    },

    createLg: {
        font: "14px Averta !important",
        fontWeight: `${500} !important`,
        background: `#0C75EB !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "6px !important",
        padding: '4px, 24px, 4px, 24px !important',
        height: "38px !important",
        variant: "outlined",
        width: '220px !important',
    },


    // Reminders Button styles
    lightblueBtn: {
        font: "14px Averta !important",
        fontWeight: `${500} !important`,
        background: `#318CF1 !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "4px !important",
        padding: '4px, 24px, 4px, 24px !important',
        height: "25px !important",
        variant: "outlined",
        width: '103px !important',
    },

    //activity track
    activity: {
        font: "15px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "156px !important",
        height: "35px !important",
        "&:hover": {
            background: "#ffffff !important",
            border: '1px solid #000000',
            color: '#000000 !important',
        }
    },
    cancelActivity: {
        font: "14px Averta !important",
        fontWeight: `${400} !important`,
        // background: ` #000000 !important`,
        color: '#000000 !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid  #000000  !important`,
        minWidth: "91px !important",
        height: "35px !important",
        variant: "outlined",
        '&:hover': {
            background: ` #000000 !important`,
            color: '#FFFFFF !important',
        },
    },

    addNewFull: {
        height: '33px !important',
        width: "100%",
        backgroundColor: "#E8F3FE !important",
        color: ` ${blue} !important`,
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    disableAddNewFull: {
        height: '33px !important',
        width: "100%",
        backgroundColor: "#FAFAFA !important",
        color: ` #C7CCD3 !important`,
        font: "16px Averta !important",
        fontWeight: `${600} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        variant: "outlined",
        border: "none !important"

    },
    blueBorderOutlined: {
        height: '43px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        minWidth: '141px !important',
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "14px Averta !important",
        fontWeight: `${500} !important`,
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${500} !important`,
        }
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    blueNext: {
        font: "14px Averta !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "4px !important",
        minWidth: "141px !important",
        height: "43px !important",
        // variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
    redPayroll: {
        background: `#fff !important`,
        borderRadius: "50px !important",
        height: "48px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${700} !important`,
        color: "#EA5B45 !important",
        border: `1px solid #EA5B45 !important`,
        minWidth: "300px !important",
    },
    disablePayroll: {
        background: `#EAECF0 !important`,
        borderRadius: "50px !important",
        height: "48px",
        padding: "0px 8px !important",
        opacity: 1,
        textTransform: "capitalize !important",
        font: "18px Averta !important",
        fontWeight: `${700} !important`,
        color: "#C7CCD3 !important",
        minWidth: "300px !important",
        cursor: 'default !important'
    },
    rehire: {
        font: "16px Averta !important",
        height: "35px",
        width: "120px",
        padding: "0px 18px 2px 18px !important",
        color: `${blue} !important`,
        fontWeight: `${700} !important`,
        background: '#fff !important',
        border: `1px solid ${blue} !important`,
        textTransform: 'none !important',
        borderRadius: '8px !important'
    },
    cancelOutlineAverta: {
        border: '1px solid #525252 !important',
        width: '126px !important',
        height: '48px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "18px Averta !important",
        fontWeight: `${600} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#171717 !important',
        textTransform: 'Capitalize !important'
    },
    cancelOutlineRed: {
        border: '1.2px solid #E51A1A !important',
        width: '126px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#E51A1A !important',
        textTransform: 'Capitalize !important'
    },
    finishFilledAverta: {
        border: '1px solid #0C75EB !important',
        width: '126px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#0C75EB !important',
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#FFFFFF !important',
        textTransform: 'Capitalize !important',
        '&:hover': {
            transform: "scale3d(1.05, 1.05, 1)",
        },
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        },
        '&:disabled': {
            opacity: '0.8'
        }
    },
    finishFilledAvertaLargeWidth: {
        border: '1px solid #0C75EB !important',
        width: '278px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#0C75EB !important',
        font: "16px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#FFFFFF !important',
        textTransform: 'Capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '14px Averta !important',
            fontWeight: `${600} !important`,
        }
    },
    finishLater: {
        width: '126px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#525252 !important',
        textTransform: 'Capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
            fontWeight: `${600} !important`,
        }
    },
    redBackground: {
        width: '126px !important',
        height: '44px !important',
        border: '1px solid #E51A1A !important',
        background: '#E51A1A !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#fff !important',
        textTransform: 'Capitalize !important',
        '&:disabled': {
            opacity: '0.8'
        }
    },
    redBackgroundWidth: {
        height: '44px !important',
        border: '1px solid #E51A1A !important',
        background: '#E51A1A !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#fff !important',
        textTransform: 'Capitalize !important'
    },
    smallBlueOutline: {
        border: '1px solid #0C75EB !important',
        width: '85px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#fff !important',
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#0C75EB !important',
        textTransform: 'Capitalize !important'
    },
    smallBlackOutline: {
        border: '1px solid #262626 !important',
        width: '85px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#fff !important',
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#262626 !important',
        textTransform: 'Capitalize !important'
    },
    smallBlue: {
        border: '1px solid #0C75EB !important',
        width: '85px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#0C75EB !important',
        font: "16px Averta !important",
        fontWeight: `${400} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#fff !important',
        textTransform: 'Capitalize !important',
        '&:disabled': {
            opacity: '0.8'
        }
    },
    addButtonTooltip: {
        font: "12px Averta !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "100% !important",
        height: "30px !important",
        fontWeight: `${600} !important`,
        variant: "outlined",
    },
    redBorder: {
        border: '1.2px solid #F70D0D !important',
        width: '100% !important',
        height: '42px !important',
        padding: '14px 17px 14px 17px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#F70D0D !important',
        textTransform: 'Capitalize !important',
        '&:hover': {
            background: '#FFFFFF !important',
            // transform: "scale3d(1.05, 1.05, 1)",
        }
    },
    finishFilledAvertaLargeWidth350: {
        border: '1px solid #0C75EB !important',
        width: '350px !important',
        height: '44px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        background: '#0C75EB !important',
        font: "16px Averta !important",
        fontWeight: `${700} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#FFFFFF !important',
        textTransform: 'Capitalize !important'
    },
    clearAllRed: {
        width: '126px !important',
        height: '48px !important',
        padding: '14px 20px 14px 20px !important',
        gap: '10px !important',
        borderRadius: '8px !important',
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        lineHeight: '19px !important',
        letterSpacing: '0em !important',
        color: '#F70D0D !important',
        textTransform: 'Capitalize !important',
        '&:disabled': {
            opacity: '0.8'
        }
    },
    blackCancel100: {
        font: "16px Averta !important",
        color: `${lightGreyText} !important`,
        textTransform: "none !important",
        border: `1px solid ${grey} !important`,
        borderRadius: "8px !important",
        width: "100% !important",
        height: "42px !important",
        background: '#FFFFFF !important',
        variant: "outlined",
        fontWeight: `${600} !important`,
    },
    dropdownAdd: {
        font: "14px Averta !important",
        background: `#F5F9FF !important`,
        color: `${blue} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "33px !important",
        width: '100% !important',
        fontWeight: `${600} !important`,
        variant: "outlined",
        cursor: 'pointer'
    },
    blueboldCancel: {
        height: '43px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        padding: '0px 25px !important'
    },
    whiteboldButton: {
        border: '1px solid #0C75EB !important',
        height: '44px !important',
        padding: '0px 25px !important',
        borderRadius: '8px !important',
        background: `${blue} !important`,
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        color: '#FFFFFF !important',
        textTransform: 'capitalize !important',
    },
    redboldCancel: {
        height: '43px !important',
        background: `#FFFFFF !important`,
        color: `${cancelRed} !important`,
        textTransform: 'capitalize !important',
        borderRadius: '8px !important',
        border: `1px solid ${cancelRed} !important`,
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        padding: '0px 25px !important'
    },
    blueboldCancelClient: {
        height: '43px !important',
        background: `${blue} !important`,
        color: `#fff !important`,
        textTransform: 'capitalize !important',
        borderRadius: '8px !important',
        border: `none !important`,
        font: "14px Averta !important",
        fontWeight: `${700} !important`,
        minWidth: '110px !important'
    },
    dashboardUpgrade: {
        height: '32px !important',
        font: '14px Averta !important',
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '111px',
        borderRadius: '4px !important',
        fontWeight: `${600} !important`,
        background: '#012241 !important'
    },
    dashboardrenew: {
        font: "14px Averta !important",
        fontWeight: `${600} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "4px !important",
        minWidth: "98px !important",
        height: "32px !important",
        variant: "outlined",
    },
    analyticsView: {
        height: '44px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        borderRadius: '8px !important',
        border: `1px solid ${blue} !important`,
        font: "16px Averta !important",
        fontWeight: `${700} !important`,
        padding: '0px 22px !important',
        textTransform: 'capitalize !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '13px Averta !important',
            fontWeight: `${700} !important`
        },
        [useTheme().breakpoints.down('lg')]: {
            font: '8px Averta !important',
            fontWeight: `${700} !important`
        }
    },
    save14: {
        font: "14px Averta !important",
        height: '40px !important',
        color: '#FFFFFF !important',
        textTransform: 'capitalize !important',
        minWidth: '120px !important',
        borderRadius: '8px !important',
        background: `${blue} !important`,
        fontWeight: "400",
        width: "150px"
    },
    smallBorderBlue: {
        font: "16px Averta !important",
        fontWeight: `${700} !important`,
        background: ` ${btnTxtWhite} !important`,
        color: `#0C75EB !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        border: `1px solid #0C75EB !important`,
        minWidth: "95px !important",
        height: "42px !important",
        variant: "outlined",
        '&:disabled': {
            opacity: '0.8'
        }
    },
    saveBtn400: {
        font: "16x Averta !important",
        fontWeight: `${400} !important`,
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        width: "89px !important",
        height: "42px !important",
        variant: "outlined"
    },
    lightBluesmall: {
        font: "14px Averta !important",
        background: `#F5F9FF !important`,
        color: `${blue} !important`,
        textTransform: "none !important",
        borderRadius: "8px !important",
        height: "35px !important",
        width: '120px !important',
        fontWeight: `${600} !important`,
        variant: "outlined",
        "&:hover": {
            // transform: "scale3d(1.05, 1.05, 1)",
            background: `${btnBgBlue.shade3} !important`
        }
    },
    manageSubscription: {
        background: "#0C75EB !important", color: "white !important", textTransform: 'capitalize !important', font: '14px Averta !important', fontWeight: '600 !important', width: '188px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: '12px Averta !important',
        }
    },
    upgradePlan: {
        font: "14px Averta !important",
        background: `${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "119px !important",
        height: "44px !important",
        variant: "outlined",
        fontWeight: '600px !important',
        [useTheme().breakpoints.down('xl')]: {
            font: "12px Averta !important",
            fontWeight: '600px !important',
        }
    },
    CreditsButton: {
        height: '54px !important',
        font: '14px Averta !important',
        textTransform: 'capitalize !important',
        width: '183px',
        borderRadius: '8px !important',
        fontWeight: `${600} !important`,
        backgroundImage: 'linear-gradient(96.25deg, #E88CFF -1.31%, #1A30FF 196.53%)',
        '&:hover': {
            backgroundImage: 'linear-gradient(263.26deg, #E88CFF -27.81%, #1A30FF 351.3%)',
        },
    },
    viewPlans: {
        height: '26px !important',
        font: '12px Averta !important',
        textTransform: 'capitalize !important',
        width: '171px !important',
        color: '#fff !important',
        background: '#279475 !important',
        borderRadius: '39px !important',
        fontWeight: `${600} !important`,
    },
    guidingEdit: {
        font: "12px Averta !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "4px !important",
        minWidth: "81px !important",
        height: "32px !important",
        fontWeight: `${600} !important`
        // variant: "outlined",
        // "&:hover": {
        //     transform: "scale3d(1.05, 1.05, 1)",
        // }
    },
}));

export default ButtonStyles;

