import React, { useEffect, useState } from 'react';
import UserProfileStyles from '../UserProfileStyles'; // eslint-disable-next-line
import { Box, Grid, Stack } from '@mui/material';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi'; // eslint-disable-next-line
import { BlackToolTip, addErrorMsg, addLoader, addSuccessMsg, dateFormat, openDocumentInNewtab, removeLoader } from '../../../../utils/utils'; // eslint-disable-next-line
import Button from '../../../../components/customButton/Button';
import LocalStorage from '../../../../utils/LocalStorage';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import FileSvg from '../../../../assets/svg/File.svg'
import noDataFound from '../../../../assets/svg/NoDataFoundIcon.svg'
import FileInput from '../../../../components/muiFileInput/FileInput';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select'
import Date from '../../../../components/datePicker/Date';
import moment from 'moment';
import CommonApi from '../../../../apis/CommonApi';
import { isValid, validate_emptyField, validate_passport_optional } from '../../../../components/Validation';
import LoadingScreen from './LoadingScreen';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Sure from '../../../../assets/svg/placementPop.svg'
import Userplus from '../../../../assets/svg/plus.svg';
import changesSent from '../../../../assets/svg/changesSent.svg'

export default function PassportView(props) {
    // eslint-disable-next-line
    const classes = UserProfileStyles(); // eslint-disable-next-line
    const [tab, setTab] = useState(0);
    // eslint-disable-next-line
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        setCurrent(event)
    };
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [formLoader, setformLoader] = useState(false) // eslint-disable-next-line
    const [list, setList] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listi94, setListi94] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [listWork, setListWork] = useState({
        documents: []
    }); // eslint-disable-next-line
    const [current, setCurrent] = useState("education");
    const [employee, setEmployee] = useState(props.employee)
    const [formOpen, setformOpen] = useState(false)
    const [countriesList, setCountriesList] = useState([]);
    const [clearPopup1, setclearPopup1] = useState(false);
    const [clearPopup2, setclearPopup2] = useState(false);
    const [clearPopup3, setclearPopup3] = useState(false);
    const [clearPopup, setclearPopup] = useState(false);// eslint-disable-next-line
    const [error, setError] = useState({
        issued_country_id: "",
        valid_from: "",
        valid_till: "",
        document_number: "",
        documents: [
            {
                id: "",
                new_document_id: "",
                name: ""
            }
        ],
        clear_all: false
    });
    const [state, setState] = useState(
        {
            issued_country_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    name: ""
                }
            ],
            documents_deleted_ids: [],
            clear_all: false
        }
    );

    useEffect(() => {
        setEmployee(props.employee)
        setCurrent(props.documentPosition) // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        if (props.documentPosition === 'work') {
            getPassport();
            getCountriesList();
        }
        // eslint-disable-next-line
    }, [props])

    const getCountriesList = () => {
        let search = "";
        CommonApi.getCountryList(search).then((response) => {
            if (response.data.statusCode == 1003) {
                setCountriesList(response.data.data);
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const getPassport = () => {
        setLoading(true)
        setError({
            ...error,
            document_number: ''
        })
        // return console.log(EmployeeAPI.getPassports(), "  222");
        EmployeesApi.getPassportsConsultant(props.employee.id).then((response) => {
            setTimeout(() => {
                setLoading(false)
                if (response.data.statusCode == 1003) {
                    if (response.data.data.length > 0) {
                        setList(response.data.data);
                        if (response.data.data[0].documents.length === 0) {
                            let data = response.data.data[0]
                            data.documents = [
                                {
                                    id: "",
                                    new_document_id: "",
                                    name: ""
                                }
                            ]
                            setState({ ...data })
                        }
                        else {
                            setState(response.data.data[0])
                        }

                    }
                    else {
                        setList({
                            issued_country_id: "",
                            valid_from: "",
                            valid_till: "",
                            document_number: "",
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    name: ""
                                }
                            ],
                            documents_deleted_ids: [],
                            clear_all: false,
                            id: ''
                        })
                        setState({
                            issued_country_id: "",
                            valid_from: "",
                            valid_till: "",
                            document_number: "",
                            documents: [
                                {
                                    id: "",
                                    new_document_id: "",
                                    name: ""
                                }
                            ],
                            clear_all: false,
                            documents_deleted_ids: [],
                            id: ''
                        })
                    }

                } else {
                    addErrorMsg(response.data.message);
                    setLoading(false);
                }
            }, 400)
        });
    }

    const openInNewTab = (args, documentUrl) => {
        if (args.aws_s3_status == 0 || args.aws_s3_status == false) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        } else if (args.aws_s3_status == undefined || args.aws_s3_status == undefined) {
            const newWindow = window.open(documentUrl, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        else {
            openDocumentInNewtab(args.document_key, args.document_path)
        }
    }

    const uploadDocs = (value) => {
        addLoader()
        const formData = new FormData();
        formData.append("files", value.target.files[0]);
        formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
        console.log(value.target.files[0].type, '(value.target.files[0].type')
        CommonApi
            .documentUpload("passport-document", formData, LocalStorage.getAccessToken())
            .then((response) => {
                if (response.data.statusCode == 1003) {
                    let docInfo = response.data.data
                    const data = {
                        id: response.data.data.id,
                        url: response.data.data.document_url
                    }
                    CommonApi.ocrDocumentUpload('passport-data', data).then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader()
                            state.document_number = res.data.data.passport_number ? res.data.data.passport_number : state.document_number
                            state.valid_from = res.data.data.date_of_issue ? moment(res.data.data.date_of_issue, 'DD/MM/YYYY').format(dateFormat()) : state.valid_from
                            state.valid_till = res.data.data.date_of_expiry ? moment(res.data.data.date_of_expiry, 'DD/MM/YYYY').format(dateFormat()) : state.valid_till
                            state.documents[0].new_document_id = response.data.data.id
                            state.documents[0].document_url = response.data.data.document_url
                            state.documents[0].name = value.target.files[0].name
                            state.issued_country_id = response.data.data.country_code ? response.data.data.country_code == "IND" ? 1 : state.issued_country_id : state.issued_country_id
                            state.clear_all = false
                            setState({ ...state })
                            handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                        } else {
                            removeLoader()
                            state.documents[0].new_document_id = response.data.data.id
                            state.documents[0].document_url = response.data.data.document_url
                            state.documents[0].name = value.target.files[0].name
                            state.clear_all = false
                            setState({ ...state })
                            handleDocumentValidations({ name: value.target.name, value: docInfo.id });
                        }
                    })
                } else {

                    addErrorMsg(response.data.message);
                    removeLoader()
                }
            });
    };

    const handleChange = (e) => {
        state.clear_all = false
        setState({
            ...state, [e.target.name]: e.target.value
        })
        handleValidations(e.target);
    };

    const handleChangeDate = (e, name) => {
        state.clear_all = false
        let data = { name: name, value: e == null ? '' : moment(e.$d).format(dateFormat()) }
        if (data.name == 'valid_from') {
            state.valid_till = ''
        }
        setState({ ...state, [data.name]: data.value })
        handleValidations(data);
    };

    const handleValidations = (input) => {
        // let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "document_number":
                err.document_number = validate_passport_optional(input.value);
                break;
            case "valid_till":
                err.valid_till = moment(input.value).isBefore(moment(state.valid_from)) ? 'End date should be greater than start date' : ''
                break;
            default:
                break;
        }
        setError(err);
    };

    const handleDocumentValidations = (input) => {
        let err = error;
        switch (input.name) {
            case "documents":
                err.documents = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const deleteDoc = () => {
        state.documents[0].new_document_id = ''
        state.documents[0].document_url = ''
        state.documents[0].name = ''
        state.clear_all = false
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({ ...state })
    }

    const clearAll = () => {
        if (state.documents[0].id != '') {
            state.documents_deleted_ids = [state.documents[0].id]
            state.documents[0].id = ''
        }
        setState({
            ...state,
            issued_country_id: "",
            valid_from: "",
            valid_till: "",
            document_number: "",
            clear_all: true,
            documents: [
                {
                    id: "",
                    new_document_id: "",
                    name: ""
                }
            ]
        })
        setError({
            ...error,
            document_number: ''
        })
        setclearPopup(false)
    }

    const submitForm = (args) => {
        let data = {
            document_id: state.documents[0].new_document_id,
            document_number: state.document_number,
            issue: state.issued_country_id,
            date_issue: state.valid_from,
            date_end: state.valid_till
        }
        if (error.document_number !== '' && isValid(data) == false) {
            return true
        }
        if (state.id !== '') {
            state['request_id'] = LocalStorage.uid();
            state['employee_id'] = props.employee.id;
            if (isValid(data)) {
                state['clear_all'] = true;
            }
            if (state.clear_all == false && state.document_number == '') {
                error.document_number = 'This field is required'
                setError({ ...error })
            }
            else {
                state.entity = 'passport'
                if (args) {
                    setclearPopup2(true)
                }
                else {
                    EmployeesApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                        if (res.data.statusCode === 1003) {
                            addSuccessMsg(`Changes Saved`);
                            setclearPopup1(true)
                            setformOpen(false);
                            getPassport()
                            props.setformEdit(false)
                        } else {
                            addErrorMsg(res.data.message);
                        }
                    })
                }

            }
        }
        else {
            state['request_id'] = LocalStorage.uid()
            state['employee_id'] = props.employee.id
            if (isValid(data)) {
                setformOpen(false);
                return true
            }
            else {
                if (state.document_number === '') {
                    error.document_number = 'This field is required'
                    setError({ ...error })
                }
                else {
                    state.entity = 'passport'
                    if (args) {
                        setclearPopup2(true)
                    }
                    else {
                        EmployeesApi.employeeUpdateConsultant(state, LocalStorage.getAccessToken()).then((res) => {
                            if (res.data.statusCode === 1003) {
                                addSuccessMsg(`Passport Added`);
                                setformOpen(false);
                                setclearPopup1(true)
                                getPassport()
                                props.setformEdit(false)
                            } else {
                                addErrorMsg(res.data.message);
                            }
                        })
                    }
                }
            }
        }
    }

    const editDetails = () => {
        if (props.employee.pending_requests.passport) {
            setclearPopup3(true)
        }
        else {
            setformOpen(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container>
            {
                loading ?
                    <Grid container>
                        <LoadingScreen />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item lg={11} md={11} sm={11} xs={11}>
                            <Box p={1} pl={2} pt={2}>
                                <Text largeBlue>
                                    Passport
                                </Text>
                            </Box>
                        </Grid>
                        {
                            formOpen ? '' :
                                employee && employee.is_off_boarding_initiated === false && list.length > 0 ?
                                    <Grid item lg={1} md={1} sm={1} xs={1}>
                                        <Box p={1} pr={0}>
                                            <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                                        </Box>
                                    </Grid>
                                    : ''
                        }
                        {
                            formOpen ? '' :
                                list.length > 0 ?
                                    <Grid item container lg={12}>
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Passport Number
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].document_number ? list[0].document_number : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Issued Country
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].country_name ? list[0].country_name : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Date of Issue
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_from ? list[0].valid_from : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Date of Expiry
                                                </Text>
                                                <Text pt={1} mediumBlack14>
                                                    {list[0].valid_till ? list[0].valid_till : '-'}
                                                </Text>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={3} md={4} sm={6} xs={12}>
                                            <Box p={1} pt={2} pl={2}>
                                                <Text mediumLabel>
                                                    Passport Document
                                                </Text>
                                                {list[0].documents.length > 0 && list[0].documents[0].name !== '' ?
                                                    <Stack direction={'row'} pt={1} sx={{ cursor: 'pointer' }} onClick={() => { openInNewTab(list[0].documents[0], list[0].documents[0].document_url) }}>
                                                        <img src={FileSvg} alt='img' style={{ paddingRight: '5px' }} />
                                                        <Text mediumBlue>
                                                            <BlackToolTip arrow placement='top' title={list[0].documents[0].name}>
                                                                {list[0].documents[0].name.slice(0, 16)} {list[0].documents[0].name.length > 16 ? '...' : ''}
                                                            </BlackToolTip>
                                                        </Text>
                                                    </Stack>
                                                    : '-'
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'}>
                                            <img src={noDataFound} alt='nodata' />
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text largeBlack>No Documents Uploaded</Text>
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>Upload Passport document for this employee by </Text>
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={1}>
                                            <Text mediumLabel sx={{ fontSize: '14px !important' }}>on the below button.</Text>
                                        </Grid>
                                        {
                                            employee.is_off_boarding_initiated === false &&
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} pt={3}>
                                                <Button finishFilledAvertaLargeWidth onClick={() => { editDetails() }}> <img src={Userplus} alt='plus' /> Add Passport Details</Button>
                                            </Grid>
                                        }
                                    </Grid>
                        }
                        {formOpen ?
                            <Grid container p={2} pl={0}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <FileInput
                                            name={"Passport"}
                                            FileName={state.documents[0].name}
                                            handleChange={uploadDocs}
                                            label={"Passport"}
                                            documentUrl={state.documents[0].document_url}
                                            handleDelete={() => deleteDoc()}
                                            actionState={state.documents[0].name ? 1 : ''}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'document_number',
                                                value: state.document_number,
                                                type: 'text',
                                                inputProps: { minLength: 8, maxLength: 14 }
                                            }}
                                            handleChange={handleChange}
                                            clientInput
                                            labelText={'Passport Number'}
                                            error={error.document_number}
                                        />
                                        <Text red>{error.document_number}</Text>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Select
                                            options={countriesList}
                                            name='issued_country_id'
                                            value={state.issued_country_id}
                                            onChange={handleChange}
                                            label={'Issued Country'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Date
                                            labelText={"Date Of Issue"}
                                            name={"valid_from"}
                                            maxDate={moment().format(dateFormat())}
                                            value={state.valid_from}
                                            onChange={(e) => handleChangeDate(e, "valid_from")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <Box p={1} pt={2} pl={2}>
                                        <Date
                                            labelText={"Date Of Expiry"}
                                            name={"valid_till"}
                                            value={state.valid_till}
                                            minDate={state.valid_from}
                                            disabled={state.valid_from == ''}
                                            onChange={(e) => handleChangeDate(e, "valid_till")}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item container lg={12} md={12} sm={12} xs={12} p={2} pl={2}>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3}>
                                        <Button cancelOutlineAverta onClick={() => { setformOpen(false); getPassport(); props.setformEdit(false) }}>Cancel</Button>
                                    </Grid>
                                    <Grid item container lg={6} md={6} sm={6} xs={12} pt={3} justifyContent={'flex-end'}>
                                        <Stack direction={'row'}>
                                            <Button clearAllRed onClick={() => { setclearPopup(true) }}>Clear all</Button>
                                            <Button smallBlue onClick={() => { submitForm('popup') }}>Save</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }
                        <Grid container>
                            <Box height={'5vh'} />
                        </Grid>
                    </Grid>
            }
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to clear all the information</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledAverta onClick={() => { clearAll() }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup2} setOpenPopup={setclearPopup2} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to make this changes?</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button blueBorderOutlined onClick={() => { setclearPopup2(false) }}>
                                Cancel
                            </Button>
                            <Button finishFilledAverta onClick={() => { submitForm(); setclearPopup2(false); }} >
                                Yes
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup1} setOpenPopup={setclearPopup1} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={changesSent} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Changes Sent For Approval</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>"Thank you for updating your details. Your changes</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>have been submitted for approval to your employer. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Once approved, the new details will be displayed here. </Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Click 'OK' to proceed."</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledAverta onClick={() => { setclearPopup1(false); setformOpen(false); props.setformEdit(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={clearPopup3} setOpenPopup={setclearPopup3} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledAverta onClick={() => { setclearPopup3(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>

    );
}