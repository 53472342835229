import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { CircularProgress as MuiCircularProgress } from '@mui/material';
import Text from '../customText/Text';
import blueEdit from '../../assets/client/blue-edit.svg';
import { makeStyles } from '@mui/styles';
import Button from '../customButton/Button';

const Styles = makeStyles(() => ({
    editIcon: {
        background: 'white', borderRadius: '50%', height: '20px', width: '20px', textAlign: 'center', alignItems: 'center', position: 'absolute', top: '75%', left: '87%', transform: 'translate(-50%, -50%)'
    }
}))

/* Custom Circular Progress */
function CircularProgress(props) {
    const classes = Styles();

    /**
     * Function is for adding circular progress bar based on value around the image 
     * ---------------------------------------------------------------------------
     * Decleration:-
     * ======
     * @parem value :- based on value showing progrees bar expected type number other wise it will took default
     * @parem size :- circular progress size expected type number other wise it will took default
     * @parem color : circular progress color expected type string 
     * @parem src :- image source placed inside circular progress
     * @parem imgWidth and @parem imgHeight :-
     * image width and height expected type `string` other wise it will took default
     * */
    const { value, size, color, src, imgWidth, imgHeight, thickness, margin, percentage, name, disable, avatarWidth, avatarHeight, edit, handleEdit, tooltipContent, dashboardContent, cursor } = props;

    const onInputClick = (event) => {
        event.target.value = ''
    }

    return (
        <div>
            {
                edit ?
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <Box position="relative" display="inline-flex" textAlign='center'>
                            <MuiCircularProgress variant="determinate" sx={{ color: { color } }} thickness={thickness ? thickness : 1.2} value={value ? value : 100} size={size ? size : 81} />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Tooltip arrow title={tooltipContent}>
                                    {
                                        src ?
                                            <Typography variant="caption" component="div" color="textSecondary">
                                                <img src={src} alt="icon" width={imgWidth ? imgWidth : "70px"} height={imgHeight ? imgHeight : "70px"} style={{ margin: margin ? margin : "7px 0px 0px 0px", borderRadius: '50%' }} />
                                            </Typography> :
                                            <Avatar sx={{ background: disable ? '#adacac' : '#e5b256', width: avatarWidth ? avatarWidth : '70px', height: avatarHeight ? avatarHeight : '70px' }} ><Typography sx={{ textTransform: 'capitalize' }}>{name}</Typography></Avatar>
                                    }
                                </Tooltip>
                            </Box>
                        </Box>
                        {
                            props.hideEdit === false ? ""
                                :
                                <Box className={classes.editIcon}>
                                    <Button sx={{ padding: '0px 0px 4px 1px !important', minWidth: '0px !important' }} component="label" >
                                        <input hidden type="file" onChange={handleEdit} onClick={onInputClick} />
                                        <img src={blueEdit} alt='edit' />
                                    </Button>
                                </Box>
                        }
                    </Box>
                    :
                    <Box position="relative" display="inline-flex" textAlign='center'>
                        <MuiCircularProgress variant="determinate" sx={{ color: { color } }} thickness={thickness ? thickness : 1.5} value={value ? value : 100} size={size ? size : 81} />
                        <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            // cursor={`default !important`}
                        >
                            <Tooltip arrow title={
                                <>
                                    <Text mediumWhite>Profile Completion {percentage ? `${percentage}%` : ''}</Text>
                                    {
                                        dashboardContent ? dashboardContent : ''
                                    }
                                </>
                            }>
                                {
                                    src ?
                                        <Typography variant="caption" component="div" color="textSecondary">
                                            <img src={src} alt="icon" width={imgWidth ? imgWidth : "70px"} height={imgHeight ? imgHeight : "70px"} style={{ margin: margin ? margin : "7px 0px 0px 0px", borderRadius: '50%', cursor: `${cursor ? cursor : 'default'}` }} />
                                        </Typography> :
                                        <Avatar sx={{ background: disable ? '#adacac' : '#e5b256', width: avatarWidth ? avatarWidth : '70px', height: avatarHeight ? avatarHeight : '70px', cursor: `${cursor ? cursor : 'default'} !important` }} ><Typography sx={{ textTransform: 'capitalize' }}>{name}</Typography></Avatar>
                                }
                            </Tooltip>
                        </Box>
                    </Box>
            }
        </div>
    );
}

export default CircularProgress;